import React, { useState, useEffect } from 'react';
import IconSearchLoupe from "../../assets/img/search-loupe.svg";
import AddPositionModal from "../../components/Modals/AddPositionModal";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";
import InfoModal from "../../components/Modals/InfoModal";
import Table from "../../components/ui/Table";
import { useSelector } from "react-redux";
import { getSelectedIncident } from "../../redux/slices/incidentsSlice";
import { incidents } from '../../api/index';
import {NotificationManager, NotificationContainer} from "react-notifications";

export const PermissionsAdmin = () => {
    const selectedIncident = useSelector(getSelectedIncident);

    const [addPositionModalOpen, setAddPositionModalOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [positions, setPositions] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [confirmDeleteModalOpenId, setConfirmDeleteModalOpenId] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [edit, setEdit] = useState(null);
    const [selectedPermission, setSelectedPermission] = useState(null);

    const fetchPositions = async() => {
        let res = await incidents.getIncidentPositions(selectedIncident.id);
        setPositions(res.data?.data?.positions);
        setTableData(res.data?.data?.positions);
    }

    const actionView = (value) => {
        setSelectedPermission(positions.find(item => +item.id === +value));
    }

    const actionEdit = (value) => {
        setSelectedPermission(positions.find(item => +item.id === +value));
        setEdit(true);
    }

    const actionDelete = async() => {
        if(confirmDeleteModalOpenId){
            try {
                await incidents.deleteIncidentPosition(selectedIncident.id, confirmDeleteModalOpenId);
                setConfirmDeleteModalOpenId(false);
                await fetchPositions();
                setInfoModalConfig({
                    icon: require('../../assets/img/updated-img.png'),
                    title: 'Deleted Success',
                    subtitle: 'John Doe user detail deleted successfully'
                });
                setInfoModalOpen(true);
            } catch (err) {
                console.error(err, 'error')
                NotificationManager.error("Something went wrong");
            }
        }
    }

    const [infoModalConfig, setInfoModalConfig] = useState({
        icon: false,
        title: '',
        subtitle: ''
    });

    useEffect(()=>{
        if(selectedIncident?.id || (selectedIncident?.id && !positions)){
            fetchPositions();
        }
    },[selectedIncident])

    return (
        <>
            <div className="bg-gray w-full flex justify-between items-center flex-wrap px-[29px] pt-[20px]">
                <div className="input-custom mb-[20px]" style={{ maxWidth: '300px', width: '100%' }}>
                    <form action="" className="form-search search-border h-[32px]">
                        <button type="submit" className="form-search__btn">
                            <img src={IconSearchLoupe} />
                        </button>
                        <input
                            type="search" className="form-search__input" placeholder="Search..."
                            value={searchString} onInput={(e)=>{ setSearchString(e.target.value); }}
                        />
                    </form>
                </div>
                <a
                    href="#" className="btn btn--primary btn--lg-2 rounded-[10px] mb-[20px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        setAddPositionModalOpen(true);
                    }}
                >
                    <span className="ico mr-[8px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.16699V15.8337" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16602 10H15.8327" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    Add Position
                </a>
            </div>
            <div className="dash-page-content-item">
                <div className="card-body mb-[24px]">
                    <Table 
                        initialData={positions}
                        setSortedData={setTableData}
                        tHead={[
                            {
                                content: 'User',
                                sortField: 'user',
                            },
                            {
                                content: 'Component',
                            },
                            {
                                content: 'Section',
                                sortField: 'section',
                            },
                            {
                                content: 'Positions',
                                sortField: 'positions',
                            },
                        ]}
                        tBody={
                            (tableData).filter(itemF => itemF?.user?.name.toLowerCase().includes(searchString.toLowerCase())).map((item, index)=>{
                                return [
                                    item.id,
                                    item.user.name,
                                    item.component,
                                    item.section,
                                    item.items.map(itemPos => itemPos.position.name.split('_').join(' ')).join(', '),
                                ]
                            })
                        }
                        actions={[
                            {
                                onClick: actionView,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </>
                            },
                            {
                                onClick: actionEdit,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </>
                            },
                            {
                                listLine: true,
                            },
                            {
                                onClick: setConfirmDeleteModalOpenId,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>

                                    </span>
                                    <span className="text-[#EA5E42]">
                                        Delete
                                    </span>
                                </>
                            }
                        ]}
                    />
                    {
                        !positions.length ? <h3 className="text-center p-[30px] font-[600]">Permissions list is empty</h3> : null
                    }
                </div>
            </div>

            <AddPositionModal
                open={addPositionModalOpen || selectedPermission}
                close={()=>{
                    setAddPositionModalOpen(false);
                    setEdit(null);
                    setSelectedPermission(null);
                }}
                created={()=>{fetchPositions()}}
                edit={ edit }
                selectedPermission={ selectedPermission }
            />

            <ConfirmDeleteModal
                open={confirmDeleteModalOpenId}
                close={()=>{setConfirmDeleteModalOpenId(false)}}
                submit={()=>{
                    actionDelete();
                }}
            />

            <InfoModal
                icon={infoModalConfig.icon}
                title={infoModalConfig.title}
                subtitle={infoModalConfig.subtitle}
                open={infoModalOpen}
                close={()=>{setInfoModalOpen(false)}}
                submit={()=>{setInfoModalOpen(false)}}
            />

            <NotificationContainer />
        </>
    )
}
