import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../api/index';

export const fetchLogin = createAsyncThunk(
    '/login',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await auth.login({
                email: payload.email,
                password: payload.password,
            });

            const access_token = res.data?.access_token || '';
            const refresh_token = res.data?.refresh_token || '';

            if(payload?.remember){
                let date = new Date();
                date.setDate(date.getDate() + 7);
                localStorage.setItem('last_login', date);
                localStorage.setItem('remember', "true");
            } else {
                localStorage.setItem('last_login', new Date());
                localStorage.setItem('remember', "false");
            }

            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);

            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchLogout = createAsyncThunk(
    '/logout',
    async (_, { rejectWithValue }) => {
        try {
            const res = await auth.logout();

            if(res?.data?.success){
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("last_login");
                localStorage.removeItem("remember");
            }
            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchDemoRequest = async (payload) => {
    try {
        const res = await auth.demoRequest(payload);
        return res;

    } catch (err) {
        return err.response.data;
    }
};

export const fetchIsAdmin = createAsyncThunk(
    '/fetchIsAdmin',
    async (_, { rejectWithValue }) => {
        try {
            const res = await auth.checkIsAdmin()
            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchResendCode = async () => {
    try {
        const res = await auth.resendCode();

        return res.data

    } catch (err) {
        return null;
    }
};

export const fetchVerifyCode = async (code) => {
    try {
        const res = await auth.verifyCode(code);

        return res.data

    } catch (err) {
        return null;
    }
};