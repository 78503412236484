import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    adminCreateIncident, adminDeleteIncident,
    adminFetchAllIncidents,
    adminFetchIncidentsType, adminUpdateIncident, fetchSubIncidents, fetchCreateSubIncident,
} from "../../thunks/admin/adminIncidentsThunks";

const initialState = {
    incidents: null,
    incidentsTypes: null,
    subIncidents: null,
}

const adminIncidentsSlice = createSlice({
    name: 'adminIncidents',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(adminFetchAllIncidents.fulfilled, (state, action) => {
            return {
                ...state,
                incidents: action.payload.incidents,
            };
        });
        builder.addCase(adminFetchIncidentsType.fulfilled, (state, action) => {
            return {
                ...state,
                incidentsTypes: action.payload.incidentTypes,
            };
        });
        builder.addCase(adminCreateIncident.fulfilled, (state, action) => {
            return {
                ...state,
                incidents: state.incidents ? [...state.incidents, action.payload.new_incident] : [action.payload.new_incident]
            };
        });
        builder.addCase(adminUpdateIncident.fulfilled, (state, action) => {
            const inc = action.payload.this_incident
            return {
                ...state,
                incidents: state?.incidents.map(el => {
                    if (el.id === inc.id) {
                        return inc
                    }
                    return el
                })
            };
        });
        builder.addCase(adminDeleteIncident.fulfilled, (state, action) => {
            const {id} = action.payload
            const newIncidents = state.incidents.filter(inc => inc.id !== id)
            return {
                ...state,
                incidents: newIncidents
            };
        });

        builder.addCase(fetchSubIncidents.fulfilled, (state, action) => {
            return {
                ...state,
                subIncidents: action.payload,
            };
        });
    }
})


export const {  } = adminIncidentsSlice.actions

export const getAdminIncidents = (state) => state.adminIncidents.incidents;
export const getAdminIncidentsType = (state) => state.adminIncidents.incidentsTypes;
export const getSubIncidents = (state) => state.adminIncidents.subIncidents;

export default adminIncidentsSlice.reducer