import {api} from "..";

export const settingsApi = {    
    getProfile() {
        return api.get('/settings/profile')
    },

    updateProfile(id, payload) {
        return api.post(`/settings/profile/${id}`, payload, {
            headers: payload?.avatar !== null ? {
                'Content-Type': 'multipart/form-data'
            } : {}
        })
    },

    changePassword(payload) {
        return api.put(`/settings/profile/change-password`, payload)
    },

    getCreditCards() {
        return api.get('/settings/profile/credit-cards')
    },

    setDefaultCard(payload) {
        return api.post('/stripe/set-default-card', payload)
    },

    deleteCard(payload) {
        return api.post('/stripe/delete-card', payload)
    },

    getInvoices(limit = 1, ending_before='', starting_after = '') {
        return api.get(`/settings/profile/invoices?limit=${limit}&${ending_before.length ? `&ending_before=${ending_before}` : '' }${starting_after.length ? `&starting_after=${starting_after}` : '' }`)
    },

    downloadAllInvoices() {
        return api.get(`/settings/profile/invoices/download`)
    },

    getSubscriptionPlans() {
        return api.get(`/subscription-plans`)
    },

    subscribePlan(payload) {
        return api.post(`/stripe/subscribe`, payload)
    },

    unsubscribePlan() {
        return api.post(`/stripe/unsubscribe`)
    },

    getShowingTabs(){
        return api.get(`/showing-tabs`)
    },
}