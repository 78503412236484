import React from 'react'
import { Icon } from '../ui/Icon'

const ForgotPasswordSend = ({ open, close, submit, email }) => {

    return (
        <div className={`modal modal-1 ${open ? 'modal--show' : ''}`}>
            <div className="modal__dialog ">
                <div className="modal__content request-demo-modal-success">

                        <div className="mb-[16px] d-flex justify-center">
                            <Icon src="img/updated-img.png" />
                        </div>


                    <div className="d-flex flex-col items-center mb-[30px]">
                        <h2 className="font-[700] mb-[22px] leading-[32px] text-center">We send a password restore link at {email ? email : "[Your Entered Email]"}</h2>
                        {/* <p className="text-[14px] font-[400] leading-[22px] text-center opacity-60 ">Your demo request has been received, and our team is excited to assist you. We'll be in touch shortly via email to schedule your personalized demo.</p> */}
                    </div>

                    <div className="d-flex items-center">
                        <a 
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px] w-[130px] mx-auto"
                            onClick={(e)=>{
                                e.preventDefault();
                                close();
                            }}
                        >Ok</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordSend;