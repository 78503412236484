import React, {useEffect, useState} from 'react';


export const Editor2 = ({ value = "", setValue }) => {
    const [editorInstance, setEditorInstance] = useState(null);

    useEffect(()=>{
        window.CKEDITOR.ClassicEditor.create(document.getElementById("editor"), {
            toolbar: {
                items: [
                    'bold', 'italic', 'strikethrough', 'link', 'fontBackgroundColor',
                    'fontSize', 'blockQuote', 'bulletedList', 'numberedList'
                ],
                shouldNotGroupWhenFull: true
            },
            placeholder: 'Enter your text',
            htmlSupport: {
                allow: [
                    {
                        name: /.*/,
                        attributes: true,
                        classes: true,
                        styles: true
                    }
                ]
            },
            htmlEmbed: {
                showPreviews: true
            },
            link: {
                decorators: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    toggleDownloadable: {
                        mode: 'manual',
                        label: 'Downloadable',
                        attributes: {
                            download: 'file'
                        }
                    }
                }
            },
            removePlugins: [
                'CKBox',
                'CKFinder',
                'EasyImage',
                'RealTimeCollaborativeComments',
                'RealTimeCollaborativeTrackChanges',
                'RealTimeCollaborativeRevisionHistory',
                'PresenceList',
                'Comments',
                'TrackChanges',
                'TrackChangesData',
                'RevisionHistory',
                'Pagination',
                'WProofreader',
                'MathType',
                'SlashCommand',
                'Template',
                'DocumentOutline',
                'FormatPainter',
                'TableOfContents'
            ]
        }).then( editor => {
            setEditorInstance(editor);
            editor.model.document.on('change:data', () => {
                setValue(editor.getData());
                // .replace(/<\/?[^>]+(>|$)/g, "")
            } );
        } )
        .catch( error => {
            console.error( error );
        } );;
    },[]);

    return (
        <div id="container">
            <div id="editor" dangerouslySetInnerHTML={{ __html: value }}>
                {/* {value} */}
            </div>
        </div>
    )
}
