import React, { useState, useEffect } from 'react';

const Table = ({ tHead, tBody, actions, initialData, setSortedData, actionClear = false, actionsSingleBtn = false }) => {
    const [sortActive, setSortActive] = useState(null);
    const [actionView, setActionView] = useState({
        index: null,
        open: false,
        right: 0,
        top: 0
    });

    const setSort = (fieldName = false) => {
        if(!fieldName){
            return false;
        }

        let getField = (value, fieldNameString) =>{
            return fieldNameString.split('.')
            .reduce(function (object, property) {
                return object?.[property];
            }, value)
        }

        if(sortActive?.field !== fieldName){
            setSortActive({ field: fieldName, type: 'asc' });

            setSortedData([...initialData].sort( (a, b) => {
                if ( getField(a, fieldName) < getField(b, fieldName) ){
                    return -1;
                }
                if ( getField(a, fieldName) > getField(b, fieldName) ){
                    return 1;
                }
                return 0;
            } ));

            // setSortedData([...initialData].sort( (a, b) => {
            //     if ( a[fieldName] < b[fieldName] ){
            //         return -1;
            //     }
            //     if ( a[fieldName] > b[fieldName] ){
            //         return 1;
            //     }
            //     return 0;
            // } ));

        } else if(sortActive?.field === fieldName && sortActive?.type === 'desc'){
            setSortActive(null);
            setSortedData([...initialData]);
        } else {
            setSortActive({ field: fieldName, type: 'desc' });

            setSortedData([...initialData].sort( (a, b) => {
                if ( getField(a, fieldName) < getField(b, fieldName) ){
                    return 1;
                }
                if ( getField(a, fieldName) > getField(b, fieldName) ){
                    return -1;
                }
                return 0;
            } ));

            // setSortedData([...initialData].sort( (a, b) => {
            //     if ( a[fieldName] < b[fieldName] ){
            //         return 1;
            //     }
            //     if ( a[fieldName] > b[fieldName] ){
            //         return -1;
            //     }
            //     return 0;
            // } ));

        }
    }

   

    const dropdownActionClose = () => {
        setActionView({ index: null, open: false, right: 0, })
    }

    const toggleAction = (e, index) => {
        setActionView((prevState)=>{
            return {
                index: index,
                open: prevState.index === index ? !prevState.open : true,
                right: window.innerWidth-e.clientX,
                top: ((e.clientY + e.target.closest('.table-wrapper-box').querySelector(`.dropdown__body_${index}`).offsetHeight+20) > window.innerHeight) ? e.clientY - (e.target.closest('.table-wrapper-box').querySelector(`.dropdown__body_${index}`).offsetHeight+30) : e.clientY+20,
            }
        });
    }

    const handleClickOutside = (e) => {
        if(!e.target.closest('.table-dropdown-action')){
            dropdownActionClose();
        }
    }

    const handleClose = () => {
        dropdownActionClose()
    }

    useEffect(() => {
        const mainEl = document.getElementsByClassName('dashboard-content')[0]

        if (actionView.open) {
            mainEl.addEventListener('scroll', handleClose)
        } else {
            mainEl.removeEventListener('scroll', handleClose)
        }

        return () => {
            mainEl.removeEventListener('scroll', handleClose)
        }
    }, [actionView.open])

    useEffect(()=>{
        document.addEventListener('click', handleClickOutside, true);

        return ()=>{
            document.removeEventListener('click', handleClickOutside, true);
        }
    },[])

    return (
        <>  
            <div className="table-wrapper-box">
                <div className="table-wrapper">
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                {
                                    tHead ? tHead.map((item, index)=>{
                                        return <th
                                            className={`text-left ${item.sortField ? 'cursor-pointer' : ''} ${item?.show === false || item?.show === true ? item?.show ? '' : 'd-none' : ''}`} key={index}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                setSort(item?.sortField)
                                            }}
                                        >
                                            <div className="d-flex items-center">
                                                {item?.content ? item.content : item}
                                                { item?.sortField ? <svg xmlns="http://www.w3.org/2000/svg" width="5" height="14" viewBox="0 0 5 14" fill="none" style={{ marginLeft: "3px" }}>
                                                    <g opacity="0.4">
                                                        { sortActive === null || (sortActive.field === item?.sortField && sortActive.type === 'asc') ? <path d="M2.26953 2.77656C2.38947 2.63263 2.61053 2.63263 2.73047 2.77656L4.58995 5.00795C4.75279 5.20334 4.61384 5.5 4.35949 5.5H0.640512C0.386161 5.5 0.247214 5.20334 0.410046 5.00794L2.26953 2.77656Z" fill="#121327"/> : null }
                                                        { sortActive === null || (sortActive.field === item?.sortField && sortActive.type === 'desc') ? <path d="M2.73047 11.2234C2.61053 11.3674 2.38947 11.3674 2.26953 11.2234L0.410046 8.99205C0.247214 8.79666 0.386161 8.5 0.640512 8.5H4.35949C4.61384 8.5 4.75279 8.79666 4.58995 8.99206L2.73047 11.2234Z" fill="#121327"/> : null }
                                                    </g>
                                                </svg> : null }
                                            </div>
                                        </th>
                                    }) : <th></th>
                                }
                                { actions ? <th className="text-center --sticky">{actionClear ? '' : 'Action'}</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tBody.map((row, rowIndex)=>{
                                    return <tr key={rowIndex}>
                                        {
                                            row.slice(1).map((col, colIndex)=>{
                                                return <td key={colIndex} className={col ? '' : 'd-none'}>{col?.content ? col.content : col}</td>
                                            }) 
                                        }
                                        { actions ? 
                                            <td className="text-center --sticky" style={{ zIndex: actionView.index === rowIndex ? '2' : '1' }}>
                                                {
                                                    actionsSingleBtn ? 
                                                    <a onClick={(e)=>{
                                                        e.preventDefault();
                                                        actions.onClick(row[0]);
                                                    }} className="btn cursor-pointer" style={{verticalAlign: 'middle'}}>
                                                        { actions.content }
                                                    </a> :
                                                    <div className="event-action">
                                                        <div
                                                            className={`dropdown table-dropdown-action ${actionView.index === rowIndex && actionView.open ? 'dropdown-open' : ''}`} data-dropdown="dropdown" data-position="top-end"
                                                        >
                                                            <a href="#" className="btn btn-dropdown" data-role="button" onClick={(e)=>{
                                                                e.preventDefault();
                                                                toggleAction(e, rowIndex);
                                                            }}>
                                                                <span className="ico ico-20 justify-center">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                        <g opacity="0.6">
                                                                        <path d="M10.9973 16.0438C10.7451 16.0438 10.5388 16.2502 10.5415 16.5023C10.5415 16.7545 10.7479 16.9609 11.0001 16.9609C11.2522 16.9609 11.4586 16.7545 11.4586 16.5023C11.4586 16.2502 11.2522 16.0438 10.9973 16.0438" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M10.9973 10.5415C10.7451 10.5415 10.5388 10.7478 10.5415 11C10.5415 11.2522 10.7479 11.4586 11.0001 11.4586C11.2522 11.4586 11.4586 11.2522 11.4586 11C11.4586 10.7478 11.2522 10.5415 10.9973 10.5415" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M10.9973 5.03918C10.7451 5.03918 10.5388 5.24552 10.5415 5.49771C10.5415 5.7499 10.7479 5.95623 11.0001 5.95623C11.2522 5.95623 11.4586 5.7499 11.4586 5.49771C11.4586 5.24552 11.2522 5.03918 10.9973 5.03918" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </td> 
                                        : null }
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                { actionsSingleBtn ? null :
                    actions ? tBody.map((row, rowIndex)=>{
                        return <div
                            className={`dropdown__body dropdown__custom is-open dropdown__body_${rowIndex} ${actionView.index === rowIndex && actionView.open ? '' : 'dropdown-visible-hidden'}`} data-role="dropdown"
                            style={{ right: actionView.right+'px', top: actionView.top+'px' }} key={rowIndex}
                        >
                            <ul className="dropdown-list whitespace-nowrap p-[6px] rounded-[8px]">
                                { 
                                    actions.map((actionItem, actionIndex)=>{
                                        return <li className={`${actionItem?.content ? 'dropdown-list--item' : ''} ${actionItem?.listLine ? 'dropdown-list-line' : ''}`} key={actionIndex}>
                                            { actionItem?.content && actionItem?.onClick ? 
                                            <a 
                                                href="#" className="dropdown-link pr-[40px]"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    actionItem.onClick(row[0]);
                                                    dropdownActionClose();
                                                }}
                                            >
                                                { actionItem?.content }
                                            </a> : actionItem?.listLine ? <span></span> : null }
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    }) : null
                }
            </div>
        </>
    )
}

export default Table