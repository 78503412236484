import { api } from "..";

export const impactsApi = {
    getImpacts(id) {
        return api.get(`/incident/${id}/impact`);
    },
    createImpact(payload) {
        return api.post(`/impact/store`, payload);
    },
    updateImpact(id, payload) {
        return api.put(`/impact/${id}/edit`, payload);
    },
    deleteImpact(id) {
        return api.delete(`/impact/${id}/delete`);
    },
};