import React, { useState, useEffect, useRef } from 'react'
import IcoLink from "../../assets/img/ico-link.svg"
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax 
mapboxgl.accessToken = 'pk.eyJ1IjoiYnVsbGk0a2EiLCJhIjoiY2p6OW4ycTl4MDBnMTNtbW1xdXR4MmlzaiJ9.5bOGzWl5DExspo93meu8Bg';

const Map = ({ hideHeader = false, hideOverlay = false, lat = 37.758336, lng = -122.4389057, change, markers, centerMarker = false }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [localcLat, setLocalLat] = useState(lat);
    const [localcLng, setLocalLng] = useState(lng);
    const [zoom, setZoom] = useState(11);

    const toggleFullscreen = () => {
        if(map.current){
            map.current.getContainer().requestFullscreen();
        }
    }

    useEffect(()=>{
        if (map.current) return;
        let mapItem = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [localcLng, localcLat],
            zoom: zoom
        });

        mapItem.on('move', (e) => {
            if(change){
                change(mapItem.getCenter());
            }
        });

        if(markers){
            markers.forEach(item => {
                let marker = new mapboxgl.Marker()
                .setLngLat([+item.longitude, +item.latitude])
                .addTo(mapItem);
            });
        }
    }, []);

    return (
        <div className={`map-widget w-full h-full ${hideOverlay ? 'hide-overlay' : ''}`}>
            {!hideHeader ?  <div className="map-widget--header">
                <span className="def-text-2 font-[600] text-white mr-[10px]">
                    Situational Maps and Figures (+)
                </span>
                {/* COMMENT: full size map hidden by d-none */}
                <a href="#" className="btn btn-link d-none" onClick={(e)=>{
                    e.preventDefault();
                    toggleFullscreen();
                }}>
                    <span className="ico ico-20">
                        <img src={IcoLink} />
                    </span>
                </a>
            </div> : null }
            <div className="map-widget--body" style={{ maxHeight: "318px", minHeight: "200px" }}>
                { centerMarker ? <svg display="block" height="41px" width="27px" viewBox="0 0 27 41" className="center-marker">
                    <defs><radialGradient id="shadowGradient"><stop offset="10%" stopOpacity="0.4"></stop><stop offset="100%" stopOpacity="0.05"></stop></radialGradient></defs>
                    <ellipse cx="13.5" cy="34.8" rx="10.5" ry="5.25" fill="url(#shadowGradient)"></ellipse>
                    <path fill="#3FB1CE" d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"></path>
                    <path opacity="0.25" d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"></path>
                    <circle fill="white" cx="13.5" cy="13.5" r="5.5"></circle>
                </svg> : null }
                <div ref={mapContainer} className="map-container" />
            </div>
        </div>
    )
}

export default Map