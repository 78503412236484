export function fill_array(obj_fill){
    var data = obj_fill.data;
    var space_splitter = obj_fill.coll_split;
    var splitter = space_splitter + 1;
    var total_length = obj_fill.array_fill.length;
    var split_char = obj_fill.char;
    const new_array = [];

    for(let i = 0; i < total_length; i++){
        new_array.push(split_char);
    }
    for(let j = 0; j < new_array.length; j++){
        if(j % splitter == 0){
            let length = data.length - 1;
            let dell_data = j / splitter;

            if(new_array[j+space_splitter] !== undefined){
                if(data[dell_data] == undefined){
                    data[dell_data] = "";
                }

                new_array[j+space_splitter] = data[dell_data];
            }
        }
    }
    return new_array;
}

export const customScale = {
    id: "customScale",
    beforeDatasetsDraw: function(chart){
        const {ctx} = chart;
        
        ctx.save();
        let chart_area_bottom = chart.chartArea.bottom;
        let chart_area_top = chart.chartArea.top;
        let chart_scales = chart.scales;
        let axes_x = chart_scales.x;
        let ticks_axes_x = axes_x?.ticks;

        if(ticks_axes_x?.length) ticks_axes_x.forEach((tick, index) => {
            
            if(tick.label == ""){
                return;
            }else{
                if(index > 0 && index < ticks_axes_x.length - 1){
                    ctx.beginPath(); 
                    ctx.moveTo(
                        axes_x.getPixelForValue(tick.value), 
                        chart_area_bottom - 7
                    );  
                    ctx.lineTo(
                        axes_x.getPixelForValue(tick.value), 
                        chart_area_bottom
                    );
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
                    ctx.stroke();
                    
                    ctx.restore(); 
                }
                
            }
        })
        
    }
}