import { api } from "..";

export const executionChecklistApi = {
    getSelectedPosition(){
        return window.selectedPositionWindow;
    },
    
    getExecutionChecklist(id) {
        return api.get(`/incident/${id}/tasks?position_id=${this.getSelectedPosition()}`);
    },
    createTask(payload) {
        // payload={
        //     "description":"some 11111111111",
        //     "agency_id":1,
        //     "incident_id":1,
        //     "lifeline_id":1,
        //     "esf_id":1,
        //     "status":"active",
        //     "comment":"new tassk custom",
        //     "priority":"hight",
        //     "resources":"aDFGDFGFGDFctive",
        //     "RRF_number":"3456789",
        //     "source":"acGDFGDtive"
        // }
        return api.post(`/task/store?position_id=${this.getSelectedPosition()}`, payload);
    },
    getTask(id) {
        return api.get(`/task/${id}/show?position_id=${this.getSelectedPosition()}`);
    },
    updateTask(id, payload) {
        return api.put(`/task/${id}/edit?position_id=${this.getSelectedPosition()}`, payload);
    },
    deleteTask(id) {
        return api.delete(`/task/${id}/delete?position_id=${this.getSelectedPosition()}`)
    },
    taskAddHistory(id, payload) {
        return api.put(`/task/${id}/add-history?position_id=${this.getSelectedPosition()}`, payload);
    },

    getTaskPriorities(){
        return api.get('/task-priorities');
    },

    getParentPositions(position_id){
        return api.get(`/positions/${position_id}/parents`);
    },

    requestImport(payload) {
        return api.post(`/task-import`, payload, {headers: {
            'Content-Type': 'multipart/form-data',
        } });
    },

    requestDownloadTemplate() {
        return api.get(`/task-import-template`, {responseType: 'blob'});
    }
}