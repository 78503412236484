import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import { SelectBox } from "../../components/ui/SelectBox";
import Table from "../../components/ui/Table";
import CreateTaskModal from "../../components/Modals/CreateTaskModal";

import IcoEnergy from "../../assets/img/ico-energy.png";
import IcoTransportation from "../../assets/img/ico-transportation.png";
import IcoSafety from "../../assets/img/ico-safety.png";
import IcoHealth from "../../assets/img/ico-health.png";
import IcoHazardous from "../../assets/img/ico-hazardous.png";
import IcoFood from "../../assets/img/ico-food.png";
import IcoCommunications from "../../assets/img/ico-communications.png";
import SearchLoupe from "../../assets/img/search-loupe.svg";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import { getSelectedIncident, getSelectedIncidentId } from '../../redux/slices/incidentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {deleteTaskFetch, executionChecklistFetch} from '../../redux/thunks/executionChecklistThunk';
import { getAgencies, getTasks } from '../../redux/slices/executionChecklistSlice';
import { useMemo } from 'react';
import { priorityPillsConfig, statusPillsConfig, statusPillsOptions } from '../../maps';
import {NotificationManager} from "react-notifications";

import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";
import { Icon } from '../../components/ui/Icon';
import { ReadClose } from "../../helpers/checkPermission";
import { adminFetchRegions } from '../../redux/thunks/admin/userManagementThunks';
import { executionChecklistApi } from '../../api/utils/executionChecklistApi';
import downloadBlob from '../../helpers/downloadBlob';

export const ExecutionChecklist = ({ innerPage = false }) => {
    const localReadClose = (name, navigateActive, field = 'read') => {
        return ReadClose(name, navigateActive, field)
    }

    const checkRead = localReadClose('tasks', true);
    const fileInp = useRef(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tasks = useSelector(getTasks);

    const [tableData, setTableData] = useState([]);
    const [configureOpen, setConfigureOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [headerFilter, setHeaderFilter] = useState({
        lifeline: '',
        esf: '',
        status: '',
        agency: ''
    });
    const [createTaskOpen, setCreateTaskOpen] = useState(false);

    const subIncident = useSelector(getSelectedIncidentId)

    const [tableColumnsShow, setTableColumnsShow] = useState(null);

    const [confirmDeleteModalOpenId, setConfirmDeleteModalOpenId] = useState(false);

    const actionView = (value) => {
        navigate(`/task/${value}`)
    }
    const actionEdit = (value) => {
        const data = tasks.find(el => el.id === value)
        navigate(`/task/edit/${value}`)
    }
    const actionDelete = async(value = confirmDeleteModalOpenId) => {
        try {
            await dispatch(deleteTaskFetch(value)).unwrap()
            NotificationManager.success("Task deleted successfully");
        } catch (err) {
            NotificationManager.error("Task did not deleted");
        }
    }

    const selectedIncident = useSelector(getSelectedIncident);
    const selectedIncidentId = useSelector(getSelectedIncidentId);
    
    const [lifelineOptions, setLifelineOptions] = useState([])
    const [agencyOptions, setAgencyOptions] = useState([])
    const createTaskPayload = useMemo(()=>{
        return {
            "incident_id": selectedIncident.id,
        }
    }, [selectedIncident])
    
    const tableDropdownPermissions = {
        'edit': localReadClose('tasks', false, 'create'),
        'delete': localReadClose('tasks', false, 'delete'),
    }

    useEffect(()=>{
        if(checkRead && selectedIncident?.id){
            dispatch(executionChecklistFetch(selectedIncident?.id))
        }
    },[selectedIncident, selectedIncidentId])
    
    useEffect(() => {
        if(tasks){
            let filtered = tasks.filter(item => 
                (headerFilter?.status ? item.status === headerFilter.status : true) &&
                (headerFilter?.esf ? item.esf === headerFilter.esf : true) &&
                (headerFilter?.lifeline ? item.lifeline.id === headerFilter.lifeline : true) &&
                (headerFilter?.agency ? item.agency.id === headerFilter.agency : true)
            )
            let searched = filtered.filter(item=>(
                item.lifeline.name.toLowerCase().includes(searchString.toLowerCase()) ||
                item.description.toLowerCase().includes(searchString.toLowerCase()) ||
                statusPillsConfig[item.status]?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
                priorityPillsConfig[item.priority]?.name?.toLowerCase().includes(searchString.toLowerCase())
            ))
            setTableData(searched);


            let lifelineOptionsArray = [];
            tasks.forEach(item=>{
                if(!lifelineOptionsArray.find(item1=>item1.value===item.lifeline.id)){
                    lifelineOptionsArray.push(
                        { 
                            label:item.lifeline.name,
                            value:item.lifeline.id,
                        }
                    )
                };
            })
            setLifelineOptions([{label: 'All lifeline',
                value: ''}, ...lifelineOptionsArray])

            let agencyOptionsArray = [];
            tasks.forEach(item=>{
                if(!agencyOptionsArray.find(item1=>item1.value===item.agency.id)){
                    agencyOptionsArray.push(
                        { 
                            label:item.agency.name,
                            value:item.agency.id,
                        }
                    )
                };
            })
            setAgencyOptions([{label: 'All agency',
                value: '', placeholder: true}, ...agencyOptionsArray])
            

        }
    }, [tasks, headerFilter, searchString])

    const importCsv = async (event) => {
        const selectedFile = event.target.files[0];

        let formData = new FormData();

        formData.append('file', selectedFile)
        formData.append('sub_incident_id', subIncident)
        formData.append('incident_id', selectedIncident.id)


        const res = await executionChecklistApi.requestImport(formData)

        if (res?.data?.success) {
            NotificationManager.success('File imported')
            dispatch(executionChecklistFetch(selectedIncident?.id))
        } else {
            NotificationManager.error("Something went wrong")
        } 

        event.target.value = '';

    };
    
    useEffect(() => {
        dispatch(adminFetchRegions())
        let gettedTableConfig = localStorage.getItem('tableconfig-execution-checklist');

        if(gettedTableConfig && !tableColumnsShow){
            setTableColumnsShow(JSON.parse(gettedTableConfig));
        } else {
            setTableColumnsShow({
                status: true,
                id: true,
                lifeline: true,
                esf: true,
                task: true,
                rrf_created: true,
                priority: true,
                created_modified: true,
            })
        }
    }, [])

    const swiperData = useMemo(()=>{
        return{
            status:{
                tasks: tableData.length,
                inProgres:  tableData.filter(item=>item.status==='in-progres').length,
                notStarted: tableData.filter(item=>item.status==='not-started').length,
                completed: tableData.filter(item=>item.status==='completed').length
            },
            rrfCreated: 0,
            priority:{
                tasks: tableData.length,
                high: tableData.filter(item=>item.priority==='hight').length,
                low: tableData.filter(item=>item.priority==='low').length,
                medium: tableData.filter(item=>item.priority==='medium').length,
                critical: tableData.filter(item=>item.priority==='priority').length
            }
        }
    }, [tableData])

    const LLIcons = {
        "Energy": IcoEnergy,
        "Transportation": IcoTransportation,
        "Safety and Security": IcoSafety,
        "Health and Medical": IcoHealth,
        "Hazardous Material": IcoHazardous,
        "Food, water, shelter": IcoFood,
        "Communications": IcoCommunications,
    }

    const downloadTemplate = async () => {
        const res = await executionChecklistApi.requestDownloadTemplate()
        if (res?.status === 200) {
            downloadBlob(res.data, 'tasks-template.csv');
            NotificationManager.success('File downloaded')
        } else {
            NotificationManager.error("Something went wrong")
        }
    }

    return (
        <div>
            { !innerPage ? <>
                <div className="dash-page-content-item">
                    <ul className="list-feature mb-[-12px]">
                        {
                            [
                                IcoEnergy, IcoTransportation, IcoSafety, IcoHealth,
                                IcoHazardous, IcoFood, IcoCommunications
                            ].map((item, index)=>{
                                return <li className="list-feature--item mb-[12px]" key={index}>
                                <span className="icon-feature icon-feature--60">
                                    <span className="icon-wrapp">
                                        <img src={item} />
                                    </span>
                                </span>
                            </li>
                            })
                        }
                    </ul>
                </div>
                <div className="bg-gray w-full  px-[29px] pt-[20px] pb-[10px]">
                    <div className="flex justify-between items-center flex-wrap mb-[6px]">
                        <div className="flex items-center flex-wrap">
                            <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                                <SelectBox 
                                    propsOptions={lifelineOptions}
                                    value={headerFilter.lifeline}
                                    change={(value)=>{
                                        setHeaderFilter((prevState)=>{
                                            return ({...prevState, lifeline: value})
                                        })
                                    }}
                                />
                            </div>
                            <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                                <SelectBox 
                                    propsOptions={agencyOptions}
                                    value={headerFilter.agency}
                                    change={(value)=>{
                                        setHeaderFilter((prevState)=>{
                                            return ({...prevState, agency: value})
                                        })
                                    }}
                                />
                            </div>
                            <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                                <SelectBox 
                                    propsOptions={[
                                        {label: 'ESF - 13', value: 'ESF - 13'},
                                        {label: 'ESF - 14', value: 'ESF - 14'},
                                        {label: 'All ESF', value: ''},
                                    ]}
                                    value={headerFilter.esf}
                                    change={(value)=>{
                                        setHeaderFilter((prevState)=>{
                                            return ({...prevState, esf: value})
                                        })
                                    }}
                                />
                            </div>
                            <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                                <SelectBox 
                                    propsOptions={statusPillsOptions}
                                    value={headerFilter.status}
                                    change={(value)=>{
                                        setHeaderFilter((prevState)=>{
                                            return ({...prevState, status: value})
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex items-center flex-wrap">
                            {
                                localReadClose('tasks', false, 'create') ? 
                                <a
                                    href="#" className="btn btn--primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        setCreateTaskOpen(true);
                                    }}
                                >
                                    <span className="ico mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4.16666V15.8333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M4.16602 10H15.8327" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>New Task</span>
                                </a> : null
                            }

                            <a
                                href="#" className="btn btn--shadow-primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    fileInp.current.click()
                                }}
                            >
                                
                                <span className="ico mr-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3086 3.81159C10.9502 4.07903 9.16785 6.07338 9.16602 8.44686V8.74198C10.1909 7.50706 11.704 6.78115 13.3086 6.75448V8.3118C13.3087 8.53783 13.4378 8.74397 13.6412 8.84263C13.8445 8.9413 14.0864 8.91516 14.264 8.77533L18.1197 5.72989C18.2568 5.62209 18.3368 5.45738 18.3368 5.28304C18.3368 5.10869 18.2568 4.94399 18.1197 4.83619L14.264 1.79158C14.0867 1.65114 13.8446 1.62454 13.6411 1.72312C13.4375 1.82171 13.3083 2.02809 13.3086 2.25428V3.81159Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M17.5043 10V13.3347C17.5043 15.6369 15.638 17.5031 13.3359 17.5031H6.66645C4.3643 17.5031 2.49805 15.6369 2.49805 13.3347V6.66528C2.49805 4.36314 4.3643 2.49688 6.66645 2.49688H9.16749" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span>Import</span>
                            </a>
                            <input
                                ref={fileInp}
                                type="file"
                                id="fileInput"
                                style={{ display: "none" }}
                                onChange={importCsv}
                            />
                            <a
                                href="#" className="btn btn--shadow-primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    downloadTemplate()
                                }}
                            >
                                
                                <span className="ico mr-[8px]">
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"/>
                                    <path d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                </span>
                                <span>Template</span>
                            </a>
                            <div className="dropdown configure-dropdown mb-[10px] relative" data-dropdown="dropdown" data-position="top-end">
                                <a href="#" className="btn btn--shadow-primary btn--lg-2 rounded-[10px] " data-role="button" onClick={(e)=>{
                                    e.preventDefault();
                                    setConfigureOpen(!configureOpen);
                                }}>
                                    <span className="ico mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 4.16667H17.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M2.5 4.16667H9.16667" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.834 9.99967H17.5007" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M2.5 9.99967H5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M15 15.8327H17.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M2.5 15.8327H9.16667" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.5108 2.98813C15.1616 3.63896 15.1616 4.69396 14.5108 5.34479C13.86 5.99563 12.805 5.99563 12.1541 5.34479C11.5033 4.69396 11.5033 3.63896 12.1541 2.98813C12.805 2.33729 13.86 2.33729 14.5108 2.98813" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.84479 8.82113C8.49563 9.47197 8.49563 10.527 7.84479 11.1778C7.19396 11.8286 6.13896 11.8286 5.48813 11.1778C4.83729 10.527 4.83729 9.47197 5.48813 8.82113C6.13896 8.1703 7.19396 8.1703 7.84479 8.82113" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.5108 14.6551C15.1616 15.306 15.1616 16.361 14.5108 17.0118C13.86 17.6626 12.805 17.6626 12.1541 17.0118C11.5033 16.361 11.5033 15.306 12.1541 14.6551C12.805 14.0043 13.86 14.0043 14.5108 14.6551" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    Configure
                                </a>

                                <div className={`dropdown__body w-[370px] ${configureOpen ? 'is-open' : ''}`} data-role="dropdown" style={{ right: '0', position: 'absolute' }}>
                                    <div className="dropdown-content p-[14px] border-bottom">
                                        <div className="row w-full gx-3 gy-4">
                                            { tableColumnsShow &&
                                                [
                                                    { label: 'Status', value: 'status' },
                                                    { label: 'Task ID', value: 'id' },
                                                    { label: 'Lifeline', value: 'lifeline' },
                                                    { label: 'ESF', value: 'esf' },
                                                    { label: 'Task', value: 'task' },
                                                    { label: 'RRF Created', value: 'rrf_created' },
                                                    { label: 'Priority', value: 'priority' },
                                                    { label: 'Created / Modified', value: 'created_modified' },
                                                ].map((item, index)=>{
                                                    return <div className="col-6" key={index}>
                                                        <label className="custom-checkbox checkbox-gray">
                                                            <input type="checkbox" className="custom-checkbox__input" checked={tableColumnsShow[item.value]} onChange={(e)=>{
                                                                setTableColumnsShow((prevState)=>{
                                                                    return {
                                                                        ...prevState, [item.value]: e.target.checked
                                                                    }
                                                                });
                                                            }} />
                                                            <span className="custom-checkbox__input-fake"></span>
                                                            <span className="custom-checkbox__label">{item.label}</span>
                                                        </label>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="dropdown__footer p-[14px] flex justify-end">
                                        <a
                                            href="#" className="btn btn--primary btn--lg rounded-[10px]" 
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                setConfigureOpen(!configureOpen);
                                                localStorage.setItem(
                                                    'tableconfig-execution-checklist',
                                                    JSON.stringify(tableColumnsShow)
                                                );
                                            }}
                                        >Update Configuration</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label className="custom-checkbox">
                        <input type="checkbox" className="custom-checkbox__input" />
                        <span className="custom-checkbox__input-fake"></span>
                        <span className="custom-checkbox__label">View connected partners lines of efforts</span>
                    </label>
                </div>
            </> : null }
            <div className={!innerPage ? `dash-page-content-item` : ''}>
                { !innerPage ?
                <div className="flex items-center justify-between flex-wrap">
                    <Swiper
                        className="vertical-slider mb-[16px]"
                        slidesPerView={1}
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        direction="vertical"
                    >
                        <SwiperSlide style={{ height: '26px' }}>
                            <ul className="filter-list" style={{ pointerEvents: 'none' }}>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Status:</span>  
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Tasks</span>
                                        <span className="filter-count --primary-dark">{swiperData.status.tasks}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>In Progress</span>
                                        <span className="filter-count --primary">{swiperData.status.inProgres}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Not Started</span>
                                        <span className="filter-count --secondary">{swiperData.status.notStarted}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Completed</span>
                                        <span className="filter-count --success">{swiperData.status.completed}</span>
                                    </a>
                                </li>
                            </ul>
                        </SwiperSlide>
                        <SwiperSlide style={{ height: '26px' }}>
                            <ul className="filter-list" style={{ pointerEvents: 'none' }}>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>RRF(s):</span>  
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Created</span>
                                        <span className="filter-count --primary">{swiperData.rrfCreated}</span>
                                    </a>
                                </li>
                            </ul>
                        </SwiperSlide>
                        <SwiperSlide style={{ height: '26px' }}>
                            <ul className="filter-list" style={{ pointerEvents: 'none' }}>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Priority:</span>  
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Tasks</span>
                                        <span className="filter-count --primary-dark">{swiperData.priority.tasks}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>High</span>
                                        <span className="filter-count --warning">{swiperData.priority.high}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Low</span>
                                        <span className="filter-count --primary">{swiperData.priority.low}</span>
                                    </a>
                                </li>

                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Medium</span>
                                        <span className="filter-count --success">{swiperData.priority.medium}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="filter-btn">
                                        <span>Critical</span>
                                        <span className="filter-count --secondary">{swiperData.priority.critical}</span>
                                    </a>
                                </li>
                            </ul>
                        </SwiperSlide>
                    </Swiper>

                    <div className="input-custom mb-[16px]" style={{ maxWidth: "300px", width: "100%" }}>
                        <div className="form-search search-border h-[32px]">
                            <button type="submit" className="form-search__btn">
                                <img src={SearchLoupe} />
                            </button>
                            <input type="search" className="form-search__input" placeholder="Search..." value={searchString} onInput={(e)=>{
                                setSearchString(e.target.value)
                            }} />
                        </div>
                    </div>
                </div> : null }
                <div className="card-body">
                    { tableColumnsShow && <Table 
                        // actions={true}
                        initialData={(innerPage ? tableData.slice(-5) : tableData)}
                        setSortedData={setTableData}
                        tHead={innerPage ? [
                            {
                                content: 'LL',
                                sortField: 'lifeline',
                                show: tableColumnsShow?.lifeline
                            },
                            {
                                content: 'Task ID',
                                sortField: 'id',
                                show: tableColumnsShow?.id
                            },
                            {
                                content: 'ESF',
                                show: tableColumnsShow?.esf
                            },
                            {
                                content: 'Task Info',
                                show: tableColumnsShow?.task
                            },
                            {
                                content: 'RRF',
                                show: tableColumnsShow?.rrf_created
                            },
                            {
                                content: 'Status',
                                sortField: 'status',
                                show: tableColumnsShow?.status
                            },
                            {
                                content: 'Priority',
                                sortField: 'priority',
                                show: tableColumnsShow?.priority
                            },
                        ] : [
                            {
                                content: 'Status',
                                sortField: 'status',
                                show: tableColumnsShow?.status
                            },
                            {
                                content: 'Task ID',
                                sortField: 'id',
                                show: tableColumnsShow?.id
                            },
                            {
                                content: 'Lifeline',
                                sortField: 'lifeline',
                                show: tableColumnsShow?.lifeline
                            },
                            {
                                content: 'ESF',
                                show: tableColumnsShow?.esf
                            },
                            {
                                content: 'Task',
                                show: tableColumnsShow?.task
                            },
                            {
                                content: 'RRF Created',
                                show: tableColumnsShow?.rrf_created
                            },
                            {
                                content: 'Priority',
                                sortField: 'priority',
                                show: tableColumnsShow?.priority
                            },
                            {
                                content: 'Created / Modified',
                                sortField: 'updated_at',
                                show: tableColumnsShow?.updated_at
                            }
                        ]}
                        tBody={
                            (innerPage ? tableData.slice(-5) : tableData)
                            .map((item, index)=>{
                                return innerPage ? [
                                    item.id,
                                    tableColumnsShow?.lifeline ? 
                                        LLIcons[item.lifeline.name] ?
                                        <img className='w-[30px] h-[30px]' src={LLIcons[item.lifeline.name]} alt="" /> :
                                        item.lifeline.name : null,
                                    tableColumnsShow?.id ? item.id : null,
                                    tableColumnsShow?.esf ? (item.esf || '-') : null,
                                    tableColumnsShow?.task ? item.description : null,
                                    tableColumnsShow?.rrf_created ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity={item.rrf_created ? '0.2' : '1'}>
                                            <path d="M10.3125 13.0625L8.25 11" stroke={item.rrf_created ? '#121327' : '#2F80ED'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M13.75 9.625L10.3125 13.0625" stroke={item.rrf_created ? '#121327' : '#2F80ED'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke={item.rrf_created ? '#121327' : '#2F80ED'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>
                                    : null,
                                    tableColumnsShow?.status ? <span className={`pill ${statusPillsConfig[item.status]?.class}`}>
                                        <span className="pill-status"></span>
                                        { statusPillsConfig[item.status]?.title }
                                    </span> : null,
                                    tableColumnsShow?.priority ? <span className={`pill ${priorityPillsConfig[item.priority]?.class}`}>
                                    <span className="flex mr-[5px]">
                                        { item.priority === 'hight' && 
                                            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.15359 2.3C5.30755 2.03333 5.69245 2.03333 5.84641 2.3L9.74353 9.05C9.89748 9.31667 9.70504 9.65 9.39711 9.65H1.60289C1.29497 9.65 1.10252 9.31667 1.25648 9.05L5.15359 2.3Z" stroke="#F59E0C" strokeWidth="1.2"></path>
                                            </svg>
                                        }
                                        { item.priority === 'low' && 
                                            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.84641 9.7C5.69245 9.96667 5.30755 9.96667 5.15359 9.7L1.25648 2.95C1.10252 2.68333 1.29497 2.35 1.60289 2.35L9.39712 2.35C9.70504 2.35 9.89749 2.68333 9.74353 2.95L5.84641 9.7Z" stroke="#2F80ED" strokeWidth="1.2"></path>
                                            </svg>
                                        }
                                        { item.priority === 'medium' && 
                                            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5.5" cy="6" r="4.9" stroke="#02BC77" strokeWidth="1.2"></circle>
                                            </svg>
                                        }
                                        { item.priority === 'priority' && 
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.49935 7.60667V5.58084" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.49881 9.23437C6.42406 9.23437 6.36339 9.29504 6.36393 9.36979C6.36393 9.44454 6.4246 9.5052 6.49935 9.5052C6.5741 9.5052 6.63477 9.44454 6.63477 9.36979C6.63477 9.29504 6.5741 9.23437 6.49881 9.23437" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.59957 2.74196L11.7482 10.0025C12.2303 10.8464 11.6209 11.8967 10.6491 11.8967H2.3519C1.37961 11.8967 0.770231 10.8464 1.25286 10.0025L5.40148 2.74196C5.88736 1.891 7.11369 1.891 7.59957 2.74196Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg> 
                                        }
                                    </span>
                                    {priorityPillsConfig[item.priority]?.title}
                                    </span> : null,
                                ] : [
                                    item.id,
                                    tableColumnsShow?.status ? <span className={`pill ${statusPillsConfig[item.status]?.class}`}>
                                        <span className="pill-status"></span>
                                        { statusPillsConfig[item.status]?.title }
                                    </span> : null,
                                    tableColumnsShow?.id ? item.id : null,
                                    tableColumnsShow?.lifeline ? item.lifeline.name : null,
                                    tableColumnsShow?.esf ? (item.esf || '-') : null,
                                    tableColumnsShow?.task ? item.description : null,
                                    tableColumnsShow?.rrf_created ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity={item.rrf_created ? '0.2' : '1'}>
                                            <path d="M10.3125 13.0625L8.25 11" stroke={item.rrf_created ? '#121327' : '#2F80ED'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M13.75 9.625L10.3125 13.0625" stroke={item.rrf_created ? '#121327' : '#2F80ED'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke={item.rrf_created ? '#121327' : '#2F80ED'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>
                                     : null,
                                    tableColumnsShow?.priority ? <span className={`pill ${priorityPillsConfig[item.priority]?.class}`}>
                                        <span className="flex mr-[5px]">
                                            { item.priority === 'hight' && 
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.15359 2.3C5.30755 2.03333 5.69245 2.03333 5.84641 2.3L9.74353 9.05C9.89748 9.31667 9.70504 9.65 9.39711 9.65H1.60289C1.29497 9.65 1.10252 9.31667 1.25648 9.05L5.15359 2.3Z" stroke="#F59E0C" strokeWidth="1.2"></path>
                                                </svg>
                                            }
                                            { item.priority === 'low' && 
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.84641 9.7C5.69245 9.96667 5.30755 9.96667 5.15359 9.7L1.25648 2.95C1.10252 2.68333 1.29497 2.35 1.60289 2.35L9.39712 2.35C9.70504 2.35 9.89749 2.68333 9.74353 2.95L5.84641 9.7Z" stroke="#2F80ED" strokeWidth="1.2"></path>
                                                </svg>
                                            }
                                            { item.priority === 'medium' && 
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="5.5" cy="6" r="4.9" stroke="#02BC77" strokeWidth="1.2"></circle>
                                                </svg>
                                            }
                                            { item.priority === 'priority' && 
                                                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.49935 7.60667V5.58084" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M6.49881 9.23437C6.42406 9.23437 6.36339 9.29504 6.36393 9.36979C6.36393 9.44454 6.4246 9.5052 6.49935 9.5052C6.5741 9.5052 6.63477 9.44454 6.63477 9.36979C6.63477 9.29504 6.5741 9.23437 6.49881 9.23437" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.59957 2.74196L11.7482 10.0025C12.2303 10.8464 11.6209 11.8967 10.6491 11.8967H2.3519C1.37961 11.8967 0.770231 10.8464 1.25286 10.0025L5.40148 2.74196C5.88736 1.891 7.11369 1.891 7.59957 2.74196Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg> 
                                            }
                                        </span>
                                        {priorityPillsConfig[item.priority]?.title}
                                    </span> : null,
                                    tableColumnsShow?.created_modified ? (item.updated_at.split('T')[0] || '-'): null,
                                ]
                            })
                        }
                        actionsSingleBtn={innerPage}
                        actions={innerPage ? 
                            {
                                onClick: actionView,
                                content: <span className="ico ico-20">
                                    <Icon src="img/share-arrow-square.svg" />
                                </span>
                            } : [
                            {
                                onClick: actionView,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </>
                            },
                            {
                                onClick: tableDropdownPermissions.edit ? actionEdit : () =>{ NotificationManager.error("You do not have access to this action") },
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </>
                            },
                            {
                                listLine: true,
                            },
                            {
                                onClick: tableDropdownPermissions.delete ? setConfirmDeleteModalOpenId : () =>{ NotificationManager.error("You do not have access to this action") },
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>

                                    </span>
                                    <span className="text-[#EA5E42]">
                                        Delete
                                    </span>
                                </>
                            }
                        ]}
                        actionClear={innerPage}
                    /> }
                </div>
            </div>

            <CreateTaskModal
                open={createTaskOpen}
                createTaskPayload={createTaskPayload}
                close={()=>{setCreateTaskOpen(false)}}
            />

            <ConfirmDeleteModal
                open={confirmDeleteModalOpenId}
                close={()=>{setConfirmDeleteModalOpenId(false)}}
                submit={()=>{
                    actionDelete();
                }}
            />
        </div>
    )
}
