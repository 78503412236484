import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { 
    fetchRequests,
    fetchRequestOptions, 
} from '../thunks/resourceRequestThunks'

const initialState = {
    data: [],
    options: {
        statuses: null,
        priorities: null,
        kinds: null,
    },
}

const resourceRequestSlice = createSlice({
    name: 'resourceRequest',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchRequests.fulfilled, (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        });
        builder.addCase(fetchRequests.rejected, (state) => {
            return {
                ...state,
                data: []
            };
        });

        builder.addCase(fetchRequestOptions.fulfilled, (state, action) => {
            return {
                ...state,
                options: action.payload,
            };
        });
        builder.addCase(fetchRequestOptions.rejected, (state) => {
            return {
                ...state,
                options: {
                    statuses: null,
                    priorities: null,
                    kinds: null,
                }
            };
        });
    },
})


export const getResourceRequests = (state) => state.resourceRequest.data?.data;
export const getResourceRequestOptions = (state) => state.resourceRequest.options;

export default resourceRequestSlice.reducer