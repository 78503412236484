import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';

import './assets/css/bootstrap-grid.css'
import './assets/css/reset.css'
import './assets/css/global.css'
import 'react-notifications/lib/notifications.css';
import './assets/fonts/lato-500/stylesheet.css'
import './assets/css/components/components.css'

import './assets/css/app.css'
import './assets/css/main-alpha.css'
import './assets/css/main-bravo.css'
import './assets/css/main-charlie.css'
import './assets/css/main-delta.css'
import './assets/css/media-alpha.css'
import './assets/css/media-bravo.css'
import './assets/css/media-charlie.css'
import './assets/css/media-delta.css'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);


