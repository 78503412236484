import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SelectBox } from "../../components/ui/SelectBox";
import { Icon } from '../../components/ui/Icon';
import AddImpactModal from "../../components/Modals/AddImpactModal";
import AddBLUFModal from "../../components/Modals/AddBLUFModal";

import { useDispatch, useSelector } from "react-redux";
import { getImpacts } from "../../redux/slices/impactsSlice";
import { impactsFetch } from "../../redux/thunks/impactsThunks";
import { getSelectedIncident, } from "../../redux/slices/incidentsSlice";

import { impacts } from '../../api/index';

import Table from "../../components/ui/Table";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";
import {NotificationManager, NotificationContainer} from "react-notifications";

import { adminFetchAllUsers } from '../../redux/thunks/admin/userManagementThunks';
import { getUsers } from "../../redux/slices/admin/userManagementSlice";

import { bluf } from '../../api/index';

import { ReadClose } from "../../helpers/checkPermission";

export const Impact = () => {
    const checkRead = ReadClose('situational-awareness', true);

    const dispatch = useDispatch();
    const impactsData = useSelector(getImpacts);
    const selectedIncident = useSelector(getSelectedIncident);

    const users = useSelector(getUsers);

    const [addImpactModalOpen, setAddImpactModalOpen] = useState(false);
    const [addBLUFModalOpen, setAddBLUFModalOpen] = useState(false);

    const [impactConfirmDeleteModalOpenId, setImpactConfirmDeleteModalOpenId] = useState(false);
    const [blufConfirmDeleteModalOpenId, setBlufConfirmDeleteModalOpenId] = useState(false);

    const [impactView, setImpactView] = useState(false);
    const [impactEdit, setImpactEdit] = useState(false);

    const [blufView, setBlufView] = useState(false);
    const [blufEdit, setBlufEdit] = useState(false);

    const [searchImpact, setSearchImpact] = useState("");
    const [searchBluf, setSearchBluf] = useState("");

    const [blufs, setBlufs] = useState([]);

    const getDotColor = (value) => {
        const colors = {
            "blue": "#2F80ED",
            "red": "#ea5e42",
            "purple": "#7416f7",
            "orange": "#f59e0c",
        }
        
        return colors[value] ? colors[value] : "#2F80ED";
    }

    const [tableData, setTableData] = useState(impactsData?.length ? impactsData : []);

    const [blufsTableData, setBlufsTableData] = useState(blufs?.length ? blufs : []);

    const impactActionView = (id) => {
        setImpactView(tableData.find(item => item.id === id));
    }
    const impactActionEdit = (id) => {
        setImpactView(tableData.find(item => item.id === id));
        setImpactEdit(true);
    }
    const impactActionDelete = async() => {
        if(impactConfirmDeleteModalOpenId){
            try {
                await impacts.deleteImpact(impactConfirmDeleteModalOpenId);

                setImpactConfirmDeleteModalOpenId(false);

                if(selectedIncident?.id){
                    dispatch(impactsFetch(selectedIncident?.id));
                }

                NotificationManager.success("Impact has been removed");
            } catch (err) {
                NotificationManager.error("Something went wrong");
            }
        }
    }

    const getBlufs = async() => {
        let res = await bluf.getBlufs(selectedIncident?.id);
        setBlufs(res.data.data);
    }

    const blufActionView = (id) => {
        setBlufView(blufsTableData.find(item => item.id === id));
    }
    const blufActionEdit = (id) => {
        setBlufView(blufsTableData.find(item => item.id === id));
        setBlufEdit(true);
    }
    const blufActionDelete = async() => {
        if(blufConfirmDeleteModalOpenId){
            try {
                await bluf.deleteBluf(blufConfirmDeleteModalOpenId);

                setBlufConfirmDeleteModalOpenId(false);

                await getBlufs();

                NotificationManager.success("BLUF has been removed");
            } catch (err) {
                NotificationManager.error("Something went wrong");
            }
        }
    }



    useEffect(()=>{
        if(checkRead && !users?.length){
            dispatch(adminFetchAllUsers())
        }
    },[])

    useEffect(()=>{
        if(checkRead && selectedIncident?.id){
            dispatch(impactsFetch(selectedIncident?.id));
            
            getBlufs();
        }
    },[selectedIncident]);

    useEffect(()=>{
        setTableData(impactsData);
    },[impactsData]);

    useEffect(()=>{
        setBlufsTableData(blufs);
    },[blufs]);

    return (
        <>
            <div className="bg-gray w-full flex justify-between items-center px-5 pt-[16px] sm-flex-wrap" style={{ columnGap: '16px' }}>
                <div className="flex items-center flex-wrap flex-auto">
                    <h5 className="font-[600] mr-[16px] mb-[16px]">Impact</h5>

                    <div className="input-custom mb-[16px]" style={{ maxWidth: '300px', width: '100%' }}>
                        <div className="form-search search-border h-[32px]">
                            <button type="submit" className="form-search__btn pointer-events-none">
                                <Icon src="img/search-loupe.svg" />
                            </button>
                            <input
                                type="search" className="form-search__input" placeholder="Search..."
                                value={searchImpact} onInput={(e)=>{ setSearchImpact(e.target.value); }}
                            />
                        </div>
                    </div>
                </div>
                <a
                    href="#" className="btn btn--primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[16px]" data-modal="#add-impact" 
                    onClick={(e)=>{
                        e.preventDefault();
                        setAddImpactModalOpen(true);
                    }}
                >
                    <span className="ico mr-[8px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.16666V15.8333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16602 10H15.8327" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    <span>Add Impact</span>
                </a>
            </div>
            <div className="dash-page-content-item" style={{ paddingBottom: '0' }}>
                { impactsData?.length ? 
                <Table
                    initialData={impactsData}
                    setSortedData={setTableData}
                    tHead={[
                        { content: 'Situation' },
                        { content: 'Affected Lifelines' },
                        { content: 'Importance' },
                        { content: 'Actions Taken / Planned' },
                        { content: 'User Name' },
                        { content: 'Identified Gaps' },
                    ]}
                    tBody={
                        tableData.filter(
                            itemF => itemF.situation_title.toLowerCase().includes(searchImpact.toLowerCase()) ||
                            itemF.affected_lifeline.toLowerCase().includes(searchImpact.toLowerCase()) ||
                            itemF.importance_text.toLowerCase().includes(searchImpact.toLowerCase())
                        ).map((item, index)=>{
                            return [
                                item?.id,
                                item.situation_title,
                                <><span className="dot-status" style={{ backgroundColor: getDotColor(item.color) }}></span> { item.affected_lifeline }</>,
                                item.importance_text,
                                <div className="d-flex flex-col">
                                    {
                                        item?.actions.map((actionItem, actionItemIndex)=>{
                                            return <label className="custom-checkbox checkbox-border mb-[10px] flex" key={actionItemIndex+'-1'}>
                                                <input type="checkbox" className="custom-checkbox__input" />
                                                <span className="custom-checkbox__input-fake"></span>
                                                <span className="custom-checkbox__label">{ actionItem.task_rrf ? actionItem.task_rrf : `task_id: ${actionItem.task_id}` }</span>
                                            </label>
                                            // return Object.entries(actionItem).map((item2, index2)=>{
                                            //     return <label className="custom-checkbox checkbox-border mb-[10px] flex" key={`${actionItemIndex}-${index2}`}>
                                            //         <input type="checkbox" className="custom-checkbox__input" />
                                            //         <span className="custom-checkbox__input-fake"></span>
                                            //         <span className="custom-checkbox__label">{item2[0]}: {item2[1]}</span>
                                            //     </label>
                                            // })
                                        })
                                    }
                                </div>,
                                <div>
                                    {
                                        item?.actions.map((actionItem, actionItemIndex)=>{
                                            return <div className="mb-[10px]" style={{ lineHeight: "20px" }} key={actionItemIndex+'-2'}>
                                                { item?.user_name || users?.find(userItem => userItem.id === actionItem.user_id)?.full_name || "-" }
                                            </div>
                                        })
                                    }
                                </div>,
                                item.identified_gaps
                            ]
                        })
                    }
                    actions={[
                        {
                            onClick: impactActionView,
                            content: <>
                                <span className="ico ico-12 mr-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span>
                                    View
                                </span>
                            </>
                        },
                        {
                            onClick: impactActionEdit,
                            content: <>
                                <span className="ico ico-12 mr-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span>
                                    Edit
                                </span>
                            </>
                        },
                        {
                            listLine: true,
                        },
                        {
                            onClick: setImpactConfirmDeleteModalOpenId,
                            content: <>
                                <span className="ico ico-12 mr-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>

                                </span>
                                <span className="text-[#EA5E42]">
                                    Delete
                                </span>
                            </>
                        }
                    ]}
                    actionClear
                /> : 
                <div className="pb-[30px]">
                    <h5>Impacts list is empty, create new or select another incident</h5>
                </div> }
            </div>

            <div className="bg-gray w-full flex justify-between items-center px-5 pt-[16px] sm-flex-wrap" style={{ columnGap: '16px' }}>
                <div className="flex items-center flex-wrap flex-auto">
                    <h5 className="font-[600] mr-[16px] mb-[16px]">BLUF</h5>

                    <div className="input-custom mb-[16px]" style={{ maxWidth: '300px', width: '100%' }}>
                        <div className="form-search search-border h-[32px]">
                            <button type="submit" className="form-search__btn pointer-events-none">
                                <Icon src="img/search-loupe.svg" />
                            </button>
                            <input
                                type="search" className="form-search__input" placeholder="Search..."
                                value={searchBluf} onInput={(e)=>{ setSearchBluf(e.target.value); }}
                            />
                        </div>
                    </div>
                </div>
                <a 
                    href="#" className="btn btn--primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[16px]" data-modal="#add-BLUF"
                    onClick={(e)=>{
                        e.preventDefault();
                        setAddBLUFModalOpen(true);
                    }}
                >
                    <span className="ico mr-[8px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.16666V15.8333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16602 10H15.8327" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    <span>Add BLUF</span>
                </a>
            </div>
            <div className="dash-page-content-item" style={{ paddingBottom: '0' }}>                
                {
                    blufs?.length ? 
                    <Table
                        initialData={blufs}
                        setSortedData={setBlufsTableData}
                        tHead={[
                            { content: 'ESF / Agency' },
                            { content: 'BLUF' },
                            { content: 'Documentation' },
                            { content: 'Lifelines' },
                            { content: 'Actions Taken / Planned' },
                            { content: 'User Name' },
                            { content: 'Date Uploaded' },
                        ]}
                        tBody={
                            blufsTableData.filter(
                                itemF => itemF.agency_name.toLowerCase().includes(searchBluf.toLowerCase()) ||
                                itemF.description.toLowerCase().includes(searchBluf.toLowerCase()) ||
                                itemF.lifeline_name.toLowerCase().includes(searchBluf.toLowerCase())
                            ).map((blufItem, blufIndex)=> {
                                return [
                                    blufItem.id,
                                    blufItem.agency_name || '-',
                                    blufItem.description || '-',
                                    blufItem.documentation ? 
                                        <a href={blufItem.documentation} download={true} target='_blank' className="color-primary btn">
                                            <span className="flex mr-[6px]">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.9008 7.76575L8.57613 13.0905C8.41982 13.2468 8.2078 13.3347 7.98671 13.3347H6.6645C6.20407 13.3347 5.83082 12.9615 5.83082 12.5011V11.1788C5.83089 10.9575 5.91768 10.745 6.07258 10.5869L11.9334 4.59277C12.5382 3.9741 13.3654 3.62321 14.2306 3.61835C15.0958 3.6135 15.9269 3.95508 16.5386 4.56692L16.5986 4.62695C17.2234 5.24675 17.5737 6.09109 17.5712 6.97113C17.5687 7.85117 17.2136 8.69349 16.5853 9.30973C14.6195 11.2347 11.9267 13.9141 10.3844 15.4531C9.60283 16.2323 8.54422 16.6697 7.44065 16.6695H4.99714C3.61585 16.6695 2.49609 15.5497 2.49609 14.1684V11.7266C2.49609 10.6213 2.93513 9.56119 3.7166 8.77951L8.89876 3.59735" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span>{ blufItem.documentation_name || blufItem.documentation.split('/').pop() }</span>
                                        </a> 
                                    : null,
                                    blufItem.lifeline_name || '-',
                                    <div className="d-flex flex-col">
                                        {
                                            blufItem?.actions.map((actionItem, actionItemIndex)=>{
                                                return <label className="custom-checkbox checkbox-border mb-[10px] flex" key={actionItemIndex+'-1'}>
                                                    <input type="checkbox" className="custom-checkbox__input" />
                                                    <span className="custom-checkbox__input-fake"></span>
                                                    <span className="custom-checkbox__label">{ actionItem.task_rrf ? actionItem.task_rrf : `task_id: ${actionItem.task_id}` }</span>
                                                </label>
                                            })
                                        }
                                    </div>,
                                    <div>
                                        {
                                            blufItem?.actions.map((actionItem, actionItemIndex)=>{
                                                return <div className="mb-[10px]" style={{ lineHeight: "20px" }} key={actionItemIndex+'-2'}>
                                                    { users?.find(userItem => userItem.id === actionItem.user_id)?.full_name || "-" }
                                                </div>
                                            })
                                        }
                                    </div>,
                                    blufItem.date || '-',
                                ]
                            })
                        }
                        actions={[
                            {
                                onClick: blufActionView,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </>
                            },
                            {
                                onClick: blufActionEdit,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </>
                            },
                            {
                                listLine: true,
                            },
                            {
                                onClick: setBlufConfirmDeleteModalOpenId,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>

                                    </span>
                                    <span className="text-[#EA5E42]">
                                        Delete
                                    </span>
                                </>
                            }
                        ]}
                        actionClear
                    />
                : <div className="pb-[30px]">
                    <h5>BLUF list is empty, create new or select another incident</h5>
                </div> }
            </div>

            <AddImpactModal
                open={addImpactModalOpen || impactView}
                edit={impactEdit}
                close={()=>{
                    setAddImpactModalOpen(false);
                    setImpactView(false);
                    setImpactEdit(false);
                }}
                impact={impactView}
                selectedIncidentId={selectedIncident?.id}
                created={(updated = false)=>{
                    dispatch(impactsFetch(selectedIncident?.id));
                    if(updated){
                        NotificationManager.success("Impact has been updated");
                    } else {
                        NotificationManager.success("Impact has been created");
                    }
                }}
            />

            { addBLUFModalOpen || blufView ?
            <AddBLUFModal
                open={addBLUFModalOpen || blufView}
                edit={blufEdit}
                close={()=>{
                    setAddBLUFModalOpen(false);
                    setBlufView(false);
                    setBlufEdit(false);
                }}
                selectedBluf={blufView}
                selectedIncidentId={selectedIncident?.id}
                created={(updated = false)=>{
                    getBlufs();
                    if(updated){
                        NotificationManager.success("BLUF has been updated");
                    } else {
                        NotificationManager.success("BLUF has been created");
                    }
                }}
            /> : null }

            <ConfirmDeleteModal
                open={impactConfirmDeleteModalOpenId || blufConfirmDeleteModalOpenId}
                close={()=>{
                    setImpactConfirmDeleteModalOpenId(false);
                    setBlufConfirmDeleteModalOpenId(false);
                }}
                submit={()=>{
                    if(impactConfirmDeleteModalOpenId) impactActionDelete();
                    if(blufConfirmDeleteModalOpenId) blufActionDelete();
                }}
            />

            <NotificationContainer />
        </>
    )
}
