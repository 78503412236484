import React, { useEffect, useRef, useState, useCallback } from "react";


export const Icon = ({ src, alt, className }) => {
    const imgPath = require(`../../assets/${src}`);
    return (
        <img 
            src={imgPath} 
            className={className} 
            alt={alt || 'Icon'} 
        />
    )
}


export const SVGIcon = ({ src, alt, className }) => {
    
}