import { api } from "..";

export const blufApi = {
    getBlufs(id) {
        return api.get(`/blufs/${id}`);
    },

    createBluf(id, payload) {
        return api.post(`/blufs/${id}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    getSingleBluf(incident_id, bluf_id) {
        return api.get(`/blufs/${incident_id}/${bluf_id}`);
    },

    updateBluf(incident_id, bluf_id, payload) {
        return api.post(`/blufs/${incident_id}/${bluf_id}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    deleteBluf(id) {
        return api.delete(`/blufs/${id}`);
    },
};