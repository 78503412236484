import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import IconCalendarSchedule from "../../assets/img/icon-calendar-schedule.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAdminIncidentsType} from "../../redux/slices/admin/adminIncidentsSlice";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import { fetchCreateSubIncident, adminFetchIncidentsType } from "../../redux/thunks/admin/adminIncidentsThunks";
import {NotificationManager} from "react-notifications";

import { adminIncidents } from "../../api";

const AddSubIncidentModal = ({ open, close, incId, subIncId, created, edit = false }) => {

    const incidentTypes = useSelector(getAdminIncidentsType)
    const dispatch = useDispatch()

    const [show, setShow] = useState(false);

    const [formErrors, setFormErrors] = useState({});

    const [formData, setFormData] = useState({
        "area": "",
        "type": null,
        "level": "",
        "number": "",
        "impact_date": "",
        "time_zone": "",
        "status": "inactive",
        "execution_checklist": "",
        "PSMAs": "",
        "inc_output": "",
    })

    const validateForm = () => {
        const obj = Object.entries(formData)
        const errors = {}
        for (let i = 0; i < obj.length; i++) {
            const [name, val] = obj[i]
            if (!val && name !== 'execution_checklist' && name !== 'PSMAs' && name !== 'inc_output') {
                errors[name] = true
            }
        }

        setFormErrors(errors)
        return errors
    }

    const incidentOptionsTypes = incidentTypes && incidentTypes.map(el => ({label: el.name, value: el.id}))


    const timeZones = [
        { label: 'Etc/GMT+12', value: 'UTC-12:00' },
        { label: 'Etc/GMT+11', value: 'UTC-11:00' },
        { label: 'Pacific/Honolulu', value: 'UTC-10:00' },
        { label: 'America/Anchorage', value: 'UTC-09:00' },
        { label: 'America/Los_Angeles', value: 'UTC-08:00' },
        { label: 'America/Denver', value: 'UTC-07:00' },
        { label: 'America/Chicago', value: 'UTC-06:00' },
        { label: 'America/New_York', value: 'UTC-05:00' },
    ];
    const createSubIncident = async () => {
        const errors = validateForm()
        if (Object.values(errors).includes(true)) return;

        let res = null;
        if(edit){
            let editRes = await adminIncidents.editSubIncident(incId, subIncId, formData);
            res = editRes;
        } else {
            res = await dispatch(fetchCreateSubIncident({incId, payload: formData}))
        }

        if (res?.error) {
            NotificationManager.error('Oops, we have some problem')
            return
        }

        if (res?.payload?.success || res?.data?.success) {
            if(created) created(res);

            NotificationManager.success(edit ? 'Sub Incident has been updated' : 'Sub Incident has been added');
            closeModal();
        }

    }

    const handleCheckboxChange = (changeEvent) => {
        setFormData(prevState => ({...prevState, status: changeEvent.target.checked ? 'active' : 'inactive' }));
    };

    const handleDateChange = (date) => {
        const dateString = date.toISOString().slice(0, 10)
        setFormData((prevState) => ({ ...prevState, impact_date: dateString }))
        setFormErrors(prevState => ({...prevState, impact_date: false}))
    };

    const closeModal = () => {
        setShow(false);

        setTimeout(() => {
            close();
        }, 300);
    }

    const fetchData = async() => {
        const res = await adminIncidents.getSubIncident(incId, subIncId);
        
        setFormData({
            "area": res.data.data.area,
            "type": res.data.data.type.id,
            "level": res.data.data.declaration_level,
            "number": res.data.data.inc_number,
            "impact_date": res.data.data.impact_date,
            "time_zone": res.data.data.time_zone,
            "status": res.data.data.status,
            "execution_checklist": res.data.data.execution_checklist,
            "PSMAs": res.data.data.PSMAs,
            "inc_output": res.data.data.inc_output,
        });
    }

    useEffect(()=>{
        setShow(true);

        if(!incidentOptionsTypes){
            dispatch(adminFetchIncidentsType());
        }

        if(edit && incId && subIncId){
            fetchData();
        }
    }, []);

    return (
        <FullHeightModal
            title={edit ? "Edit Sub Incident" : "Add Sub Incident"}
            headerIcon={IconPlusAdd}
            open={show}
            close={closeModal}
            submitBtnText={"Save"}
            submitMethod={createSubIncident}
        >
            <div className="row gutter-x-16">
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Select Area</label>
                        <input className={`input-custom__input ${formErrors.area && 'error-field'}`} type="text" placeholder="some area" value={formData.area}
                               onChange={(e) => {
                                   setFormData(prev => ({...prev, area: e.target.value}))
                                   setFormErrors(prev => ({...prev, area: false}))
                               }}
                               disabled="" />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="select-wrap select-border h-36px mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident Type</label>
                        <SelectBox 
                            className={`default-select ${formErrors.type && 'select-error'}`}
                            propsOptions={incidentOptionsTypes || []}
                            value={formData.type}
                            change={(value)=>{
                                setFormErrors(prev => ({...prev, type: false}))
                                setFormData((prevState)=>{
                                    return ({...prevState, type: value})
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="select-wrap select-border h-36px mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Declaration Level</label>
                        <SelectBox
                            className={`default-select ${formErrors.level && 'select-error'}`}
                            propsOptions={[
                                {label: 'increment', value: 'increment'}
                            ]}
                            value={formData.level}
                            change={(value)=>{
                                setFormErrors(prev => ({...prev, level: false}))
                                setFormData((prevState)=>{
                                    return ({...prevState, level: value})
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    {/*<div className="select-wrap select-border h-36px mb-[16px]">*/}
                    {/*    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident Number</label>*/}
                    {/*    <SelectBox */}
                    {/*        className="default-select"*/}
                    {/*        propsOptions={[*/}
                    {/*            {label: 'Select', value: '1'},*/}
                    {/*            {label: 'Select 2', value: '2'},*/}
                    {/*            {label: 'Select 3', value: '3'},*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident Number</label>
                        <input className={`input-custom__input ${formErrors.number && 'error-field'}`} type="text" placeholder="XX-XXXX-XX"
                               value={formData.number}
                               onChange={(e) => {
                                   setFormData(prev => ({...prev, number: e.target.value}))
                                   setFormErrors(prev => ({...prev, number: false}))
                               }}
                               disabled="" />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Impact Date / Time</label>
                        <div className="relative">
                            <div className="icon-right icon-right-index">
                                <img src={IconCalendarSchedule} />
                            </div>
                            {/*<input className="input-custom__input data-calendar pr-[40px]" type="text" placeholder="" />*/}
                            <DatePicker
                                selected={formData.impact_date ? new Date(formData.impact_date) : null}
                                onChange={handleDateChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="----/--/--"
                                className={`input-custom__input data-calendar pr-[40px] ${formErrors.impact_date && 'error-field'}`}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="select-wrap select-border h-36px mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident Timezone</label>
                        <SelectBox
                            className={`default-select ${formErrors.time_zone && 'select-error'}`}
                            propsOptions={timeZones}
                            value={formData.time_zone}
                            change={(value)=>{
                                setFormErrors(prev => ({...prev, time_zone: false}))
                                setFormData((prevState)=>{
                                    return ({...prevState, time_zone: value})
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident output</label>
                        <input className="input-custom__input" type="text" placeholder="" value={formData.inc_output} disabled="true" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 flex items-end mb-[16px]">
                    <label className="custom-checkbox checkbox-border mb-[8px]">
                        <input type="checkbox" className="custom-checkbox__input" value="active"
                               checked={formData.status === 'active'}
                               onChange={handleCheckboxChange} />
                        <span className="custom-checkbox__input-fake"></span>
                        <span className="custom-checkbox__label">Active Incident</span>
                    </label>
                </div>
                <div className="col-12">
                    <h5 className="mb-[16px] font-[600]">Launch Supporting Documents</h5>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="flex flex-col">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Execution Checklist</label>
                        <label className="custom-radio mb-[16px]">
                            <input type="radio" className={`custom-radio__input`}
                                   value="Hurricane Plan A"
                                   checked={formData.execution_checklist === 'Hurricane Plan A'}
                                   onChange={event => {
                                       setFormErrors(prev => ({...prev, execution_checklist: false}))
                                       setFormData(prevState => ({...prevState, execution_checklist: event.target.value}))}}
                                   name="r1"  />
                            <span className={`custom-radio__input-fake ${formErrors.execution_checklist && 'radio-error'}`}></span>
                            <span className="custom-radio__label">Hurricane Plan A</span>
                        </label>
                        <label className="custom-radio mb-[16px]">
                            <input type="radio" className={`custom-radio__input`}
                                   value="Hurricane Plan B"
                                   checked={formData.execution_checklist === 'Hurricane Plan B'}
                                   onChange={event => {
                                       setFormErrors(prev => ({...prev, execution_checklist: false}))
                                       setFormData(prevState => ({...prevState, execution_checklist: event.target.value}))}}
                                   name="r1" />
                            <span className={`custom-radio__input-fake ${formErrors.execution_checklist && 'radio-error'}`}></span>
                            <span className="custom-radio__label">Hurricane Plan B</span>
                        </label>
                        <label className="custom-radio mb-[16px]">
                            <input type="radio" className={`custom-radio__input`}
                                   value="Hurricane Plan C"
                                   checked={formData.execution_checklist === 'Hurricane Plan C'}
                                   onChange={event => {
                                       setFormErrors(prev => ({...prev, execution_checklist: false}))
                                       setFormData(prevState => ({...prevState, execution_checklist: event.target.value}))
                                   }}
                                   name="r1" />
                            <span className={`custom-radio__input-fake ${formErrors.execution_checklist && 'radio-error'}`}></span>
                            <span className="custom-radio__label">Hurricane Plan C</span>
                        </label>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="flex flex-col">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">PSMAs</label>
                        <label className="custom-radio mb-[16px]">
                            <input type="radio" className={`custom-radio__input`}
                                   value="Hurricane PSMA"
                                   checked={formData.PSMAs === 'Hurricane PSMA'}
                                   onChange={event => {
                                       setFormErrors(prev => ({...prev, PSMAs: false}))
                                       setFormData(prevState => ({...prevState, PSMAs: event.target.value}))}}
                                   name="r2" />
                            <span className={`custom-radio__input-fake ${formErrors.PSMAs && 'radio-error'}`}></span>
                            <span className="custom-radio__label">Hurricane PSMA</span>
                        </label>
                        <label className="custom-radio mb-[16px]">
                            <input type="radio" className={`custom-radio__input`}
                                   value="Flood PSMA"
                                   checked={formData.PSMAs === 'Flood PSMA'}
                                   onChange={event => {
                                       setFormErrors(prev => ({...prev, PSMAs: false}))
                                       setFormData(prevState => ({...prevState, PSMAs: event.target.value}))}}
                                   name="r2" />
                            <span className={`custom-radio__input-fake ${formErrors.PSMAs && 'radio-error'}`}></span>
                            <span className="custom-radio__label">Flood PSMA</span>
                        </label>
                        <label className="custom-radio mb-[16px]">
                            <input type="radio" className={`custom-radio__input`}
                                   value="Tornado PSMA"
                                   checked={formData.PSMAs === 'Tornado PSMA'}
                                   onChange={event => {
                                       setFormErrors(prev => ({...prev, PSMAs: false}))
                                       setFormData(prevState => ({...prevState, PSMAs: event.target.value}))}}
                                   name="r2" />
                            <span className={`custom-radio__input-fake ${formErrors.PSMAs && 'radio-error'}`}></span>
                            <span className="custom-radio__label">Tornado PSMA</span>
                        </label>
                    </div>
                </div>
            </div>
        </FullHeightModal>
    )
}

export default AddSubIncidentModal;