import { createAsyncThunk } from '@reduxjs/toolkit';
import {meetings} from "../../api";

export const fetchAllMeetings = createAsyncThunk(
    '/meetings/fetchAllMeetings',
    async (id, { rejectWithValue }) => {
        try {
            const res = await meetings.getAll(id)

            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data)
        }
    },
)

export const fetchCreateMeetings = createAsyncThunk(
    '/meetings/fetchCreateMeetings',
    async (payload, { rejectWithValue }) => {
        try {
            const {selectedDate, ...newPayload} = payload

            const res = await meetings.create(newPayload)

            const newMeet = res.data.data.new_meeting

            return {newMeet, selectedDate}
        } catch (err) {

            return rejectWithValue(err?.response.data)
        }
    },
)

export const fetchUpdateMeetings = createAsyncThunk(
    '/meetings/fetchUpdateMeetings',
    async (payload, { rejectWithValue }) => {
        try {
            const {selectedDate, ...newPayload} = payload
            console.log(newPayload, 'pay');
            const res = await meetings.edit(newPayload);

            const newMeet = res.data.data.meeting
            return {newMeet, selectedDate}
        } catch (err) {

            return rejectWithValue(err?.response.data)
        }
    },
)

export const fetchDeleteMeeting = createAsyncThunk(
    '/meetings/fetchDeleteMeeting',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await meetings.delete(payload.id)

            return {id: payload.id, selectedDate: payload.selectedDate}
        } catch (err) {

            return rejectWithValue(err?.response.data)
        }
    },
)