import {api} from "../../index";

export const userManagementApi = {
    getAllUsers() {
        return api.get("/admin/user-management");
    },
    getPositions() {
        return api.get('/admin/user-positions')
    },
    createPosition(payload) {
        return api.post('/admin/positions/create', payload)
    },
    getOneUser(id) {
        return api.get(`/admin/user-management/${id}`)
    },
    getRegions() {
        return api.get('/admin/regions')
    },
    getAgencies() {
        return api.get('/admin/agencies')
    },
    getStates(id) {
        return api.get(`/admin/regions/${id}/states`)
    },
    getIncidentPositions(id) {
        return api.get(`/admin/incident/${id}/positions`)
    },
    createIncidentPosition(id, payload) {
        return api.post(`/admin/incident/${id}/positions/store`, payload)
    },
    updateIncidentPosition(incidentId, id, payload) {
        return api.put(`/admin/incident/${incidentId}/positions/${id}/edit`, payload)
    },
    deleteIncidentPosition(incidentId, id) {
        return api.delete(`/admin/incident/${incidentId}/positions/${id}/delete`)
    },
    createUser(payload) {
        console.log('createUser');
        return api.post('/admin/user-management', payload).catch(err => {
            console.log(err.response);
            if(err.response) {
                console.log(err.response);
                return err.response
            }
            return err
        })
    },
    updateUser(id, payload) {
        return api.put(`/admin/user-management/${id}`, payload)
    },
    deleteUser(id) {
        return api.delete(`/admin/user-management/${id}`)
    }
};
