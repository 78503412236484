import React, {useRef, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from "./AuthLayout";
import IcoSms from "../../assets/img/ico-sms.svg";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {fetchResendCode, fetchVerifyCode} from "../../redux/thunks/authThunks";

const Authentication = () => {
    const navigate = useNavigate();
    
    const submitForm = async (e) => {
        e.preventDefault();
        const otp = code.join('')

        if (otp.length < 6) {
            return
        }

        let res = await fetchVerifyCode(otp)
        if (res.success) {
            NotificationManager.success("Success");
            navigate('/')
        } else {
            NotificationManager.error("Incorrect code");
        }
    }

    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handleInputChange = (e, index) => {
        const value = e.target.value;

        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value !== '') {
                if (index < 5) {
                    inputRefs[index + 1].current.focus();
                }
            } else {
                if (index > 0) {
                    inputRefs[index - 1].current.focus();
                }
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && code[index] === '' && index > 0) {
            inputRefs[index - 1].current.focus();
            const newCode = [...code];
            newCode[index - 1] = code[index];
            newCode[index] = '';
            setCode(newCode);
        }
    };

    const resendCode = async () => {
        let res = await fetchResendCode()

        if (res.success) {
            NotificationManager.success("Code send");
        }
    }

    return (
        <AuthLayout>
            <form method="post" action="#" className="form max-w-[350px] mx-auto" onSubmit={submitForm}>
                <div className="form-header text-center justify-center items-center">
                    <div className="icon-circle icon-circle--primary icon-circle--60 mb-[26px]">
                        <span className="ico ico-32">
                            <img src={IcoSms} />
                        </span>
                    </div>
                    <h1 className="heading-1 font-[600]">Enter verification code</h1>
                    <p className="mt-[12px] text-06-2">
                        We’ve sent a code to 
                        <span style={{ color: "rgba(18, 19, 39, 1)" }}>example@email.com</span>
                    </p>
                </div>
                <div className="form-body mt-[26px]">
                    <div className="row mb-[-20px]">
                        <div className="col-12 mb-[20px]">
                            <div className="code">
                                {code.map((digit, index) => (
                                    <div className="code-item" key={index}>
                                        <label className="code-label">
                                            <input
                                                className="input-custom__input"
                                                type="text"
                                                value={digit}
                                                maxLength="1"
                                                onChange={(e) => handleInputChange(e, index)}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                ref={inputRefs[index]}
                                            />
                                        </label>
                                    </div>
                                ))}

                                {/*<div className="code-item">*/}
                                {/*    <label className="code-label">*/}
                                {/*        <input className="input-custom__input" type="number" min="0" max="9" maxLength="1" autoFocus="" />*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                {/*<div className="code-item">*/}
                                {/*    <label className="code-label">*/}
                                {/*        <input className="input-custom__input" type="number" min="0" max="9" maxLength="1" />*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                {/*<div className="code-item">*/}
                                {/*    <label className="code-label">*/}
                                {/*        <input className="input-custom__input" type="number" min="0" max="9" maxLength="1" />*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                {/*<div className="code-item">*/}
                                {/*    <label className="code-label">*/}
                                {/*        <input className="input-custom__input" type="number" min="0" max="9" maxLength="1" />*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                {/*<div className="code-item">*/}
                                {/*    <label className="code-label">*/}
                                {/*        <input className="input-custom__input" type="number" min="0" max="9" maxLength="1" />*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                {/*<div className="code-item">*/}
                                {/*    <label className="code-label">*/}
                                {/*        <input className="input-custom__input" type="number" min="0" max="9" maxLength="1" />*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-body mt-[26px] text-center">
                    <p className="def-text-1">
                        Didn’t get a code? &nbsp;
                        <button onClick={resendCode} className="btn text-primary">
                            Click to resend.
                        </button>
                    </p>
                    <div className="col-btns col-btns--8 mt-[26px]">
                        <div className="col">
                            <Link to="/login" className="btn btn--shadow p-[11px] 
                            rounded-[300px] mb-[15px]">
                                <span className="def-text-1 font-[500]">
                                    Cancel
                                </span>
                            </Link>
                        </div>
                        <div className="col">
                            <button type="submit" className="btn btn--primary p-[11px] 
                            rounded-[300px] mb-[15px]">
                                <span className="def-text-1 font-[500]">
                                    Verify
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <NotificationContainer />
        </AuthLayout>
    )
}

export default Authentication