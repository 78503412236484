import React, { useMemo, useState } from 'react'
import { Icon } from '../ui/Icon'
import { SelectBox } from '../ui/SelectBox'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchLaunch, setDisplaySettings } from '../../redux/thunks/launchThunks';
import { useDispatch, useSelector } from 'react-redux';
import { getLaunchData } from '../../redux/slices/launchSlice';
import { useCallback } from 'react';
import { launchApi } from '../../api/utils/launchApi';

import ConfirmDeleteModal from "./ConfirmDeleteModal";

export const IncidentLaunchModal = ({open, close}) => {
    const launchData = useSelector(getLaunchData);

    const dispatch = useDispatch();


    const [isCollapse, setIsCollapse] = useState(false)
    
    const [show, setShow] = useState(false)

    const [selectData, setSelectData] = useState({
        region: '',
        state: '',
        area: '',
        type: ''
    })
    const [searchPosition, setSearchPosition] = useState("")

    const [incidentsArr, setIncidentsArr] = useState([]);

    const [saveThisSearch, setSaveThisSearch] = useState(true);
    const [justViewing, setJustViewing] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [showNonActive, setShowNonActive] = useState(false);
    
    const [searchIncident, setSearchIncident] = useState('');

    const [confirmDeleteModalOpenId, setConfirmDeleteModalOpenId] = useState(false);

    const [fetchLoading, setFetchLoading] = useState(false);
    const [launchLoading, setLaunchLoading] = useState(false);

    const onClose = () => {
        setShow(false);
        setTimeout(() => {
            close();
        }, 300);
    }

    const fetchLaunchMethod = async() => {
        if(!incidentsArr.length) setFetchLoading(true);
        await dispatch(fetchLaunch());
        setFetchLoading(false);
    }

    const checkSavedSearch = () => {
        let gettedSearchConfig = localStorage.getItem('incident-launch-search-config');

        if(gettedSearchConfig){
            gettedSearchConfig = JSON.parse(gettedSearchConfig);

            setSelectData(gettedSearchConfig.selectData);
            setSearchPosition(gettedSearchConfig.searchPosition);
            setShowNonActive(gettedSearchConfig.showNonActive);
            setSearchIncident(gettedSearchConfig.searchIncident);
        }
    }

    const setSearchConfig = () => {
        localStorage.setItem('incident-launch-search-config', JSON.stringify({
            selectData,
            searchIncident,
            showNonActive,
            searchPosition,
        }));
    }

    const changeCheckboxHand = (id, parentId = null) => {
        if(parentId){
            setIncidentsArr(incidentsArr.map((el) => el.id === parentId ? 
                ({
                    ...el,
                    sub_incidents: el.sub_incidents.map((subEl) => subEl.id === id ? ({...subEl, checked: !subEl.checked}) : subEl)
                }) 
            : el))
        } else {
            setIncidentsArr(incidentsArr.map((el) => el.id === id ? ({...el, checked: !el.checked}) : el))
            
        }
    }

    const removeSavedIncident = async(id) => {
        // setSavedIncidentsArr(savedIncidentsArr.filter((el) => el.id !== id))

        await launchApi.deleteLaunch(confirmDeleteModalOpenId);
        await fetchLaunchMethod();
    }

    let filteredPositions = useMemo(()=>{
        if(!launchData.positions) return null
        return Object.entries(launchData.positions).filter(([positionId, positionTitle])=>positionTitle.toLowerCase().includes(searchPosition.toLowerCase().trim()));
    }, [launchData, searchPosition])

    let filteredIncidents = incidentsArr.filter(item=>{
        return searchIncident ? item.name.toLowerCase().includes(searchIncident.toLowerCase()) : true && 
            item.type.name.includes(selectData.type) &&
            (selectData.area !== "" ? item.sub_incidents.map(itemSub => itemSub.area).includes(selectData.area) : true)
    })

    let launchIncident = async(event) => {
        event.preventDefault();
        let res = {
            "save_search": saveThisSearch,
            "read_only": justViewing,
            "status_incidents": false,
            "incidents": incidentsArr.filter(incident => incident.checked).map(incident=>({ id: incident.id, sub_incidents: incident.sub_incidents.filter(sub_item => sub_item.checked).map(incident => incident.id) })),
            // [
            //   {
            //     "id": 1
            //   },
            //   {
            //     "id": 2
            //   },
            //   {
            //     "id": 3
            //   }
            // ],
            "positions": selectedPosition ? [
                {
                    "position": selectedPosition
                },
            ] : [],
          
        }

        // return;

        setLaunchLoading(true);
        await dispatch(setDisplaySettings(res));
        await fetchLaunchMethod();

        // if(saveThisSearch){
        //     setSearchConfig();
        // } else {
        //     localStorage.removeItem('incident-launch-search-config');
        // }

        setLaunchLoading(false);
    }

    const findName = (elIncidents) => {
        const parsed = JSON.parse(elIncidents).pop();
        const finded = launchData.incidents.find((itemF) => +(itemF?.id) === +(parsed.id));

        return finded?.name || '-';
    }

    const typeOptions = launchData?.incidents ? Array.from(new Set(launchData?.incidents.map((item) => item.type.name))).map((item) => {
        return {
            label: item,
            value: item
        }
    }) : [];

    const areaOptions = launchData?.incidents ? Array.from(new Set(launchData?.incidents.map((item) => item.sub_incidents).flat().map(item => item.area))).map((item) => {
        return {
            label: item,
            value: item
        }
    }) : [];

    const getLaunchesSubs = (val) => {
        const parsed = JSON.parse(val);

        const finded = launchData.incidents.find((itemF) => +(itemF?.id) === +(parsed[0].id));

        return parsed[0]?.sub_incidents ? finded.sub_incidents.filter(item => parsed[0]?.sub_incidents.includes(item.id)) : [];
    }


    useEffect(() => {
        // checkSavedSearch();
        fetchLaunchMethod();
    }, [])

    useEffect(()=>{
        if(!launchData.incidents) return;
        let res = launchData.incidents.map(incident=>{
            return {
                ...incident,
                checked: false,
                sub_incidents : incident.sub_incidents.map(sub_incident=>{
                    return {
                        ...sub_incident,
                        name:sub_incident.area,
                        checked: false
                    }
                })
            }
        })
        setIncidentsArr(res)
    }, [launchData])

    useEffect(()=>{
        if(open){
            setTimeout(() => {
                setShow(true);
            }, 50);
        }
    }, [open])
    
    return (
        <>
            {!isCollapse ? <div id="incident-launch" className={`modal modal-default ${show && 'modal--show'}`} data-select2-id="select2-data-incident-launch">
                <div className="modal__dialog modal__dialog--1200">
                    <div className="modal__content" data-select2-id="select2-data-82-eb8s">
                        <div className="modal__header">
                            <h2 className="modal__title">
                                <div className="ico-24 mr-[9px]">
                                    <Icon src="img/icon-play.svg" alt="" />
                                </div>
                                Launch Incident
                            </h2>
                            <div className="modal__header-right">
                                <a onClick={(e) => {e.preventDefault(); setIsCollapse(true)}} href="#" className="btn btn--outline-secondary-3 p-[5px] rounded-[7px] mr-[8px]" data-collapse-modal="">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M6.66797 8.33325L10.0013 11.6666L13.3346 8.33325" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </a>
                                <a onClick={(e) => {e.preventDefault(); close && onClose()}} href="#" className="btn btn-close" data-close-modal="">
                                    <Icon src="img/delete-disabled.svg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="modal__body bg-[#F8F8F8]">
                            <div className="flex items-center flex-wrap" data-select2-id="select2-data-81-m1bl">
                                <div className="input-custom flex-auto mb-[10px] md:mr-[8px]">
                                    <form action="" className="form-search h-[36px]">
                                        <button type="submit" className="form-search__btn">
                                            <Icon src="img/search-loupe.svg" />
                                        </button>
                                        <input
                                            value={searchIncident} onChange={(event)=>{setSearchIncident(event.target.value);}}
                                            type="search" className="form-search__input" placeholder="Search..."
                                        />
                                    </form>
                                </div>

                                {/* <div className="select-wrap h-36px w-full md:w-[200px] mb-[10px] md:mr-[8px]" data-select2-id="select2-data-80-yuv9">
                                    <SelectBox 
                                        propsOptions={[
                                            {label: 'Region', value: '1'},
                                            {label: 'Region 2', value: '2'},
                                            {label: 'Region 3', value: '3'},
                                        ]}
                                        value={selectData.region}
                                        change={(value)=>
                                            setSelectData((prevState)=>({...prevState, region: value}))
                                        }
                                    />
                                </div> */}
                                {/* <div className="select-wrap h-36px w-full md:w-[200px] mb-[10px] md:mr-[8px]" data-select2-id="select2-data-88-fbbi">
                                    <SelectBox 
                                        propsOptions={[
                                            {label: 'State', value: '1'},
                                            {label: 'State 2', value: '2'},
                                            {label: 'State 3', value: '3'},
                                        ]}
                                        value={selectData.state}
                                        change={(value)=>
                                            setSelectData((prevState)=>({...prevState, state: value}))
                                        }
                                    />
                                </div> */}
                                <div className="select-wrap h-36px w-full md:w-[200px] mb-[10px] md:mr-[8px]" data-select2-id="select2-data-88-fbbi">
                                    <SelectBox 
                                        propsOptions={[
                                            {label: 'All area', value: ''},
                                            ...areaOptions
                                        ]}
                                        value={selectData.area}
                                        change={(value)=> {
                                            setSelectData((prevState)=>({...prevState, area: value}));
                                        }}
                                    />
                                </div>
                                <div className="select-wrap h-36px w-full md:w-[200px] mb-[10px]" data-select2-id="select2-data-92-6vf1">
                                    <SelectBox 
                                        propsOptions={[
                                            { label:'All types', value: '' },
                                            ...typeOptions
                                        ]}
                                        value={selectData.type}
                                        change={(value)=>{
                                            setSelectData((prevState)=>({...prevState, type: value}));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center flex-wrap">
                                <label className="custom-checkbox mr-[20px] mt-[10px]">
                                    <input type="checkbox" className="custom-checkbox__input" checked={showNonActive} onChange={(e)=>{setShowNonActive(e.target.checked);}} />

                                    <span className="custom-checkbox__input-fake"></span>
                                    <span className="custom-checkbox__label">Include <span className="color-danger font-[700]" style={{ margin: "0 3px" }}>Non-Active</span> / Exercise Incidents</span>
                                </label>
                                {/* <label className="custom-checkbox mt-[10px]">
                                    <input type="checkbox" className="custom-checkbox__input" checked={justViewing} onChange={(e)=>{setJustViewing(e.target.checked)}} />
                                    <span className="custom-checkbox__input-fake"></span>
                                    <span className="custom-checkbox__label">Just Viewing</span>
                                </label> */}
                            </div>
                        </div>
                        <div className="modal__body">
                            <ul className="row gutter-x-16">
                                <li className="col-12 col-lg-4">
                                    <div className="incident-launch-item">
                                        { fetchLoading ?
                                            <div className="d-flex items-center justify-center p-[30px] w-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                                                </svg>
                                            </div> : 
                                            filteredIncidents && filteredIncidents.map((el) => 
                                            <>
                                                <label key={el.id} className="custom-checkbox checkbox-border border-bottom px-[12px] py-[10px]">
                                                    <input type="checkbox" className="custom-checkbox__input checkbox-bg-js" onChange={(e) => changeCheckboxHand(el.id)} checked={el.checked} />
                                                    <span className="custom-checkbox__input-fake"></span>
                                                    <span className="custom-checkbox__label flex-col">
                                                        <span className="block font-[700] text-[14px] mb-[4px]">{el.name}</span>
                                                        <span className="block text-[14px]" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "250px" }}>
                                                            { el.incident_comment }
                                                        </span>
                                                    </span>
                                                </label>
                                                {
                                                    el.checked ? el.sub_incidents.filter(itemSub => showNonActive ? true : itemSub.status === 'active').map((subEl) =>
                                                        <label key={subEl.id} className="custom-checkbox checkbox-border border-bottom px-[12px] py-[10px] bg-[#F8F8F8]">
                                                            <input type="checkbox" className="custom-checkbox__input checkbox-bg-js" checked={subEl.checked} onChange={(e) => changeCheckboxHand(subEl.id, el.id)} />
                                                            <span className="custom-checkbox__input-fake"></span>
                                                            <span className="custom-checkbox__label flex-col">
                                                                <span className="block font-[700] text-[14px] mb-[4px]">{subEl.area}</span>
                                                                <p className="opacity-80">{subEl.desc}</p>
                                                            </span>
                                                        </label>
                                                    ) : null
                                                }
                                                
                                            </>
                                        )}
                                    </div>
                                </li>
                                <li className="col-12 col-lg-4">
                                    <div className="incident-launch-item">
                                        {
                                            fetchLoading ?
                                            <div className="d-flex items-center justify-center p-[30px] w-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                                                </svg>
                                            </div> :
                                            <div className="flex flex-col">
                                                <div className="flex items-center justify-between border-bottom-2 px-[12px] pt-[12px]">
                                                    <h5 className="font-[600] mb-[12px] mr-[15px]">Position</h5>
                                                    <div className="input-custom mb-[12px]">
                                                        <form action="" className="form-search search-border h-[32px]">
                                                            <button type="submit" className="form-search__btn">
                                                                <Icon src="img/search-loupe.svg" />
                                                            </button>
                                                            <input
                                                                type="search" className="form-search__input" value={searchPosition}
                                                                onInput={(event=>{ setSearchPosition(event.target.value); })}
                                                                placeholder="Search position..."
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="p-[12px]">
                                                    {filteredPositions && filteredPositions.map(([positionId, positionTitle])=>{
                                                        return (<label className="custom-radio w-full border-bottom pb-[12px] mb-[12px]" key={positionTitle}>
                                                            <input type="radio" className="custom-radio__input" name="position-radios" value={positionId} onChange={(e)=>{
                                                                setSelectedPosition(e.target.value);
                                                            }} />
                                                            <span className="custom-radio__input-fake"></span>
                                                            <span className="custom-radio__label">{positionTitle}</span>
                                                        </label>)
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className="col-12 col-lg-4">
                                    <div className="incident-launch-item gradient">
                                        {
                                            fetchLoading ?
                                            <div className="d-flex items-center justify-center p-[30px] w-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                                                </svg>
                                            </div> : 
                                            <>
                                                <div className="flex items-center border-bottom-3 px-[16px] py-[18px]">
                                                    <div className="mr-[8px]">
                                                        <Icon src="img/floppy-save.svg" />
                                                    </div>
                                                    <h5 className="font-[600]">Saved Incidents</h5>
                                                </div>
                                                <div className="px-[16px] py-[10px]">
                                                    {launchData?.launches?.length && launchData?.incidents ? 
                                                    launchData.launches.map((el, idx) => 
                                                    <>
                                                        <div key={el.id + '-' + launchData?.incidents.length}
                                                            // onClick={()=>{
                                                            //     onClose()
                                                            // }}
                                                            className={' pb-[10px] mb-[10px]' + 
                                                            ` ${(launchData.launches.length - 1) === idx ? '' : 'border-bottom-3' }` }>
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex flex-col">
                                                                        <span className="font-[700] mb-[4px]">
                                                                            { findName(el.incidents) }
                                                                        </span>
                                                                        <span className="opacity-80 text-[#95AACB]">
                                                                            { new Date(el.created_at).toDateString() }
                                                                        </span>
                                                                    </div>
                                                                    <button onClick={(e) => {e.preventDefault(); setConfirmDeleteModalOpenId(el.id); }} className="btn trash-delete">
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.4999H6.54867C5.67617 17.4999 4.95117 16.8266 4.88617 15.9558L4.13867 5.83325H15.8337L15.1112 15.9516C15.0487 16.8241 14.3228 17.4999 13.4487 17.4999V17.4999Z" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M10.0007 9.16675V14.1667" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M3.33398 5.83341H16.6673" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M14.1673 5.83333L13.3232 3.58167C13.079 2.93083 12.4573 2.5 11.7623 2.5H8.23898C7.54398 2.5 6.92232 2.93083 6.67815 3.58167L5.83398 5.83333" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M12.8576 9.16675L12.4992 14.1667" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M7.1418 9.16675L7.50013 14.1667" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                { getLaunchesSubs(el.incidents)?.length ? <p className="mt-[5px]">Sub incidents:</p> : null }
                                                                {
                                                                    getLaunchesSubs(el.incidents).map((subItem, subItemIndex) => {
                                                                        return <div key={subItemIndex} className='opacity-80 text-[#95AACB]'>
                                                                            <p className="mt-[3px]">{ subItem?.area }{ subItem?.execution_checklist.split(' ').join('')?.length ? ` - ${subItem.execution_checklist}` : '' }</p>
                                                                        </div>
                                                                    })
                                                                }
                                                        </div>
                                                    </>) : null}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </li>
                            </ul>
                            <div className="flex items-center justify-end">
                                <label className="custom-checkbox checkbox-border mr-[20px]">
                                    <input type="checkbox" className="custom-checkbox__input" checked={saveThisSearch} onChange={(event)=>{setSaveThisSearch(event.target.checked)}} />
                                    <span className="custom-checkbox__input-fake"></span>
                                    <span className="custom-checkbox__label">Save this search</span>
                                </label>
                                <a href="#" onClick={launchIncident} className="btn btn--primary btn--lg-3 rounded-[8px] relative">
                                    {
                                        launchLoading ? <div className="btn-loader">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="currentColor" />
                                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="currentColor" className="spinner_ajPY"/>
                                            </svg>
                                        </div> : null
                                    }
                                    Launch Incident
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            open ? <div className="modal-tabs"><div className="modal-collapsed">
                <div className="modal-collapsed__body">
                        <span className="modal-collapsed__title">Launch Incident</span>
                        <div className="modal-collapsed__actions">
                            <a onClick={(e) => {e.preventDefault(); setIsCollapse(false)}} href="#" data-collapse-show="#incident-launch" className="btn"><Icon src="img/maximize.svg" /></a>
                            <a onClick={(e) => {
                                e.preventDefault()
                                close && onClose()
                                setTimeout(() => {
                                setIsCollapse(false)
                                }, 300)
                            }} href="#" data-modal-remove="#incident-launch" className="btn"><Icon src="img/error-white.svg" /></a>
                        </div>
                    </div>
                </div>
            </div> : null }


            <ConfirmDeleteModal
                open={confirmDeleteModalOpenId}
                close={()=>{setConfirmDeleteModalOpenId(false)}}
                submit={()=>{
                    removeSavedIncident();
                }}
            />
        </>
    )
}
