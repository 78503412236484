import * as yup from 'yup';

const newCalendarEventShema = yup.object().shape({
    time: yup
        .string()
        .min(5, 'Enter a valid time XX:XX')
        .max(5, 'Enter a valid time XX:XX')
        .required('Time is required'),
    duration: yup
        .number()
        .typeError('Duration must be a number')
        .min(1, 'Min 1 minute')
        .required('Duration is required'),
    days: yup
        .number()
        .typeError('Duration must be a number')
        .max(30, 'Max 30 days')
        .required('Days is required')
        .min(1, 'Minimun 1 day'),
    description: yup
        .string()
        .required('Description is required'),
    link: yup
        .string()
        .required('Link is required'),
})

export default newCalendarEventShema;
