import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import incidentsSlice from './slices/incidentsSlice';
import notificationSlice from "./slices/notificationSlice";
import launchSlice from './slices/launchSlice';
import resourceRequestSlice from './slices/resourceRequestSlice';
import executionChecklistSlice from './slices/executionChecklistSlice';
import impactsSlice from './slices/impactsSlice';
import meetingsSlice from "./slices/meetingsSlice";
import userManagementSlice from "./slices/admin/userManagementSlice";
import adminIncidentsSlice from "./slices/admin/adminIncidentsSlice";
import settingsSlice from "./slices/settingsSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        incidents: incidentsSlice,
        notifications: notificationSlice,
        launch: launchSlice,
        resourceRequest: resourceRequestSlice,
        executionChecklist: executionChecklistSlice,
        impacts: impactsSlice,
        meetings: meetingsSlice,
        userManagement: userManagementSlice,
        adminIncidents: adminIncidentsSlice,
        settings: settingsSlice,
    },
});