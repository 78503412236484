import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import { userManagement } from '../../api/index';
import { useSelector } from "react-redux";
import { getSelectedIncident } from "../../redux/slices/incidentsSlice";

const AddPositionModal = ({ open, close, created, edit, selectedPermission }) => {
    const view = selectedPermission && !edit;

    const selectedIncident = useSelector(getSelectedIncident);

    const clearData = {
        user_id: null,
        component: "",
        section: "",
        incident_id: selectedIncident?.id,
        positions: []
    };

    const [payload, setPayload] = useState(clearData);

    const [users, setUsers] = useState(null);
    const [positions, setPositions] = useState(null);

    const [addNewShow, setAddNewShow] = useState(false);
    const [newName, setNewName] = useState("");

    const [userRoleError, setUserRoleError] = useState(false);

    const createPosition = async() => {
        const errors = validateForm()

        if (Object.values(errors).includes(true)) return

        let createPositionRes = await userManagement.createIncidentPosition(selectedIncident?.id, payload);

        if(createPositionRes?.data?.success){
            closeModal();
            if(created){ created(); }
        }
    }

    const closeModal = () => {
        close();
    }

    const [formErrors, setFormErrors] = useState({})

    const validateForm = () => {
        const obj = Object.entries(payload)
        const errors = {}
        for (let i = 0; i < obj.length; i++) {
            const [name, val] = obj[i]
            if (!val) {
                errors[name] = true
            }
        }

        setFormErrors(errors)

        if (!payload.positions.length) {
            setFormErrors(prev => ({...prev, positions: true}))
        }

        return errors
    }


    const fetchOptions = async() => {
        let usersRes = await userManagement.getAllUsers();
        setUsers(usersRes.data.data);

        let positionsRes = await userManagement.getPositions();
        setPositions(positionsRes.data.data);
    }

    const currentUser = payload.user_id && payload.positions ? users.find(item => +item.id === +payload.user_id) : null;

    const checkUserRoleError = (permissionId) => {
        if(currentUser && !currentUser?.roles?.map(item => item.id).includes(permissionId)){
            setUserRoleError(true);
        } else {
            setUserRoleError(false);
        }
    }

    const createMainPosition = async() => {
        let res = await userManagement.createPosition({ name: newName });
        
        if(res?.data?.success){
            let positionsRes = await userManagement.getPositions();
            setPositions(positionsRes.data.data);
        }
    }

    const updatePosition = async() => {
        await userManagement.updateIncidentPosition(selectedIncident?.id, selectedPermission?.id, payload);

        closeModal();
        if(created){ created(); }
    }

    useEffect(()=>{
        if(selectedIncident?.id){
            fetchOptions();
        }
    },[selectedIncident])

    useEffect(()=>{
        if(payload?.positions?.length) payload.positions.forEach(item => checkUserRoleError(item.id));
    },[payload])

    useEffect(()=>{
        if(selectedPermission){
            setPayload({
                user_id: selectedPermission.user_id,
                component: selectedPermission.component,
                section: selectedPermission.section,
                incident_id: selectedPermission?.id,
                positions: selectedPermission.items.map(item => { return { id: item.position_id } })
            })
        } else {
            setPayload(clearData);
            setUserRoleError(false);
        }
    },[selectedPermission])

    return (
        <FullHeightModal
            title={selectedPermission && edit ? "Edit Position" : selectedPermission && !edit ? "View Position" : "Add Position"}
            headerIcon={IconPlusAdd}
            open={open}
            close={closeModal}
            submitBtnText={selectedPermission && edit ? "Update Position" : selectedPermission && !edit ? null : "Create Position"}
            submitMethod={selectedPermission && edit ? updatePosition : selectedPermission && !edit ? null : createPosition}
        >
            <div className="row gutter-x-16" style={{ pointerEvents: view ? 'none' : 'initial' }}>
                {/* <div className="col-12">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Position Name</label>
                        <input className="input-custom__input" type="text" placeholder="Position name" />
                    </div>
                </div> */}
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Position User</label>
                        <SelectBox 
                            className={`default-select ${formErrors.user_id && 'select-error'}`}
                            propsOptions={
                                users?.map(item => {
                                    return {
                                        label: item.name, value: item.id
                                    }
                                })
                            }
                            value={payload.user_id}
                            change={(value) => {
                                setPayload((prevState) => {
                                    return { ...prevState, user_id: value }
                                })
                                setFormErrors(prev => ({...prev, user_id: false}))
                            }}
                        />
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Component</label>
                        <input
                            className={`input-custom__input ${formErrors.component && 'error-field'}`} type="text" placeholder="Enter component"
                            value={payload.component}
                            onInput={(e) => {
                                setPayload((prevState) => {
                                    return { ...prevState, component: e.target.value }
                                })
                                setFormErrors(prev => ({...prev, component: false}))
                            }}
                        />
                    </div>
                </div>

                <div className="col-12">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Section</label>
                        <input
                            className={`input-custom__input ${formErrors.section && 'error-field'}`} type="text" placeholder="Enter section"
                            value={payload.section}
                            onInput={(e) => {
                                setPayload((prevState) => {
                                    return { ...prevState, section: e.target.value }
                                })
                                setFormErrors(prev => ({...prev, section: false}))
                            }}
                        />
                    </div>
                </div>

                <div className="col-12">
                    <h5 className="mb-[16px] font-[600]">Section Positions</h5>
                </div>

                <div className="col-12 expandable">
                    <div className="expandable-target row gutter-x-16">
                        {
                            positions?.map(item => {
                                return <div className="col-lg-6" key={item.id}>
                                    <label className="custom-checkbox checkbox-border mb-[18px]">
                                        <input
                                            type="checkbox"
                                            className={`custom-checkbox__input`}
                                            value={item.id}
                                            checked={payload.positions.map(itemM => itemM.id).includes(item.id)}
                                            onChange={(e) => {
                                                if(e.target.checked){
                                                    setPayload((prevState) => {
                                                        return { ...prevState, positions: [
                                                            ...prevState.positions,
                                                            { id: +e.target.value }
                                                        ] }
                                                    });
                                                    checkUserRoleError(item.id);
                                                } else {
                                                    setPayload((prevState) => {
                                                        return { ...prevState, positions: prevState.positions.filter(itemF => +itemF.id !== +e.target.value) }
                                                    });
                                                    setUserRoleError(false);
                                                }
                                                setFormErrors(prev => ({...prev, positions: false}))
                                            }}
                                        />
                                        <span className={`custom-checkbox__input-fake ${formErrors.positions && 'radio-error'}`}></span>
                                        <span className="custom-checkbox__label" style={{ textTransform: 'capitalize' }}>
                                            { item.name.split('_').join(' ') }
                                        </span>
                                    </label>
                                </div>
                            })
                        }
                    </div>
                    { !view ?
                    <div className="row gutter-x-16">
                        <div className="col-lg-6">
                            <button onClick={(e) => {
                                e.preventDefault();
                                setAddNewShow(!addNewShow);
                            }} className="btn-expandable-show btn btn--primary btn--lg-3 rounded-[8px] w-full mb-[16px]">
                                { addNewShow ? 'Close' : 'Add a new position' }
                            </button>
                        </div>
                        { addNewShow ? <div className="col-lg-6">
                            <div className="expandable-input-container input-custom h-36px input-border mb-[16px]">
                                <div className="input-custom__wrap-input">
                                    <input
                                        className="input-custom__input expandable-input" type="text" placeholder="Position name"
                                        value={newName} onInput={(e)=>{ setNewName(e.target.value); }}
                                    />
                                    <button
                                        className="btn-expandable-add input-icon-right btn btn--primary btn--lg-3 rounded-[8px] right-0"
                                        style={{ right: "0" }} onClick={(e)=>{ e.preventDefault(); createMainPosition(); }}
                                    >✓</button>
                                </div>
                            </div>
                        </div> : null }
                    </div> : null }
                    { userRoleError && !view ? 
                    <div className="row gutter-x-16">
                        <div className="col-lg-12">
                            <h4>The selected user does not have permission for this position</h4>
                        </div>
                    </div> : null }
                </div>
            </div>
        </FullHeightModal>
    )
}

export default AddPositionModal;