import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ImgGoogle from '../../assets/img/ico-google.svg';
import AuthLayout from "./AuthLayout";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { fetchLogin } from "../../redux/thunks/authThunks";
import RequestDemoModal from '../../components/Modals/RequestDemoModal';
import RequestDemoSuccessModal from '../../components/Modals/RequestDemoSuccessModal';
import { api } from '../../api';
import { authApi } from '../../api/utils/authApi';
import ForgotPasswordSend from '../../components/Modals/ForgotPasswordSend';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState({
        login: "",
        password: ""
    })
    const [remember, setRemember] = useState(false)

    const [emailError, setEmailError] = useState(false)

    const [requestModal, setRequestModal] = useState(false)
    const [requestSuccessModal, setRequestSuccessModal] = useState(false)
    const [forgotPassModal, setForgotPassModal] = useState({open: false, email: null})
    const [requestDemoSuccessCompanyName, setRequestDemoSuccessCompanyName] = useState(false)


    const changeData = (e) => {
        if (e.target.name === 'login') {
            setEmailError(false)
        }
        setLoginData((prevState)=>{
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        });
    }

    const forgotPass = async (e) => {
        e.preventDefault()
        const emailValidator = (val) => /\S+@\S+\.\S+/.test(val)

        if (!emailValidator(loginData.login)) {
            setEmailError(true)
            return
        }

        console.log(loginData.login);
        const res = await authApi.forgotPassword(loginData.login)

        setForgotPassModal({open: true, email: loginData.login})

    }


    const submitForm = async(e) => {
        e.preventDefault();
        if(!loginData.login.length){
            NotificationManager.error("Input Login please");
            return false;
        }

        if(!loginData.password.length){
            NotificationManager.error("Input Password please");
            return false;
        }

        try{
            const res = await dispatch(fetchLogin({
                "email": loginData.login,
                "password": loginData.password,
                "remember": remember
            })).unwrap();

            if(res?.access_token && res?.refresh_token){
                NotificationManager.success("Success");
                navigate('/');
            }

        }catch(e){
            NotificationManager.error('Wrong email or password');
            // setLoading(false);
        }

        // setTimeout(() => {
        //     navigate('/authentication');
        // }, 1000);
    }

    return (
        <AuthLayout>
            <div className="form max-w-[350px] mx-auto">
                <div className="form-header text-center">
                    <h1 className="heading-1 font-[600]">Sign in</h1>
                    <p className="mt-[12px] text-06-2">
                        Login to your personal account
                    </p>
                    <div className="flex flex-col w-full mt-[28px] mb-[-15px]">
                        <a href="#" className="btn btn--shadow p-[11px] rounded-[300px] w-full mb-[15px]">
                            <span className="ico ico-22 mr-[10px]"><img src={ImgGoogle} /></span>
                            <span className="def-text-1 font-[500]">Login with Google</span>
                        </a>
                    </div>
                    <div className="mt-[28px] or-line">
                        <span className="text-06-2 def-text-1">or</span>
                    </div>
                </div>
                <div className="form-body mt-[26px]">
                    <div className="row mb-[-20px]">
                        <div className="col-12 mb-[20px]">
                            <div className="input-custom">
                                <label className="input-custom__label font-[500] mb-[10px]">
                                    Login
                                </label>
                                <input
                                    className={`input-custom__input ${emailError && 'input-error' }`} type="mail" name="login" placeholder="Enter your email..."
                                    value={loginData.login} onInput={changeData}
                                />
                            </div>
                        </div>
                        <div className="col-12 mb-[20px]">
                            <div className="input-custom">
                                <div className="flex items-center flex-wrap justify-between">
                                    <label className="input-custom__label font-[500] mb-[10px] mr-[10px]">
                                        Password
                                    </label>
                                    <a href="#" onClick={forgotPass} className="btn font-[500] text-primary mb-[10px]">
                                        Forgot Password?
                                    </a>
                                </div>
                                <input
                                    className="input-custom__input" type="password" name="password" placeholder="Enter your password..."
                                    value={loginData.password} onInput={changeData}
                                />
                            </div>
                        </div>
                        <div className="col-12 mb-[20px]">
                            <div className="flex flex-col mb-[-15px]">
                                <label className="custom-checkbox mb-[15px]">
                                    <input type="checkbox" className="custom-checkbox__input" checked={remember} onChange={(e)=> { setRemember(e.target.checked) }} />
                                    <span className="custom-checkbox__input-fake"></span>
                                    <span className="custom-checkbox__label">
                                        Remember me
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-footer mt-[26px]">
                    <button onClick={submitForm} className="btn btn--primary p-[11px] 
                    rounded-[300px] w-full mb-[26px]">
                        <span className="def-text-1 font-[500]">
                            Sign In
                        </span>
                    </button>
                    <p className="def-text-1">
                        New to Beacon? &nbsp;
                        <button onClick={(e) => {e.preventDefault();setRequestModal(true)}} className="btn text-primary">
                            Request a Demo
                        </button>
                    </p>
                </div>
            </div>

            <NotificationContainer />

            <RequestDemoModal
                open={requestModal}
                close={() => setRequestModal(false)}
                submit={(companyName) => {
                    setRequestDemoSuccessCompanyName(companyName);
                    setRequestModal(false);
                    setRequestSuccessModal(true);
                }}
            />
            
            <RequestDemoSuccessModal
                open={requestSuccessModal}
                companyName={ requestDemoSuccessCompanyName }
                close={() => setRequestSuccessModal(false)}
            />

            <ForgotPasswordSend 
                open={forgotPassModal.open}
                email={forgotPassModal.email}
                close={() => setForgotPassModal({open: false, email: null})}
            />

        </AuthLayout>
    )
}

export default Login