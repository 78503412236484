import React, { useState, useEffect } from 'react'
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar, Chart } from 'react-chartjs-2';
import { 
    customScale, 
    // fill_array
} from '../../helpers/chartFunctions';
import { Calendar } from '../../components/Calendar/Calendar';

import { 
    // useDispatch,
    useSelector 
} from "react-redux";
import { getSelectedIncident, } from "../../redux/slices/incidentsSlice";

import { ExecutionChecklist } from "./ExecutionChecklist";
import { IncidentResourceRequest } from "./IncidentResourceRequest";

import { getResourceRequests, getResourceRequestOptions } from "../../redux/slices/resourceRequestSlice";

import { Link } from 'react-router-dom';

import { ReadClose } from "../../helpers/checkPermission";

export const IncidentWorkspace = () => {
    const calendarRead = ReadClose('meetings', false);

    const selectedIncident = useSelector(getSelectedIncident);
    // console.log('selectedIncident:', selectedIncident);
    const resourceRequestData = useSelector(getResourceRequests);

    const resourceRequestOptions = useSelector(getResourceRequestOptions);

    ChartJS.register(customScale)

    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const generateDate = (date) => {
        return `${shortMonthNames[new Date(date).getMonth()]} ${new Date(date).getDate()}`
    }

    const generateDateForDates = () => {
        let months_labels = resourceRequestData?.map(item => { return generateDate(item.created_at) });

        let dates = [];
        if(months_labels){
            for (let index = 0; index < months_labels.length; index++) {
                let datesLabels = dates.map(item => item.date);
                if(!dates.length || !datesLabels.includes(months_labels[index])){
                    dates.push({
                        date: months_labels[index],
                        value: 1
                    })
                } else {
                    dates[dates.findIndex(item => item.date === months_labels[index])].value ++;
                }
            }
        }

        return dates
    }

    let dates = generateDateForDates();

    const lineChartData = {
        month_array: dates?.map(item => item.date),
        data_lines: [
            {
                line: dates?.map(item => item.value)
            },
            // {
            //     line: [
            //         1, 1.5, 2.1, 1.7, 1.8, 2.1, 2, 
            //         1.8, 2.5, 2.9, 3.2, 3.9, 3.8, 3.9,
            //         4.7, 4, 4, 3.5, 3.9, 4.2, 4.3, 
            //         5.1, 5.5, 5.7, 5.5, 5.2, 5.7, 5, 7
            //     ]
            // }
        ],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    min: 0,
                    // max: 100,
                    grid: {
                        drawTicks: false,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 12,
                        stepSize: 1.0,
                        font: {
                            color: "rgba(18, 19, 39, 0.6)",
                            size: 12,
                            weight: 400,
                            family: "Lato, sans-serif"
                        }
                    },
                },
                y: {
                    min: 0,
                    // max: 6,
                    grid: {
                        drawTicks: false,
                        color: (context) => {
                            if(context.index === 0){
                                return "";
                            }else{
                                return 'rgba(0, 0, 0, 0.1)';
                            }
                        },
                    },
                    border: {
                        dash: [5, 5],
                        color: 'rgba(0,0,0,0)'
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 12,
                        callback: function(label, index, labels) {
                            return `${label}.00`;
                        },
                        stepSize: 1.0,
                        font: {
                            color: "rgba(18, 19, 39, 0.6)",
                            size: 12,
                            weight: 400,
                            family: "Lato, sans-serif"
                        }
                    },
                    
                }
            },
            plugins: {
                customScale,
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true
                },
                datalabels: {
                    display: false,
                },
            },
            elements: {
                point: {
                    hoverBorderWidth: 0,
                    borderWidth: 0,
                    hoverRadius: 0,
                    radius: 0,
                },
                line: {
                    borderWidth: 2,
                }
            },
        }
    }

    const statusColors = {
        'in_progress': '#2F80ED',
        'not_started': '#B3B5D5',
        'completed': '#02BC77',
        'pending': '#F59E0C',
        'canceled': '#EA5E42',
    }

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                hoverBorderWidth: 0,
                borderWidth: 0,
                hoverRadius: 0,
                radius: 0,
            },
            line: {
                borderWidth: 2
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += resourceRequestOptions?.statuses[Object.entries(statusColors).find(item => item[1] === context.dataset.backgroundColor)[0]] + `: ${context.parsed.y}`;
                        }
                        return label;
                    }
                }
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            x: {
                min: 0,
                // max: 25,
                grid: {
                    drawTicks: false,
                    display: false,
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
                
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    padding: 12,
                    stepSize: 1.0,
                    font: {
                        color: "rgba(18, 19, 39, 0.6)",
                        size: 12,
                        weight: 400,
                        family: "'Lato', sans-serif"
                    }
                }
                
            },
            
            y: {
                min: 0,
                // max: 25,
                grid: {
                    drawTicks: false,
                    drawBorder: false,
                    
                    color: (context) => {
                        if(context.index === 0){
                            return "";
                        }else{
                            return 'rgba(0, 0, 0, 0.1)';
                        }
                    },
                    zeroLineWidth: 1,
                },
                border: {
                    dash: [5, 5],
                    color: 'rgba(0,0,0,0)'
                },
                
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    padding: 12,
                    stepSize: 5,
                    font: {
                        color: "rgba(18, 19, 39, 0.6)",
                        size: 12,
                        weight: 400,
                        family: "'Lato', sans-serif"
                    }
                }
            }
        }
    }
        
    // let chart_line = fill_array(
    //     {
    //         data: lineChartData.month_array,
    //         coll_split: 4,
    //         array_fill: function(){
    //             for(let j = 0; j < lineChartData.data_lines.length; j++){
    //                 return lineChartData.data_lines[j].line;
    //             } 
    //         }(),
    //         char: ""
    //     }
    // );


    const lineData = {
        labels: lineChartData.month_array,
        datasets: [
            {
                data: lineChartData.data_lines[0].line,
                borderColor: '#0275FF',
                tension: 0,
                fill: true,
                backgroundColor: (context) => {
                    const { ctx } = context.chart
                    const bg = ctx.createLinearGradient(0, 0, 0, 300)
                    bg.addColorStop(0, 'rgba(47, 128, 237, 0.2)')
                    bg.addColorStop(1, 'rgba(47, 128, 237, 0)')
                    return bg
                }
            },
            // {
            //     data: lineChartData.data_lines[1].line,
            //     backgroundColor: "transparent",
            //     borderColor: '#FF9800',
            //     tension: 0,
                
            // },
        ],
    }

    const [byStatusData, setByStatusData] = useState(null);
    const [barsData, setBarsData] = useState(null);
    const [barChartKey, setBarChartKey] = useState(0);

    const byStatusGenerate = () => {
        let dates = [];
        let dataLines = {};

        if(resourceRequestData){
            for (let index = 0; index < resourceRequestData.length; index++) {
                if(!dates.length || (!dates.find(item => item.date === generateDate(resourceRequestData[index].created_at) && item.status === resourceRequestData[index].status))){
                    dates.push({
                        date: generateDate(resourceRequestData[index].created_at),
                        status: resourceRequestData[index].status,
                        value: 1
                    })
                } else {
                    dates.find(item => item.date === generateDate(resourceRequestData[index].created_at)).value++;
                }
            }
            
            // заполняем объект статус именами
            [...new Set(dates.map(item => item.status))].forEach(item => {
                dataLines[item] = [];
            });
    
            [...new Set(dates.map(item => item.date))].forEach(dateItem => {
                // фильтруем данные по дате
                let filteredDataItem = dates.filter(item => item.date === dateItem);
                // виды статусов
                let statusNames = [...new Set(dates.map(item => item.status))];

                for (let index = 0; index < statusNames.length; index++) {
                    let findedByStatus = filteredDataItem.find(itemF => itemF.status === statusNames[index]);
                    // если нашли данные по такому же статусу в данной дате, то пушим количество rrf, если нет то 0
                    if(findedByStatus){
                        dataLines[statusNames[index]].push(findedByStatus.value)
                    } else {
                        dataLines[statusNames[index]].push(0);
                    }
                }
            })
        }

        setByStatusData({
            dates,
            dataLines,
        });

        setBarsData({
            labels: [...new Set(dates.map(item => item.date))],
            datasets: Object.entries(dataLines).map(item => {
                return {
                    data: item[1],
                    backgroundColor: statusColors[item[0]],
                    borderRadius: 10,
                    barPercentage: 0.60,
                    categoryPercentage: 0.25
                }
            })
        });

        setBarChartKey((prevState) => ++prevState);
    }

    useEffect(()=>{
        byStatusGenerate();
    },[])

    useEffect(()=>{
        byStatusGenerate();
    },[resourceRequestData])

    return (
        <div className="dash-page-content-item">
            <div className="card card-secondary card-border mb-[20px]">
                <div className="card-header px-[20px] pt-[16px] flex flex-wrap justify-between">
                    <div className="card-header--item mb-[20px]">
                        <h2 className="color-primary mb-[12px]">
                            {selectedIncident.name} - { selectedIncident.selected_sub_incident.inc_number }
                        </h2>
                        <p>FEMA Registration Number: { selectedIncident.selected_sub_incident.inc_output }</p>
                    </div>
                    <div className="card-header--item mb-[20px]">
                        <a href="/#" className="btn btn-white rounded-[300px] px-[11px] py-[5px]">
                            <span className="text-[12px] text-color-60">Delegation of Authority</span>
                        </a>
                    </div>
                </div>
                {
                    selectedIncident?.incident_comment ? <div className="card-body px-[20px] pb-[16px]">
                        <div className="text-color-60 leading-[22px]">
                            <p className="">
                                { selectedIncident.incident_comment }
                            </p>
                        </div>
                    </div> : null
                }
            </div>
            <div className="row guttres-20">
                <div className={calendarRead ? `col-xl-8 col-lg-6 col-12` : 'col-xl-12 col-lg-12 col-12'}>
                    <div className="card card-border mb-[20px]">
                        <div className="card-header flex flex-wrap justify-between p-[16px] pb-0">
                            <div className="card-header--item mb-[16px]">
                                <h5 className="font-semibold">
                                    Execution Checklist
                                </h5>
                            </div>
                            <div className="card-header--item mb-[16px]">
                                <Link to="/execution-checklist" className="btn">
                                    <span className="color-primary">View all</span>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <ExecutionChecklist innerPage={true} />
                        </div>
                    </div>
                    <div className="card card-border">
                        <div className="card-header flex flex-wrap justify-between p-[16px] pb-0">
                            <div className="card-header--item mb-[16px]">
                                <h5 className="font-semibold">
                                    Resource Request
                                </h5>
                            </div>
                            <div className="card-header--item mb-[16px]">
                                <Link to="/resource-request" className="btn">
                                    <span className="color-primary">View all</span>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <IncidentResourceRequest innerPage={true} />
                        </div>
                    </div>
                </div>
                { calendarRead ? <div className="col-xl-4 col-lg-6 col-12">
                    <Calendar />
                </div> : null }
            </div>
            <div className="row guttres-20">
                <div className="col-lg-6 col-sm-12 pt-[20px]">
                    <div className="chart-block">
                        <div className="chart-header p-[16px] pb-[24px]">
                            <span className="def-text-2 font-[600]">
                                RRFs over time of incedent
                            </span>
                        </div>
                        { resourceRequestData ? 
                        <div className="chart-body px-[16px] pb-[20px]">
                            <Chart
                                id='LineChart1902231'
                                type='line'
                                data={lineData}
                                options={lineChartData.options}
                                width="444" 
                                height="222" 
                                style={{display:' block', boSsizing:' border-box', width: '444px',  height:' 222px'}} 
                            />
                        </div> :
                        <div className="chart-body px-[16px] pb-[20px]">
                            <h3>Resource Request List Is Empty</h3>
                        </div> }
                        {/* <div className="chart-footer p-[16px]">
                            <ul className="chart-list mb-[-8px]">
                                <li className="chart-list--item mb-[8px]">
                                    <div className="label-chart">
                                        <span className="chart-status mr-[8px]" style={{backgroundColor:'#2F80ED'}}></span>
                                        Blue Label
                                    </div>
                                </li>
                                <li className="chart-list--item mb-[8px]">
                                    <div className="label-chart">
                                        <span className="chart-status mr-[8px]" style={{backgroundColor:'#FF9800'}}></span>
                                        Yellow Label
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 pt-[20px]">
                    <div className="chart-block">
                        <div className="chart-header p-[16px] pb-[24px]">
                            <span className="def-text-2 font-[600]">
                                RRFs over time of incedent
                            </span>
                        </div>
                        { byStatusData?.dataLines  && lineData ? <>
                            <div className="chart-body px-[16px] pb-[20px]" key={barChartKey}>
                                <Bar
                                    id='BarChart00912312'
                                    data={barsData}
                                    options={barChartOptions}
                                    width="444" 
                                    height="222" 
                                    style={{display: 'block', boxSizing: 'border-box', width: '444px', height: '222px'}}
                                />
                            </div>
                            <div className="chart-footer p-[16px]">
                                <ul className="chart-list mb-[-8px]">
                                    {/* <li className="chart-list--item mb-[8px]">
                                        <div className="label-chart">
                                            <span className="chart-status mr-[8px]" style={{backgroundColor:'#2F80ED'}}></span>
                                            Blue Label
                                        </div>
                                    </li>
                                    <li className="chart-list--item mb-[8px]">
                                        <div className="label-chart">
                                            <span className="chart-status mr-[8px]" style={{backgroundColor:'#FF9800'}}></span>
                                            Yellow Label
                                        </div>
                                    </li>
                                    <li className="chart-list--item mb-[8px]">
                                        <div className="label-chart">
                                            <span className="chart-status mr-[8px]" style={{backgroundColor:'#435776'}}></span>
                                            Gray Label
                                        </div>
                                    </li> */}
                                    {
                                        Object.entries(byStatusData.dataLines).map(item => item[0]).map((item, index) => {
                                            return <li className="chart-list--item mb-[8px]" key={index}>
                                                <div className="label-chart">
                                                    <span className="chart-status mr-[8px]" style={{ backgroundColor: statusColors[item] }}></span>
                                                    { resourceRequestOptions?.statuses?.[item] }
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </> : <div className="px-[16px] pb-[20px]">
                            <h3>Loading...</h3>
                        </div> }
                    </div>
                </div>
            </div>
        </div>
    )
}
