import {createAsyncThunk} from "@reduxjs/toolkit";
import {adminIncidents, incidents} from "../../../api";

export const adminFetchAllIncidents = createAsyncThunk(
    '/adminFetchAllIncidents',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await adminIncidents.getAllIncidents()
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminFetchIncidentsType = createAsyncThunk(
    '/adminFetchIncidentsType',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await adminIncidents.getAdminIncidentsType()
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminCreateIncident = createAsyncThunk(
    '/adminCreateIncident',
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await adminIncidents.adminCreateIncident(payload)
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminDeleteIncident = createAsyncThunk(
    '/adminDeleteIncident',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await adminIncidents.adminDeleteIncident(id)
            return {id}
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminUpdateIncident = createAsyncThunk(
    '/adminUpdateIncident',
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {id, data} = payload
            const res = await adminIncidents.updateIncident(id, data)
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchSubIncidents = createAsyncThunk(
    '/incident-sub-incidents',
    async (id, { rejectWithValue }) => {
        try {
            const res = await adminIncidents.getSubIncidents(id);
            return res.data.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateSubIncident = createAsyncThunk(
    '/incident/fetchCreateSubIncident',
    async (data, { rejectWithValue }) => {
        try {
            const res = await adminIncidents.createSubIncident(data.incId, data.payload);
            return {incId: data.incId, ...res.data};
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);