import { createAsyncThunk } from '@reduxjs/toolkit';
import { executionChecklist } from '../../api/index';

export const executionChecklistFetch = createAsyncThunk(
    '/executionChecklistFetch',
    async (id, { rejectWithValue }) => {
        try {
            const res = await executionChecklist.getExecutionChecklist(id);
            const resPriorities = await await executionChecklist.getTaskPriorities();

            return {
                ...res.data,
                data: {
                    ...res.data.data,
                    priorities: resPriorities?.data?.data?.statuses 
                }
            };
        } catch (err) {
            if(err?.response.data.message === "Unauthenticated."){
                window.location.replace('/login');
            }
            return rejectWithValue(err?.response.data);
        }
    },
);

export const createTaskFetch = createAsyncThunk(
    '/createTaskFetch',
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await executionChecklist.createTask(payload);
            return res.data.data.new_task
        } catch (err) {
            console.log('task created error', err);

            if(err?.response.data.message === "Unauthenticated."){
                window.location.replace('/login');
            }
            return rejectWithValue(err?.response.data);
        }
    },
);

export const deleteTaskFetch = createAsyncThunk(
    '/deleteTaskFetch',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await executionChecklist.deleteTask(id);

            return {id}
        } catch (err) {
            console.log('task created error', err);

            if(err?.response.data.message === "Unauthenticated."){
                window.location.replace('/login');
            }
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getParentPositions = async (position_id) => {
    try {
        const res = await executionChecklist.getParentPositions(position_id);

        return res.data
    } catch (error) {
        return false
    }
}
