import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSelectedIncident} from "../../redux/slices/incidentsSlice";
import {fetchCreateMeetings, fetchUpdateMeetings} from "../../redux/thunks/meetingsThunks";
import {NotificationManager} from "react-notifications";
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectBox } from "../ui/SelectBox";
import { Controller, useForm } from 'react-hook-form';
import newCalendarEventShema from '../../schemas/calendarEventShemas';

export const ModifyCalendarEvent = ({ data, onCancel, mode, selectedDate, allUsers = [] }) => {
    const dispatch = useDispatch()
    const inc = useSelector(getSelectedIncident)
    const getDate = () => {
        const date = new Date()
        if (mode === 'edit' && data) {
            const [hours, minutes] = data.date.split(' ')[1].split(':')
            return `${hours}:${minutes}`
        }
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }

    const [diasbleBtn, setDisableBtn] = useState(false)

    const { handleSubmit, control, setValue, clearErrors, formState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(newCalendarEventShema),
    })

    const formatNumFields = (e, name) => {
        const onlyNumbers = (val) => val.replace(/\D/g, '')
        setValue(name, onlyNumbers(e.target.value))
        clearErrors(name)
    }

    const [selectedUser, setSelectedUser] = useState(data?.users.map(el => el.id) || []);
    const [users, setUsers] = useState(data?.users?.map(item => item.id) || [])

    const formatTime = (val) => {
        let formattedTime = val.toString().replace(/\D/g, '');
        if (formattedTime.length > 2) {
            formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
        }
        setValue('time', formattedTime)
    }

    useEffect(() => {
        setValue('time', getDate())
        setValue('description', data?.description || '')
        setValue('link', data?.link || '')
        setValue('duration', data?.duration || 10)
        setValue('days', data?.days || 1)
    }, [])

    const addNewEvent = (formData) => {
        console.log(formData, 'formData')

        const obj = {
            description: formData.description,
            date: `${selectedDate} ${formData.time}`,
            link: formData.link,
            incident_id: inc.id,
            selectedDate,
            id: data?.id || null,
            duration: formData.duration,
            users: selectedUser,
            days: formData.days,
        }

        const req = async () => {
            setDisableBtn(true)
            try {
                const action = mode === 'create' ? fetchCreateMeetings(obj) : fetchUpdateMeetings(obj);
                const res = await dispatch(action).unwrap()
                NotificationManager.success(mode === 'create' ? "Meet has been created" : "Meet has been updated");
                onCancel?.()
            } catch (err) {
                console.error(err, 'error')
                NotificationManager.error("Something went wrong");
            }
            setDisableBtn(false)
        }
       req()
    }

    const handleWheel = (event) => {
        event.preventDefault()
    }
    
    const addWheelListener = (fieldName) => {
        const inputElement = document.getElementById(fieldName)
        if (inputElement) {
            inputElement.addEventListener('wheel', handleWheel, { passive: false })
            return () => {
                inputElement.removeEventListener('wheel', handleWheel)
            }
        }
    }
    
    useEffect(() => {
        const cleanupDuration = addWheelListener('duration')
        const cleanupDays = addWheelListener('days')
    
        return () => {
            cleanupDuration()
            cleanupDays()
        }
    }, [])

    return (
        <>
            <form className='events-list edit-event-form' onSubmit={handleSubmit(addNewEvent)}>
                <div className='calendar-event-input-block'>
                    <label htmlFor="time">Time</label>
                    <Controller
                        name="time"
                        defaultValue=""
                        control={control}
                        render={({ field }) => 
                            <input
                                maxLength={5}
                                className={`datapicker-time ${formState.errors?.time && 'input-error' }`} type="text" id='time'
                                {...field}
                                onChange={(e) => { field.onChange(); formatTime(e.target.value) }}
                                />
                        }
                    />
                    <p className='error-field-text'>{formState.errors.time?.message}</p>
                </div>

                <div className='calendar-event-input-block'>
                    <label htmlFor="duration">Duration (minutes)</label>
                    <Controller
                        name="duration"
                        control={control}
                        defaultValue={10}
                        render={({ field }) => 
                            <input
                                maxLength={5}
                                className={`${formState.errors?.duration && 'error-field' }`} type="number" id='duration'
                                {...field}
                                onChange={(e) => { field.onChange(); formatNumFields(e, 'duration') }}
                                />
                        }
                    />
                    <p className='error-field-text'>{formState.errors.duration?.message}</p>
                </div>

                <div className='calendar-event-input-block'>
                    <label htmlFor="days">Within days</label>
                    <Controller
                        name="days"
                        defaultValue={1}
                        control={control}
                        render={({ field }) => 
                            <input
                                maxLength={5}
                                className={`${formState.errors?.days && 'error-field' }`} type="text" id='days'
                                {...field}
                                onChange={(e) => { field.onChange(); formatNumFields(e, 'days') }}
                                />
                        }
                    />
                    <p className='error-field-text'>{formState.errors.days?.message}</p>
                </div>

                <div className='calendar-event-input-block'>
                    <label htmlFor="description">Description</label>
                    <Controller
                        name="description"
                        defaultValue=""
                        control={control}
                        render={({ field }) => 
                            <input
                                className={`${formState.errors?.description && 'error-field' }`} type="text" id='description'
                                {...field}
                                onChange={(e) => { field.onChange(); setValue("description", e.target.value.trimStart()) }}
                            />
                        }
                    />
                    <p className='error-field-text'>{formState.errors.description?.message}</p>
                </div>

                <div className='calendar-event-input-block'>
                    <label htmlFor="link">Link</label>
                    <Controller
                        name="link"
                        defaultValue=""
                        control={control}
                        render={({ field }) => 
                            <input
                                className={`${formState.errors?.link && 'error-field' }`} type="text" id='link'
                                {...field}
                                onChange={(e) => { field.onChange(); setValue("link", e.target.value.trimStart()) }}
                            />
                        }
                    />
                    <p className='error-field-text'>{formState.errors.link?.message}</p>
                </div>
                <div className='calendar-event-input-block'>
                    <label htmlFor="link">Users</label>
                    <ul className="mb-[5px]">
                        {
                            selectedUser?.length ? selectedUser.map((item, index)=>{
                                return <li key={index} className="d-flex items-center justify-between mb-[5px]">
                                    <p style={{color: '#fff', fontWeight: '400'}}>{ allUsers.find(itemF => +itemF.id === +item)?.full_name || allUsers.find(itemF => +itemF.id === +item)?.name || allUsers.find(itemF => itemF.id === item)?.email }</p>
                                    <a
                                        href="#" className="item-delete-btn"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setSelectedUser(prevState => {
                                                return prevState.filter(itemF => itemF !== item);
                                            });
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.66797 6.66663L13.3346 13.3333" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M13.3346 6.66663L6.66797 13.3333" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                </li>
                            }) : null
                        }
                    </ul>
                    <div className="d-flex items-center">
                        <SelectBox
                            key={users?.length + '-' + allUsers?.length}
                            className={`default-select w-full flex-1 `}
                            propsOptions={
                                allUsers?.filter(item => !users.includes(item.id))?.map(item => {
                                    return {
                                        label: item.full_name, value: item.id
                                    }
                                })
                            }
                            value={selectedUser}
                            change={(value) => {
                                setSelectedUser(prev => [...prev, value]);
                            }}
                        />
                        {/* <a
                            href="#"
                            className='action-calendar-btn' 
                            onClick={(e)=>{
                                e.preventDefault();
                                setUsers(prevState => {
                                    return[...prevState, selectedUser]
                                });
                                setSelectedUser(null);
                            }}
                        >Add User</a> */}
                    </div>
                </div>

                <div className='calendar-event-actions-block'>
                    <a href="#" className='action-calendar-btn mr-[10px]' onClick={onCancel}>Cancel</a>
                    <button className='action-calendar-btn' disabled={diasbleBtn} type='submit' >{mode === 'create' ? "Create" : "Save"}</button>
                </div>
            </form>
        </>
    )
}
