import Login from './auth/Login'
import Authentication from './auth/Authentication'
import { CoordinationDashboard } from './Incident/CoordinationDashboard'
import { IncidentWorkspace } from './Incident/IncidentWorkspace'
import { IncidentOverview } from './Incident/IncidentOverview'
import { ExecutionChecklist } from './Incident/ExecutionChecklist'
import { TaskPage } from './Incident/TaskPage'
import { IncidentResourceRequest } from './Incident/IncidentResourceRequest'
import { ResourceRequestRRF } from './Incident/ResourceRequestRRF'
import { SupportPlan } from './SupportPlan/SupportPlan'
import { SituationAwareness } from './SituationAwareness/SituationAwareness'
import { UserManagerAdmin } from './AdminDashboard/UserManagerAdmin'
import { PermissionsAdmin } from './AdminDashboard/PermissionsAdmin'
import { IncidentsAdmin } from './AdminDashboard/IncidentsAdmin'
import { IncidentsSubIncidentAdmin } from './AdminDashboard/IncidentsSubIncidentAdmin'
import { Impact } from './Impact/Impact'
import { MyProfile } from './AccountSettings/MyProfile';
import { PaymentMethods } from './AccountSettings/PaymentMethods';
import { PlansBillingHistory } from './AccountSettings/PlansBillingHistory';
import { Security } from './AccountSettings/Security';
import { UpgradeYourPlan } from './AccountSettings/UpgradeYourPlan';


export const Pages = {
    Login,
    Authentication,
    CoordinationDashboard,
    IncidentWorkspace,
    IncidentOverview,
    ExecutionChecklist,
    TaskPage,
    IncidentResourceRequest,
    ResourceRequestRRF,
    SupportPlan,
    SituationAwareness,
    UserManagerAdmin,
    PermissionsAdmin,
    IncidentsAdmin,
    IncidentsSubIncidentAdmin,
    Impact,
    MyProfile,
    PaymentMethods,
    PlansBillingHistory,
    Security,
    UpgradeYourPlan,
}