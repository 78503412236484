import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { 
    fetchLaunch, setDisplaySettings, 
} from '../thunks/launchThunks'

const initialState = {
    launchData:{}
}

const launchSlice = createSlice({
    name: 'launch',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchLaunch.fulfilled, (state, action) => {
            return {
                ...state,
                launchData: action.payload.data,
            };
        });
        // builder.addCase(fetchLogin.rejected, (state) => {
        //     return {
        //         ...state,
        //         auth: {
        //             user_email: null,
        //             username: null,
        //             access_token: null,
        //             refresh_token: null
        //         }
        //     };
        // });
        builder.addCase(setDisplaySettings.fulfilled, (state, action) => {
            return {
                ...state
            }
            // return {
            //     ...state,
            //     launchData: action.payload.data,
            // };
        });
    }
})


export const {  } = launchSlice.actions

export const getLaunchData = (state) => {
    return state.launch.launchData
};

export default launchSlice.reducer