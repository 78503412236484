import { api } from "..";

export const launchApi = {
    getLaunch() {
        return api.get('/user/launch-panel');
    },
    setLaunchSettings(body){
        return api.post('/set-display-settings/launch', body)
    },
    deleteLaunch(id){
        return api.delete(`/launch/${id}/delete`)
    }


};