import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import IconSearchLoupe from "../../assets/img/search-loupe.svg";

import AddSubIncidentModal from "../../components/Modals/AddSubIncidentModal";
import SubIncidentViewModal from "../../components/Modals/SubIncidentViewModal";

import Table from "../../components/ui/Table";

import { adminIncidents } from "../../api";

import {
    fetchSubIncidents,
} from "../../redux/thunks/admin/adminIncidentsThunks";
import  { getSubIncidents } from "../../redux/slices/admin/adminIncidentsSlice";
import { NotificationManager } from "react-notifications";

import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";

export const IncidentsSubIncidentAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { id } = useParams();

    const allSubIncidents = useSelector(getSubIncidents);
    const [selectedIncident, setSelectedIncident] = useState(null);

    const [addSubIncidentModalOpen, setAddSubIncidentModalOpen] = useState(false);
    const [subIncidentViewModalId, setSubIncidentViewModalId] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);

    const [configureOpen, setConfigureOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [confirmDeleteModalOpenId, setConfirmDeleteModalOpenId] = useState(false);
    const [editSubIncident, setEditSubIncident] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [tableColumnsShow, setTableColumnsShow] = useState(null);

    const fetchSelectInsident = async() => {
        const res = await adminIncidents.getOneIncidents(id);
        setSelectedIncident(res.data.data);
    }

    const fetchData = async() => {
        setFetchLoading(true);
        await dispatch(fetchSubIncidents(id));
        setFetchLoading(false);
    }

    const actionView = (value) => {
        // navigate(`/task/${value}`)
        setSubIncidentViewModalId(value);
    }
    const actionEdit = (value) => {
        setEditSubIncident(true);
        setSubIncidentViewModalId(value);
        setAddSubIncidentModalOpen(true);
        // const data = tasks.find(el => el.id === value)
        // navigate(`/task/edit/${value}`)
    }
    const actionDelete = async(value = confirmDeleteModalOpenId) => {
        try {
            await adminIncidents.deleteSubIncident(id, value);
            await fetchData();
            NotificationManager.success("Sub incident deleted successfully");
        } catch (err) {
            NotificationManager.error("Sub incident did not deleted");
        }
    }

    const subCreated = () => {
        fetchData();
    }

    useEffect(()=>{
        if(id){
            fetchData();
            fetchSelectInsident();
        }
    },[id]);

    useEffect(() => {
        let gettedTableConfig = localStorage.getItem('tableconfig-sub-incidents');

        if(gettedTableConfig && !tableColumnsShow){
            setTableColumnsShow(JSON.parse(gettedTableConfig));
        } else {
            setTableColumnsShow({
                area: true,
                declaration_level: true,
                type: true,
                impact_date: true,
                status: true,
            })
        }
    }, [])

    useEffect(() => {
        if(allSubIncidents?.sub_incident?.length){
            setTableData(allSubIncidents?.sub_incident.filter(item => 
                item.area.toLowerCase().includes(searchString.toLowerCase()) ||
                item.type.name.toLowerCase().includes(searchString.toLowerCase()) ||
                item.impact_date.toLowerCase().includes(searchString.toLowerCase())
            ));
        }
    }, [allSubIncidents, searchString])

    return (
        <>
            <nav className="page-nav-menu px-[30px] ovh">
                <ul className="breadcrumbs py-[14px]">
                    <li className="breadcrumb-item">
                        <Link to="/superuser/incidents" className="breadcrumb-link">Incidents</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <a href="#" className="breadcrumb-link">{ selectedIncident?.name || 'Loading...' }</a>
                    </li>
                </ul>
            </nav>
            <div className="bg-gray w-full flex justify-between items-center flex-wrap px-[29px] pt-[20px]">
                <div className="input-custom mb-[20px]" style={{ maxWidth: '300px', width: '100%' }}>
                    <form action="" className="form-search search-border h-[32px]">
                        <button type="submit" className="form-search__btn">
                            <img src={IconSearchLoupe} />
                        </button>
                        <input
                            type="search" className="form-search__input" placeholder="Search..."
                            value={ searchString } onInput={(e)=>{
                                setSearchString(e.target.value);
                            }}
                        />
                    </form>
                </div>
                <div className="btns-group">
                    <a
                        href="#" className="btn btn--primary btn--lg-2 rounded-[10px] mb-[20px]"
                        onClick={(e)=>{
                            e.preventDefault();
                            setAddSubIncidentModalOpen(true);
                        }}
                    >
                        <span className="ico mr-[8px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 4.16699V15.8337" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M4.16602 10H15.8327" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        Add Sub Incident
                    </a>
                    <div className="dropdown configure-dropdown mb-[20px] relative" data-dropdown="dropdown" data-position="top-end">
                        <a href="#" className="btn btn--shadow-primary btn--lg-2 rounded-[10px] " data-role="button" onClick={(e)=>{
                            e.preventDefault();
                            setConfigureOpen(!configureOpen);
                        }}>
                            <span className="ico mr-[8px]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 4.16667H17.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2.5 4.16667H9.16667" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M10.834 9.99967H17.5007" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2.5 9.99967H5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15 15.8327H17.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2.5 15.8327H9.16667" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M14.5108 2.98813C15.1616 3.63896 15.1616 4.69396 14.5108 5.34479C13.86 5.99563 12.805 5.99563 12.1541 5.34479C11.5033 4.69396 11.5033 3.63896 12.1541 2.98813C12.805 2.33729 13.86 2.33729 14.5108 2.98813" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M7.84479 8.82113C8.49563 9.47197 8.49563 10.527 7.84479 11.1778C7.19396 11.8286 6.13896 11.8286 5.48813 11.1778C4.83729 10.527 4.83729 9.47197 5.48813 8.82113C6.13896 8.1703 7.19396 8.1703 7.84479 8.82113" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M14.5108 14.6551C15.1616 15.306 15.1616 16.361 14.5108 17.0118C13.86 17.6626 12.805 17.6626 12.1541 17.0118C11.5033 16.361 11.5033 15.306 12.1541 14.6551C12.805 14.0043 13.86 14.0043 14.5108 14.6551" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                            Configure
                        </a>

                        <div className={`dropdown__body w-[370px] ${configureOpen ? 'is-open' : ''}`} data-role="dropdown" style={{ right: '0', position: 'absolute' }}>
                            <div className="dropdown-content p-[14px] border-bottom">
                                <div className="row w-full gx-3 gy-4">
                                    { tableColumnsShow &&
                                        [
                                            { label: 'Area', value: 'area' },
                                            { label: 'Declaration Level', value: 'declaration_level' },
                                            { label: 'Incident Type', value: 'type' },
                                            { label: 'Impact Date / Time', value: 'impact_date' },
                                            { label: 'Active Incident', value: 'status' },
                                        ].map((item, index)=>{
                                            return <div className="col-6" key={index}>
                                                <label className="custom-checkbox checkbox-gray">
                                                    <input type="checkbox" className="custom-checkbox__input" checked={tableColumnsShow[item.value]} onChange={(e)=>{
                                                        setTableColumnsShow((prevState)=>{
                                                            return {
                                                                ...prevState, [item.value]: e.target.checked
                                                            }
                                                        });
                                                    }} />
                                                    <span className="custom-checkbox__input-fake"></span>
                                                    <span className="custom-checkbox__label">{item.label}</span>
                                                </label>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="dropdown__footer p-[14px] flex justify-end">
                                <a
                                    href="#" className="btn btn--primary btn--lg rounded-[10px]" 
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        setConfigureOpen(!configureOpen);
                                        localStorage.setItem(
                                            'tableconfig-sub-incidents',
                                            JSON.stringify(tableColumnsShow)
                                        );
                                    }}
                                >Update Configuration</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dash-page-content-item">
                <div className="card-body mb-[24px]">
                    { tableColumnsShow && <Table
                        initialData={tableData}
                        setSortedData={setTableData}
                        tHead={[
                            {
                                content: 'Area',
                                sortField: 'area',
                                show: tableColumnsShow?.area
                            },
                            {
                                content: 'Declaration Level',
                                sortField: 'declaration_level',
                                show: tableColumnsShow?.declaration_level
                            },
                            {
                                content: 'Incident Type',
                                sortField: 'type.name',
                                show: tableColumnsShow?.type
                            },
                            {
                                content: 'Impact Date / Time',
                                sortField: 'impact_date',
                                show: tableColumnsShow?.impact_date
                            },
                            {
                                content: 'Active Incident',
                                sortField: 'status',
                                show: tableColumnsShow?.status
                            }
                        ]}
                        tBody={
                            tableData
                            .map((item, index)=>{
                                return [
                                    item.id,
                                    tableColumnsShow?.area ? item.area : null,
                                    tableColumnsShow?.declaration_level ? item.declaration_level : null,
                                    tableColumnsShow?.type ? item.type.name : null,
                                    tableColumnsShow?.impact_date ? item.impact_date.split('-').reverse().join('.') : null,
                                    tableColumnsShow?.status ? <span className="ico">
                                        { item.status !== "active" ?
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.2">
                                                <path d="M10.3125 13.0625L8.25 11" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M13.75 9.625L10.3125 13.0625" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </g>
                                        </svg> :
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3125 13.0625L8.25 11" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.75 9.625L10.3125 13.0625" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg> }
                                    </span> : null,
                                ]
                            })
                        }
                        actions={[
                            {
                                onClick: actionView,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </>
                            },
                            {
                                onClick: actionEdit,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </>
                            },
                            {
                                listLine: true,
                            },
                            {
                                onClick: setConfirmDeleteModalOpenId,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>

                                    </span>
                                    <span className="text-[#EA5E42]">
                                        Delete
                                    </span>
                                </>
                            }
                        ]}
                    /> }
                    {
                        fetchLoading ?
                        <div className="d-flex items-center justify-center p-[30px] w-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                            </svg>
                        </div> : null
                    }
                </div>
            </div>

            { addSubIncidentModalOpen ? <AddSubIncidentModal
                open={addSubIncidentModalOpen}
                incId={selectedIncident?.id}
                subIncId={subIncidentViewModalId}
                close={()=>{
                    setAddSubIncidentModalOpen(false);
                    setEditSubIncident(false);
                    setSubIncidentViewModalId(false);
                }}
                created={()=>{ subCreated() }}
                edit={editSubIncident}
            /> : null}

            { subIncidentViewModalId && !editSubIncident ? 
            <SubIncidentViewModal
                open={subIncidentViewModalId}
                incId={selectedIncident?.id}
                subIncId={subIncidentViewModalId}
                close={()=>{setSubIncidentViewModalId(false)}}
            /> : null }

            <ConfirmDeleteModal
                open={confirmDeleteModalOpenId}
                close={()=>{setConfirmDeleteModalOpenId(false)}}
                submit={()=>{
                    actionDelete();
                }}
            />
        </>
    )
}
