import * as yup from 'yup';

const baseForm = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    alter_email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    name: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    surname: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    phone: yup
        .number()
        .typeError('Must be a number')
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    // agency: yup
    //     .string()
    //     .required('Is a required field'),
    // region: yup
    //     .string()
    //     .required('Is a required field'),
    // state: yup
    //     .string()
    //     .required('Is a required field'),
})

export const addUserShema = yup.object().shape({
    password: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
}).concat(baseForm)

export const editUserShema = yup.object().shape({
    password: yup
        .string()
        .optional(),
}).concat(baseForm)
