import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {fetchAllMeetings, fetchCreateMeetings, fetchDeleteMeeting, fetchUpdateMeetings} from "../thunks/meetingsThunks";

const createHashTable = (data) => {
    const newDateHashTable = {};

    for (let i = 0; i < data.length; i++) {
        const el = data[i]
        const date = el.date.split(' ')[0];

        if (!newDateHashTable[date]) {
            newDateHashTable[date] = [el]
            continue
        }
        newDateHashTable[date].push(el)
    }
    return newDateHashTable;
}


const initialState = {
    meetingsHashTable: null
}

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchAllMeetings.pending, (state) => {
            return {
                meetingsHashTable: null,
            }
        })
        builder.addCase(fetchAllMeetings.fulfilled, (state, action) => {
            return {
                meetingsHashTable: createHashTable(action.payload.data)
            }
        })

        builder.addCase(fetchCreateMeetings.fulfilled, (state, action) => {

            const { selectedDate, newMeet } = action.payload;

            // const updatedMeetingsHashTable = {
            //     ...state.meetingsHashTable,
            //     [selectedDate]: [
            //         ...(state.meetingsHashTable[selectedDate] || []),
            //         ...newMeet
            //     ]
            // };

            let updatedMeetingsHashTable = {
                ...state.meetingsHashTable,
            }

            for (let index = 0; index < newMeet.length; index++) {
                updatedMeetingsHashTable[newMeet[index].date.split(' ')[0]] = [
                    ...(state.meetingsHashTable[newMeet[index].date.split(' ')[0]] || []),
                    newMeet[index]
                ];
            }

            return {
                ...state,
                meetingsHashTable: updatedMeetingsHashTable
            }
        })
        builder.addCase(fetchUpdateMeetings.fulfilled, (state, action) => {
            const { selectedDate, newMeet } = action.payload;

            const updatedMeetingsArray = (state.meetingsHashTable[selectedDate] || []).map(existingMeeting => {
                if (existingMeeting.id === newMeet.id) {
                    return newMeet
                }
                return existingMeeting
            });
            

            const updatedMeetingsHashTable = {
                ...state.meetingsHashTable,
                [selectedDate]: updatedMeetingsArray
            }

            return {
                ...state,
                meetingsHashTable: updatedMeetingsHashTable
            };

        })
        builder.addCase(fetchDeleteMeeting.fulfilled, (state, action) => {
            const { selectedDate, id } = action.payload;

            const updatedMeetingsArray = (state.meetingsHashTable[selectedDate] || []).filter(existEl => existEl.id !== id)

            const updatedMeetingsHashTable = {
                ...state.meetingsHashTable,
                [selectedDate]: updatedMeetingsArray
            }

            return {
                ...state,
                meetingsHashTable: updatedMeetingsHashTable
            };

        })
    }
})

export const {  } = meetingsSlice.actions

export const getMeetHashTable = (state) => state.meetings.meetingsHashTable

export default meetingsSlice.reducer