import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";

import { adminIncidents } from "../../api";

const SubIncidentViewModal = ({ open, close, incId, subIncId }) => {
    const [data, setData] = useState(null);
    const closeModal = () => {
        close();
    }

    const fetchData = async() => {
        const res = await adminIncidents.getSubIncident(incId, subIncId);
        setData(res.data.data);
    }

    useEffect(()=>{
        if(incId && subIncId){
            fetchData();
        }
    }, [incId, subIncId]);

    return (
        <FullHeightModal
            title="Sub Incident"
            widthClassName="modal__dialog--500"
            open={open}
            close={closeModal}
            hideFooter={true}
        >
            { data ? <div className="row gutter-x-16">
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Select Area</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.area }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Incident Type</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.type.name }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Declaration Level</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.declaration_level }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Incident Number</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.inc_number }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Impact Date / Time</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.impact_date }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Incident Timezone</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.time_zone }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Incident Output</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.inc_output }</span>
                </div>
                <div className="col-12 col-lg-6 flex items-end mb-[16px]">
                    <label className="custom-checkbox checkbox-border mb-[8px] pointer-events-none">
                        <input type="checkbox" className="custom-checkbox__input" checked={ data.status === 'active' } />
                        <span className="custom-checkbox__input-fake"></span>
                        <span className="custom-checkbox__label">Active Incident</span>
                    </label>
                </div>

                <div className="col-12">
                    <h5 className="mb-[16px] font-[600]">Launch Supporting Documents</h5>
                </div>

                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Execution Checklist</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.execution_checklist }</span>
                </div>
                <div className="col-12 col-lg-6">
                    <label className="input-custom__label text-[14px] font-[700] mb-[5px]">PSMAs</label>
                    <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ data.PSMAs }</span>
                </div>
            </div> : <div className="d-flex items-center justify-center p-[30px] w-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                </svg>
            </div> }
        </FullHeightModal>
    )
}

export default SubIncidentViewModal;