import { api } from "..";

export const incidentsApi = {
    getSelectedPosition(){
        return window.selectedPositionWindow;
    },

    getIncidents() {
        return api.get('');
    },
    
    getSituationAwareness(id) {
        return api.get(`/situation-awareness/${id}?position_id=${this.getSelectedPosition()}`);
    },
    updateSituationAwareness(id, payload) {
        return api.post(`/situation-awareness/${id}?position_id=${this.getSelectedPosition()}`, payload);
    },

    getIncidentSupportPlan(id) {
        return api.get(`/incident/${id}/support-plan?position_id=${this.getSelectedPosition()}`);
    },
    updateSupportPlan(incidentId, planId, payload) {
        return api.put(`/incident/${incidentId}/support-plan/${planId}/edit?position_id=${this.getSelectedPosition()}`, payload);
    },
    createSupportPlan(incidentId, payload) {
        return api.post(`/incident/${incidentId}/support-plan/store?position_id=${this.getSelectedPosition()}`, payload);
    },
    
    getIncidentPositions(incidentId) {
        return api.get(`/admin/incident/${incidentId}/positions`);
    },
    createIncidentPosition(incidentId, payload) {
        return api.post(`/admin/incident/${incidentId}/positions/store`, payload);
    },
    updateIncidentPosition(incidentId, positionId, payload) {
        return api.put(`/admin/incident/${incidentId}/positions/${positionId}/edit`, payload);
    },
    deleteIncidentPosition(incidentId, positionId) {
        return api.delete(`/admin/incident/${incidentId}/positions/${positionId}/delete`);
    },
 
    getCoordinationDashboard(incidentId, params) {
        return api.get(`/${incidentId}/coordination-dashboard${params}`);
    },
};