import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import {adminIncidents} from "../../api";

const ViewMainIncidentModal = ({ open, close, incidentId }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [incident, setIncident] = useState(null);

    const closeModal = () => {
        close();
    }

    useEffect(() => {
        if (!incidentId) return

        const req = async () => {
            try {
                const res = await adminIncidents.getOneIncidents(incidentId)
                setIncident(res.data.data)
            } catch (e) {

            }
            setIsLoading(false)
        }
        setIsLoading(true)
        req()

        return () => setIncident(null)

    }, [incidentId])

    return (
        <FullHeightModal
            title="Incident"
            widthClassName="modal__dialog--500"
            open={open}
            close={closeModal}
            hideFooter={true}
        >
            {!isLoading && incident ? <>
                <div className="row gutter-x-16">
                    <div className="col-12 col-lg-6">
                        <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Main Incident Name</label>
                        <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ incident.name }</span>
                    </div>
                    <div className="col-12 col-lg-6">
                        <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Incident Type</label>
                        <span className="block text-[14px] leading-[22px] opacity-80 mb-[16px]">{ incident.type.name }</span>
                    </div>
                    <div className="col-12">
                        <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Additional Comments</label>
                        <p className="text-[14px] leading-[22px] opacity-80 mb-[16px]">{ incident.incident_comment || 'Nothing' }</p>
                    </div>
                    <div className="col-12 mb-[16px]">
                        <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Sub incidents</label>
                        {
                            incident?.sub_incidents?.length ? 
                        
                        <div className="table-wrapper">
                            <table className="table thead-rounded" cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className="text-left">Number</th>
                                        <th className="text-left">Area</th>
                                        <th className="text-left">Declaration Level</th>
                                        <th className="text-left">Impact Date</th>
                                        <th className="text-left">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        incident?.sub_incidents.map((item, index)=>{
                                            return <tr key={index}>
                                                <td>
                                                    { item.inc_number }
                                                </td>
                                                <td className="text-left">
                                                    { item.area }
                                                </td>
                                                <td>
                                                    { item.declaration_level }
                                                </td>
                                                <td className="text-left">
                                                    { item.impact_date }
                                                </td>
                                                <td className="text-center w-full">
                                                    <div className="d-flex justify-center">
                                                        <span className="ico">
                                                            { item.status === 'inactive' || item.status === 'deactive' ?
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g opacity="0.2">
                                                                    <path d="M10.3125 13.0625L8.25 11" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.75 9.625L10.3125 13.0625" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </g>
                                                            </svg> :
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.3125 13.0625L8.25 11" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M13.75 9.625L10.3125 13.0625" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            </svg> }
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div> : <div>
                            <p className="opacity-80 mb-[4px] text-[14px] leading-[22px]">Sub incidents list is empty</p>
                        </div> }
                    </div>
                    <div className="col-12">
                        <label className="input-custom__label text-[14px] font-[700] mb-[5px]">Incident History</label>
                        <div className="incident-name-list">
                            {   incident?.history?.length ? 
                                incident?.history?.map((item, index)=>{
                                    return <div className="incident-name-item" key={index}>
                                        <div className="incident-name__head mb-[4px]">
                                            <div className="flex items-center">
                                                <div className={`incident-name-decor-img ${item.label === 'Created' ? 'decor-green' : 'decor-blue'} mr-[12px]`}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M4.41018 13.0157L13.0152 4.41074C13.3402 4.08574 13.8677 4.08574 14.1927 4.41074L15.5893 5.80741C15.9143 6.13241 15.9143 6.65991 15.5893 6.98491L6.98352 15.5891C6.82768 15.7457 6.61602 15.8332 6.39518 15.8332H4.16602V13.6041C4.16602 13.3832 4.25352 13.1716 4.41018 13.0157Z"
                                                            stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M11.459 5.9668L14.034 8.5418" stroke="currentColor" strokeWidth="1.2"
                                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                                <a href="#" className="font-[700] text-[14px] leading-[18px]">{ item.date }</a>
                                            </div>
                                            <span className={`pill ${item.label === 'Created' ? 'pill-success' : 'pill-primary'}`}>
                                            <span className="pill-status"></span>
                                            { item.label }
                                        </span>
                                        </div>
                                        <p className="opacity-80 mb-[4px] text-[14px] leading-[22px] pl-[44px]">{ item.context }</p>
                                    </div>
                                }) : 
                                <div>
                                    <p className="opacity-80 mb-[4px] text-[14px] leading-[22px]">History list is empty</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                
            </> : <div style={{textAlign: 'center', marginTop: '25px'}}>Loading</div>}
        </FullHeightModal>
    )
}

export default ViewMainIncidentModal;