let transformDataToArray = (data) => {
    return Object.entries(data).map(([id, value])=>{
        return {
            id:id,
            value: id,
            label:value.title,
            ...value
        }
    })
} 
export const statusPillsConfig = {
    'in-progress':{
        title: 'In-progress', 
        class: 'pill-primary',
    },
    'not_started':{
        title: 'Not Started', 
        class: 'pill-gray',
    },
    'completed':{
        title: 'Completed', 
        class: 'pill-success',

    },
    'active':{
        title: 'Pending', 
        class: 'pill-yellow',

    },
    'canceled':{
        title: 'Canceled', 
        class: 'pill-danger',
    }

}
export const statusPillsOptions = transformDataToArray(statusPillsConfig)

export const priorityPillsConfig = {
    'low':{
        title: 'Low',
        class: 'pill-primary'
    },
    'hight':{
        title: 'High',
        class: 'pill-yellow'
    },
    'medium':{
        title: 'Medium',
        class: 'pill-success'
    },
    'priority':{
        title: 'Critical',
        class: 'pill-danger'
    }

}
