import React from 'react'
// import { Editor } from '../../components/Editor'
import { Editor2 } from '../../components/Editor2'


export const SituationAndSupportEdit = ({data, setData, canEdit = true}) => {
    if(!canEdit){
        window.location.replace('/situational-awareness')
    }

    return canEdit ? (
        <div className="col-xl-7 col-lg-6 col-12 flex flex-col ">
            <div className="input-custom mb-[20px]">
                <label className="input-custom__label font-[500] mb-[10px]">Title</label>
                <input
                    className="input-custom__textarea" type="text" placeholder="Enter title..."
                    value={data?.title} onInput={(e)=>{setData((prevState) => { return {...prevState, title: e.target.value }  })}}
                />
            </div>
            <div className="input-custom flex-auto mb-[20px]">
                <label className="input-custom__label font-[500] mb-[10px]">Description</label>

                {/* <Editor /> */}
                <Editor2
                    value={data?.description}
                    setValue={
                        (value)=>{
                            setData((prevState) => {
                                return {...prevState, description: value} 
                            })
                        }
                    }
                />
            </div>
        </div>
    ) : null
}
