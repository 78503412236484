import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    fetchIsAdmin,
    fetchLogin,
} from '../thunks/authThunks'

const initialState = {
    auth: {
        user_email: null,
        username: null,
        access_token: null,
        refresh_token: null,
        isAdmin: false,
    },
    crud: {
        "rrf": {
            "read": 0,
            "create": 0,
            "delete": 0,
            "update": 0,
            "approve": 0,
        },
        "tasks": {
            "read": 0,
            "create": 0,
            "delete": 0,
            "update": 0,
            "approve": 0,
        },
        "meetings": {
            "read": 0,
            "create": 0,
            "delete": 0,
            "update": 0,
            "approve": 0,
        },
        "support-plan": {
            "read": 0,
            "create": 0,
            "delete": 0,
            "update": 0,
            "approve": 0,
        },
        "situational-awareness": {
            "read": 0,
            "create": 0,
            // "delete": 0,
            // "update": 0,
            // "approve": 0,
        }
    },
    selectedPosition: null,
    parentsPositions: [],
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentCRUD: (state, action) => {
			state.crud = action.payload;
		},
        setSelectedPosition: (state, action) => {
			state.selectedPosition = action.payload;
		},
        setParentsPositions: (state, action) => {
			state.parentsPositions = action.payload;
		},
    },
    extraReducers(builder) {
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            return {
                ...state,
                auth: action.payload,
            };
        });
        builder.addCase(fetchLogin.rejected, (state) => {
            return {
                ...state,
                auth: {
                    user_email: null,
                    username: null,
                    access_token: null,
                    refresh_token: null
                }
            };
        });
        builder.addCase(fetchIsAdmin.fulfilled, (state,action) => {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    isAdmin: action.payload.is_admin
                }
            }
        })
        
    }
})


export const { setCurrentCRUD, setSelectedPosition, setParentsPositions } = authSlice.actions

export const getAuth = (state) => state.auth.auth;
export const getIsAdmin = (state) => state.auth.auth.isAdmin
export const getCurrentCRUD = (state) => state.auth.crud;
export const getSelectedPosition = (state) => state.auth.selectedPosition;
export const getParentsPositions = (state) => state.auth.parentsPositions;

export default authSlice.reducer