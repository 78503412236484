import React, { useEffect, useState } from 'react'
import Select, {
    components,
    ControlProps,
    Props,
    StylesConfig,
  } from 'react-select';


export const SelectBox = ({type, propsOptions, change, value=null, profileName = "Jackson Smith", menuPortalTarget, menuPosition, className, isSearchable = false, placeholder, isMulti=false }) => {
    // console.log(value, 'select box incomin value');
    
    let selectComponents = {
        Control: ({ children, ...props }) => {
            let item=props.getValue()[0]||props.options[0];
            return(
                <components.Control {...props} className={`select-selection ${props.menuIsOpen ? 'select-selection-focused' : ''}`} style="outline:none;">
                    {/* {(type==='test')&&<div className=''>
                        {children}
                    </div>} */}
                    {/* {(type==='languages-footer')&&<div className='selectbox__control-icon-languages selectbox__control-icon-languages-radius'>
                        {item.icon}
                    </div>}
                    {(type==='profile-header')&&<div className='selectbox__control-icon-profile'>
                        <ProfileIcon></ProfileIcon>
                    </div>}
                    {(type==='offer-platform')&&<div className='selectbox__control-icon'>
                        {item.icon}
                    </div>} */}
                    { children }

                    <span className="select-selection-arrow-wrpr" role="presentation"><b role="presentation"></b></span>

                </components.Control>
            )
        },
        DropdownIndicator:({ children, ...props })=>{
            return(
                <components.DropdownIndicator {...props} className="select-selection-arrow" >
                    {/* {type==='profile-header'&&<ProfileDropdownArrow style={{'transform':(props.selectProps.menuIsOpen?'rotate(180deg)':'')}}></ProfileDropdownArrow>} */}
                </components.DropdownIndicator>
            )
        },
        Option:({ children, ...props })=>{
            let item = props.data;
            return(
                <components.Option {...props} className={`select2-results__option ${props.isFocused || props.isSelected ? 'select2-results__option--selected' : ''}`}>
                    {/* {item.icon && <div className='selectbox__menu-option-icon'>{item.icon}</div>} */}
                    {children} 
                </components.Option>
            )
        },
        IndicatorSeparator:()=>{
            return null
        },
        GroupHeading:({ children, ...props })=>{
            return(
                <components.GroupHeading {...props} className="select-options-group-title" >
                    {children}
                </components.GroupHeading>
            )
        },
        Group:({ children, ...props })=>{
            return(
                <components.Group {...props} className="select-options-group" >
                    {children}
                </components.Group>
            )
        },
        MenuPortal:({ children, ...props })=>{
            return(
                <components.MenuPortal {...props} style={{ zIndex: '999' }} className="select-menu-portal" >
                    {children}
                </components.MenuPortal>
            )
        }
    }
    let classNames = {
        singleValue:()=>`aaa`, 
        valueContainer:()=>'bbb',
        menu:()=>'ccc select2-dropdown select2-dropdown--below',
        // select2-dropdown select2-dropdown--below select2-container select2-container--default select2-container--open 
        container:()=>'ddd   '
    }
    let options = propsOptions ? propsOptions : []

    let selectBoxValue=null;

    const [selectValue, setSelectValue] = useState(findValue(value));

    useEffect(()=>{
        setSelectValue(findValue(value))
    }, [value])

    function findValue(valueItem){
        if(options[0]?.options && valueItem){
            for (let index = 0; index < options.length; index++) {
                for (let index2 = 0; index2 < options[index].options.length; index2++) {
                    if(options[index].options[index2]?.value === valueItem){
                        selectBoxValue = options[index].options[index2];
                        return options[index].options[index2];
                    }
                }
            }
        }

        return options.find((option)=>option.value === value);
    }

    function onChange(value){
        selectBoxValue = value;
            if(change){
                isMulti ? change(selectBoxValue.map((value) => value.value)) : change(value.value);
            }
            // setSelectValue((prev)=>value);
    }
    return (
        <div className={`${type} ${className ? className : ''}`}>
            <div style={{ width: "100%", position: 'relative' }}>
                <Select
                    // defaultValue={selectBoxValue ? selectBoxValue : options[0]}
                    placeholder={placeholder || 'Select...'}
                    options={options}
                    classNames={classNames}
                    clearable={false}
                    components={selectComponents}
                    // menuPosition="fixed"
                    // menuPlacement={type==='languages-footer'?'top':'bottom'}
                    isSearchable={isSearchable}
                    onChange={onChange}
                    value={selectBoxValue ? selectBoxValue : value ? selectValue : undefined}
                    // menuIsOpen={true}
                    isMulti={isMulti}
                    menuPortalTarget={menuPosition !== 'absolute' ? menuPortalTarget ? menuPortalTarget : document.body : null}
                    menuPosition={menuPosition ? menuPosition : 'fixed'}
                />
            </div>
        </div>
    )
}