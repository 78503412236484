import React, { useEffect, useState } from 'react';
import IconDeleteDisabled from "../../assets/img/delete-disabled.svg";
import { SelectBox } from "../ui/SelectBox";
import { resourceRequest } from '../../api/index';

const RouteRRFModal = ({ open, close, submit, data, statuses, parentsPositions = [] }) => {
    const [show, setShow] = useState(false);
    const [locations, setLocations] = useState(null);
    const [payload, setPayload] = useState({
        "status": "",
        "state_id": 1,
        "facility_poc": "",
        "city": "",
        "address": "",
        "zip_code": "",
        "longitude": "-122.4389057",
        "latitude": "37.758336"
    });

    const getLocations = async() => {
        let result = await resourceRequest.getLocations();
        setLocations(result?.data?.data.filter(item => item.latitude.includes('.')));
    }


    const onClose = () => {
        setShow(false);
        setTimeout(() => {
            close();
        }, 300);
    }

    useEffect(()=>{
        setShow(true);

        getLocations();

        setPayload(data);
    }, []);

    return (
        <div className={`modal modal-1 ${show ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__header justify-end">
                        <a href="#" className="btn btn-close" onClick={(e)=>{
                            e.preventDefault();
                            onClose();
                        }}>
                            <img src={IconDeleteDisabled} alt="" />
                        </a>
                    </div>
                    <div className="modal__body">
                        <h2 className="font-[700] mb-[14px] text-center mt-[-55px]">Route RRF</h2>
                        
                        { !locations ?
                        <div className="d-flex items-center justify-center p-[30px] w-100 min-h-[142px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                            </svg>
                        </div> : <>
                            <div className="select-wrap select-border h-36px mb-[14px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Position Name</label>
                                { locations ? 
                                <SelectBox
                                    className="default-select"
                                    placeholder="Choose Location..."
                                    propsOptions={
                                        locations.map((item) => {
                                            return {
                                                label: `${item.city ? item.city+', ' : ''}${item.address ? item.address+', ' : ''}${item.zip_code ? item.zip_code : ''}`,
                                                value: item
                                            }
                                        })
                                    }
                                    change={(value) => {
                                        setPayload((prevState) => {
                                            return {
                                                ...prevState, 
                                                longitude: String(value.longitude),
                                                latitude: String(value.latitude),
                                                facility_poc: value.facility_poc,
                                                address: value.address,
                                                city: value.city,
                                                zip_code: value.zip_code,
                                                state_id: value.state_id,
                                            }
                                        })
                                    }}
                                /> : null }
                            </div>

                            { !parentsPositions?.length ? <div className="select-wrap select-border h-36px">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Status</label>
                                <SelectBox 
                                    className="default-select"
                                    propsOptions={
                                        Object.entries(statuses).map((item)=>{
                                            return { label: item[1], value: item[0] }
                                        })
                                    }
                                    value={payload?.status}
                                    change={(value)=>{
                                        setPayload((prevState)=>{
                                            return {
                                                ...prevState,
                                                status: value
                                            }
                                        })
                                    }}
                                />
                            </div> : null }
                        </> }
                    </div>
                    <div className="modal__footer">
                        <a 
                            href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                onClose();
                            }}
                        >Cancel</a>
                        <a 
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                submit && submit(payload);
                                onClose();
                            }}
                        >Submit for Review</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RouteRRFModal;