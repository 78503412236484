import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import { impacts } from '../../api/index';
import { useDispatch, useSelector } from 'react-redux';

import { executionChecklistFetch } from '../../redux/thunks/executionChecklistThunk';
import {getTasks} from "../../redux/slices/executionChecklistSlice";

import { adminFetchAllUsers } from '../../redux/thunks/admin/userManagementThunks';
import { getUsers } from "../../redux/slices/admin/userManagementSlice";

const AddImpactModal = ({ open, close, selectedIncidentId, created, impact, edit }) => {
    const dispatch = useDispatch();

    const tasks = useSelector(getTasks);

    const users = useSelector(getUsers);

    const test = false;

    const clearData = {
        "situation_title": "",
        "affected_lifeline": "",
        "color": "blue",
        "importance_text": "",
        "identified_gaps": "",
        "incident_id": selectedIncidentId,
        "actions": []
    };

    const [data, setData] = useState(test ? {
        "situation_title": "some situation_title",
        "affected_lifeline": "some affected_lifeline",
        "color": "blue",
        "importance_text": "some importance_text",
        "identified_gaps": "some identified_gaps",
        "incident_id": selectedIncidentId,
        "actions": [
            {
                "task_id": 1,
                "user_id": 1
            }
        ]
    } : clearData);

    const [actionLastKey, setActionLastKey] = useState(0);

    const createImpact = async() => {
        await impacts.createImpact(data);
        closeModal();
        if(created){ created(); }
    }

    const updateImpact = async() => {
        await impacts.updateImpact(impact.id, data);
        closeModal();
        if(created){ created(true); }
    }

    const closeModal = () => {
        close();
    }

    useEffect(()=>{
        if(!users?.length){
            dispatch(adminFetchAllUsers())
        }
    },[])

    useEffect(()=>{
        if(!tasks?.length && selectedIncidentId){
            dispatch(executionChecklistFetch(selectedIncidentId))
        }
    },[selectedIncidentId])

    useEffect(()=>{
        if(impact){
            setData(impact);
        } else {
            setData(clearData);
        }
    },[impact])

    return (
        <FullHeightModal
            title={impact && !edit ? "View Impact" : impact && edit ? "Edit Impact" : "Add Impact"}
            headerIcon={IconPlusAdd}
            open={open}
            close={closeModal}
            submitBtnText={impact && !edit ? null : impact && edit ? "Save" : "Create"}
            submitMethod={impact && edit ? updateImpact : createImpact}
            widthClassName="modal__dialog--500"
        >
            <div style={{ pointerEvents: impact && !edit ? 'none' : 'initial' }}>
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Situation</label>
                    <input
                        className="input-custom__input" type="text" placeholder="Enter situation title"
                        value={data.situation_title} onInput={(e)=>{
                            setData((prevState)=>{
                                return { ...prevState, situation_title: e.target.value }
                            })
                        }}
                    />
                </div>
                <div className="row gutter-x-16">
                    <div className="col-12 col-lg-6">
                        <div className="input-custom h-36px input-border mb-[16px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Affected Lifeline</label>
                            <input
                                className="input-custom__input" type="text" placeholder="Enter affected lifeline"
                                value={data.affected_lifeline} onInput={(e)=>{
                                    setData((prevState)=>{
                                        return { ...prevState, affected_lifeline: e.target.value }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="select-wrap select-border h-36px mb-[16px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Color</label>
                            <SelectBox 
                                className="default-select"
                                propsOptions={[
                                    {label: 'Blue', value: 'blue'},
                                    {label: 'Red', value: 'red'},
                                    {label: 'Purple', value: 'purple'},
                                    {label: 'Orange', value: 'orange'},
                                ]}
                                value={data.color}
                                change={(value)=>{
                                    setData((prevState)=>{
                                        return { ...prevState, color: value }
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-custom mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Importance</label>
                    <textarea
                        className="input-custom__textarea" rows="5" placeholder="Enter importance text..."
                        value={data.importance_text} onInput={(e)=>{
                            setData((prevState)=>{
                                return { ...prevState, importance_text: e.target.value }
                            })
                        }}
                    ></textarea>
                </div>
                <div className="input-custom mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Identified Gaps</label>
                    <textarea
                        className="input-custom__textarea" rows="5" placeholder="Enter identified text..."
                        value={data.identified_gaps} onInput={(e)=>{
                            setData((prevState)=>{
                                return { ...prevState, identified_gaps: e.target.value }
                            })
                        }}
                    ></textarea>
                </div>
                <div className="input-custom mb-[16px]">
                    <div className="d-flex justify-between items-center">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Actions Taken / Planned</label>
                        { impact && !edit ? null :
                            <a href="#" className="btn btn--primary btn--lg-3 rounded-[8px] mb-[10px]" onClick={(e)=>{
                                e.preventDefault();
                                setData((prevState)=>{
                                    return { ...prevState, actions: [...prevState.actions, {
                                        "task_id": null,
                                        "user_id": null
                                    } ] }
                                })
                            }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 4.16663V15.8333" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.16797 10H15.8346" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Add Task
                            </a>
                        }
                    </div>
                    {
                        data.actions.map((item, index)=>{
                            return <div className="flex items-center mb-[8px]" key={index+'-'+actionLastKey}>
                                {
                                    impact && !edit ? null :
                                    <label className="custom-checkbox checkbox-border">
                                        <input type="checkbox" className="custom-checkbox__input" />
                                        <span className="custom-checkbox__input-fake"></span>
                                    </label>
                                }
                                <div className="px-[10px] flex-auto" style={{ maxWidth: '222px' }}>
                                    <SelectBox 
                                        key={tasks?.length}
                                        className="default-select flex-1"
                                        propsOptions={tasks.map((itemTask) => {
                                            return {label: itemTask.description, value: itemTask.id}
                                        })}
                                        isSearchable={true}
                                        value={item?.task_id}
                                        change={(value)=>{
                                            setData((prevState)=>{
                                                return {
                                                    ...prevState,
                                                    actions: [...prevState.actions].map((itemMap, itemMapIndex) => itemMapIndex === index ? ({...itemMap, task_id: value}) : itemMap)
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <SelectBox
                                    key={users?.length}
                                    className="default-select flex-1"
                                    propsOptions={users.map((userItem) => {
                                        return {label: userItem.full_name, value: userItem.id}
                                    })}
                                    value={item?.user_id}
                                    change={(value)=>{
                                        setData((prevState)=>{
                                            return {
                                                ...prevState,
                                                actions: [...prevState.actions].map((itemMap, itemMapIndex) => itemMapIndex === index ? ({...itemMap, user_id: value}) : itemMap)
                                            }
                                        })
                                    }}
                                />
                                {
                                    impact && !edit ? null :
                                    <a href="#" className="btn" onClick={(e)=>{
                                        e.preventDefault();
                                        setActionLastKey((prevState)=>{return prevState+data.actions.length;})
                                        setData((prevState)=>{
                                            return { ...prevState, actions: prevState.actions.filter((item, index2) => {
                                                return index2 !== index
                                            }) }
                                        });
                                    }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.4">
                                                <path d="M6.66797 6.66663L13.3346 13.3333" stroke="#414252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M13.3346 6.66663L6.66797 13.3333" stroke="#414252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </g>
                                        </svg>
                                    </a>
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        </FullHeightModal>
    )
}

export default AddImpactModal;