import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    executionChecklistFetch,
    createTaskFetch, deleteTaskFetch
} from '../thunks/executionChecklistThunk'

const initialState = {
    data: null,
}

const executionChecklistSlice = createSlice({
    name: 'executionChecklist',
    initialState,
    extraReducers(builder) {
        builder.addCase(executionChecklistFetch.fulfilled, (state, action) => {
            return {
                ...state,
                data: action.payload.data,
            };
        });
        builder.addCase(executionChecklistFetch.rejected, (state) => {
            return {
                ...state,
                data: null
            };
        });
        builder.addCase(createTaskFetch.fulfilled, (state, action) => {
            return {
                ...state,
                data: {...state.data, tasks: [...state.data.tasks, action.payload]}
            }
        });
        builder.addCase(deleteTaskFetch.fulfilled, (state, action) => {
            return {
                ...state,
                data: {...state.data, tasks: [...state.data.tasks.filter(el => el.id !== action.payload.id)]}
            }
        });

    },

    reducers: {
        // setSelectedIncident: (state, action) => {
		// 	state.selectedIncident = action.payload;
		// },
        // setSelectedIncidentId: (state, action) => {
		// 	state.selectedIncidentId = action.payload;
		// },
    }
})


// export const { setSelectedIncident, setSelectedIncidentId } = incidentsSlice.actions

export const getTasks = (state) => state.executionChecklist.data?.tasks

export const getTasksData = (state) => state.executionChecklist.data
export const getAgencies = (state) => state.executionChecklist.data?.agencies
// export const getSelectedIncidentId = (state) => state.incidents.selectedIncidentId;
// export const getSelectedIncident = (state) => state.incidents.selectedIncident;

export default executionChecklistSlice.reducer