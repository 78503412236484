import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { settings } from '../../api/index';
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../redux/slices/settingsSlice";
import { getProfile } from "../../redux/thunks/settingsThunks";
import InfoModal from "../../components/Modals/InfoModal";
import ConfirmModal from "../../components/Modals/ConfirmModal";

export const UpgradeYourPlan = () => {
    const dispatch = useDispatch();

    const successIcon = require('../../assets/img/updated-img.png');
    const warningIcon = require('../../assets/img/warning-icon.png');

    const [infoModalConfig, setInfoModalConfig] = useState({
        open: false,
        icon: successIcon,
        title: ''
    });

    const [confirmModalConfig, setConfirmModalConfig] = useState({
        open: false,
        submit: false,
        title: false,
    });

    const [monthlyYearly, setMonthlyYearly] = useState('month');
    const [plans, setPlans] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [subscribeLoading, setSubscribeLoading] = useState(null);

    const fetchPlans = async() => {
        let res = await settings.getSubscriptionPlans();
        setPlans(res.data.data)
    }

    const fetchCards = async() => {
        let res = await settings.getCreditCards();
        setPaymentMethods(res.data.data);
    }

    const gettedProfileData = useSelector(getProfileData);

    const currentPlan = gettedProfileData?.plan?.plan?.id || null;

    const fetchProfileData = async() => {
        await dispatch(getProfile());
    }

    const subscribePlan = async(id) => {
        if(!paymentMethods.length){
            setInfoModalConfig({
                open: true,
                icon: warningIcon,
                title: 'Please add payment method'
            })
            return false;
        }

        setConfirmModalConfig({
            open: true,
            submit: async() => {
                setSubscribeLoading(id);

                let res = await settings.subscribePlan({
                    "plan_id": id,
                    "type": monthlyYearly
                });
        
                await fetchProfileData();

                setSubscribeLoading(null);
            },
            title: 'Confirm Subscribe',
        })
    }

    useEffect(()=>{
        fetchPlans();

        fetchCards();

        if(!gettedProfileData){
            fetchProfileData();
        }
    },[])

    return (
        <>
            <div className="settings-page-container" style={{ maxWidth: "892px" }}>
                <div className="mb-[32px]">
                    <Link
                        to="/settings/plans-and-billing-history" className="btn btn--outline-default btn--md rounded-[8px] py-[8px]"
                    >
                        <span className="mr-[8px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path d="M4.16536 10.0001L15.832 10.0001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.33203 5.83333L4.16537 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.33203 14.1667L4.16537 10.0001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                            </svg>
                        </span>
                        Back to Settings
                    </Link>
                </div>
                <h3 className="settings-page-main-title">Upgrade Your Plan</h3>
                <div className="d-flex align-items-center justify-between w-full mb-[32px]">
                    <p className="settings-page-subtitle" style={{ marginBottom: "0px" }}>
                        Change your workspace plan according to your needs
                    </p>
                    <div className="switch-btn-box">
                        {
                            ["month", "year"].map((item, index)=>{
                                return  <a
                                    href="#" className={`switch-btn-item ${monthlyYearly === item ? 'active' : ''}`} key={index}
                                    onClick={(e)=>{ e.preventDefault(); setMonthlyYearly(item); }}
                                >
                                    { item }
                                </a>
                            })
                        }
                    </div>
                    
                </div>
                <div className="plan-items-wrpr">
                    {
                        plans.map((planItem, planItemIndex)=>{
                            return <div className={`plan-item ${ planItem.id === currentPlan ? 'active' : '' }`} key={planItemIndex}>
                                <h3 className="plan-item-name">{ planItem.title }</h3>
                                <div className="plan-item-price">
                                    { monthlyYearly === 'month' ? planItem.price_m === 0 ? 'FREE' : `$${planItem.price_m}.00` : planItem.price_y === 0 ? 'FREE' : `$${planItem.price_y}.00` }
                                </div>
                                <div className="per-date">per {monthlyYearly === 'month' ? 'month' : 'year'}</div>
                                <ul>
                                    {
                                        planItem.options.map((featureItem, featureItemIndex)=>{
                                            return <li key={featureItemIndex}>
                                                <span className="mr-[8px]">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.3346 4.33325L6.0013 11.6666L2.66797 8.33325" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </span> { featureItem.title }
                                            </li>
                                        })
                                    }
                                </ul>
                                <a
                                    href="#" className={`btn btn--md rounded-[8px] py-[8px] w-full relative ${ planItem.id === currentPlan ? 'btn--grey disabled' : 'btn--primary' }`}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        subscribePlan(planItem.id);
                                    }}
                                >
                                    { subscribeLoading === planItem.id ?
                                        <div className="btn-loader">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#ffffff" />
                                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#ffffff" className="spinner_ajPY"/>
                                            </svg>
                                        </div> : null
                                    }
                                    { planItem.id === currentPlan ? 'Current Plan' : 'Upgrade Plan' }
                                </a>
                            </div>
                        })
                    }
                </div>
            </div>

            <InfoModal
                icon={infoModalConfig.icon}
                title={infoModalConfig.title}
                open={infoModalConfig.open}
                close={()=>{setInfoModalConfig({
                    open: false,
                    icon: false,
                    title: ''
                })}}
            />

            <ConfirmModal
                close={()=>{
                    setConfirmModalConfig({
                        open: false,
                        submit: false,
                        title: false,
                    })
                }}
                open={ confirmModalConfig.open }
                submit={ confirmModalConfig.submit }
                title={ confirmModalConfig.title }
            />
        </>
    )
}
