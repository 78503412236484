import React, { useState, useRef, useEffect } from 'react';


export const FlyDropdown = ({ children, forceClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const blockRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    useEffect(() => {
      setIsOpen(false)
    }, [forceClose])
  
    const calculatePosition = () => {
      if (dropdownRef.current && blockRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const blockRect = blockRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: dropdownRect.bottom + window.scrollY,
          left: dropdownRect.left + window.scrollX - (blockRect.width - dropdownRect.width),
        });
      }
    };
  
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('click', handleOutsideClick);
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    const han = () => {
        setIsOpen(false);
        setDropdownPosition({
            top: 0,
            left: 0,
          });
    }

    useEffect(() => {
        const mainEl = document.getElementsByClassName('dashboard-content')[0]

        if (isOpen) {
            mainEl.addEventListener('scroll', han)
        } else {
            mainEl.removeEventListener('scroll', han)
        }

        return () => {
            mainEl.removeEventListener('scroll', han)
        }
    }, [isOpen])
  
    useEffect(() => {
      calculatePosition();
    }, [isOpen]);
  
    return (
      <div style={{ position: 'relative' }} ref={dropdownRef}>
        <button
          onClick={(e) => {
            handleToggle();
          }}
          className="btn btn-dropdown"
          data-role="button"
        >
          <span className="ico ico-20 justify-center">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9968 16.0438C10.7446 16.0438 10.5383 16.2501 10.541 16.5023C10.541 16.7545 10.7474 16.9608 10.9996 16.9608C11.2518 16.9608 11.4581 16.7545 11.4581 16.5023C11.4581 16.2501 11.2518 16.0438 10.9968 16.0438" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M10.9968 10.5415C10.7446 10.5415 10.5383 10.7478 10.541 11C10.541 11.2522 10.7474 11.4585 10.9996 11.4585C11.2518 11.4585 11.4581 11.2522 11.4581 11C11.4581 10.7478 11.2518 10.5415 10.9968 10.5415" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M10.9968 5.03918C10.7446 5.03918 10.5383 5.24552 10.541 5.49771C10.541 5.7499 10.7474 5.95623 10.9996 5.95623C11.2518 5.95623 11.4581 5.7499 11.4581 5.49771C11.4581 5.24552 11.2518 5.03918 10.9968 5.03918" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>                
            </svg>
          </span>
        </button>
        {isOpen && (
          <div
            ref={blockRef}
            style={{
              position: 'fixed',
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              zIndex: 1000,
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  };