import {api} from "..";

export const meetingsApi = {
    getSelectedPosition(){
        return window.selectedPositionWindow;
    },

    getAll(id) {
        return api.get(`/incident/${id}/meetings?position_id=${this.getSelectedPosition()}`)
    },

    create(payload) {
        return api.post(`/meeting/store?position_id=${this.getSelectedPosition()}`, {
            description: payload.description,
            date: payload.date,
            incident_id: payload.incident_id,
            link: payload.link,
            duration: payload.duration,
            users: payload.users,
            days: payload.days,
        })
    },

    edit(payload) {
        return api.put(`/meeting/${payload.id}/edit?position_id=${this.getSelectedPosition()}`, {
            description: payload.description,
            date: payload.date,
            link: payload.link,
            duration: payload.duration,
            users: payload.users,
            days: payload.days,
        })
    },

    delete(id) {
        return api.delete(`/meeting/${id}/delete?position_id=${this.getSelectedPosition()}`)
    },
}