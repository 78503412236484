import React, { useState, useEffect } from 'react'
import { SituationAndSupportContent } from '../Parts/SituationAndSupportContent'
import { LatestLifelineStatus } from '../Parts/LatestLifelineStatus'
import { SituationAndSupportEdit } from '../Parts/SituationAndSupportEdit'
import { SituationAndSupportHeader } from '../Parts/SituationAndSupportHeader'

import { UpdatedModal } from '../../components/Modals/UpdatedModal'

import { useDispatch, useSelector } from "react-redux";
import { fetchIncidentSituationAwareness } from "../../redux/thunks/incidentsThunks";
import { getSelectedIncident, } from "../../redux/slices/incidentsSlice";

import { incidents } from '../../api/index';
import { situationPageData } from '../Parts/supportSituationData'
import { ReadClose } from "../../helpers/checkPermission";


export const SituationAwareness = () => {
    const localReadClose = (name, navigateActive, field = 'read') => {
        return ReadClose(name, navigateActive, field)
    }

    const checkRead = localReadClose('situational-awareness', true);

    const [step, setStep] = useState(1);

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false)

    const selectedIncident = useSelector(getSelectedIncident);

    const [situationAwarenessData, setSituationAwareness] = useState(null);

    const [updatedPriorities, setUpdatedPriorities] = useState(null)

    const [updatedData, setUpdatedData] = useState(null);

    const getSituationAwareness = async() =>{
        let res = await dispatch(fetchIncidentSituationAwareness(selectedIncident?.id)).unwrap();
        if(res?.data?.title){
            setSituationAwareness(res);
            setUpdatedData({
                title: res?.data?.title,
                description: res?.data?.description
            })
            setUpdatedPriorities(res.life_line_with_statuses.map(el => ({
                ...el,
                statuses: el.statuses[0]
            })))
        }
    }
    

    const save = async() => {

        const res = await incidents.updateSituationAwareness(selectedIncident?.id, {
            title: updatedData.title,
            description: updatedData.description,
            // statuses: situationAwarenessData.statuses,
            statuses: updatedPriorities.map(el => ({
                life_line_id: el.id,
                types: [el.statuses]
                }
            ))
        });

        setStep(1);
        setModal('update');
        // getSituationAwareness();
    }

    const canEdit = localReadClose('situational-awareness', false, 'update') && !situationAwarenessData?.data?.length && situationAwarenessData?.data?.title;

    useEffect(() => {
        if(checkRead && selectedIncident?.id){
            getSituationAwareness()
        }
    }, [selectedIncident])

    return (
        <>

            <SituationAndSupportHeader
                title={'Situation Awareness'}
                step={step}
                stepCallback={setStep}
                canEdit={canEdit}
                save={save}
            />

            <div className="dash-page-content-item flex-1">
                <div className="row g-5">                    
                    { step === 1 ? 
                        <SituationAndSupportContent
                            data={updatedData}
                        /> :
                        <SituationAndSupportEdit
                            type="situationAwareness"
                            data={updatedData}
                            setData={setUpdatedData}
                            canEdit={canEdit}
                        />
                    }

                    { situationAwarenessData?.statuses ?
                        <LatestLifelineStatus canEdit={step===2}
                            priorities={updatedPriorities}
                            titles={situationAwarenessData && Object.keys(situationAwarenessData?.statuses)}
                            isSituation
                            newObjCallBack={setUpdatedPriorities}
                        />
                    : null }
                </div>
            </div>

            {
                modal === 'update' ? 
                    <UpdatedModal onConfirm={() => {setModal(false); }} />
                : null
            }
        </>
    )
}