import * as yup from 'yup';

const requestDemoSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    first_name: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    last_name: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    organisation: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    question: yup
        .string()
        .required('Is a required field')
        .min(5, 'Min 5 characters'),
})

export default requestDemoSchema;
