import React, {useState} from 'react'
import {Icon} from '../ui/Icon'
import {useDispatch, useSelector} from "react-redux";
import {getAllNotifications} from "../../redux/slices/notificationSlice";
import {fetchClearAllNotifications, fetchRemoveNotifications} from "../../redux/thunks/notificationThunks";
import {NotificationManager} from "react-notifications";

const notificationBlocks = [
    {
        id: 1,
        img: "img/icon-notifications-1.svg",
        title: "Report successfully created",
        text: "Custom Report Name created today at 20:30 AM",
        date: "Now 15, 2023",
    },
    {
        id: 2,
        img: "img/icon-notifications-2.svg",
        title: "New task created",
        text: "DR-4597-NY, new task with ID #123",
        date: "Now 15, 2023",
    },
    {
        id: 3,
        img: "img/icon-notifications-3.svg",
        title: "New incident launched",
        text: "Incident that created DR-4597-NY",
        date: "Now 15, 2023",
    },
    {
        id: 4,
        img: "img/icon-notifications-4.svg",
        title: "Task status changed",
        text: "Task #123 change to status Completed",
        date: "Now 15, 2023",
    },
    {
        id: 5,
        img: "img/icon-notifications-5.svg",
        title: "Report successfully created",
        text: "Created Custom Report Name at 20:30 AM",
        date: "Now 15, 2023",
    },
    {
        id: 6,
        img: "img/icon-notifications-2.svg",
        title: "New task created",
        text: "DR-4597-NY, new task with ID #123",
        date: "Now 15, 2023",
    },
    {
        id: 7,
        img: "img/icon-notifications-7.svg",
        title: "DR-4597-NY, new task with ID #123",
        text: "Custom Report Name created today at 20:30 AM",
        date: "Now 15, 2023",
    },
    {
        id: 8,
        img: "img/icon-notifications-8.svg",
        title: "New incident launched",
        text: "Custom Report Name created today at 20:30 AM",
        date: "Now 15, 2023",
    },
    {
        id: 9,
        img: "img/icon-notifications-4.svg",
        title: "Report successfully created",
        text: "Custom Report Name created today at 20:30 AM",
        date: "Now 15, 2023",
    }
]

export const NotificationModal = ({open, onClose}) => {
    const dispatch = useDispatch()
    const actualNotifications = useSelector(getAllNotifications)
    const [pending, setPending] = useState(false)
    // const [notifications, setNotifications] = useState(notificationBlocks)

    const removeNotification =  (id) => {
        // const updatedNotifications = notifications.filter(notification => notification.id !== id);
        // setNotifications(updatedNotifications)
    }

    const clearAll = () => {
        // setNotifications([])
    }

    const handleNotificationAction = async (e, id = null) => {
        e.stopPropagation()
        setPending(true);
        try {
            if (id !== null) {
                await dispatch(fetchRemoveNotifications(id)).unwrap();
                NotificationManager.success("Notification has been removed");
            } else {
                await dispatch(fetchClearAllNotifications()).unwrap();
                NotificationManager.success("Notifications have been removed");
            }
        } catch (e) {
            NotificationManager.error("Oops, please try again later");
        }
        setPending(false);
    };


    return (
        <div id="notification" className={`modal modal-default modal-document ${open && 'modal--show'}`}>
            <div className="modal__dialog modal__dialog--600">
                <div className="modal__content">
                    <div className="modal__header border-bottom">
                        <h2 className="modal__title">
                            <div className="ico-24 mr-[9px]">
                                <Icon src="img/bell-notifications.svg" alt=""/>
                            </div>
                            Notification
                        </h2>
                        <div className="modal__header-right">
                            <button disabled={pending} onClick={handleNotificationAction}
                               className="btn btn--outline-secondary-2 btn--md radius-5 mr-[8px]">Clear All</button>
                            <a onClick={(e) => {
                                e.preventDefault();
                                onClose()
                            }} href="#" className="btn btn-close" data-close-modal="">
                                <Icon src="img/delete-disabled.svg" alt=""/>
                            </a>
                        </div>
                    </div>
                    <div className="modal__body">
                        <div className="notification-list">
                            {/*{notifications.map((el, idx) => <div key={idx} className="notification-item">*/}
                            {/*    <div className="notification-item__head mb-[4px]">*/}
                            {/*        <div className="flex items-center">*/}
                            {/*            <div className="decor-circle-32x32 mr-[12px]">*/}
                            {/*                <Icon src={el.img} alt="" />*/}
                            {/*            </div>*/}
                            {/*            <a href="#" className="font-[700] text-[14px] leading-[22px]">{el.title}</a>*/}
                            {/*        </div>*/}
                            {/*        <a onClick={() => removeNotification(el.id)} href="#" className="btn btn--25">*/}
                            {/*            <Icon src="img/icon-delete-disabled-20x20.svg" alt="" />*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="pl-[44px]">*/}
                            {/*        <p className="opacity-80 mb-[4px] text-[14px] leading-[22px]">{el.text}</p>*/}
                            {/*        <span className="opacity-40 font-[500] text-[13px] leading-[20px]">{el.date}</span>*/}
                            {/*    </div>*/}
                            {/*</div>)}*/}
                            {
                                actualNotifications && actualNotifications.length ? actualNotifications.map((el, idx) =>
                                        <div key={el.id} className="notification-item">
                                            <div className="notification-item__head mb-[4px]">
                                                <div className="flex items-center">

                                                    {/* TODO notifications doesn't contain image */}
                                                    <div className="decor-circle-32x32 mr-[12px]">
                                                        <Icon src='img/icon-notifications-4.svg' alt="" />
                                                    </div>

                                                    <a href="#"
                                                       className="font-[700] text-[14px] leading-[22px]">{el.title}</a>
                                                </div>
                                                <button onClick={(e) => handleNotificationAction(e, el.id)} disabled={pending}
                                                   className="btn btn--25">
                                                    <Icon src="img/icon-delete-disabled-20x20.svg" alt=""/>
                                                </button>
                                            </div>
                                            <div className="pl-[44px]">
                                                <p className="opacity-80 mb-[4px] text-[14px] leading-[22px]">{el.context}</p>
                                                <span
                                                    className="opacity-40 font-[500] text-[13px] leading-[20px]">{el.date}</span>
                                            </div>
                                        </div>)
                                    :
                                    <p className='empty-notifications'>You don't have any notification</p>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
