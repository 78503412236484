import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import IconSearchLoupe from "../../assets/img/search-loupe.svg";

import AddMainIncidentModal from "../../components/Modals/AddMainIncidentModal";
import ViewMainIncidentModal from "../../components/Modals/ViewMainIncidentModal";
import AddSubIncidentModal from "../../components/Modals/AddSubIncidentModal";
import {useDispatch, useSelector} from "react-redux";
import {
    adminDeleteIncident,
    adminFetchAllIncidents,
    adminFetchIncidentsType
} from "../../redux/thunks/admin/adminIncidentsThunks";
import {getAdminIncidents} from "../../redux/slices/admin/adminIncidentsSlice";
import InfoModal from "../../components/Modals/InfoModal";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";
import Table from '../../components/ui/Table';


export const IncidentsAdmin = () => {
    const dispatch = useDispatch()

    const [infoModalConfig, setInfoModalConfig] = useState({
        icon: false,
        title: '',
        subtitle: ''
    });
    const [confirmDeleteModalState, setConfirmDeleteModalState] = useState({open: false, id: null});
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [addMainIncidentModalState, setAddMainIncidentModalState] = useState({open: false, mode: null, id: null});
    const [viewMainIncidentModalState, setViewMainIncidentModalState] = useState({open: false, id: null});
    const [addSubIncidentModalState, setAddSubIncidentModalState] = useState({open: false, id: null});

    const [searchVal, setSearchVal] = useState('')
    const [debouncedValue, setDebouncedValue] = useState('');
    const [incidentsFiltered, setIncidentsFiltered] = useState([])

    const [fetchLoading, setFetchLoading] = useState(false);

    const fetchData = async() => {
        setFetchLoading(true);
        await dispatch(adminFetchAllIncidents())
        await dispatch(adminFetchIncidentsType())
        setFetchLoading(false);
    }



    const allIncidents = useSelector(getAdminIncidents)

    const onAddIncidentSuccess = () => {
        setInfoModalConfig({
            icon: require('../../assets/img/updated-img.png'),
            title: addMainIncidentModalState.mode === 'edit' ? 'Incident Updated' : 'Incident Created',
            subtitle: addMainIncidentModalState.mode === 'edit' ? 'Incident updated successfully' : 'Incident create successfully'
        });
        setInfoModalOpen(true);
    }

    const subCreated = () => {
        fetchData();
    }

    useEffect(() => {
        if (debouncedValue) {
            setIncidentsFiltered([...allIncidents].filter(el => el.name.toLowerCase().includes(debouncedValue)))
        } else {
            setIncidentsFiltered(allIncidents && allIncidents.length ? allIncidents : [])
        }
    }, [debouncedValue, allIncidents]);

    useEffect(() => {
        const debounceDelay = setTimeout(() => {
            setDebouncedValue(searchVal);
        }, 500)

        return () => {
            clearTimeout(debounceDelay)
        };
    }, [searchVal]);

    useEffect(() => {
        fetchData();
    }, []);


    const actionView = (inc) => {
        setViewMainIncidentModalState({open:true,id:inc})
    }

    const actionEdit = (inc) => {
        setAddMainIncidentModalState({open: true, mode: 'edit', id: inc})
    }

    const actionAddSub = (inc) => {
        setAddSubIncidentModalState({open: true, id: inc});
    }

    const setConfirmDelete = (inc) => {
        setConfirmDeleteModalState({open: true, id: inc})
    }

    return (
        <>
            <div className="bg-gray w-full flex justify-between items-center flex-wrap px-[29px] pt-[20px]">
                <div className="input-custom mb-[20px]" style={{ maxWidth: '300px', width: '100%' }}>
                    <form action="" className="form-search search-border h-[32px]">
                        <button type="submit" className="form-search__btn">
                            <img src={IconSearchLoupe} />
                        </button>
                        <input
                            type="search" className="form-search__input" placeholder="Search..."
                            value={searchVal} onChange={(e) => setSearchVal(e.target.value)}
                        />
                    </form>
                </div>
                <a
                    href="#" className="btn btn--primary btn--lg-2 rounded-[10px] mb-[20px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        setAddMainIncidentModalState({open: true, mode: 'add', id: null});
                    }}
                >
                    <span className="ico mr-[8px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.16699V15.8337" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16602 10H15.8327" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    Add Main Incident
                </a>
            </div>
            <div className="dash-page-content-item">
                <div className="card-body mb-[24px]">
                    {
                        fetchLoading ?
                        <div className="d-flex items-center justify-center p-[30px] w-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                            </svg>
                        </div> :
                        <Table 
                            initialData={incidentsFiltered}
                            setSortedData={setIncidentsFiltered}
                            tHead={[
                                {
                                    content: 'Main Incident Name',
                                    sortField: 'name',
                                },
                                {
                                    content: 'Incident Type',
                                    sortField: 'type.name',
                                },
                                {
                                    content: 'Position Email'
                                },
                                {
                                    content: 'Sub Incident',
                                    sortField: 'sub_incidents',
                                },
                                {
                                    content: 'Additional Comments'
                                },
                            ]}
                            tBody={
                                incidentsFiltered.map((inc, index)=>{
                                    return [
                                        inc.id,
                                        <a href="#" onClick={(e)=>{
                                            e.preventDefault();
                                            setViewMainIncidentModalState({open: true, id: inc.id});
                                        }}>{ inc.name }</a>,
                                        inc.type.name,
                                        inc.user.email,
                                        <Link to={`/superuser/sub-incidents/${inc.id}`} style={{ color: '#2F80ED', borderBottom: '1px solid #2F80ED' }}>{ inc.sub_incidents?.length || 0}</Link>,
                                        <div className="incident-comment">{ inc.incident_comment || 'Nothing' }</div>
                                    ]
                                })
                            }
                            actions={[
                                {
                                    onClick: actionView,
                                    content: <>
                                        <span className="ico ico-12 mr-[8px]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span>
                                            View
                                        </span>
                                    </>
                                },
                                {
                                    onClick: actionAddSub,
                                    content: <>
                                        <span className="ico ico-12 mr-[8px]">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 8V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M16 12H8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        <span>
                                            Add Sub Incident
                                        </span>
                                    </>
                                },
                                {
                                    onClick: actionEdit,
                                    content: <>
                                        <span className="ico ico-12 mr-[8px]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span>
                                            Edit
                                        </span>
                                    </>
                                },
                                {
                                    listLine: true,
                                },
                                {
                                    onClick: setConfirmDelete,
                                    content: <>
                                        <span className="ico ico-12 mr-[8px]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>

                                        </span>
                                        <span className="text-[#EA5E42]">
                                            Delete
                                        </span>
                                    </>
                                }
                            ]}
                        />
                    }
                </div>
            </div>

            { addMainIncidentModalState.open ?
                <AddMainIncidentModal
                    open={addMainIncidentModalState.open}
                    close={()=>{setAddMainIncidentModalState({open: false, mode: null, id: null})}}
                    onSuccess={onAddIncidentSuccess}
                    mode={addMainIncidentModalState.mode}
                    incidentId={addMainIncidentModalState.id}
                />
            : null }

            <ViewMainIncidentModal
                open={viewMainIncidentModalState.open}
                close={()=>{setViewMainIncidentModalState({open:false,id:null})}}
                incidentId={viewMainIncidentModalState.id}
            />

            { addSubIncidentModalState.open ?
                <AddSubIncidentModal
                    open={addSubIncidentModalState.open}
                    incId={addSubIncidentModalState.id}
                    created={()=>{ subCreated() }}
                    close={()=>{setAddSubIncidentModalState({open: false, id: null})}}
                />
            : null }

            <InfoModal
                icon={infoModalConfig.icon}
                title={infoModalConfig.title}
                subtitle={infoModalConfig.subtitle}
                open={infoModalOpen}
                close={()=>{setInfoModalOpen(false)}}
                submit={()=>{setInfoModalOpen(false)}}
            />

            <ConfirmDeleteModal
                open={confirmDeleteModalState.open}
                close={()=>{setConfirmDeleteModalState({open: false, id: null})}}
                submit={async ()=>{
                    try {
                        await dispatch(adminDeleteIncident(confirmDeleteModalState.id))
                        setConfirmDeleteModalState({open: false, id: null});
                        setInfoModalConfig({
                            icon: require('../../assets/img/updated-img.png'),
                            title: 'Deleted Success',
                            subtitle: 'Delete successfully'
                        });
                        setInfoModalOpen(true);
                    } catch (err) {

                    }

                }}
            />
        </>
    )
}
