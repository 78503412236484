import React, { useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import MessageQuestionsSvg from '../../assets/img/messagequestion.svg';
import { messages } from '../../api/index';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import contactSupportSchema from '../../schemas/contactSupportShemas';

const ContactSupportModal = ({ open, close, infoOpen }) => {

    const { handleSubmit, register, setValue, clearErrors, formState: {errors} } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(contactSupportSchema),
    })

    const closeModal = () => {
        close();
    }

    const send = async(payload) => {
        const res = await messages.send(payload);
        
        if(infoOpen && res?.data){
            infoOpen({
                open: true,
                icon: res.data?.success ? require('../../assets/img/updated-img.png') : null,
                title: res.data?.message
            })
        }

        closeModal();
    }

    const formatNumFields = (e, name) => {
        const onlyNumbers = (val) => val.replace(/\D/g, '')
        setValue(name, onlyNumbers(e.target.value))
        clearErrors(name)
    }

    return (
        <FullHeightModal
            title="Contact Support"
            headerIcon={MessageQuestionsSvg}
            open={open}
            close={closeModal}
            submitBtnText={"Send Message"}
            submitMethod={handleSubmit(send)}
            widthClassName="modal__dialog--500"
        >
            <div>
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Full Name</label>
                    <input
                        {...register('name')}
                        className={`input-custom__input ${errors?.name && 'error-field'}`}
                        type="text"
                        id="name"
                        placeholder="Enter your full name..."
                        onChange={(e) => {
                            setValue('name', e.target.value.trimStart());
                        }}
                    />
                <p className="error-field-text">{errors.name?.message}</p>
            </div>

            <div className="row gutter-x-16">
                <div className="col-12 col-lg-6">
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Email</label>
                    <input
                    {...register('email')}
                    className={`input-custom__input ${errors?.email && 'error-field'}`}
                    type="text"
                    id="email"
                    placeholder="Enter your email"
                    onChange={(e) => {
                        setValue('email', e.target.value.trimStart());
                    }}
                    />
                    <p className="error-field-text">{errors.email?.message}</p>
                </div>
                </div>

                <div className="col-12 col-lg-6">
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Phone Number</label>
                    <input
                    {...register('phone')}
                    className={`input-custom__input ${errors?.phone && 'error-field'}`}
                    type="text"
                    id="phone"
                    placeholder="Enter your phone number..."
                    onChange={(e) => {
                        formatNumFields(e, 'phone')
                    }}
                    />
                    <p className="error-field-text">{errors.phone?.message}</p>
                </div>
                </div>
            </div>

            <div className="input-custom h-36px input-border mb-[16px]">
                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Subject</label>
                <input
                {...register('subject')}
                className={`input-custom__input ${errors?.subject && 'error-field'}`}
                type="text"
                id="subject"
                placeholder="Enter subject of your problem..."
                onChange={(e) => {
                    setValue('subject', e.target.value.trimStart());
                }}
                />
                <p className="error-field-text">{errors.subject?.message}</p>
            </div>

            <div className="input-custom h-36px input-border mb-[16px]">
                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Message</label>
                <textarea
                style={{ resize: 'none' }}
                cols="30"
                rows="10"
                {...register('message')}
                className={`input-custom__textarea min-h-[250px] ${errors?.message && 'textarea-error'}`}
                type="text"
                id="message"
                placeholder="Describe us your problem..."
                onChange={(e) => {
                    setValue('message', e.target.value.trimStart());
                }}
                />
                <p className="error-field-text">{errors.message?.message}</p>
            </div>
            </div>
        </FullHeightModal>
    )
}

export default ContactSupportModal;