import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    adminCreateUser, adminDeleteUser,
    adminFetchAgencies,
    adminFetchAllUsers,
    adminFetchPositions,
    adminFetchRegions, adminUpdateUser
} from "../../thunks/admin/userManagementThunks";

const initialState = {
    users: null,
    positions: null,
    regions: null,
    agencies: null
}

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(adminFetchAllUsers.fulfilled, (state, action) => {
            return {
                ...state,
                users: action.payload,
            };
        });
        builder.addCase(adminFetchPositions.fulfilled, (state, action) => {
            return {
                ...state,
                positions: action.payload,
            };
        });
        builder.addCase(adminFetchRegions.fulfilled, (state, action) => {
            return {
                ...state,
                regions: action.payload,
            };
        });
        builder.addCase(adminFetchAgencies.fulfilled, (state, action) => {
            return {
                ...state,
                agencies: action.payload,
            };
        });
        builder.addCase(adminCreateUser.fulfilled, (state, action) => {
            
            const newUsers = state.users ? [...state.users, action.payload] : [action.payload]
            return {
                ...state,
                users: newUsers
            };

        });
        builder.addCase(adminUpdateUser.fulfilled, (state, action) => {

            const newUsers = state.users.map(el => {
                if (el.id === action.payload.data.id) {
                    return action.payload.data
                }
                return el
            })

            return {
                ...state,
                users: newUsers
            }
        });
        builder.addCase(adminDeleteUser.fulfilled, (state, action) => {
            const {id} = action.payload
            const newUsers = state.users.filter(user => user.id !== id)
            return {
                ...state,
                users: newUsers
            };
        });
        // builder.addCase(fetchLogin.rejected, (state) => {
        //     return {
        //         ...state,
        //         auth: {
        //             user_email: null,
        //             username: null,
        //             access_token: null,
        //             refresh_token: null
        //         }
        //     };
        // });

    }
})


export const {  } = userManagementSlice.actions

export const getUsers = (state) => state.userManagement.users;
export const getPositions = (state) => state.userManagement.positions
export const getRegions = (state) => state.userManagement.regions
export const getAgencies = (state) => state.userManagement.agencies

export default userManagementSlice.reducer