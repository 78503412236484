import React from 'react';
import IconDeleteDisabled from "../../assets/img/delete-disabled.svg";

const ConfirmModal = ({ open, close, submit, title }) => {
    return (
        <div className={`modal modal-1 ${open ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__header justify-end">
                        <a href="#" className="btn btn-close" onClick={(e)=>{
                            e.preventDefault();
                            close();
                        }}>
                            <img src={IconDeleteDisabled} alt="" />
                        </a>
                    </div>
                    <div className="modal__body">
                        <h2 className="font-[700] mb-[14px] text-center mt-[-35px] mb-[-35px]">{ title }</h2>
                    </div>
                    <div className="modal__footer">
                        <a 
                            href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                close();
                            }}
                        >Cancel</a>
                        <a 
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                submit();
                                close();
                            }}
                        >Confirm</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmModal;