import React from 'react'

export const SideBarAccordion = ({ linkContent, ulContent, initOpen = false}) => {
    
    const [isOpen, setIsOpen] = React.useState(false)

    const ref = React.useRef(null)

    React.useEffect(() => {
        if (!ref.current) return
        let height = 0
        if (isOpen) {
            ref.current.style.display = 'block'
            height = ref.current.clientHeight
            ref.current.style.height = '0px'
            
            
            setTimeout(() => {
                ref.current.style.height = height + 'px'
            }, 0
            )
           
        } else {
            ref.current.style.height = '0px'
            
            setTimeout(() => {
                ref.current.style.display = 'none'
                ref.current.style.height = null

            }, 300)
        }
    }, [isOpen])

    React.useEffect(() => {
        if (initOpen) {
            setTimeout(() => {
                setIsOpen(true)
            }, 500)
        } 
    }, [initOpen])

    return (
        <li className={`sidebar-item ${isOpen && 'menu-opening menu-open'}`}>

            <a href="#" className="sidebar-link" onClick={() => setIsOpen(!isOpen)}>
                {linkContent}
            </a>

            <ul ref={ref} className={`sub-menu sub-accordion`} style={{display: 'none'}}>
                {ulContent}
            </ul>

        </li>
    )
}
