import React, { useEffect, useState } from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom'

import EmptyAvatar from '../assets/img/empty-avatar.svg';
import WarningSvg from '../assets/img/warning.svg'
import MessageQuestionsSvg from '../assets/img/messagequestion.svg'
import NotificationSvg from '../assets/img/notification.svg'
import SettingsSvg from '../assets/img/Settings.svg'
import { NotificationModal } from './Modals/NotificationModal';
import ContactSupportModal from './Modals/ContactSupportModal';
import { Icon } from '../components/ui/Icon';
import InfoModal from "./Modals/InfoModal";

import { useDispatch, useSelector } from "react-redux";
import { fetchLogout } from "../redux/thunks/authThunks";
import { getSelectedIncident, } from "../redux/slices/incidentsSlice";
import { getAuth, getCurrentCRUD } from "../redux/slices/authSlice";

import { getProfileData, getShowingTabsData } from "../redux/slices/settingsSlice";
import { getShowingTabs } from "../redux/thunks/settingsThunks";

import { settings } from "../api/index";

export const Header = ({ breadcrumbs, hideHeaderNav, headerTitle, tabListProps }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedIncident = useSelector(getSelectedIncident);
    const authData = useSelector(getAuth);
    const profileData = useSelector(getProfileData);
    const fetchTabsData = useSelector(getShowingTabsData);
    const currentCRUD = useSelector(getCurrentCRUD);

    const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false)
    const [isNotifyModal, setIsNotifyModal] = useState(false)
    const [isUserDropdown, setIsUserDropdown] = useState(false)
    const [tabList, setTabList] = useState([])

    const [infoModalConfig, setInfoModalConfig] = useState({
        open: false,
        icon: false,
        title: ''
    });
    
    const handleClickOutside = (e) => {
        if(!e.target.closest('.dropdown__body') && !e.target.closest('.header-user-action') ){
            setIsUserDropdown(false);
        }
    }

    const logout = async() => {
        await dispatch(fetchLogout());
        // navigate("/login");
        window.location.replace('/login');
        // localStorage.removeItem('admin')
    }

    const fetchTabs = async() => {
        await dispatch(getShowingTabs());
    }

    useEffect(()=>{
        document.addEventListener('click', handleClickOutside, true);

        fetchTabs();

        return ()=>{
            document.removeEventListener('click', handleClickOutside, true);
        }
    },[])

    useEffect(()=>{
        if(tabListProps){
            setTabList(tabListProps)
        } else {
            if(fetchTabsData) {
                let hiddenTabs = localStorage.getItem('hidden_tabs') ? JSON.parse(localStorage.getItem('hidden_tabs')) : [];

                // rrf
                // tasks
                // report
                // meetings
                // approve-rrf
                // support-plan
                // situational-awareness

                let tabsList = [
                    { id: 1, path: "/", title: "Incident Page", canClose: false },
                    { id: 2, path: "/coordination", title: "Coordination", canClose: true },
                    { id: 3, path: "/incident-overview", title: "Incident Overview", canClose: true},
                    { id: 4, path: "/execution-checklist", title: "Execution Checklist", canClose: true},
                    { id: 5, path: "/support-plan", title: "Support Plan", canClose: true},
                    { id: 6, path: "/resource-request", title: "Resource Request", canClose: true},
                    { id: 7, path: "/situational-awareness", title: "Situational Awareness", canClose: true},
                ];

                if(!(!!currentCRUD?.["tasks"]?.read)){ tabsList[3].closeFromCRUD = true; }
                if(!(!!currentCRUD?.["support-plan"]?.read)){ tabsList[4].closeFromCRUD = true; }
                if(!(!!currentCRUD?.["rrf"]?.read)){ tabsList[5].closeFromCRUD = true; }
                if(!(!!currentCRUD?.["situational-awareness"]?.read)){ tabsList[6].closeFromCRUD = true; }

                let alowTabIds = fetchTabsData.filter(item => !!item.show).map(item => +item.id);

                setTabList(tabsList.filter(item => !item?.closeFromCRUD && alowTabIds.includes(+item.id) && !hiddenTabs.includes(+item.id)));
            }
        }
    },[tabListProps, fetchTabsData, currentCRUD])

    let params = useParams();
    const setCrumbTitle = (value) => {
        if(value.includes(':')){
            return value.split(':')[0]+params[value.split(':').pop()]
        } else {
            return value;
        } 
    }

    const closeTab = (id) => {
        setTabList(tabList.filter((tab) => tab.id !== id));
        let hiddenTabs = localStorage.getItem('hidden_tabs') || [];
        localStorage.setItem('hidden_tabs', JSON.stringify([...JSON.parse(hiddenTabs), id]));
    }

    return (
        <>
            <header className="header-dasboard">
                <div className="header-dasboard__body">
                    { headerTitle ? headerTitle :
                        <h5 className="header-title flex-auto">
                            <img src={WarningSvg} className="mr-[8px]" alt="" /> 
                            <span>{selectedIncident.name} - { selectedIncident.selected_sub_incident?.inc_number }</span>
                        </h5>
                    }
                    <a href="#" onClick={(e) => {e.preventDefault(); setContactSupportModalOpen(true)}} className="btn header-action">
                        <img src={MessageQuestionsSvg} alt="" />
                    </a>
                    <a onClick={(e) => {e.preventDefault(); setIsNotifyModal(true)}} href="" className="btn header-action">
                        <img src={NotificationSvg} alt="" />
                    </a>
                    <NavLink to="/settings/my-profile" className="btn header-action">
                        {/* <img src={SettingsSvg} alt="" /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.9401 3.5L9.8803 6.02244C9.66833 6.08603 9.47756 6.19202 9.28678 6.298L6.76434 5.23815L5.23815 6.76434L6.298 9.28678C6.19202 9.49875 6.10723 9.66833 6.02244 9.8803L3.5 10.9401V13.0598L6.02244 14.1197C6.10723 14.3317 6.19202 14.5012 6.298 14.7132L5.23815 17.2357L6.76434 18.7618L9.28678 17.702C9.47756 17.7868 9.66833 17.8928 9.8803 17.9776L10.9401 20.5H13.0598L14.1197 17.9776C14.3105 17.8928 14.5224 17.808 14.7132 17.702L17.2357 18.7618L18.7618 17.2357L17.702 14.7132C17.7868 14.5224 17.8928 14.3105 17.9776 14.1197L20.5 13.0598V10.9401L17.9776 9.8803C17.914 9.68953 17.808 9.47756 17.702 9.28678L18.7618 6.76434L17.2357 5.23815L14.7132 6.298C14.5224 6.21322 14.3105 6.10723 14.1197 6.02244L13.0598 3.5L10.9401 3.5ZM12 8.79925C13.7594 8.79925 15.1796 10.2195 15.1796 11.9788C15.1796 13.7382 13.7594 15.1584 12 15.1584C10.2406 15.1584 8.82045 13.7382 8.82045 11.9788C8.82045 10.2195 10.2406 8.79925 12 8.79925Z" fill="currentColor"/>
                        </svg>
                    </NavLink>
                    <div className="dropdown header-user-action" data-dropdown="dropdown">
                        <a onClick={(e) => {e.preventDefault(); setIsUserDropdown(!isUserDropdown)}} href="#" className="btn btn-profile" data-role="button" style={{ background: "#EBEDF0" }}>
                            <img src={profileData?.avatar ? profileData.avatar : EmptyAvatar} />
                        </a>
                    </div>
                </div>
            </header>
            { !hideHeaderNav ?
                !breadcrumbs ? 
                <nav className="page-nav-menu">
                    <ul className="page-nav-list">
                        {
                            tabList.map((tab, i) => {
                                return (
                                    <li key={i} className="page-nav-item">
                                        <NavLink to={tab.path} className="page-nav-link">
                                            {tab.title}
                                        </NavLink>
                                        { tab.canClose && 
                                            <span className="close-tab" onClick={() => closeTab(tab.id)}>
                                                <Icon src="img/delete-disabled.svg"/>
                                            </span> 
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </nav> :
                <nav className="page-nav-menu px-[30px] ovh">
                    <ul className="breadcrumbs py-[14px]">
                        {
                            breadcrumbs.map((item, index)=>{
                                return <li className="breadcrumb-item" key={index}>
                                    {item.url ?
                                    <NavLink to={item.url} className="breadcrumb-link">
                                        { item.title }
                                    </NavLink> :
                                    <a href="#" className="breadcrumb-link">
                                        { setCrumbTitle(item.title) }
                                    </a> }
                                </li>
                            })
                        }
                    </ul>
                </nav>
                : null
            }

                <div className={`dropdown__body ${isUserDropdown && 'is-open'}`} data-role="dropdown" 
                    style={{width: '220px', right: '18px', top: '39px', zIndex: '100'}}>
                    <div className="pt-[10px] px-[6px]">
                        <div className="flex items-center px-[6px] pb-[10px] border-bottom mb-[10px] dropdown-closet-close">
                            <div className="user-ava-24x24 mr-[10px]">
                                <img src={profileData?.avatar ? profileData.avatar : EmptyAvatar} />
                            </div>
                            <div className="flex flex-col">
                                <span className="font-[700] text-[14px] leading-[20px]">{ `${profileData?.name} ${profileData?.surname}` }</span>
                                <span className="text-[13px] leading-[16px] opacity-80">{ profileData?.email }</span>
                            </div>
                        </div>
                    </div>
                    <ul className="px-[6px] pb-[6px]">
                        <li>
                            <NavLink to="/settings/my-profile" className="btn profile-btn" onClick={()=>{setIsUserDropdown(false);}}>
                                <span className="flex mr-[10px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M11.658 6.93659C12.5734 7.85204 12.5734 9.33629 11.658 10.2517C10.7425 11.1672 9.25829 11.1672 8.34284 10.2517C7.42739 9.33629 7.42739 7.85204 8.34284 6.93659C9.25829 6.02114 10.7425 6.02114 11.658 6.93659" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14.7558 15.7983C13.56 14.5392 11.8733 13.75 9.99997 13.75C8.12664 13.75 6.43997 14.5392 5.24414 15.7992" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                My Profile
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="#" className="btn profile-btn"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    logout();
                                    setIsUserDropdown(false);
                                }}
                            >
                                <span className="flex mr-[10px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 10.0002H12.9167" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M6.66602 6.66679V5.27831C6.666 4.46357 7.25502 3.76823 8.05868 3.63429L14.7253 2.52318C15.2086 2.44263 15.7028 2.5788 16.0767 2.89548C16.4505 3.21215 16.666 3.67727 16.666 4.16719V15.833C16.666 16.3229 16.4505 16.7881 16.0766 17.1047C15.7028 17.4214 15.2086 17.5576 14.7253 17.477L8.05868 16.3659C7.25504 16.232 6.66602 15.5367 6.66602 14.7219V13.3335" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.416 7.5L12.916 10L10.416 12.5" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>

            <NotificationModal
                open={isNotifyModal}
                onClose={() => setIsNotifyModal(false)}
            />

            <ContactSupportModal
                open={contactSupportModalOpen}
                infoOpen={(payload)=>{
                    setInfoModalConfig(payload)
                }}
                close={() => setContactSupportModalOpen(false)}
            />

            <InfoModal
                icon={infoModalConfig.icon}
                title={infoModalConfig.title}
                open={infoModalConfig.open}
                close={()=>{setInfoModalConfig({
                    open: false,
                    icon: false,
                    title: ''
                })}}
            />
        </>
    )
}
