import React from 'react'
import { Icon } from '../ui/Icon'
import { ReadClose } from "../../helpers/checkPermission";
import { FlyDropdown } from '../ui/FlyDropdown'

export const CalendarMark = ({data, setModifyEvent, setDelModal}) => {
    const localReadClose = (name, navigateActive, field) => {
        return ReadClose(name, navigateActive, field)
    }

    const [isOpen, setIsOpen] = React.useState(false)

    const parentRef = React.useRef(null)
    const dropdownRef = React.useRef(null)

    React.useEffect(() => {
        parentRef.current = document.getElementById('events-list')
    }, [])

    // const outsideHandler = (e) => {
    //     if (!dropdownRef.current?.contains(e.target) && e.target !== dropdownRef.current) {
    //       setIsOpen(false);
    //     }
    //   };

    // const openDropdown = (e) => {
    //     setIsOpen(!isOpen)
    // }
    
    // React.useEffect(() => {
    //     if (isOpen) {
    //         document.addEventListener('click', outsideHandler)
    //     }

    //     return () => document.removeEventListener('click', outsideHandler)
    // }, [isOpen])

    const getHM = (dt) => {
        const myDate = new Date(dt);
        return {
            hours: myDate.getHours(),
            minutes: myDate.getMinutes()
        }
    }

    const { hours, minutes } = getHM(data.date);

    const checkLessTen = (val) => {
        return String(val).length < 2 ? `0${val}` : val;
    }

    const permissionsData = {
        update: localReadClose('meetings', false, 'update'),
        delete: localReadClose('meetings', false, 'delete')
    };

    const forceClose = () => {}

    return (
        <li className="event custom-dropdown">
            <div className="event-time">
                <span>
                    {checkLessTen(hours) + ':' + checkLessTen(minutes)}
                </span>
            </div>
            <div className="event-info items-center">
                <div className="flex flex-col flex-auto">
                    <p className="event-title">
                        {data.description}
                    </p>
                    <span className="event-id">
                        {data.id}
                    </span>
                </div>
            </div>

            <FlyDropdown forceClose={forceClose}>
                <div  className={`dropdown dropdown-open`} data-dropdown="dropdown" data-position="top-end">
                    <div  className={`dropdown__body dropdown__custom dropdown__custom-open`} data-role="dropdown">
                        <ul  className="dropdown-list whitespace-nowrap p-[5px]" style={{minWidth: '100px'}}>
                            <li className="dropdown-list--item">
                                <button onClick={() => setModifyEvent({id: data.id, mode: 'show'})} className="dropdown-link">
                                    <span className="ico ico-12 mr-[8px]">
                                        <Icon src="img/ico-view.svg" />
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </button>
                            </li>
                            {
                                permissionsData?.update ?
                                <li className="dropdown-list--item">
                                    <button
                                        onClick={() => { setModifyEvent({id: data.id, mode: 'edit'}) } }
                                        className="dropdown-link"
                                    >
                                        <span className="ico ico-12 mr-[8px]">
                                            <Icon src="img/ico-delete.svg" />
                                        </span>
                                        <span>
                                            Edit
                                        </span>
                                    </button>
                                </li> : null
                            }
                            {
                                permissionsData?.delete ? <>
                                <li className=" dropdown-list-line"><span></span></li>
                                <li className="dropdown-list--item ">
                                    <button onClick={() => {setModifyEvent({id: data.id, mode: 'all'}); setDelModal(true); forceClose() }} className="dropdown-link pr-[40px]">
                                        <span className="ico ico-12 mr-[8px]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span className="text-[#EA5E42]">Delete</span>
                                    </button>
                                </li>
                                </> : null
                            }
                        </ul>
                    </div>
                </div>
            </FlyDropdown>


            {/* <div className="event-action" ref={dropdownRef}>
                <div  className={`dropdown ${isOpen && 'dropdown-open'}`} data-dropdown="dropdown" data-position="top-end">
                    <a onClick={openDropdown} href="#" className="btn btn-dropdown" data-role="button">
                        <span className="ico ico-20 justify-center">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9968 16.0438C10.7446 16.0438 10.5383 16.2501 10.541 16.5023C10.541 16.7545 10.7474 16.9608 10.9996 16.9608C11.2518 16.9608 11.4581 16.7545 11.4581 16.5023C11.4581 16.2501 11.2518 16.0438 10.9968 16.0438" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.9968 10.5415C10.7446 10.5415 10.5383 10.7478 10.541 11C10.541 11.2522 10.7474 11.4585 10.9996 11.4585C11.2518 11.4585 11.4581 11.2522 11.4581 11C11.4581 10.7478 11.2518 10.5415 10.9968 10.5415" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.9968 5.03918C10.7446 5.03918 10.5383 5.24552 10.541 5.49771C10.541 5.7499 10.7474 5.95623 10.9996 5.95623C11.2518 5.95623 11.4581 5.7499 11.4581 5.49771C11.4581 5.24552 11.2518 5.03918 10.9968 5.03918" stroke="#95AACB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                    </a>
                    <div  className={`dropdown__body dropdown__custom ${isOpen && 'dropdown__custom-open'}`} data-role="dropdown">
                        <ul  className="dropdown-list whitespace-nowrap p-[5px]" style={{minWidth: '100px'}}>
                            <li className="dropdown-list--item">
                                <button onClick={() => setModifyEvent({id: data.id, mode: 'show'})} className="dropdown-link">
                                    <span className="ico ico-12 mr-[8px]">
                                        <Icon src="img/ico-view.svg" />
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </button>
                            </li>
                            {
                                localReadClose('meetings', false, 'create') ?
                                <li className="dropdown-list--item">
                                    <button onClick={() => setModifyEvent({id: data.id, mode: 'edit'})} className="dropdown-link">
                                        <span className="ico ico-12 mr-[8px]">
                                            <Icon src="img/ico-delete.svg" />
                                        </span>
                                        <span>
                                            Edit
                                        </span>
                                    </button>
                                </li> : null
                            }
                            {
                                localReadClose('meetings', false, 'delete') ? <>
                                    <li className=" dropdown-list-line"><span></span></li>
                                    <li className="dropdown-list--item ">
                                        <button onClick={() => {setDelModal(true); setModifyEvent({id: data.id, mode: 'all'}); setIsOpen(false)}} className="dropdown-link pr-[40px]">
                                            <span className="ico ico-12 mr-[8px]">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span className="text-[#EA5E42]">Delete</span>
                                        </button>
                                    </li>
                                </> : null
                            }
                        </ul>
                    </div>
                </div>
            </div> */}
        </li>
    )
}
