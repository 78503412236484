import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import IconPlusAdd from "../../assets/img/plus-add.svg";


const AddPaymentMethod = ({ open, close, confirm }) => {
    const closeModal = () => {
        close();
    }

    const generateStripe = async(token) => {
        let stripe = window.Stripe('pk_test_L5jN78SKGrdJ13G331socqG500Fl8g7gAV');
        let auth_token = token;
        let card = null;
        let exp = null;
        let cvc = null;

        const createElements = function(){
            let elements = stripe.elements();
            let style = { style:
                {
                    base: {
                        color: '#121327',
                        fontSize: '14px',
                        '::placeholder': {
                            color: 'rgba(255, 255, 255, 0.6)',
                        },
                    }
                }
            };
            card = elements.create('cardNumber', style);
            exp = elements.create('cardExpiry', style);
            cvc = elements.create('cardCvc', style);
            card.mount("#cardNumber");
            exp.mount("#cardExp")
            cvc.mount("#cardCVC");
        }
    
        await createElements();
        
        const sendAjax = async(url, data={}, method="POST", headers={}) => {
            try{
                let additional = {};
                if(method=='POST'){ additional = { body: JSON.stringify(data) }; };
                
                return await fetch(url, {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": `Bearer ${auth_token}`,
                        ...headers
                    },
                    ...additional
                }).then((res)=>{ return res.json(); });
            }catch(e){
                alert("error");
                return false;
            }
        }
        
        window.saveCard = async() => {
            let isDefault = window.document.querySelector(".is_default_checkbox").checked ? 1 : 0;
            document.querySelector('.add-card-submit')?.classList.add('hidden');
            document.querySelector('.add-card-loading')?.classList.remove('hidden');
    
            var stripe_data = await stripe.createPaymentMethod({
                type: 'card',
                card: card,
            });
            if(stripe_data.error){
                alert(stripe_data.error.message);
                return false;
            }
    
            var intent_data = (await sendAjax("https://beacon.lighthouseemsolutions.com/api/stripe/setup-intent", {})).data;

            let stripe_confirm = await stripe.confirmCardSetup(intent_data.client_secret, {
                payment_method: stripe_data.paymentMethod.id,
            });
            
            if(stripe_confirm.error){
                alert("error");
                return false;
            }
            let card_data = {
                "stripe_id": stripe_data.paymentMethod.id,
                "last4": stripe_data.paymentMethod.card.last4,
                "exp_month": stripe_data.paymentMethod.card.exp_month,
                "exp_year": stripe_data.paymentMethod.card.exp_year,
                "is_default": isDefault,
            };
    
            let resp = await sendAjax("https://beacon.lighthouseemsolutions.com/api/stripe/save-card", card_data, "POST");
            if(window.confirmAddCardPopup){
                await window.confirmAddCardPopup();
            }
    
            document.querySelector('.add-card-submit')?.classList.remove('hidden');
            document.querySelector('.add-card-loading')?.classList.add('hidden');
    
            return stripe_data;
        }
    
        
    }

    const run = async() => {
        // let payment = new Payment(localStorage.getItem("access_token"));
        // windowLocal.payment = payment;
        // await payment.init();

        generateStripe(localStorage.getItem("access_token"))
    }

    useEffect(()=>{
        if(confirm){
            window.confirmAddCardPopup = confirm;
        }        
    }, [confirm])

    useEffect(()=>{
        run();
    }, [])

    return (
        <FullHeightModal
            title="Add Payment Method"
            headerIcon={IconPlusAdd}
            open={open}
            close={closeModal}
            submitBtnText={"Add Payment Method"}
            submitMethod={window?.saveCard}
            widthClassName="modal__dialog--500"
        >
            <div>
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Credit/debit card number</label>
                    <div className="relative">
                        <div id="cardNumber" className="input-custom__input"></div>
                    </div>
                </div>
                <div className="row gutter-x-16">
                    <div className="col-12 col-lg-6">
                        <div className="input-custom h-36px input-border mb-[16px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Expiration Date</label>
                            <div id="cardExp" className="input-custom__input"></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="input-custom h-36px input-border mb-[16px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">CVV Code</label>
                            <div id="cardCVC" className="input-custom__input"></div>
                        </div>
                    </div>
                </div>
                <div className="input-custom mb-[16px]">
                    <label className="custom-checkbox checkbox-border mb-[20px]">
                        <input type="checkbox" className="custom-checkbox__input is_default_checkbox" />
                        <span className="custom-checkbox__input-fake"></span>
                        <span className="custom-checkbox__label">Set as default payment method</span>
                    </label>
                </div>
            </div>
        </FullHeightModal>
    )
}

export default AddPaymentMethod;