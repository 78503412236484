import React from 'react'
import { useState } from 'react';
import {useDispatch} from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import {fetchDemoRequest} from "../../redux/thunks/authThunks";
import {NotificationManager} from "react-notifications";
import { Controller, useForm } from 'react-hook-form';
import requestDemoSchema from '../../schemas/requestDemoShemas';

const RequestDemoModal = ({ open, close, submit }) => {
    const dispatch = useDispatch()

    const { handleSubmit, control, setValue, clearErrors, formState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(requestDemoSchema),
    })

    const onlyLetersAllow = (event, name) => {
        const value = event.target.value.replace(/\d/g, '')
        setValue(name, value.trimStart())
        clearErrors(name)
    }

    const preSubmit = async (data) => {
        
        let res = await fetchDemoRequest({
                "first_name": data.first_name,
                "last_name": data.last_name,
                "organization": data.organisation,
                "business_email": data.email,
                "message": data.question
            }
        )

        if (res?.data?.success) {
            submit(data.organisation)
            return
        }

        NotificationManager.error(res.data.message)

    }

    return (
        <div className={`modal modal-1 ${open ? 'modal--show' : ''}`}>
            <div className="modal__dialog ">
                <div className="modal__content request-demo-modal">
                    <div className="d-flex flex-col items-center">
                        <h2 className="font-[700] mb-[14px]">Request a Demo</h2>
                        <p className="text-[14px] font-[400] leading-[22px] text-center opacity-60 whitespace-nowrap">Ready to Explore? Request Your Demo Now!</p>
                    </div>

                    <form onSubmit={handleSubmit(preSubmit)}>
                    <div className='d-flex my-[30px] request-demo-modal-body'>
                        
                        <div className='request-modal-body__inputs'>
                            <div className="input-custom h-40px input-border mb-[16px]">
                                <label className="d-block text-[13px] font-[600] mb-[10px] opacity-70">First Name</label>
                                
                                <Controller
                                    name="first_name"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => 
                                        <input
                                            placeholder="Type first name..."
                                            className={`input-custom__input request-demo-input ${formState.errors?.first_name && 'input-error' }`} type="text"
                                            {...field}
                                            onChange={(e) => { field.onChange(); onlyLetersAllow(e, 'first_name');  }}
                                         />
                                    }
                                />
                                <p className='error-field-text'>{formState.errors.first_name?.message}</p>
                            </div>

                            <div className="input-custom h-40px input-border mb-[16px]">
                                <label className="d-block text-[13px] font-[600] mb-[10px] opacity-70">Last Name</label>
                                
                                <Controller
                                    name="last_name"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => 
                                        <input
                                        placeholder="Type last name..."
                                        className={`input-custom__input request-demo-input ${formState.errors?.last_name && 'input-error' }`} type="text" 
                                        {...field} 
                                        onChange={(e) => { field.onChange(); onlyLetersAllow(e, 'last_name');  }}/>
                                    }
                                />
                                <p className='error-field-text'>{formState.errors.last_name?.message}</p>
                            </div>

                            <div className="input-custom h-40px input-border mb-[16px]">
                                <label className="d-block text-[13px] font-[600] mb-[10px] opacity-70">Organisation</label>
                               
                                <Controller
                                    name="organisation"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => 
                                        <input
                                        placeholder="Type your organization name..."
                                        className={`input-custom__input request-demo-input ${formState.errors?.organisation && 'input-error' }`} type="text" 
                                        {...field} 
                                        onChange={(e) => { field.onChange(); onlyLetersAllow(e, 'organisation');  }}/>
                                    }
                                />
                                <p className='error-field-text'>{formState.errors.organisation?.message}</p>
                            </div>
                            
                            <div className="input-custom h-40px input-border">
                                <label className="d-block text-[13px] font-[600] mb-[10px] opacity-70">Business Email</label>
                                
                                <Controller
                                    name="email"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => 
                                        <input
                                        placeholder="Type business email..."
                                        className={`input-custom__input request-demo-input ${formState.errors?.email && 'input-error' }`} type="text" 
                                        {...field} 
                                        onChange={(e) => { field.onChange(); setValue("email", e.target.value.trimStart()) }}/>
                                    }
                                />
                                <p className='error-field-text'>{formState.errors.email?.message}</p>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <div className="input-custom d-flex flex-col h-full">
                                <label className="d-block text-[13px] font-[600] mb-[10px] opacity-70">How can we help you?</label>
                                
                                <Controller
                                    name="question"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => 
                                        <textarea
                                        className={`input-custom__textarea request-demo-modal__textarea ${formState.errors?.question && 'input-error' }`} 
                                        rows="5" 
                                        placeholder="Type your message here..."
                                        {...field}
                                        onChange={(e) => { field.onChange(); setValue("question", e.target.value.trimStart()) }}/>
                                    }
                                />
                                <p className='error-field-text'>{formState.errors.question?.message}</p>
                            </div>
                        </div>
                        
                    </div>
                    

                    <div className="d-flex">
                        <a
                            href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[12px] ml-auto"
                            onClick={(e)=>{
                                e.preventDefault();
                                close();
                            }}
                        >Cancel</a>
                        <button  type='submit'
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px]"
                        >Request a Demo</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RequestDemoModal;