import { api } from "..";

export const resourceRequestApi = {
    getSelectedPosition(){
        return window.selectedPositionWindow;
    },

    createRequest(id, payload){
        return api.post(`/${id}/requests?position_id=${this.getSelectedPosition()}`, payload);
    },

    getRequests(id) {
        return api.get(`/${id}/requests?position_id=${this.getSelectedPosition()}`);
    },
    requestGetById(incidentId, id) {
        return api.get(`/${incidentId}/requests/${id}?position_id=${this.getSelectedPosition()}`);
    },

    updateRequest(incidentId, id, payload){
        return api.put(`/${incidentId}/requests/${id}?position_id=${this.getSelectedPosition()}`, payload);
    },

    updateRequestTaskId(incidentId, id, payload){
        return api.put(`/${incidentId}/requests/${id}/update-task?position_id=${this.getSelectedPosition()}`, payload);
    },

    deleteRequest(id){
        return api.delete(`/requests/${id}?position_id=${this.getSelectedPosition()}`);
    },

    requestExport(id) {
        return api.get(`/request-export/${id}`);
    },
    requestImport(payload) {
        return api.post(`/request-import`, payload, {headers: {
            'Content-Type': 'multipart/form-data',
        } });
    },

    getRequestStatuses() {
        return api.get(`/request-statuses`);
    },
    getRequestPriorities() {
        return api.get(`/request-priorities`);
    },
    getResourceKinds() {
        return api.get(`/resource-kinds`);
    },



    getRequestsChart(id) {
        return api.get(`/${id}/requests-stats`);
    },

    getStates(){
        return api.get(`/states`);
    },
    getAgencies(){
        return api.get(`/agencies`);
    },
    getLocations(){
        return api.get(`/locations`);
    },

    requestDownloadTemplate() {
        return api.get(`/request-import-template`, {responseType: 'blob'});
    }
};