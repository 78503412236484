import React from 'react';
import { sup } from '../SituationAwareness/SituationAwareness';

export const SituationAndSupportContent = ({data, supportPlanData}) => {

    
    return (
        <div className="col-xl-7 col-lg-6 col-12 flex flex-col ">
            <div className="editor-wrapper max-h-[400px] overflow-auto md:overflow-visible md:max-h-none">
                <div className="toolbar-container"></div>    
                {/*{data === 'support' && <div className="ck-content">*/}
                {/*    { supportPlanData?.plans?.title ?  <h4>{supportPlanData.plans.title}</h4> : null }*/}
                {/*    { supportPlanData?.plans?.description ?  <p>{supportPlanData.plans.description}</p> : null }*/}
                {/*    <ol>*/}
                {/*        {*/}
                {/*            supportPlanData?.lifelines.map((item, index)=>{*/}
                {/*                return <li key={index}>{ item.name }</li>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </ol>*/}
                {/*</div>}*/}

                <div className="ck-content">

                    

                    { data && <>
                    <h4><span style={{color: '#2F80ED', fontSize: '22px', lineHeight: '26px', fontWeight: '600'}}>{data?.title}</span></h4>
                    
                    <div dangerouslySetInnerHTML={{__html: data?.description}}></div>
                
                </>}

                </div>

            </div>
        </div>
    )
}
