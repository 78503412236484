import React, {useEffect, useState} from 'react'
import { SelectBox } from "../../components/ui/SelectBox";
import { Icon } from "../../components/ui/Icon";
import {adminFetchPositions, adminFetchAgencies} from "../../redux/thunks/admin/userManagementThunks";
import {useDispatch, useSelector} from "react-redux";
import {getPositions, getAgencies} from "../../redux/slices/admin/userManagementSlice";
// import {fetchAgencies} from "../../redux/thunks/sharedThunks";
import { incidents } from '../../api/index';
import { getSelectedIncident } from "../../redux/slices/incidentsSlice";
import Table from "../../components/ui/Table";

import {useDebounce} from "../../helpers/useDebounce";

export const CoordinationDashboard = () => {
    const dispatch = useDispatch()

    // const [selects, setSelects] = useState({
    //     position: null,
    //     agency: null
    // })

    // const [searchVal, setSearchVal] = useState('');

    // const [agenciesOptions, setAgenciesOptions] = useState(null)

    // const allUsers = useSelector(getUsers)

    // const part1 = allUsers && allUsers.slice(0, Math.ceil(allUsers.length / 3));
    // const part2 = allUsers && allUsers.slice(Math.ceil(allUsers.length / 3), Math.ceil(2 * allUsers.length / 3));
    // const part3 = allUsers && allUsers.slice(Math.ceil(2 * allUsers.length / 3));

    // const filterArr = (arr, key, keyId) => {
    //     if (!keyId) return
    //     const newArr = [...arr].filter(el => {
    //         if (Array.isArray(el[key])) {
    //             return el[key].some(sub => sub.id === keyId);
    //         } else {
    //             return (el[key].id === keyId);
    //         }
    //     });
    //     return newArr;
    // };

    // let positionsOptions = allUsers && allUsers.reduce((arr, user, index) => {
    //     for (let i = 0; i < user.roles.length; i++) {
    //         let role = user.roles[i];
    //         if (role.name === 'admin') continue
    //         if (!arr[role.name]) {
    //             arr[role.name] = {label: role.name.split('_').join(' '), value: role.id}
    //         }
    //     }
    //     return arr
    // }, {})

    // const globalFiltered = (arr) => {
    //     let a = [...arr]
    //     if (selects.agency) {
    //         a = filterArr(a, 'agency', selects.agency)
    //     }
    //     if (selects.position) {
    //         a = filterArr(a, 'roles', selects.position)
    //     }
    //     if (searchVal) {
    //         const regex = new RegExp(searchVal, 'i');
    //         a = a.filter(el => regex.test(el.full_name));
    //     }
    // }

    // allUsers && globalFiltered(allUsers)
    

    // таблицы по ролям
    // const tables = allUsers?.reduce((obj, user) => {
    //     for (let i = 0; i < user.roles.length; i++) {
    //         let {name} = user.roles[i];
    //         if (name === 'admin') continue;
    //         if (obj[name]) {
    //             obj[name].push(user);
    //         } else {
    //             obj[name] = [user];
    //         }
    //     }
    //     return obj;
    // }, {});

    const positions = useSelector(getPositions);
    const agencies = useSelector(getAgencies);

    const [loading, setLoading] = useState(false);
    const [updatedTimes, setUpdatedTimes] = useState(0);
    const isUpdated = useDebounce(updatedTimes, 1000);

    const [headquartersData, setHeadquartersData] = useState(null);
    const [headquartersTableData, setHeadquartersTableData] = useState(null);
    
    const [filters, setFilters] = useState({
        "search": "",
        "position": "",
        "agency": ""
    })


    const selectedIncident = useSelector(getSelectedIncident);

    const fetchData = async() => {
        setLoading(true);

        const res = await incidents.getCoordinationDashboard(
            selectedIncident.id,
            Object.entries(filters).filter(item => item[1] !== "").map((item, index) => `${index === 0 ? '?' : '&'}${item[0]}=${item[1]}`).join('')
        );

        let forHeadquarters = res.data.data.headquarters.map(item => {
            return {
                id: item.id,
                name: item.user.full_name,
                position: item.items[0].position.name.split('_').join(' '),
                agency: item.user?.agency?.name || '-',
                email: item.user.email,
                phone: item.user?.info?.phone || '-',
            }
        });

        setHeadquartersData(forHeadquarters);
        setHeadquartersTableData(forHeadquarters);

        setLoading(false);
    }


    useEffect(() => {
        dispatch(adminFetchPositions());
        dispatch(adminFetchAgencies());

        if(selectedIncident?.id){
            fetchData();
        }

        // if (!allUsers) {
        //     dispatch(adminFetchAllUsers())
        // }
        // const req = async () => {
        //     let res = await fetchAgencies()
        //     if (res) {
        //         setAgenciesOptions([{label: 'All', value: null},...res.data.map(el => ({label: el.name, value: el.id}))])
        //     }

        // }
        // req()
    }, []);

    useEffect(() => {
        if(isUpdated){
            fetchData();
        }
    }, [isUpdated]);

    return (<>
        <div className="bg-gray w-full flex justify-between items-center flex-wrap px-[29px] pt-[20px]">
            <div className="flex items-center flex-wrap">
                {/* <div className="select-wrap w-[150px] mb-[20px] mr-[8px]">
                    <SelectBox 
                        propsOptions={positionsOptions ? [{label: 'All', value: null},...Object.values(positionsOptions)] : []}
                        value={selects.position}
                        change={(value)=>{
                            setSelects((prevState)=>{
                                return ({...prevState, position: value})
                            })
                        }}
                    />
                </div> */}
                <div className="select-wrap w-[150px] mb-[20px] mr-[8px]">
                    <SelectBox 
                        propsOptions={[
                            {label: 'Position', value: ''},
                            ...(positions?.length ? positions.map(item => ({ label: item.name.split('_').join(' '), value: item.id })) : [])
                        ]}
                        value={filters.position}
                        change={(value)=>{
                            setFilters((prevState)=>{
                                return ({...prevState, position: value})
                            });
                            setUpdatedTimes((prevState) => prevState + 1);
                        }}
                    />
                </div>
                <div className="select-wrap w-[150px] mb-[20px] mr-[8px]">
                    <SelectBox 
                        propsOptions={[
                            {label: 'Agency', value: ''},
                            ...(agencies?.length ? agencies.map(item => ({ label: item.name, value: item.id })) : [])
                        ]}
                        value={filters.agency}
                        change={(value)=>{
                            setFilters((prevState)=>{
                                return ({...prevState, agency: value})
                            });
                            setUpdatedTimes((prevState) => prevState + 1);
                        }}
                    />
                </div>
            </div>

            <div className="input-custom mb-[20px]" style={{maxWidth: '300px', width: '100%'}}>
                <form action="" className="form-search search-border h-[32px]">
                    <button type="submit" className="form-search__btn">
                        <Icon src="img/search-loupe.svg" />
                    </button>
                    <input
                        type="search" className="form-search__input" placeholder="Search..."
                        value={filters.search} onChange={(e) => {
                            setFilters((prevState) => { return {...prevState, search: e.target.value } });
                            setUpdatedTimes((prevState) => prevState + 1);
                        }}
                    />
                </form>
            </div>
        </div>
        <div className="dash-page-content-item">
            <div className="flex items-center justify-between flex-wrap">
                <h5 className="font-[500] mr-[10px] mb-[16px]">Headquarters</h5>

                <div className="flex items-center flex-wrap">
                    <a href="#" className="btn mr-[24px] mb-[16px] items-center">
                        <span className="d-block mr-[8px]">Current Occupancy</span>
                        <span className="amount-box">{ headquartersData?.length || 0 }</span>
                    </a>

                    {/* <a href="#" className="btn mb-[16px]">
                        <span className="mr-[8px]">Current Occupancy</span>
                        <span className="amount-box">12</span>
                    </a> */}
                </div>
            </div>
            <div className="card-body mb-[24px]">
                { headquartersTableData && !loading ?
                <Table 
                    initialData={headquartersData}
                    setSortedData={setHeadquartersTableData}
                    tHead={[
                        {
                            content: 'Name',
                            sortField: 'name',
                        },
                        {
                            content: 'Position',
                            sortField: 'position',
                        },
                        {
                            content: 'Agency',
                            sortField: 'agency',
                        },
                        {
                            content: 'Email',
                            sortField: 'email',
                        },
                        {
                            content: 'Phone',
                        },
                    ]}
                    tBody={
                        headquartersTableData.map((item, index)=>{
                            return [
                                item.id,
                                item.name,
                                item.position,
                                item.agency,
                                item.email,
                                item.phone,
                            ]
                        })
                    }
                /> : <div className="d-flex items-center justify-center py-[20px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                        <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                        <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                    </svg>
                </div> }
            </div>
            
            <div className="flex items-center justify-between flex-wrap">
                <h5 className="font-[500] mr-[10px] mb-[16px]">Regional</h5>

                <div className="flex items-center flex-wrap">
                    <a href="#" className="btn mr-[24px] mb-[16px]">
                        <span className="mr-[8px]">Current Occupancy</span>
                        <span className="amount-box">74</span>
                    </a>

                    <a href="#" className="btn mb-[16px]">
                        <span className="mr-[8px]">Current Occupancy</span>
                        <span className="amount-box">12</span>
                    </a>
                </div>
            </div>

            <div className="card-body mb-[24px]">
                <div className="table-wrapper">
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className="text-left by-sort">Name </th>
                                <th className="text-left by-sort">Position</th>
                                <th className="text-left by-sort">Agency</th>
                                <th className="text-left by-sort">Email</th>
                                <th className="text-left">Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-left">
                                    Frances Swann
                                </td>
                                <td>
                                    Mission Assignment Manager
                                </td>
                                <td>
                                    FEMA
                                </td>
                                <td>
                                    stephanienicol@outlook.com
                                </td>
                                <td className="text-left">
                                    (214) 390-8650
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Kurt Bates
                                </td>
                                <td>
                                    LNO
                                </td>
                                <td>
                                    USACE
                                </td>
                                <td>
                                    dennis416@gmail.com
                                </td>
                                <td className="text-left">
                                    (256) 289-9707
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Iva Ryan
                                </td>
                                <td>
                                    RCBD
                                </td>
                                <td>
                                    FEMA
                                </td>
                                <td>
                                    james_hall@gmail.com
                                </td>
                                <td className="text-left">
                                    (814) 413-9191
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Bradley Lawlor
                                </td>
                                <td>
                                    LNO
                                </td>
                                <td>
                                    USACE
                                </td>
                                <td>
                                    eddie_lake@gmail.com
                                </td>
                                <td className="text-left">
                                    (785) 712-6532
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Mary Freund
                                </td>
                                <td>
                                    Mission Assignment Manager
                                </td>
                                <td>
                                    FEMA
                                </td>
                                <td>
                                    jerry73@aol.com
                                </td>
                                <td className="text-left">
                                    (843) 461-5941
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="flex items-center justify-between flex-wrap">
                <h5 className="font-[500] mr-[10px] mb-[16px]">State</h5>

                <div className="flex items-center flex-wrap">
                    <a href="#" className="btn mr-[24px] mb-[16px]">
                        <span className="mr-[8px]">Current Occupancy</span>
                        <span className="amount-box">74</span>
                    </a>

                    <a href="#" className="btn mb-[16px]">
                        <span className="mr-[8px]">Current Occupancy</span>
                        <span className="amount-box">12</span>
                    </a>
                </div>
            </div>
            <div className="card-body">
                <div className="table-wrapper">
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className="text-left by-sort">Name</th>
                                <th className="text-left by-sort">Position</th>
                                <th className="text-left by-sort">Agency</th>
                                <th className="text-left by-sort">Email</th>
                                <th className="text-left">Phone</th>
                                <th className="text-left by-sort">Beacon Instance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-left">
                                    Frances Swann
                                </td>
                                <td>
                                    Mission Assignment Manager
                                </td>
                                <td>
                                    FEMA
                                </td>
                                <td>
                                    stephanienicol@outlook.com
                                </td>
                                <td className="text-left">
                                    (214) 390-8650
                                </td>
                                <td className="text-center">
                                    <div>
                                        <Icon src="img/checkmark-2.svg" />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Kurt Bates
                                </td>
                                <td>
                                    LNO
                                </td>
                                <td>
                                    USACE
                                </td>
                                <td>
                                    dennis416@gmail.com
                                </td>
                                <td className="text-left">
                                    (256) 289-9707
                                </td>
                                <td className="text-center">
                                    <div>
                                        <Icon src="img/checkmark-1.svg" />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Iva Ryan
                                </td>
                                <td>
                                    RCBD
                                </td>
                                <td>
                                    FEMA
                                </td>
                                <td>
                                    james_hall@gmail.com
                                </td>
                                <td className="text-left">
                                    (814) 413-9191
                                </td>
                                <td className="text-center">
                                    <div>
                                        <Icon src="img/checkmark-1.svg" />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Bradley Lawlor
                                </td>
                                <td>
                                    LNO
                                </td>
                                <td>
                                    USACE
                                </td>
                                <td>
                                    eddie_lake@gmail.com
                                </td>
                                <td className="text-left">
                                    (785) 712-6532
                                </td>
                                <td className="text-center">
                                    <div>
                                        <Icon src="img/checkmark-2.svg" />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-left">
                                    Mary Freund
                                </td>
                                <td>
                                    Mission Assignment Manager
                                </td>
                                <td>
                                    FEMA
                                </td>
                                <td>
                                    jerry73@aol.com
                                </td>
                                <td className="text-left">
                                    (843) 461-5941
                                </td>
                                <td className="text-center">
                                    <div>
                                        <Icon src="img/checkmark-1.svg" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
}
