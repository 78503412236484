import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconAddPlus from "../../assets/img/document-add-plus.svg"

const InitialResourceRequestInfoModal = ({ open, close }) => {
    // const [taskData, setTaskData] = useState({
    //     description: '',
    //     agency: '',
    //     lifeline: '',
    //     esf: '',
    //     status: '',
    //     progressComments: '',
    //     createResourceRequest: false,
    // });

    const submit = () => {
        close();
    }

    return (
        <FullHeightModal
            title="Initial Resource Request Information"
            headerIcon={IconAddPlus}
            open={open}
            close={close}
            submitBtnText="Submit"
            submitMethod={submit}
        >
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Requestor’s Name</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={[
                        {label: 'Select name', value: '1'},
                        {label: 'Select name 2', value: '2'},
                        {label: 'Select name 3', value: '3'},
                    ]}
                    // value={taskData.agency}
                    // change={(value)=>{
                    //     setTaskData((prevState)=>{
                    //         return {
                    //             ...prevState,
                    //             agency: value
                    //         }
                    //     })
                    // }}
                />
            </div>
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Requestor’s Title</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={[
                        {label: 'Select title', value: '1'},
                        {label: 'Select title 2', value: '2'},
                        {label: 'Select title 3', value: '3'},
                    ]}
                    // value={taskData.agency}
                    // change={(value)=>{
                    //     setTaskData((prevState)=>{
                    //         return {
                    //             ...prevState,
                    //             agency: value
                    //         }
                    //     })
                    // }}
                />
            </div>
            <label className="custom-checkbox checkbox-border mb-[16px] pb-[16px] border-bottom">
                <input type="checkbox" className="custom-checkbox__input" />
                <span className="custom-checkbox__input-fake"></span>
                <span className="custom-checkbox__label">Change Requestor’s Details</span>
            </label>
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Phone Number</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={[
                        {label: 'Select number', value: '1'},
                        {label: 'Select number 2', value: '2'},
                        {label: 'Select number 3', value: '3'},
                    ]}
                    // value={taskData.agency}
                    // change={(value)=>{
                    //     setTaskData((prevState)=>{
                    //         return {
                    //             ...prevState,
                    //             agency: value
                    //         }
                    //     })
                    // }}
                />
            </div>
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Requestor’s Org</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={[
                        {label: 'Select org', value: '1'},
                        {label: 'Select org 2', value: '2'},
                        {label: 'Select org 3', value: '3'},
                    ]}
                    // value={taskData.agency}
                    // change={(value)=>{
                    //     setTaskData((prevState)=>{
                    //         return {
                    //             ...prevState,
                    //             agency: value
                    //         }
                    //     })
                    // }}
                />
            </div>
            <div className="input-custom mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Description of Requested Assistance</label>
                <textarea className="input-custom__textarea" rows="6" placeholder="Enter description..."></textarea>
            </div>
        </FullHeightModal>
    )
}

export default InitialResourceRequestInfoModal;