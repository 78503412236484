import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { Pages } from "./index"
import Layout from "../layout/Layout";

const AdminUserManagerTitle = <h5 className="header-title flex-auto">
    <span className="ico mr-[8px]">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.3271 7.93615C17.9855 8.59451 17.9855 9.66192 17.3271 10.3203C16.6688 10.9786 15.6013 10.9786 14.943 10.3203C14.2846 9.66192 14.2846 8.59451 14.943 7.93615C15.6013 7.27779 16.6688 7.27779 17.3271 7.93615Z" fill="#7416F7" fillOpacity="0.05"></path>
            <path d="M17.3271 7.93615C17.9855 8.59451 17.9855 9.66192 17.3271 10.3203C16.6688 10.9786 15.6013 10.9786 14.943 10.3203C14.2846 9.66192 14.2846 8.59451 14.943 7.93615C15.6013 7.27779 16.6688 7.27779 17.3271 7.93615" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.8357 4.92729C12.8494 5.94103 12.8494 7.58462 11.8357 8.59836C10.8219 9.61209 9.17834 9.61209 8.1646 8.59836C7.15086 7.58463 7.15086 5.94103 8.1646 4.92729C9.17833 3.91356 10.8219 3.91356 11.8357 4.92729Z" fill="#7416F7" fillOpacity="0.05"></path>
            <path d="M11.8357 4.92729C12.8494 5.94103 12.8494 7.58462 11.8357 8.59836C10.8219 9.61209 9.17834 9.61209 8.1646 8.59836C7.15086 7.58463 7.15086 5.94103 8.1646 4.92729C9.17833 3.91356 10.8219 3.91356 11.8357 4.92729" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.05758 7.93615C5.71594 8.59451 5.71594 9.66192 5.05758 10.3203C4.39922 10.9786 3.33182 10.9786 2.67346 10.3203C2.0151 9.66192 2.0151 8.59451 2.67346 7.93615C3.33182 7.27779 4.39922 7.27779 5.05758 7.93615Z" fill="#7416F7" fillOpacity="0.05"></path>
            <path d="M5.05758 7.93615C5.71594 8.59451 5.71594 9.66192 5.05758 10.3203C4.39922 10.9786 3.33182 10.9786 2.67346 10.3203C2.0151 9.66192 2.0151 8.59451 2.67346 7.93615C3.33182 7.27779 4.39922 7.27779 5.05758 7.93615" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M19.1668 15.8336V14.9202C19.1668 13.7694 18.2343 12.8369 17.0835 12.8369H16.416" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M0.833984 15.8336V14.9202C0.833984 13.7694 1.76648 12.8369 2.91732 12.8369H3.58482" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M14.4499 15.8338V14.4997C14.4499 12.8888 13.1441 11.583 11.5333 11.583H8.46745C6.85661 11.583 5.55078 12.8888 5.55078 14.4997V15.8338" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    </span>
    <span>User Manager</span>
</h5>;

const AdminPermissionsTitle = <h5 className="header-title flex-auto">
    <span className="ico mr-[8px]">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.99909 17.5034H3.33173C2.87154 17.5034 2.49805 17.1299 2.49805 16.6697V11.6677C2.49805 11.2075 2.87154 10.834 3.33173 10.834H4.99909C5.45928 10.834 5.83277 11.2075 5.83277 11.6677V16.6697C5.83277 17.1299 5.45928 17.5034 4.99909 17.5034Z" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M10.8341 14.1687H12.7791C13.1401 14.1687 13.4911 14.052 13.7795 13.8353L15.7937 12.3246C16.2964 11.9478 17 11.9978 17.4444 12.4422V12.4422C17.9379 12.9357 17.9379 13.7352 17.4444 14.2279L15.7161 15.9561C15.251 16.4213 14.6574 16.739 14.0121 16.8682L11.5694 17.3567C11.0851 17.4534 10.5857 17.4418 10.1063 17.3217L7.89706 16.7698C7.63278 16.7031 7.36184 16.6698 7.08922 16.6698H5.83203" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M10.8341 14.1687H12.0721C12.7699 14.1687 13.3352 13.6035 13.3352 12.9057V12.6531C13.3352 12.0737 12.9408 11.5685 12.3789 11.4284L10.4681 10.9507C10.1572 10.8732 9.8387 10.834 9.51857 10.834V10.834C8.74574 10.834 7.9896 11.0624 7.34683 11.4918L5.83203 12.5013" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.2086 3.47551L7.35287 3.47801C7.13195 3.47801 6.92019 3.56638 6.76429 3.72228L6.07651 4.41007C5.75054 4.73604 5.75054 5.26376 6.07651 5.58889L6.7668 6.27918C6.92269 6.43508 7.13528 6.52345 7.35621 6.52261L11.2111 6.52011L11.2203 6.52095C11.773 7.59389 12.8793 8.3342 14.1707 8.33253C16.0115 8.32753 17.5038 6.83107 17.5038 4.99114C17.5046 3.15037 16.014 1.66142 14.1732 1.66309C12.8868 1.66642 11.7831 2.40757 11.2278 3.47634" fill="#02BC77" fillOpacity="0.1"></path>
            <path d="M11.2086 3.47551L7.35287 3.47801C7.13195 3.47801 6.92019 3.56638 6.76429 3.72228L6.07651 4.41007C5.75054 4.73604 5.75054 5.26376 6.07651 5.58889L6.7668 6.27918C6.92269 6.43508 7.13528 6.52345 7.35621 6.52261L11.2111 6.52011L11.2203 6.52095C11.773 7.59389 12.8793 8.3342 14.1707 8.33253C16.0115 8.32753 17.5038 6.83107 17.5038 4.99114C17.5046 3.15037 16.014 1.66142 14.1732 1.66309C12.8868 1.66642 11.7831 2.40757 11.2278 3.47634" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M14.1716 5.21744C14.2916 5.21744 14.3883 5.1199 14.3883 4.99985C14.3883 4.8798 14.29 4.78226 14.1707 4.78226C14.0507 4.78226 13.9532 4.8798 13.9532 4.99985C13.9532 5.1199 14.0507 5.21744 14.1716 5.21744" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    </span>
    <span className="flex-auto px-[10px]">Permissions</span>
</h5>;

const AdminIncidentsTitle = <h5 className="header-title flex-auto">
    <span className="ico mr-[8px]">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99967 10.9333V7.81668" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M9.99884 13.4375C9.88384 13.4375 9.79051 13.5308 9.79134 13.6458C9.79134 13.7608 9.88468 13.8542 9.99968 13.8542C10.1147 13.8542 10.208 13.7608 10.208 13.6458C10.208 13.5308 10.1147 13.4375 9.99884 13.4375" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.691 3.44916L18.0735 14.6192C18.8151 15.9175 17.8776 17.5333 16.3826 17.5333H3.61763C2.12179 17.5333 1.18429 15.9175 1.92679 14.6192L8.30929 3.44916C9.05679 2.13999 10.9435 2.13999 11.691 3.44916Z" fill="#2F80ED" fillOpacity="0.1" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    </span>
    <span className="flex-auto px-[10px]">Incidents</span>
</h5>;

const settingsTabList = [
    { path: "/settings/my-profile", title: "My Profile", canClose: false },
    { path: "/settings/security", title: "Security", canClose: false },
    { path: "/settings/payment-methods", title: "Payment Methods", canClose: false },
    { path: "/settings/plans-and-billing-history", title: "Plans & Billing History", canClose: false },
];

const SettingsHeaderTitle = <h5 className="header-title flex-auto">
    <span className="ico mr-[8px]">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00249 2L8.00499 4.37406C7.80549 4.43392 7.62593 4.53367 7.44638 4.63342L5.07232 3.63591L3.63591 5.07232L4.63342 7.44638C4.53367 7.64588 4.45387 7.80549 4.37406 8.00499L2 9.00249V10.9975L4.37406 11.995C4.45387 12.1945 4.53367 12.3541 4.63342 12.5536L3.63591 14.9277L5.07232 16.3641L7.44638 15.3666C7.62593 15.4464 7.80549 15.5461 8.00499 15.6259L9.00249 18H10.9975L11.995 15.6259C12.1746 15.5461 12.3741 15.4663 12.5536 15.3666L14.9277 16.3641L16.3641 14.9277L15.3666 12.5536C15.4464 12.3741 15.5461 12.1746 15.6259 11.995L18 10.9975V9.00249L15.6259 8.00499C15.5661 7.82544 15.4663 7.62593 15.3666 7.44638L16.3641 5.07232L14.9277 3.63591L12.5536 4.63342C12.3741 4.55362 12.1746 4.45387 11.995 4.37406L10.9975 2L9.00249 2ZM10 6.98753C11.6559 6.98753 12.9925 8.32419 12.9925 9.98005C12.9925 11.6359 11.6559 12.9726 10 12.9726C8.34414 12.9726 7.00748 11.6359 7.00748 9.98005C7.00748 8.32419 8.34414 6.98753 10 6.98753Z" fill="#2F80ED"/>
        </svg>
    </span>
    <span className="flex-auto">Account Settings</span>
</h5>

const UpgradeYourPlanHeaderTitle = <h5 className="header-title flex-auto">
    <span className="ico mr-[8px]">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10Z" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.9987 6.66675V13.3334" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 9.16675L10 6.66675L12.5 9.16675" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </span>
    <span className="flex-auto">Upgrade Your Plan</span>
</h5>

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/login" element={<Pages.Login/>}/>
            <Route path="/authentication" element={<Pages.Authentication/>}/>

            <Route element={<Layout />}>
                <Route path="/" element={<Pages.IncidentWorkspace/>}/>
                <Route path="/coordination" element={<Pages.CoordinationDashboard/>}/>
                <Route path="/incident-overview" element={<Pages.IncidentOverview/>}/>
                <Route path="/execution-checklist" element={<Pages.ExecutionChecklist/>}/>
                <Route path="/resource-request" element={<Pages.IncidentResourceRequest />}/>


                <Route path="/support-plan" element={<Pages.SupportPlan/>}/>
                <Route path="/situational-awareness" element={<Pages.SituationAwareness/>}/>
            </Route>

            <Route element={<Layout hideHeaderNav={true} />}>
                <Route path="/impact" element={<Pages.Impact/>}/>
            </Route>

            <Route element={<Layout breadcrumbs={[{ url:'/execution-checklist', title: 'Execution Checklist' }, { title: `Task #:id`}]} />}>
                <Route path="/task/:id" element={<Pages.TaskPage />} />
                <Route path="/task/edit/:id" element={<Pages.TaskPage edit={true} />} />
            </Route>

            <Route element={<Layout breadcrumbs={[{ url:'/resource-request', title: 'Resource Request' }, { title: `RRF`}]} />}>
                <Route path="/resource-request/:incident_id/:id" element={<Pages.ResourceRequestRRF />} />
                <Route path="/resource-request/edit/:incident_id/:id" element={<Pages.ResourceRequestRRF edit={true} />} />
            </Route>

            <Route element={<Layout breadcrumbs={[{ url:'/resource-request', title: 'Resource Request' }, { title: `RRF ESF`}]} />}>
                <Route path="/rrf-esf" element={<Pages.ResourceRequestRRF />} />
            </Route>
            <Route element={<Layout breadcrumbs={[{ url:'/resource-request', title: 'Resource Request' }, { title: `RRF FEMA`}]} />}>
                <Route path="/rrf-fema" element={<Pages.ResourceRequestRRF fema={true} />} />
            </Route>
            <Route element={<Layout breadcrumbs={[{ url:'/resource-request', title: 'Resource Request' }, { title: `RRF FEMA`}]} />}>
                <Route path="/rrf-fema-signatures" element={<Pages.ResourceRequestRRF fema={true} signatures={true} />} />
            </Route>
            <Route element={<Layout breadcrumbs={[{ url:'/resource-request', title: 'Resource Request' }, { title: `RRF FEMA`}]} />}>
                <Route path="/rrf-fema-signatures/:id" element={<Pages.ResourceRequestRRF fema={true} signatures={true} />} />
            </Route>

            <Route element={<Layout hideHeaderNav={true} headerTitle={AdminUserManagerTitle} />}>
                <Route path="/superuser/user-manager" element={<Pages.UserManagerAdmin />} />
            </Route>
            {/* <Route element={<Layout hideHeaderNav={true} headerTitle={AdminPermissionsTitle} />}>
                <Route path="/superuser/permissions" element={<Pages.PermissionsAdmin />} />
            </Route> */}
            <Route element={<Layout hideHeaderNav={true} headerTitle={AdminIncidentsTitle} />}>
                <Route path="/superuser/incidents" element={<Pages.IncidentsAdmin />} />
            </Route>
            <Route element={<Layout hideHeaderNav={true} headerTitle={AdminIncidentsTitle} />}>
                <Route path="/superuser/sub-incidents/:id" element={<Pages.IncidentsSubIncidentAdmin />} />
            </Route>

            <Route element={<Layout tabListProps={settingsTabList} headerTitle={SettingsHeaderTitle} />}>
                <Route path="/settings/my-profile" element={<Pages.MyProfile />} />
                <Route path="/settings/payment-methods" element={<Pages.PaymentMethods />} />
                <Route path="/settings/security" element={<Pages.Security />} />
                <Route path="/settings/plans-and-billing-history" element={<Pages.PlansBillingHistory />} />
            </Route>

            <Route element={<Layout hideHeaderNav={true} headerTitle={UpgradeYourPlanHeaderTitle} />}>
                <Route path="/settings/upgrade-your-plan" element={<Pages.UpgradeYourPlan />} />
            </Route>
       </>
    ), { }
)