import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconAddPlus from "../../assets/img/document-add-plus.svg"
import {useDispatch, useSelector} from 'react-redux';
import { createTaskFetch } from '../../redux/thunks/executionChecklistThunk';
import { statusPillsOptions } from '../../maps';
import {NotificationManager} from "react-notifications";
import {getTasksData} from "../../redux/slices/executionChecklistSlice";
import CreateRRFModal from "../../components/Modals/CreateRRFModal";
import { adminIncidents, resourceRequest, userManagement } from '../../api/index';
import { getRegions } from '../../redux/slices/admin/userManagementSlice';
import { adminFetchRegions } from '../../redux/thunks/admin/userManagementThunks';

const CreateTaskModal = ({ open, close, createTaskPayload }) => {
    const taskOptions = useSelector(getTasksData);

    const [createRRFOpen, setCreateRRFOpen] = useState(false);

    const regionsData = useSelector(getRegions)


    const agienciesOptions = taskOptions?.agencies.map(el => ({label: el.name, value: el.id}));
    const lifelinesOptions = taskOptions?.lifelines.map(el => ({label: el.name, value: el.id}));
    const statusesOptions = taskOptions?.statuses && Object.entries(taskOptions.statuses).map(el => ({label: el[1], value: el[0]}));
    const prioritiesOptions = taskOptions?.priorities && Object.entries(taskOptions.priorities).map(el => ({label: el[1], value: el[0]}));

    const [taskData, setTaskData] = useState({
        description: '',
        agency: '',
        lifeline: '',
        esf: '',
        status: '',
        progressComments: '',
        createResourceRequest: false,
        priority: 'hight',
        region: null,
        state: null,
        incident_type: null,
    });

    function createSelectOptions(arr) {
        const options = []
        arr.forEach(el => options.push({value: el.id, label: el.name}))
        return options
    }

    const dispatch = useDispatch();

    const [statesOpt, setStatesOpt] = useState([]);
    const [incidentTypes, setIncidentTypes] = useState(null);

    const regionsOptions = regionsData ? createSelectOptions(regionsData) : []


    useEffect(() => {
        const req = async () => {
            const types = await adminIncidents.getAdminIncidentsType()
            setIncidentTypes(createSelectOptions(types.data?.data?.incidentTypes))
        }
        req()
    }, [])

    
    useEffect(() => {
        if (!taskData.region) return

        setStatesOpt(null);
        setTaskData(prev => ({...prev, state: null}))

        const req = async () => {
            try {
                const res = await userManagement.getStates(taskData.region)

                setStatesOpt(createSelectOptions(res.data.data))
            } catch (err) {

            }
        }

        req()
        
    }, [taskData.region]);

    const createTask = async(createdRRFDataArg = null) => {
        if(taskData.createResourceRequest && !createdRRFDataArg){
            setCreateRRFOpen(true);
            return false;
        }



        let payload = {
            'incident_id': createTaskPayload.incident_id,
            "description": taskData.description,
            "agency_id": taskData.agency,
            "lifeline_id": taskData.lifeline,
            "esf_id": 1,
            "status": taskData.status,
            "comment": taskData.progressComments,
            "priority": taskData.priority,
            "resources": createdRRFDataArg?.description || "",
            "RRF_number": createdRRFDataArg?.rrf || null,
            "source": createdRRFDataArg?.source || null,
            region_id: taskData.region,
            state_id: taskData.state,
            incident_type_id: taskData.incident_type
        }
        
        try {
            let createdTaskData = await dispatch(createTaskFetch(payload)).unwrap();

            if(taskData.createResourceRequest && createdRRFDataArg && createdTaskData){
                let resourceRequestRes = await resourceRequest.updateRequestTaskId(createTaskPayload.incident_id, createdRRFDataArg?.id, {
                    task_id: createdTaskData.id
                });
            }

            close()
            NotificationManager.success("Task added successfully");
        } catch (err) {
            console.log(err)
            NotificationManager.error("Task did not add");
        }
        // close();
    }

    return (
        !createRRFOpen ?
        <FullHeightModal
            title="Create Task"
            headerIcon={IconAddPlus}
            open={open}
            close={close}
            submitBtnText="Create Task"
            submitMethod={createTask}
        >
            <div className="input-custom mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Description</label>
                <textarea
                    className="input-custom__textarea" rows="4" placeholder="Enter description..."
                    value={taskData.description}
                    onInput={(e)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                description: e.target.value
                            }
                        })
                    }}
                ></textarea>
            </div>
            {/* <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">ESF</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={[
                        {label: 'ESF', value: '1'},
                        {label: 'ESF 2', value: '2'},
                        {label: 'ESF 3', value: '3'},
                    ]}
                    value={taskData.esf}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                esf: value
                            }
                        })
                    }}
                />
            </div> */}
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">ESF / Agency</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={agienciesOptions}
                    value={taskData.agency}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                agency: value
                            }
                        })
                    }}
                />
            </div>
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Lifeline</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={lifelinesOptions}
                    value={taskData.lifeline}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                lifeline: value
                            }
                        })
                    }}
                />
            </div>
            
            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Status</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={statusesOptions}
                    value={taskData.status}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                status: value
                            }
                        })
                    }}
                />
            </div>

            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Priority</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={prioritiesOptions}
                    value={taskData.priority}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                priority: value
                            }
                        })
                    }}
                />
            </div>

            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Incident type</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={incidentTypes}
                    value={taskData.incident_type}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                incident_type: value
                            }
                        })
                    }}
                />
            </div>

            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Region</label>
                <SelectBox 
                    className="default-select"
                    propsOptions={regionsOptions}
                    value={taskData.region}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                region: value
                            }
                        })
                    }}
                />
            </div>

            <div className="select-wrap select-border h-36px mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">State</label>
                {statesOpt ? <SelectBox 
                    className="default-select"
                    propsOptions={statesOpt}
                    value={taskData.state}
                    change={(value)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                state: value
                            }
                        })
                    }}
                /> : <div className="default-select d-flex justify-center pt-[8px]" style={{height: '36px', border: '1px solid #EDEDED', borderRadius: '8px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                        <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                    </svg>
                </div>}
            </div>

            <div className="input-custom mb-[16px]">
                <label className="block text-[13px] leading-[18px] font-[600] mb-[10px]">Progress Comments</label>
                <textarea
                    className="input-custom__textarea" rows="4" placeholder="Enter description..."
                    value={taskData.progressComments}
                    onInput={(e)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                progressComments: e.target.value
                            }
                        })
                    }}
                ></textarea>
            </div>
            <label className="custom-checkbox checkbox-border mb-[16px]">
                <input
                    type="checkbox" className="custom-checkbox__input"
                    checked={taskData.createResourceRequest}
                    onChange={(e)=>{
                        setTaskData((prevState)=>{
                            return {
                                ...prevState,
                                createResourceRequest: e.target.checked
                            }
                        })
                    }}
                />
                <span className="custom-checkbox__input-fake"></span>
                <span className="custom-checkbox__label">Create Resource Request</span>
            </label>
            <span className="block text-[13px] leading-[20px] opacity-50">*Clicking this button will progress you to the resource request form upon saving.</span>
        </FullHeightModal> :
        <CreateRRFModal
            created={(value)=>{
                createTask(value?.data?.data?.request);
            }}
            close={()=>{ setCreateRRFOpen(false); }}
        />
    )
}

export default CreateTaskModal;