import { api } from "..";

export const authApi = {
	login(payload) {
		return api.post("/login", payload);
	},
	logout() {
		return api.post("/logout");
	},
	async refreshToken() {
		const token = localStorage.getItem('refresh_token');

		// return api.post("/refresh-token", null, {
		// 	headers: {
		// 		Authorization: token ? `Bearer ${token}` : '',
		// 	}
		// });

		let res = await fetch("https://beacon.lighthouseemsolutions.com/api/refresh-token", {
			"headers": {
				"accept": "application/json, text/plain, */*",
				"accept-language": "ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6",
				"access-control-allow-methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				"access-control-allow-origin": "*",
				"authorization": token ? `Bearer ${token}` : '',
				"content-type": "application/json",
				"sec-ch-ua": "\"Chromium\";v=\"118\", \"Google Chrome\";v=\"118\", \"Not=A?Brand\";v=\"99\"",
				"sec-ch-ua-mobile": "?0",
				"sec-ch-ua-platform": "\"Windows\"",
				"sec-fetch-dest": "empty",
				"sec-fetch-mode": "cors",
				"sec-fetch-site": "same-origin",
				"x-requested-with": "XMLHttpRequest"
			},
			"referrer": "https://beacon.lighthouseemsolutions.com/login",
			"referrerPolicy": "strict-origin-when-cross-origin",
			"body": "null",
			"method": "POST",
			"mode": "cors",
			"credentials": "include"
		});

		res = await res.json();

		return res;
	},
	demoRequest(payload) {
		return api.post('/demo-requests', payload)
	},
	resendCode() {
		return api.post(`/otp-resend`)
	},
	verifyCode(code) {
		return api.post(`/otp-verification`, {
			otp: code
		})
	},
	checkIsAdmin() {
		return api.get('/profile/is-admin')
	},
	signSlider(){
		return api.get('/sign-slider');
	},
	forgotPassword(email) {
		return api.post('/forget-password', {email})
	}
};
