import { createSlice } from '@reduxjs/toolkit'
import { 
    impactsFetch, 
} from '../thunks/impactsThunks'

const initialState = {
    data: [],
}

const impactsSlice = createSlice({
    name: 'impacts',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(impactsFetch.fulfilled, (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        });
        builder.addCase(impactsFetch.rejected, (state) => {
            return {
                ...state,
                data: []
            };
        });
    },
})


export const getImpacts = (state) => state.impacts.data;

export default impactsSlice.reducer