import {createAsyncThunk} from "@reduxjs/toolkit";
import {userManagement} from "../../../api";


export const adminFetchAllUsers = createAsyncThunk(
    '/adminFetchAllUsers',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await userManagement.getAllUsers()
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const adminFetchPositions = createAsyncThunk(
    '/adminFetchPositions',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await userManagement.getPositions()
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminFetchRegions = createAsyncThunk(
    '/adminFetchRegions',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await userManagement.getRegions(id)
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminFetchAgencies = createAsyncThunk(
    '/adminFetchAgencies',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await userManagement.getAgencies()
            return res.data.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminCreateUser = createAsyncThunk(
    '/adminCreateUser',
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await userManagement.createUser(payload)
            console.log(res, 'res');
            if(res?.status > 200 && res?.status < 300)  return res.data.data
            else return rejectWithValue(res.data)
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminDeleteUser = createAsyncThunk(
    '/adminDeleteUser',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const res = await userManagement.deleteUser(id)
            return {...res.data, id}
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const adminUpdateUser = createAsyncThunk(
    '/adminUpdateUser',
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {id, userData} = payload
            const res = await userManagement.updateUser(id, userData)
            return res.data
        } catch (err) {
            console.log('task created error', err);
            return rejectWithValue(err?.response.data);
        }
    },
);