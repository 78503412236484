import React, { useState, useEffect } from 'react';
import { settings } from '../../api/index';
import InfoModal from "../../components/Modals/InfoModal";

export const Security = () => {
    const [infoModalConfig, setInfoModalConfig] = useState({
        open: false,
        icon: require('../../assets/img/updated-img.png'),
        title: ''
    });

    const [showPassword, setShowPassword] = useState({
        old: true,
        new: true,
        confirm: true,
    });

    const [data, setData] = useState({
        password: "",
        password_confirmation: "",
        old_password: "",
    });

    const changePassword = async() => {
        let res = null;

        try {
            res = await settings.changePassword(data);
        } catch (err) {
            return res = err;
        }

        setInfoModalConfig((prevState)=>{
            return {
                ...prevState,
                open: true,
                title: res.data.message
            }
        })
    }

    useEffect(()=>{
        setTimeout(() => {
            setShowPassword({
                old: false,
                new: false,
                confirm: false,
            })
        }, 100);
    }, []);

    return (
        <>
            <div className="settings-page-container">
                <h3 className="settings-page-main-title">Security</h3>
                <p className="settings-page-subtitle">Guard your online presence and data with enhanced security.</p>
                
                <div className="input-custom input-border mb-[24px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Old Password</label>
                    <div className="relative">
                        <input
                            className="input-custom__input" type={showPassword.old ? "text" : "password"} placeholder="Old Password..."
                            autoComplete="false" value={data.old_password} onInput={(e)=>{ setData((prevState)=>{ return {...prevState, old_password: e.target.value } }) }}
                        />
                        <a href="#" className="input-password-view-btn" onClick={(e)=>{
                            e.preventDefault();
                            setShowPassword(prevState => { return {...prevState, old: !prevState.old } });
                        }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.59813 10.3892C2.46729 10.1467 2.46729 9.85252 2.59813 9.61002C4.17479 6.69419 7.08729 4.16669 9.99979 4.16669C12.9123 4.16669 15.8248 6.69419 17.4015 9.61085C17.5323 9.85335 17.5323 10.1475 17.4015 10.39C15.8248 13.3059 12.9123 15.8334 9.99979 15.8334C7.08729 15.8334 4.17479 13.3059 2.59813 10.3892Z" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.7678 8.23223C12.7441 9.20854 12.7441 10.7915 11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223C9.20854 7.25592 10.7915 7.25592 11.7678 8.23223" stroke="#121327" strokeWidth="1.4286" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="row gutter-x-16">
                    <div className="col-12 col-lg-6">
                        <div className="input-custom input-border mb-[24px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">New Password</label>
                            <div className="relative">
                                <input
                                    className="input-custom__input" type={showPassword.new ? "text" : "password"} placeholder="New password..."
                                    autoComplete="false" value={data.password} onInput={(e)=>{ setData((prevState)=>{ return {...prevState, password: e.target.value } }) }}
                                />
                                <a href="#" className="input-password-view-btn" onClick={(e)=>{
                                    e.preventDefault();
                                    setShowPassword(prevState => { return {...prevState, new: !prevState.new } });
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.59813 10.3892C2.46729 10.1467 2.46729 9.85252 2.59813 9.61002C4.17479 6.69419 7.08729 4.16669 9.99979 4.16669C12.9123 4.16669 15.8248 6.69419 17.4015 9.61085C17.5323 9.85335 17.5323 10.1475 17.4015 10.39C15.8248 13.3059 12.9123 15.8334 9.99979 15.8334C7.08729 15.8334 4.17479 13.3059 2.59813 10.3892Z" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.7678 8.23223C12.7441 9.20854 12.7441 10.7915 11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223C9.20854 7.25592 10.7915 7.25592 11.7678 8.23223" stroke="#121327" strokeWidth="1.4286" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="input-custom input-border mb-[24px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Confirm New Password</label>
                            <div className="relative">
                                <input
                                    className="input-custom__input" type={showPassword.confirm ? "text" : "password"} placeholder="Confirm New Password..."
                                    autoComplete="false" value={data.password_confirmation} onInput={(e)=>{ setData((prevState)=>{ return {...prevState, password_confirmation: e.target.value } }) }}
                                />
                                <a href="#" className="input-password-view-btn" onClick={(e)=>{
                                    e.preventDefault();
                                    setShowPassword(prevState => { return {...prevState, confirm: !prevState.confirm } });
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.59813 10.3892C2.46729 10.1467 2.46729 9.85252 2.59813 9.61002C4.17479 6.69419 7.08729 4.16669 9.99979 4.16669C12.9123 4.16669 15.8248 6.69419 17.4015 9.61085C17.5323 9.85335 17.5323 10.1475 17.4015 10.39C15.8248 13.3059 12.9123 15.8334 9.99979 15.8334C7.08729 15.8334 4.17479 13.3059 2.59813 10.3892Z" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.7678 8.23223C12.7441 9.20854 12.7441 10.7915 11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223C9.20854 7.25592 10.7915 7.25592 11.7678 8.23223" stroke="#121327" strokeWidth="1.4286" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-end">
                    <a href="#" className="btn btn--primary btn--lg rounded-[8px]" onClick={(e)=>{
                        e.preventDefault();
                        changePassword();
                    }}>Change Password</a>
                </div>
            </div>

            <InfoModal
                icon={infoModalConfig.icon}
                title={infoModalConfig.title}
                open={infoModalConfig.open}
                close={()=>{setInfoModalConfig({
                    open: false,
                    title: ''
                })}}
            />
        </>
    )
}
