import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import IconCalendarSchedule from "../../assets/img/icon-calendar-schedule.svg";
import { resourceRequest } from '../../api/index';
import { getSelectedIncident } from "../../redux/slices/incidentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getResourceRequestOptions } from "../../redux/slices/resourceRequestSlice";
import { fetchRequestOptions } from "../../redux/thunks/resourceRequestThunks";
import { getTasksData } from "../../redux/slices/executionChecklistSlice";

import Map from "../../components/ui/Map";
import { NotificationManager } from 'react-notifications';

const CreateRRFModal = ({ close, created, taskId=false }) => {
    const token = 'pk.73fd73be485a2607469e45185aadc99b';
console.log(taskId, 'task id');
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const [step, setStep] = useState(1);
    
    const [preDeterminedLocation, setPreDeterminedLocation] = useState(false);

    const selectedIncident = useSelector(getSelectedIncident);

    const resourceRequestOptions = useSelector(getResourceRequestOptions);

    
    const taskOptions = useSelector(getTasksData);
    const lifelinesOptions = taskOptions?.lifelines.map(el => ({label: el.name, value: el.id}))

    const incidentTypes = resourceRequestOptions?.types?.map(el => ({label: el.name, value: el.id}))

    console.log(lifelinesOptions, 'options');
    const [mapUpdateKey, setMapUpdateKey] = useState(1);

    const [payload, setPayload] = useState({
        "name": "",
        "title": "",
        "phone": "",
        "email": "",
        "agency_id": 1,
        "lifeline_id": 1,
        "description": "",
        "status": "not_started",
        "priority": "",
        "need_date": "",
        "state_id": 1,
        "facility_poc": "",
        "city": "",
        "address": "",
        "zip_code": "",
        "longitude": "-122.4389057",
        "latitude": "37.758336",

        "incident_type_id": "",
        "statement": '',
    });

    const [locations, setLocations] = useState(null);

    const createRRF = async() => {
        let sendPayload = {
            ...payload,
            "sub_incident_id": selectedIncident?.selected_sub_incident.id,
            ...(taskId ? {task_id: taskId} : {})
        };

        let result = await resourceRequest.createRequest(selectedIncident?.id, sendPayload);
        //console.log(result);
        if(!result.data.success) {
            console.log(result.data.message)
            NotificationManager.error("Something went wrong");
            return
        }
        NotificationManager.success("Request created");
        closeModal();
        if(created) created(result);
    }

    const submitMethod = () => {
        if(step === 1){
            setStep(2);
        } else {
            createRRF();
        }
    }

    const closeModal = () => {
        setShow(false);

        setTimeout(() => {
            close();
            setStep(1);
            setPreDeterminedLocation(false);
        }, 300);
    }

    const getLocations = async() => {
        let result = await resourceRequest.getLocations();
        setLocations(result?.data?.data.filter(item => item.latitude.includes('.')));
    }

    const findCoordsFromData = async(value) => {
        let q = '';

        if(value){
            q += resourceRequestOptions?.states?.find(item => item.id === value)?.name
        } else {
            return;
        }

        const url = `https://api.locationiq.com/v1/autocomplete?key=${token}&q=${q}&limit=5&dedupe=1&tag=place:state&accept-language=en`;
        let res =  await fetch(url).then(res => res.json());
        
        if(res.length){
            setPayload((prevState) => {
                return {...prevState, 
                    "longitude": res[0].lon,
                    "latitude": res[0].lat
                }
            });

            setMapUpdateKey(prevState => ++prevState);
        }
    }

    useEffect(()=>{
        setShow(true);

        if(!resourceRequestOptions?.statuses){
            dispatch(fetchRequestOptions());
        }

        getLocations();
    }, []);

    // useEffect(()=>{
    //     findCoordsFromData();
    // }, [payload.state_id]);

    return (
        <FullHeightModal
            title="Create RRF"
            headerIcon={IconPlusAdd}
            open={show}
            close={closeModal}
            submitBtnText={step === 1 ? "Next" : step === 2 && preDeterminedLocation ? "Submit for Review" : "Create RRF"}
            submitMethod={submitMethod}
            cancelBtn={
                step === 2 ? preDeterminedLocation ? <a 
                    href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[8px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        setPreDeterminedLocation(false);
                    }}
                >Cancel</a> : 
                <a 
                    href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[8px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        setStep(1);
                    }}
                >Back</a> : 
                <a 
                    href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[8px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        closeModal()
                    }}
                >Cancel</a>
            }
        >
            {
                step === 1 ? 
                <>
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Requestor’s Name</label>
                        <input 
                            className="input-custom__input" type="text" placeholder="Enter your email..."
                            value={payload.name} onInput={(e) => {
                                setPayload((prevState) => {
                                    return {...prevState, name: e.target.value}
                                })
                            }}
                        />
                    </div>
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Requestor’s Title</label>
                        <input
                            className="input-custom__input" type="text" placeholder="Enter your title..."
                            value={payload.title} onInput={(e) => {
                                setPayload((prevState) => {
                                    return {...prevState, title: e.target.value}
                                })
                            }}
                        />
                    </div>

                    <div className="row gutter-x-16">
                        <div className="col-12 col-lg-6">
                            <div className="input-custom h-36px input-border mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Phone</label>
                                <input
                                    className="input-custom__input" type="text" placeholder="Enter phone"
                                    value={payload.phone} onInput={(e) => {
                                        setPayload((prevState) => {
                                            return {...prevState, phone: e.target.value}
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="input-custom h-36px input-border mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Email</label>
                                <input
                                    className="input-custom__input" type="text" placeholder="Enter email"
                                    value={payload.email} onInput={(e) => {
                                        setPayload((prevState) => {
                                            return {...prevState, email: e.target.value}
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="select-wrap select-border h-36px mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Requestor’s Org</label>
                                <SelectBox 
                                    className="default-select"
                                    propsOptions={resourceRequestOptions?.agencies?.map(item => {
                                        return { label: item.name, value: item.id }
                                    })}
                                    value={payload.agency_id}
                                    change={(value)=>{
                                        setPayload((prevState)=>{
                                            return {
                                                ...prevState,
                                                agency_id: value
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="select-wrap select-border h-36px mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Lifeline</label>
                                <SelectBox 
                                    className="default-select"
                                    propsOptions={lifelinesOptions}
                                    value={payload.lifeline_id}
                                    change={(value)=>{
                                        setPayload((prevState)=>{
                                            return {
                                                ...prevState,
                                                lifeline_id: value
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="select-wrap select-border h-36px mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident Type</label>
                                <SelectBox 
                                    className="default-select"
                                    propsOptions={incidentTypes}
                                    value={payload.incident_type_id}
                                    change={(value)=>{
                                        setPayload((prevState)=>{
                                            return {
                                                ...prevState,
                                                incident_type_id: value
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="select-wrap select-border h-36px mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Statement</label>
                                <input
                                    className="input-custom__input" type="text" placeholder="Statement"
                                    value={payload.statement} onInput={(e) => {
                                        setPayload((prevState) => {
                                            return {...prevState, statement: e.target.value}
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-custom mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Description of Requested Assistance</label>
                        <textarea
                            className="input-custom__textarea" rows="5" placeholder="Enter descriptions..."
                            value={payload.description}
                            onInput={(e)=>{
                                setPayload((prevState)=>{
                                    return {
                                        ...prevState,
                                        description: e.target.value
                                    }
                                })
                            }}
                        ></textarea>
                    </div>
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Priority</label>
                    <div className="row gutter-x-16">
                        {
                            resourceRequestOptions?.priorities ? Object.entries(resourceRequestOptions?.priorities).map((item, index)=>{
                                return <div className="col-12 col-lg-6" key={index}>
                                    <div className="flex flex-col">
                                        <label className="custom-radio w-full mb-[16px]">
                                            <input
                                                type="radio" className="custom-radio__input" name="r1"
                                                checked={ payload.priority === item[0]}
                                                onChange={(e)=>{
                                                    setPayload((prevState) => {
                                                        return {...prevState, priority: item[0]}
                                                    });
                                                }}
                                            />
                                            <span className="custom-radio__input-fake"></span>
                                            <span className="custom-radio__label">{item[1]}</span>
                                        </label>
                                    </div>
                                </div>
                            }) : null
                        }
                    </div>
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Date Needed</label>
                        <div className="relative">
                            <div className="icon-right pointer-events-none" style={{ background: "#fff", padding: "5px", paddingRight: "0" }}>
                                <img src={IconCalendarSchedule} />
                            </div>
                            <input
                                className="input-custom__input data-calendar data-calendar-input pr-[40px]" type="date" placeholder="--/--/----"
                                value={payload.need_date} onInput={(e) => {
                                    setPayload((prevState) => {
                                        return {...prevState, need_date: e.target.value}
                                    })
                                }}
                            />
                        </div>
                    </div>
                </> : <>
                    { !preDeterminedLocation ?
                        <>
                            <div className="mb-[16px]">
                                <a
                                    href="#" className="btn btn--primary btn--lg-3 py-[6px] rounded-[8px]"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        setPreDeterminedLocation(true);
                                    }}
                                >Use Pre-Determined Location</a>
                            </div>
                            <div className="input-custom h-36px input-border mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Facility POC</label>
                                <input
                                    className="input-custom__input" type="text" placeholder="Enter facility POC"
                                    value={payload.facility_poc} onInput={(e) => {
                                        setPayload((prevState) => {
                                            return {...prevState, facility_poc: e.target.value}
                                        })
                                    }}
                                />
                            </div>
                            <div className="input-custom h-36px input-border mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Address</label>
                                <input
                                    className="input-custom__input" type="text" placeholder="Enter address"
                                    value={payload.address} onInput={(e) => {
                                        setPayload((prevState) => {
                                            return {...prevState, address: e.target.value}
                                        })
                                    }}
                                />
                            </div>
                            <div className="row gutter-x-16">
                                <div className="col-12 col-lg-6">
                                    <div className="input-custom h-36px input-border mb-[16px]">
                                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">City</label>
                                        <input
                                            className="input-custom__input" type="text" placeholder="Enter city"
                                            value={payload.city} onInput={(e) => {
                                                setPayload((prevState) => {
                                                    return {...prevState, city: e.target.value}
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="input-custom h-36px input-border mb-[16px]">
                                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">ZIP Code</label>
                                        <input
                                            className="input-custom__input" type="text" placeholder="Enter ZIP code"
                                            value={payload.zip_code} onInput={(e) => {
                                                setPayload((prevState) => {
                                                    return {...prevState, zip_code: e.target.value}
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="select-wrap select-border h-36px mb-[16px]">
                                <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">State</label>
                                <SelectBox 
                                    className="default-select"
                                    propsOptions={
                                        resourceRequestOptions?.states?.map((item)=> {
                                            return {
                                                label: item.name, value: item.id
                                            }
                                        })
                                    }
                                    value={payload.state_id}
                                    change={(value)=>{
                                        findCoordsFromData(value);
                                        setPayload((prevState)=>{
                                            return {
                                                ...prevState,
                                                state_id: value
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div className="map-wrap">
                                <Map
                                    key={ mapUpdateKey }
                                    hideHeader={true} hideOverlay={true}
                                    centerMarker={true}
                                    lat={payload.latitude}
                                    lng={payload.longitude}
                                    change={(latlng)=>{
                                        setPayload((prevState) => {
                                            return {
                                                ...prevState, 
                                                longitude: String(latlng.lng),
                                                latitude: String(latlng.lat)
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </> : <>
                            <div className="row gutter-x-16">
                                <div className="col-12">
                                    <div className="select-wrap select-border h-36px">
                                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Position Name</label>
                                        { locations ? 
                                        <SelectBox 
                                            className="default-select"
                                            placeholder="Choose Location..."
                                            propsOptions={
                                                locations.map((item) => {
                                                    return {
                                                        label: `${item.city ? item.city+', ' : ''}${item.address ? item.address+', ' : ''}${item.zip_code ? item.zip_code : ''}`,
                                                        value: item
                                                    }
                                                })
                                            }
                                            change={(value) => {
                                                setPayload((prevState) => {
                                                    return {
                                                        ...prevState, 
                                                        longitude: String(value.longitude),
                                                        latitude: String(value.latitude),
                                                        facility_poc: value.facility_poc,
                                                        address: value.address,
                                                        city: value.city,
                                                        zip_code: value.zip_code,
                                                        state_id: value.state_id,
                                                    }
                                                })
                                            }}
                                        /> : null }
                                    </div>
                                </div>
                            </div>
                        </> 
                    }
                </>
            }
        </FullHeightModal>
    )
}

export default CreateRRFModal;