import { api } from ".."

export const notificationsApi = {
    getAll() {
        return api.get('/notifications')
    },

    clearAll() {
        return api.post('/notifications/clear-all')
    },

    deleteOne(id) {
        return api.post(`/notifications/remove/${id}`)
    },
}