import React, {useState} from 'react';
import { Icon } from '../../components/ui/Icon';
import { SelectBox } from "../../components/ui/SelectBox";

export const LatestLifelineStatus = ({ priorities, titles, canEdit, newObjCallBack, isSituation=false, lifelines, add, deleteItem }) => {
    const [addLifelinePriority, setAddLifelinePriority] = useState(false);
    const [newItemData, setNewItemData] = useState({
        id_LL: '',
        priority: ''
    });

    const changeRadio = (id, priority) => {
        const obj = priorities.map(el => {
            if (el.id === id) {
                isSituation ? el.statuses = priority : el.priority = priority
            }

            return el
        })

        newObjCallBack && newObjCallBack(obj)
    }

    const addNew = () => {
        add(newItemData);
        setAddLifelinePriority();
        setNewItemData({
            id_LL: '',
            priority: ''
        })
    }

    const filteredLifelines = lifelines?.filter(itemLifeline => !priorities.map(item => +item.life_line_id).includes(+itemLifeline.id));


    return (
        <div className={`col-xl-5 col-lg-6 col-12 flex flex-col  h-full`}>
            <div className="card-white flex-auto card-white--border sticky top-0">
                <div className="card-header items-center p-[15px]">
                    <div className="card-header--item">
                        <h5 className="heading-5">
                            Latest Lifeline Priorities
                        </h5>
                    </div>
                </div>

                <div className="card-body">
                    <div className="table-wrapper">
                        <table className="table" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th className="text-center">LL</th>
                                    { titles.map((el, idx) => <th key={idx} className="text-center">{el}</th>)}

                                </tr>
                            </thead>
                            <tbody>

                            { priorities.map((el, idx) => (
                                <tr key={el.id+'-'+idx}>
                                    <td className="text-center">
                                            <span className="d-flex icon-feature icon-feature--30 justify-center">
                                                { el.image ?
                                                <span className="icon-wrapp" title={el.name}>
                                                      <img src={el.image} />
                                                     {/*<Icon src={"img/ico-transportation-1.svg"} /> */}

                                                </span> :
                                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "30px" }} title={el.name}>
                                                    { el.name }
                                                </div> }
                                            </span>
                                    </td>
                                    <td className={`text-center ${!canEdit ? 'pointer-events-none' : null}`}>
                                        <label className="custom-radio radio-green">
                                            <input type="radio" className="custom-radio__input" name={`r${idx}`}
                                                   value={titles[0]}
                                                   onChange={(e) => changeRadio(el.id, titles[0])}
                                                   checked={isSituation ? el.statuses === titles[0] : el.priority === titles[0]}

                                            />
                                            <span className="custom-radio__input-fake"></span>
                                        </label>
                                    </td>
                                    <td className={`text-center ${!canEdit ? 'pointer-events-none' : null}`}>
                                        <label className="custom-radio radio-red">
                                            <input type="radio" className="custom-radio__input" name={`r${idx}`}
                                                   value={titles[1]}
                                                onChange={(e) => changeRadio(el.id, titles[1])}
                                                checked={isSituation ? el.statuses === titles[1] :el.priority === titles[1]}

                                            />
                                            <span className="custom-radio__input-fake"></span>
                                        </label>
                                    </td>
                                    <td className={`text-center ${!canEdit ? 'pointer-events-none' : null}`}>
                                        <div className="d-flex items-center justify-center">
                                            <label className="custom-radio radio-yellow">
                                                <input type="radio" className="custom-radio__input" name={`r${idx}`}
                                                    value={titles[2]}
                                                    onChange={(e) => changeRadio(el.id, titles[2])}
                                                    checked={isSituation ? el.statuses === titles[2] :el.priority === titles[2]}

                                                />
                                                <span className="custom-radio__input-fake"></span>
                                            </label>
                                            { canEdit && deleteItem ? 
                                            <a 
                                                href="#" className="item-delete-btn ml-[15px]"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    deleteItem(el);
                                                }}
                                                title="Delete"
                                            >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.66797 6.66663L13.3346 13.3333" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M13.3346 6.66663L6.66797 13.3333" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </a> : null }
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {
                                lifelines?.length && canEdit && filteredLifelines.length && add ?
                                <tr>
                                    <td colSpan="4" style={{ padding: "0" }}>
                                        <div className="d-flex items-center add-item-box p-[15px]">
                                            {
                                                addLifelinePriority ? <>
                                                    <SelectBox
                                                        key={filteredLifelines.length}
                                                        className="default-select mr-[10px] w-full"
                                                        placeholder="Choose lifeline..."
                                                        propsOptions={
                                                            filteredLifelines.map((item) => {
                                                                return {
                                                                    label: item.name,
                                                                    value: item.id
                                                                }
                                                            })
                                                        }
                                                        value={newItemData.id_LL}
                                                        change={(value) => {
                                                            setNewItemData((prevState)=>{
                                                                return {...prevState, id_LL: value}
                                                            })
                                                        }}
                                                    />
                                                    <SelectBox
                                                        className="default-select mr-[10px] w-full"
                                                        placeholder="Choose priority..."
                                                        propsOptions={
                                                            ['Low', 'Medium', 'High'].map((item) => {
                                                                return {
                                                                    label: item,
                                                                    value: item
                                                                }
                                                            })
                                                        }
                                                        value={newItemData.priority}
                                                        change={(value) => {
                                                            setNewItemData((prevState)=>{
                                                                return {...prevState, priority: value}
                                                            })
                                                        }}
                                                    />
                                                    <a
                                                        href="#" className="action-calendar-btn mr-[10px]"
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            addNew();
                                                        }}
                                                    >
                                                        Add
                                                    </a>
                                                </> : null
                                            }
                                            <a
                                                href="#" className="action-calendar-btn"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setAddLifelinePriority(prevState => !prevState);
                                                }}
                                            >
                                                { addLifelinePriority ? 'Close' : 'Add Lifeline Priority'}
                                            </a>
                                        </div>
                                    </td>
                                </tr> : null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                {/*<div className="card-body">*/}
                {/*    <div className="table-wrapper">*/}
                {/*        <table className="table" cellPadding="0" cellSpacing="0">*/}
                {/*            <thead>*/}
                {/*                {*/}
                {/*                    situationAwarenessData?.statuses ?*/}
                {/*                    <tr>*/}
                {/*                        {*/}
                {/*                            Object.entries(situationAwarenessData.statuses).map((item, index)=>{*/}
                {/*                                return <th className="text-center" key={index}>{item[1]}</th>*/}
                {/*                            })*/}
                {/*                        }*/}
                {/*                    </tr> : <tr>*/}
                {/*                        <th className="text-center">LL</th>*/}
                {/*                        <th className="text-center">High</th>*/}
                {/*                        <th className="text-center">Medium</th>*/}
                {/*                        <th className="text-center">Low</th>*/}
                {/*                    </tr>*/}
                {/*                }*/}
                {/*            </thead>*/}
                {/*            <tbody>*/}

                {/*                { supportPlanData?.priorities.map((el, idx) => (*/}
                {/*                    <tr key={idx}>*/}
                {/*                        <td className="text-center">*/}
                {/*                            <span className="d-flex icon-feature icon-feature--30 justify-center">*/}
                {/*                                <span className="icon-wrapp">*/}
                {/*                                    /!* <Icon src={el.img} /> *!/*/}
                {/*                                    /!* <Icon src={"img/ico-transportation-1.svg"} /> *!/*/}
                {/*                                    { supportPlanData?.lifelines.find(itemF => itemF.id === el.life_line_id)?.image ?*/}
                {/*                                        <img src={ supportPlanData?.lifelines.find(itemF => itemF.id === el.life_line_id).image } alt="" />*/}
                {/*                                    : supportPlanData?.lifelines.find(itemF => itemF.id === el.life_line_id) ? */}
                {/*                                        <div className="text-center">*/}
                {/*                                            { supportPlanData?.lifelines.findIndex(itemF => itemF.id === el.life_line_id)+1 }.*/}
                {/*                                        </div>*/}
                {/*                                    : null }*/}
                {/*                                </span>*/}
                {/*                            </span>*/}
                {/*                        </td>*/}
                {/*                        <td className="text-center">*/}
                {/*                            <label className="custom-radio radio-green">*/}
                {/*                                <input type="radio" className="custom-radio__input" name={`r${idx}`}*/}
                {/*                                    value={1}*/}
                {/*                                    // onChange={(e) => changeRadio(e, idx)}*/}
                {/*                                    // checked={el.select === 1}*/}
                {/*                                    checked={el.priority === "High"}*/}
                {/*                                    readOnly={true}*/}
                {/*                                />*/}
                {/*                                <span className="custom-radio__input-fake"></span>*/}
                {/*                            </label>*/}
                {/*                        </td>*/}
                {/*                        <td className="text-center">*/}
                {/*                            <label className="custom-radio radio-red">*/}
                {/*                                <input type="radio" className="custom-radio__input" name={`r${idx}`}*/}
                {/*                                    value={2}*/}
                {/*                                    // onChange={(e) => changeRadio(e, idx)}*/}
                {/*                                    // checked={el.select === 2}*/}
                {/*                                    checked={el.priority === "Medium"}*/}
                {/*                                    readOnly={true}*/}
                {/*                                />*/}
                {/*                                <span className="custom-radio__input-fake"></span>*/}
                {/*                            </label>*/}
                {/*                        </td>*/}
                {/*                        <td className="text-center">*/}
                {/*                            <label className="custom-radio radio-yellow">*/}
                {/*                                <input type="radio" className="custom-radio__input" name={`r${idx}`}*/}
                {/*                                    value={3}*/}
                {/*                                    // onChange={(e) => changeRadio(e, idx)}*/}
                {/*                                    // checked={el.select === 3}*/}
                {/*                                    checked={el.priority === "Low"}*/}
                {/*                                    readOnly={true}*/}
                {/*                                />*/}
                {/*                                <span className="custom-radio__input-fake"></span>*/}
                {/*                            </label>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                ))}*/}
                {/*            </tbody>*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
