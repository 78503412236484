import React, { useEffect, useState } from 'react'
import { SituationAndSupportContent } from '../Parts/SituationAndSupportContent'
import { LatestLifelineStatus } from '../Parts/LatestLifelineStatus'
import { SituationAndSupportEdit } from '../Parts/SituationAndSupportEdit'
import { SituationAndSupportHeader } from '../Parts/SituationAndSupportHeader'
import { useDispatch, useSelector } from "react-redux";
import { fetchIncidentSupportPlan, fetchUpdateSupportPlan } from "../../redux/thunks/incidentsThunks";
import { getSelectedIncident, } from "../../redux/slices/incidentsSlice";
import { supportPageData } from '../Parts/supportSituationData'
import { UpdatedModal } from '../../components/Modals/UpdatedModal'
import { incidents } from '../../api/index';
import { ReadClose } from "../../helpers/checkPermission";

export const SupportPlan = () => {
    const localReadClose = (name, navigateActive, field = 'read') => {
        return ReadClose(name, navigateActive, field)
    }

    const checkRead = localReadClose('support-plan', true);

    const [step, setStep] = React.useState(1);
    const dispatch = useDispatch();
    const selectedIncident = useSelector(getSelectedIncident);

    const [modal, setModal] = useState(false)

    const [supportPlanData, setSupportPlanData] = useState(null);

    const [updatedPriorities, setUpdatedPriorities] = useState(null);

    const [updatedData, setUpdatedData] = useState(null);

    const getSupportPlan = async() =>{
        const fetchPlan = async() => {
            let res = await dispatch(fetchIncidentSupportPlan(selectedIncident?.id)).unwrap();
            if(res?.plan){
                setSupportPlanData(res);
                setUpdatedData({
                    title: res.plan.title,
                    description: res.plan.description
                })
                setUpdatedPriorities(res.priorities.map(el => {
                    const lifeline = res.lifelines.find(item => item.id === el.life_line_id)
                    return {...el, ...lifeline}
                }))

                return res;
            }
        }

        let res = await fetchPlan();

        if(!res?.plan){
            await incidents.createSupportPlan(selectedIncident?.id, { title: 'Title', description: 'Description' });
            await fetchPlan();
        }
    }

    useEffect(() => {
        if(checkRead && selectedIncident?.id){
            getSupportPlan()
        }
    }, [selectedIncident])

    const setNewLifelinePriorities = (value) => {
        setUpdatedPriorities((prevState)=>{
            return [...prevState, {
                id: prevState.length > 0 ? prevState.length+1 : 1,
                life_line_id: value.id_LL,
                plan_id: supportPlanData.plan.id,
                priority: value.priority,
                image: supportPlanData?.lifelines.find(item => item.id === value.id_LL)?.image || null,
            }]
        })
    }

    const deleteLifelinePriorities = (item) => {
        setUpdatedPriorities((prevState)=>{
            return prevState.filter(itemF => itemF.id !== item.id)
        });
    }

    const save = async () => {
        const payload = {
            incidentId: supportPlanData.plan.incident_id,
            planId: supportPlanData.plan.id,
            title: updatedData.title,
            description: updatedData.description,
            priorities: updatedPriorities.map(el => ({
                "id_LL": el.life_line_id,
                "id_plan": el.plan_id,
                "priority": el.priority
            }))
        }
        let res = await dispatch(fetchUpdateSupportPlan(payload))
        setStep(1)
        setModal('update')
    }

    const canEdit = localReadClose('support-plan', false, 'update')
    
    return (
        <>
            <SituationAndSupportHeader
                title={'Support Plan'}
                step={step}
                stepCallback={setStep}
                canEdit={canEdit}
                save={save}
            />

            <div className="dash-page-content-item">
                <div className="row g-5">
                    { step === 1 ?
                        <SituationAndSupportContent
                            data={updatedData}
                        /> :
                        <SituationAndSupportEdit 
                            data={updatedData}
                            setData={setUpdatedData}
                            canEdit={canEdit}
                        />
                    }
                    
                    { supportPlanData?.priorities ?
                        <LatestLifelineStatus
                            canEdit={step===2}
                            priorities={updatedPriorities}
                            titles={['High', 'Medium', 'Low']}
                            newObjCallBack={setUpdatedPriorities}
                            lifelines={supportPlanData?.lifelines}
                            add={setNewLifelinePriorities}
                            deleteItem={deleteLifelinePriorities}
                        />
                    : null }
                </div>
            </div>

            {
                modal === 'update' ? 
                    <UpdatedModal onConfirm={() => {setModal(false); }} />
                : null
            }
        </>
    )
}
