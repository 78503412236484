import React, { useState, useEffect, useRef } from 'react'

const Accordeon = ({ title, content, initOpen = false, htmlContent }) => {
    const [isOpen, setIsOpen] = React.useState(false)

    const ref = React.useRef(null)

    React.useEffect(() => {
        if (!ref.current) return
        let height = 0
        if (isOpen) {
            ref.current.style.display = 'block'
            height = ref.current.clientHeight
            ref.current.style.height = '0px'
            
            
            setTimeout(() => {
                ref.current.style.height = height + 'px'
            }, 0
            )
           
        } else {
            ref.current.style.height = '0px'
            
            setTimeout(() => {
                ref.current.style.display = 'none'
                ref.current.style.height = null

            }, 300)
        }
    }, [isOpen])

    React.useEffect(() => {
        if (initOpen) {
            setTimeout(() => {
                setIsOpen(true)
            }, 500)
        } 
    }, [initOpen])

    return (
        <div className={`accordeon mb-[8px] ${isOpen && 'show-accordeon'}`}>
            <div className="accordeon--head items-center pt-[12px] px-[16px]" onClick={() => setIsOpen(!isOpen)}>
                <h4 className="heading-4 font-[600] mr-[10px] mb-[12px]">{ title || 'Title' }</h4>
                <button type="button" className="mb-[12px] btn btn--shadow btn-accordeon">
                    <span className="ico ico-20">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.16675V15.8334" className="y" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16667 10H15.8333" className="x" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </button>
            </div>
            <div className="accordeon--body px-[16px] pb-[12px]" ref={ref} style={{display: 'none'}}>
                {
                    htmlContent ?
                    <div dangerouslySetInnerHTML={{__html: content }} className="def-text-1 text-06-2 html-accordeon-content"></div> : 
                    <p className="def-text-1 text-06-2">
                        { content || 'Content'}
                    </p>
                }    
            </div>
        </div>
    )
}

export default Accordeon;