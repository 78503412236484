import React from 'react'
import { Icon } from '../ui/Icon'

export const UpdatedModal = ({onConfirm}) => {
    return (
        <div id="updated" className="modal modal-1 modal--show">
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__body items-center">
                        <div className="mb-[23px]">
                            <Icon src="img/updated-img.png" />
                        </div>
                        <h2 className="font-[700] mb-[14px]">Updated</h2>
                        <p className="text-[14px] leading-[22px] text-center opacity-60">Your data has been updated successfully</p>
                    </div>
                    <div className="modal__footer">
                        <a onClick={() => onConfirm && onConfirm()} href="#" className="btn btn--primary btn--lg-3 rounded-[8px] w-full" data-close-modal="">OK</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
