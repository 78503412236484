import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../redux/slices/settingsSlice";
import { getProfile } from "../../redux/thunks/settingsThunks";
import { settings } from '../../api/index';
import ConfirmModal from "../../components/Modals/ConfirmModal";

export const PlansBillingHistory = () => {
    const dispatch = useDispatch();

    const gettedProfileData = useSelector(getProfileData);

    const currentPlan = gettedProfileData?.plan;

    const [invoicesData, setInvoicesData] = useState(null);

    const [lastPageNumber, setLastPageNumber] = useState(null);


    const [page, setPage] = useState(0);
    const [hasMoreBeforePrevious, setHasMoreBeforePrevious] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const [paginationLoader, setPaginationLoader] = useState(false);

    const [confirmModalConfig, setConfirmModalConfig] = useState({
        open: false,
        submit: false,
        title: false,
    });

    const fetchInvoices = async(before = '', after = '') => {
        let res = await settings.getInvoices(5, before, after);
        setInvoicesData(res.data);
        return res.data;
    }

    const downloadAllInvoices = async() => {
        setDownloadLoading(true);
        let res = await settings.downloadAllInvoices();
        window.open(res.data.data.url, '_blank').focus();
        setDownloadLoading(false);
    }

    const changePagination = async(before, after) => {
        let pageLocal = page;

        if(after !== ''){
            setPaginationLoader('next');
            setPage((prevState) => ++prevState);
            ++pageLocal;
        } else {
            setPaginationLoader('prev');
            setPage((prevState) => --prevState);
            --pageLocal;
        }

        let res = await fetchInvoices(before, after);


        if(after !== '' && res.has_more === false){
            setLastPageNumber(pageLocal);
        } else {
            setLastPageNumber(null);
        }

        setPaginationLoader(false);
    }

    const fetchProfileData = async() => {
        await dispatch(getProfile());
    }

    const unsubscribe = () => {
        setConfirmModalConfig({
            open: true,
            submit: async()=>{
                await settings.unsubscribePlan();
                await fetchProfileData();
            },
            title: 'Confirm Cancel Subscribtion',
        });
    }

    useEffect(() => {
        fetchInvoices();
    },[])

    return (
        <>
            <div className="settings-page-container">
                <h3 className="settings-page-main-title">Plans & Billing History</h3>
                <div className="d-flex align-items-center justify-between w-full mb-[32px] flex-wrap">
                    <p className="settings-page-subtitle" style={{ marginBottom: "0px" }}>
                        Explore your subscription details and payment history
                    </p>
                    <div className="d-flex align-items-center flex-wrap">
                        { currentPlan?.next_invoice ?
                        <a
                            href="#" className="btn btn--outline-default btn--md rounded-[8px] m-[4px] py-[8px]"
                            onClick={(e)=>{
                                e.preventDefault();
                                unsubscribe();
                            }}
                        >
                            Cancel Subscribtion
                        </a> : null }
                        <Link to="/settings/upgrade-your-plan" className="btn btn--primary btn--md rounded-[8px] m-[4px] mr-[0px] py-[8px]">
                            Upgrade Plan
                        </Link>
                    </div>
                    
                </div>

                {
                    currentPlan ?
                    <div className="payment-item-card payment-current-plan mb-[32px]">
                        <div className="left-side-content">
                            <div>
                                <h3 className="item-card-number" style={{ fontSize: "20px", fontWeight: "700" }}>{ currentPlan.plan.title }</h3>
                                <p className="item-card-expires">{ currentPlan.plan.description }</p>
                            </div>
                        </div>
                        <div>
                            <h3 className="item-card-number d-flex align-items-center"><span style={{ fontSize: "20px", fontWeight: "700" }}>USD ${ currentPlan.plan.price_m }</span> <span className="item-card-expires mx-[4px]" style={{ fontSize: "20px" }}>/</span> <span className="item-card-expires">per month</span></h3>
                            { currentPlan.next_invoice ?
                                <p className="item-card-expires text-right">Next renew: { currentPlan.next_invoice }</p>
                            : null }
                        </div>
                    </div> : null
                }
                
                <div className="flex items-center justify-between flex-wrap mb-[16px]">
                    <h5 className="font-[600] mr-[10px]">Billing History</h5>
                    <div>
                        <a
                            href="#" className="btn btn--primary rounded-[8px] py-[6px] px-[10px]"
                            onClick={(e)=>{
                                e.preventDefault();
                                downloadAllInvoices();
                            }}
                        >
                            <span className="mr-[8px]">
                                { downloadLoading ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#ffffff" />
                                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#ffffff" className="spinner_ajPY"/>
                                </svg> :
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.9987 14.1667V2.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.6654 17.4999H3.33203" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.1662 10L9.9987 14.1675L5.83203 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> }
                            </span>
                            Download All
                        </a>
                    </div>        
                </div>
                <div className="card-body">
                    { invoicesData?.invoices ? <div className="table-wrapper">
                        <table className="table" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th className="text-left cursor-pointer "><div className="d-flex items-center">Invoice</div></th>
                                    <th className="text-left cursor-pointer "><div className="d-flex items-center">Amount</div></th>
                                    <th className="text-left  "><div className="d-flex items-center">Date</div></th>
                                    <th className="text-left  "><div className="d-flex items-center">Status</div></th>
                                    <th className="text-left  "><div className="d-flex items-center">Actions</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoicesData?.invoices.map((item, index)=>{
                                        return <tr key={index}>
                                            <td>{ item?.plan?.nickname || '-' }</td>
                                            <td><div className="color-primary">USD ${ item.total }</div></td>
                                            <td>{ item.date }</td>
                                            <td><span className={`pill ${item.status === "paid" ? "pill-success" : "pill-gray"}`}><span className="pill-status"></span>{ item.status }</span></td>
                                            <td>
                                                <a href={ item.link } download="true" className="d-flex justify-center">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g opacity="0.6">
                                                            <path d="M11.0013 15.5833V2.75" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M18.3346 19.2501H3.66797" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M15.5816 11L10.9974 15.5842L6.41406 11" stroke="#121327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-between pt-[10px] flex-wrap">
                            {/* <p className="gray-sm-text m-[4px] ml-[0px]">Page 1 of 10</p> */}
                            <p className="gray-sm-text m-[4px] ml-[0px]"></p>
                            <div className="d-flex align-items-center">
                                <a
                                    href="#" className={`btn btn--outline-default btn--md rounded-[8px] mr-[8px] ${page === 0 || paginationLoader === 'prev' ? 'disabled' : ''}`}
                                    onClick={async(e)=> {
                                        e.preventDefault();
                                        await changePagination(invoicesData?.invoices[0].id, '');
                                        setHasMoreBeforePrevious(true);
                                    }}
                                >
                                    <span className="mr-[8px]">
                                        {
                                            paginationLoader === 'prev' ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="currentColor" />
                                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="currentColor" className="spinner_ajPY"/>
                                            </svg> :
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.8">
                                                    <path d="M4.16536 10.0006L15.832 10.0006" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M8.33203 5.83382L4.16537 10.0005" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M8.33203 14.1672L4.16537 10.0006" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                            </svg>
                                        }
                                    </span>
                                    Previous
                                </a>
                                <a
                                    href="#" className={`btn btn--outline-default btn--md rounded-[8px] ${(invoicesData?.has_more === false && !hasMoreBeforePrevious) || lastPageNumber === page || paginationLoader === 'next' ? 'disabled' : ''}`}
                                    onClick={async(e)=> {
                                        e.preventDefault();
                                        await changePagination('', invoicesData?.invoices[invoicesData?.invoices.length-1].id);
                                    }}
                                >
                                    Next
                                    <span className="ml-[8px]">
                                        {
                                            paginationLoader === 'next' ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="currentColor" />
                                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="currentColor" className="spinner_ajPY"/>
                                            </svg> :
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.8">
                                                    <path d="M15.8346 10.0004H4.16797" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11.668 14.1672L15.8346 10.0005" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11.668 5.83374L15.8346 10.0004" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                            </svg>
                                        }
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div> : null }
                </div>
            </div>

            <ConfirmModal
                close={()=>{
                    setConfirmModalConfig({
                        open: false,
                        submit: false,
                        title: false,
                    })
                }}
                open={ confirmModalConfig.open }
                submit={ confirmModalConfig.submit }
                title={ confirmModalConfig.title }
            />
        </>
    )
}
