import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getIsAdmin, getCurrentCRUD } from "../redux/slices/authSlice";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";

export const ReadClose = (name, navigateActive = false, option = 'read') => {
    const navigate = useNavigate();

    const currentCRUD = useSelector(getCurrentCRUD);
    const isAdmin = useSelector(getIsAdmin);

    const checkArray = () => {
        let check = false;
        name.forEach(element => {
            if(!!currentCRUD[element]?.[option]){
                check = true; 
            }
        });

        return check;
    }

    useEffect(()=>{
        // rrf
        // tasks
        // report
        // meetings
        // approve-rrf
        // support-plan
        // situational-awareness


        // read
        // create
        // delete
        // upload
        // approve
        
        if(!isAdmin && (Array.isArray(name) ? !checkArray() : !(!!currentCRUD[name]?.[option])  )){
            if(navigateActive){
                navigate('/');
                NotificationManager.error("You do not have access to this section");
            }
        }
    },[])

    return (isAdmin ? true : ( Array.isArray(name) ? checkArray() : !!currentCRUD[name]?.[option] ));
}