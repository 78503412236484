import React, { useEffect, useState } from 'react'

import IconDeleteDisabled from "../../assets/img/delete-disabled.svg"
import IconMaximize from "../../assets/img/maximize.svg"
import IconErrorWhite from "../../assets/img/error-white.svg"

const FullHeightModal = ({ children, title, headerIcon, open, close, submitBtnText, submitMethod, hideFooter, widthClassName, cancelBtn = null}) => {
    const [modalCollapsed, setModalCollapsed] = useState(false);
    return (<>
        { !modalCollapsed ?
            <div className={`modal modal-default modal-document ${open ? 'modal--show' : ''}`}>
                <div className={`modal__dialog ${widthClassName ? widthClassName : 'modal__dialog--600'}`}>
                    <div className="modal__content">
                        <div className="modal__header border-bottom">
                            <h2 className="modal__title">
                                <div className="ico-24 mr-[9px]">
                                    <img src={ headerIcon } alt="" />
                                </div>
                                { title }
                            </h2>
                            <div className="modal__header-right">
                                <a href="#" className="btn btn--outline-secondary-3 p-[5px] rounded-[7px] mr-[8px]" data-collapse-modal="" onClick={(e)=>{
                                    e.preventDefault();
                                    setModalCollapsed(true)
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.66797 8.33325L10.0013 11.6666L13.3346 8.33325" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </a>
                                <a href="#" className="btn btn-close" data-close-modal="" onClick={(e)=>{
                                    e.preventDefault();
                                    close()
                                }}>
                                    <img src={ IconDeleteDisabled } alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="modal__body">
                            { children }
                        </div>
                        {!hideFooter ? <div className="modal__footer justify-end">
                            {
                                cancelBtn ? cancelBtn :
                                <a 
                                    href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] mr-[8px]"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        close()
                                    }}
                                >Cancel</a>
                            }
                            
                            { submitMethod && submitBtnText ? <button
                                href="#" className="btn btn--primary btn--lg-3 rounded-[8px]"
                                id='submit-full-modal'
                                onClick={(e)=>{
                                    e.preventDefault();
                                    submitMethod()
                                }}
                            >
                                { submitBtnText ? submitBtnText : '' }
                            </button> : null }
                        </div> : null}
                    </div>
                </div>
            </div> :
            open ? <div className="modal-tabs" style={{ zIndex: '9' }}>
                <div className="modal-collapsed">
                    <div className="modal-collapsed__body">
                        <span className="modal-collapsed__title">{title}</span>
                        <div className="modal-collapsed__actions">
                            <a href="#" className="btn" onClick={(e)=>{
                                e.preventDefault();
                                setModalCollapsed(false);
                            }}>
                                <img src={IconMaximize} />
                            </a>
                            <a href="#" className="btn" onClick={(e)=>{
                                e.preventDefault();
                                close();
                                setTimeout(() => {
                                    setModalCollapsed(false); 
                                }, 300);
                            }}>
                                <img src={IconErrorWhite} />
                            </a>
                        </div>
                    </div>
                </div>
            </div> : null
        }
    </>)
}

export default FullHeightModal;