import React from 'react';

const InfoModal = ({ open, close, icon, title, subtitle, }) => {
    return (
        <div className={`modal modal-1 ${open ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__body items-center">
                        { icon ? <div className="psma-information-img mb-[16px]">
                            <img src={icon} />
                        </div> : null }
                        { title ? <h2 className="font-[700] mb-[14px] text-center">{title}</h2> : null }
                        { subtitle ? <p className="text-[14px] leading-[22px] text-center opacity-60">{subtitle}</p> : null }
                    </div>
                    <div className="modal__footer">
                        <a 
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px] mr-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                close();
                            }}
                        >OK</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoModal;