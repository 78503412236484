import React from 'react'

const ConfirmDeleteModal = ({ open, close, submit }) => {

    return (
        <div className={`modal modal-1 ${open ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__body items-center">
                        <div className="psma-information-img mb-[16px]">
                            <img src={require('../../assets/img/trash-can-dynamic-color.png')} />
                        </div>
                        <h2 className="font-[700] mb-[14px]">Delete</h2>
                        <p className="text-[14px] leading-[22px] text-center max-w-[210px] opacity-60">Are you sure you want to delete this item</p>
                    </div>
                    <div className="modal__footer">
                        <a
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px] mr-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                submit();
                                close();
                            }}
                        >Yes</a>
                        <a 
                            href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                close();
                            }}
                        >No</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDeleteModal;