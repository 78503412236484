import React, { useEffect, useState } from 'react'
// import LogoPng from '../../assets/img/Logo.png'
import { SideBarNavigation } from './SideBarNavigation'
import { SelectBox } from '../ui/SelectBox'
import { Icon } from '../ui/Icon';
import { IncidentLaunchModal } from '../Modals/IncidentLaunchModal';

import { useDispatch, useSelector } from "react-redux";
import { 
    getIncidents,
    getSelectedIncidentId,
    setSelectedIncidentId,
    setSelectedIncident,
} from "../../redux/slices/incidentsSlice";
// import { incidentsFetch } from "../redux/thunks/incidentsThunks";

import { adminFetchPositions } from "../../redux/thunks/admin/userManagementThunks";
import { getPositions } from "../../redux/slices/admin/userManagementSlice";

import { getIsAdmin, getSelectedPosition, setCurrentCRUD, setSelectedPosition, setParentsPositions, } from "../../redux/slices/authSlice";

export const SideBar = () => {
    const dispatch = useDispatch();
    const incidents = useSelector(getIncidents);
    const selectedIncidentId = useSelector(getSelectedIncidentId);
    const positions = useSelector(getPositions);
    const isAdmin = useSelector(getIsAdmin);
    const selectedPosition = useSelector(getSelectedPosition);

    const dropdownRef = React.useRef(null)

    const [incidentModal, setIncidentModal] = useState(false);

    const [search, setSearch] = useState('');

    const sidebarToggle = (close = null) => {
        if(close === null) {
            document.querySelector('body').classList.toggle('show-sidebar');
        } else if(close){
            document.querySelector('body').classList.remove('show-sidebar');
        }
    }

    const findIncidentBySubIncId = (subId) => {
        dispatch(setSelectedIncidentId(subId));
        let findedIncident = incidents.checked_incident.find(item => item.sub_incidents.map(itemM => itemM.id).includes(subId));
        dispatch(setSelectedIncident({
            ...findedIncident,
            selected_sub_incident: findedIncident.sub_incidents.find(item => item.id === subId)
        }));
    }

    const searchIncidents = incidents?.checked_incident.filter(item => 
        item.sub_incidents?.length &&
        (
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.incident_comment.toLowerCase().includes(search.toLowerCase()) ||
            item.sub_incidents.map(subItem => subItem.inc_number.toLowerCase()).flat().find(itemF => itemF.includes(search.toLowerCase()))
        )
    );

    const outsideHandler = (e) => {
        if (!dropdownRef.current?.contains(e.target) && e.target !== dropdownRef.current) {
            setSearch('');
        }
    };

    const selectFromSearch = async(incident) => {
        const lastSub = incident.sub_incidents[incident.sub_incidents.length - 1];

        if(lastSub){
            await dispatch(setSelectedIncidentId(lastSub.id));
            await dispatch(setSelectedIncident({...incident, selected_sub_incident: lastSub}))
        }

        setSearch('');
    }

    const positionsOptions = 
        // isAdmin ?
        // (positions ? [
        //     ...positions.map(item => {
        //         let name = item.name.split("_").join(" ");
        //         return {
        //             label: name.charAt(0).toUpperCase() + name.slice(1),
        //             value: item.id
        //         }
        //     })
        // ] : []) : 
        (incidents?.auth_user?.positions ? [
            ...incidents?.auth_user?.positions.map(item => {
                return {
                    label: item.role.name_formatted,
                    value: item.position_id,
                }
            })
        ] : [])
    
    const selectPosition = (value) => {
        const allCRUDnames = [ "rrf", "tasks", "meetings", "support-plan", "situational-awareness" ];
        
        let allAllowCRUD = {};

        allCRUDnames.forEach(name => {
            allAllowCRUD[name] = {
                "read": 1,
                "create": 1,
                "delete": 1,
                "update": 1,
                "approve": 1,
            }
        })

        const findCRUDOptions = incidents?.auth_user?.positions.find(item => +item.position_id === +value)?.role.permissions;

        const parentsPositions = incidents?.auth_user?.positions.find(item => +item.position_id === +value)?.role?.parents;
        dispatch(setParentsPositions(parentsPositions));

        dispatch(setSelectedPosition(value));

        window.selectedPositionWindow = value;
        
        // if(isAdmin){
        //     dispatch(setCurrentCRUD(allAllowCRUD));
        // } else {
            dispatch(setCurrentCRUD(findCRUDOptions));
        // }
    }

    useEffect(() => {
        if(!selectedPosition && positionsOptions?.length){
            selectPosition(positionsOptions[0].value);
        }
    }, [positionsOptions]);

    useEffect(() => {
        if (!incidents) return

        let incId = null
        let inc = null

        const set = () => {
            dispatch(setSelectedIncidentId(incId))
            dispatch(setSelectedIncident(inc))
        }

        if (incidents.launched_incidents?.length && incidents.launched_incidents[0].sub_incidents?.length) {
            const lastSub = incidents.launched_incidents[0].sub_incidents[incidents.launched_incidents[0].sub_incidents.length-1]
            inc = {...incidents.launched_incidents[0], selected_sub_incident: lastSub}
            incId = lastSub.id
            set()
            return;
        }

        for (let i = 0; i < incidents.checked_incident.length; i++) {
            const element = incidents.checked_incident[i]
            if (element.sub_incidents.length) {
                const lastSub = element.sub_incidents[element.sub_incidents.length - 1]
                inc = {...element, selected_sub_incident: lastSub}
                incId = lastSub.id
                break
            }
        }
        set()
    }, [incidents]);

    useEffect(() => {
        if(!positions){
            dispatch(adminFetchPositions());
        }
    }, []);

    useEffect(() => {
        if (search.length) {
            document.addEventListener('click', outsideHandler)
        }

        return () => document.removeEventListener('click', outsideHandler)
    }, [search])

    return (
        <aside className="sidebar-nav">
            <button
                type="button"
                className="btn sidebar-btn toggler-sidebar"
                onClick={(e)=>{
                    e.preventDefault();
                    sidebarToggle();
                }}
            >
                <span className="ico ico-20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path></svg>
                </span>
            </button>
            <div className="sidebar-container">
                <a href="" className="sidebar-logo">
                    <Icon src="img/Logo.png" alt="Logo" />
                </a>
                <a onClick={(e) => {
                    e.preventDefault()
                    setIncidentModal(true);
                    sidebarToggle(true);
                }} href="" className="btn btn--primary btn--lg radius-5 w-full">
                    <span className="flex">
                        <Icon src="img/play.svg" />
                    </span>
                    <span className="px-[8px]">Launch Incident</span>
                </a>
                <hr className="sidebar-hr my-[16px]" />
                <div className="form-group mb-[16px]" key={'incident-id-'+selectedIncidentId}>
                    <label className="form-group-title">Incident</label>
                    <SelectBox
                        menuPosition="absolute"
                        propsOptions={
                            incidents?.checked_incident.map((item)=>{
                                return {
                                    label: item.name,
                                    value: item.id,
                                    options: item.sub_incidents.map((option)=>{
                                        return {
                                            label: option.inc_number,
                                            value: option.id
                                        }
                                    })
                                }
                            })
                        }
                        value={selectedIncidentId}
                        change={(value)=>{
                            findIncidentBySubIncId(value);
                        }}
                    />
                </div>
                
                <div className="form-group">
                    <label className="form-group-title">Position</label>
                    <SelectBox
                        key={ 'position-'+selectedPosition }
                        menuPosition="absolute"
                        propsOptions={ positionsOptions }
                        value={selectedPosition}
                        change={(value) => {
                            selectPosition(value);
                        }}
                    />
                </div>
                <hr className="sidebar-hr my-[16px]" />
                <div className="form-search h-[32px] mb-[14px]" style={{ padding: '0' }} ref={dropdownRef}>
                    <button type="submit" className="form-search__btn" style={{ pointerEvents: 'none' }}>
                        <Icon src="img/search-loupe.svg" />
                    </button>
                    <input
                        type="search" className="form-search__input" placeholder="Search..."
                        style={{ padding: "6px 30px 6px 34px", borderRadius: "8px" }}
                        value={search} onInput={(e)=>{ setSearch(e.target.value); }}
                        autoComplete="false"
                    />
                    { search.length ? 
                    <a href="/#" className="form-search-clear" onClick={(e)=>{
                        e.preventDefault(); setSearch("");
                    }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.66797 6.66663L13.3346 13.3333" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.3346 6.66663L6.66797 13.3333" stroke="#121327" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a> : null }

                    { search?.length ? 
                    <ul className="search-dropdown">
                        { searchIncidents?.length ? searchIncidents.map((item, itemIndex) => {
                            return <li key={itemIndex}>
                                <a href="#" onClick={(e)=>{
                                    e.preventDefault();
                                    selectFromSearch(item);
                                }}>
                                    <h3>{ item.name }</h3>
                                    <span>{ item.incident_comment }</span>
                                </a>
                            </li> }) : <li className="py-[5px] px-[10px]">
                                <h3>Not found</h3>
                            </li>
                        }
                    </ul> : null }
                </div>
                <SideBarNavigation key={ selectedPosition } />
            </div>

            { incidentModal ? <IncidentLaunchModal
                open={incidentModal}
                close={() => setIncidentModal(false)}
            /> : null }
        </aside>
    )
}
