import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminIncidents, resourceRequest } from '../../api/index';

export const fetchRequests = createAsyncThunk(
    '/requests',
    async (id, { rejectWithValue }) => {
        try {
            const res = await resourceRequest.getRequests(id);

            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchRequestOptions = createAsyncThunk(
    '/get-request-options',
    async (_, { rejectWithValue }) => {
        try {
            const resStatuses = await resourceRequest.getRequestStatuses();
            const resPriorities = await resourceRequest.getRequestPriorities();
            const resKinds = await resourceRequest.getResourceKinds();
            const states = await resourceRequest.getStates();
            const agencies = await resourceRequest.getAgencies();
            const types = await adminIncidents.getAdminIncidentsType()

            return {
                statuses: resStatuses.data?.data?.statuses,
                priorities: resPriorities.data?.data?.statuses,
                kinds: resKinds.data?.data,
                states: states.data?.data,
                agencies: agencies.data?.data,
                types: types.data?.data?.incidentTypes
            };
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchRequestsChart = createAsyncThunk(
    '/requests-stats',
    async (id, { rejectWithValue }) => {
        try {
            const res = await resourceRequest.getRequestsChart(id);

            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);