import React from 'react'
import { SaveChangesModal } from '../../components/Modals/SaveChangesModal'
// import { UpdatedModal } from '../../components/Modals/UpdatedModal'

export const SituationAndSupportHeader = ({title, step, stepCallback, canEdit, save}) => {

    const [modal, setModal] = React.useState(false)

    const changeStep = (num) => {
        stepCallback && stepCallback(num)
    }


    return (
        <>
            <div className="bg-gray w-full flex justify-between flex-wrap items-center px-5 pt-4 pb-2">
                <a href="" className="page-tabs-link mb-2">
                    <span className="page-tabs-link--ico">
                        {/* <Icon src="img/exclamation-mark.png" alt="" /> */}
                    </span>
                    <span className="px-[8px] text-elipsis page-tabs-link--text">
                        <h5 className="font-[600]">{title}</h5>
                    </span>
                </a>
                <div className="btns-group" style={{minHeight: '46.72px'}}>
                    {step === 2 && <a href="#" className="btn btn--shadow-primary btn--lg rounded-[10px] mb-2 d-none" style={{ padding: "6px 10px" }}>
                        <span className="ico mr-[8px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.3433 6.0127C18.211 8.97259 17.78 12.8313 15.3052 15.3062C12.8304 17.781 8.97161 18.212 6.01172 16.3443" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M11.668 2.69226C12.4905 2.87963 13.2752 3.20542 13.9886 3.6557" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M2.69141 11.6667C2.87878 12.4892 3.20456 13.2739 3.65485 13.9873" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.33233 2.69226C7.5098 2.87963 6.72509 3.20542 6.01172 3.6557" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M3.65485 6.0127C3.20456 6.72607 2.87878 7.51078 2.69141 8.3333" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M9.9987 7.5V12.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M7.91797 9.58333L10.0013 7.5L12.0846 9.58333" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        Upload Information
                    </a>}
                    {
                        canEdit ? (
                        step === 1 ? <a onClick={() => changeStep(2)} href="#" className="btn btn--primary btn--lg rounded-[10px] mb-2" style={{ padding: "6px 25px" }}>Edit</a> 
                        : <a onClick={() => setModal('save')} href="#" className="btn btn--primary btn--lg rounded-[10px] mb-2" style={{ padding: "6px 25px" }}>Save</a> )
                        : null
                    }
                    
                    
                </div>
            </div>


            {modal === 'save' && <SaveChangesModal onClose={() => setModal(false)} onConfirm={() => { setModal(false); save(); }} />}

            {/* {modal === 'update' && <UpdatedModal onConfirm={() => {setModal(false); save()}} />} */}
        </>
    )
}
