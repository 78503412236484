import React, { useEffect, useState } from 'react'
import {Link, useLocation, useParams, useNavigate} from 'react-router-dom';

import { SelectBox } from "../../components/ui/SelectBox";
import PsmaInformationModal from "../../components/Modals/PsmaInformationModal"
import InitialResourceRequestInfoModal from "../../components/Modals/InitialResourceRequestInfoModal"
import { adminIncidents, api, executionChecklist, userManagement} from "../../api";
import CreateRRFModal from "../../components/Modals/CreateRRFModal";
import { resourceRequest } from '../../api/index';
import { ReadClose } from "../../helpers/checkPermission";
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from '../../redux/slices/admin/userManagementSlice';
import { adminFetchRegions } from '../../redux/thunks/admin/userManagementThunks';
import { NotificationManager } from 'react-notifications';

export const TaskPage = ({edit, route}) => {
    // const { data } = route.params;
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const checkRead = edit ? ReadClose('tasks', true, 'update') : ReadClose('tasks', true);

    const { id } = useParams()
    const [taskData, setTaskData] = useState(null)
    const [lifelineOptions, setLifelineOptions] = useState(null)
    const [agencyOptions, setAgencyOptions] = useState(null)
    const [statusesOptions, setStatusesOptions] = useState(null)
    const [currentStatus, setCurrentStatus] = useState('')
    const [taskHistory, setTaskHistory] = useState([])
    const [comment, setComment] = useState('');

    const regionsData = useSelector(getRegions)

    const [incidentTypes, setIncidentTypes] = useState([]);

    const [statesOpt, setStatesOpt] = useState(null);
    

    const regionsOptions = regionsData ? createSelectOptions(regionsData) : []

    const [createRRFOpen, setCreateRRFOpen] = useState(false);

    const [addHistoryErrors, setAddHistoryErrors] = useState({status: false, comment: false})

    const currentTask = taskData?.task || null

    const [taskValues, setTaskValues] = useState({
        description: '',
        lifeline: '',
        agency: '',
        esf: '',
        incident_type: null,
        region: null,
        state: null
    })

    function createSelectOptions(arr) {
        const options = []
        arr.forEach(el => options.push({value: el.id, label: el.name}))
        return options
    }

    const getTask = async () => {
        try {
            const response = await executionChecklist.getTask(id);
            const data = response.data.data;
            setTaskData(data)
            setTaskValues({
                description: data?.task?.description || '',
                lifeline: data?.task?.lifeline.id || '',
                agency: data?.task?.agency.id || '',
                esf: '',
                incident_type: data?.task?.incident_type_id || null,
                region: data?.task?.region_id || null,
                state: data?.task?.state_id || null,
            })
            setTaskHistory(data?.task?.histories || [])

            let lifelineOptionsArray = [];
            data?.lifelines.forEach(item=>{
                if(!lifelineOptionsArray.find(item1=>item1.value===item.id)){
                    lifelineOptionsArray.push(
                        {
                            label:item.name,
                            value:item.id,
                        }
                    )
                };
            })
            setLifelineOptions([{label: 'All lifeline',
                value: ''}, ...lifelineOptionsArray])

            let agencyOptionsArray = [];
            data?.agencies.forEach(item=>{
                if(!agencyOptionsArray.find(item1=>item1.value===item.id)){
                    agencyOptionsArray.push(
                        {
                            label:item.name,
                            value:item.id,
                        }
                    )
                };
            })
            setAgencyOptions([{label: 'All agency',
                value: '', placeholder: true}, ...agencyOptionsArray])

            let statusesOptionsArray = []
            Object.values(data.statuses).forEach(item => {
                statusesOptionsArray.push(
                    {
                        label:item,
                        value:item,
                    }
                )
            })
            setStatusesOptions(statusesOptionsArray)


        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    }

    const update = async () => {

        const payload = {...taskValues}
        delete payload.state
        delete payload.region
        delete payload.incident_type

        payload.lifeline_id = taskValues.lifeline
        payload.state_id = taskValues.state
        payload.region_id = taskValues.region
        payload.incident_type_id = taskValues.incident_type

        const res = await executionChecklist.updateTask(id, {...payload})

        if (res?.data?.success) {
            NotificationManager.success("Task updated successfully");
        } else {
            NotificationManager.error("Task updated fail");
        }

        console.log(res);
    }


    useEffect(() => {
        if(checkRead){
            getTask()
        }
    }, [id])

    useEffect(() => {
        if (!regionsData) {
            dispatch(adminFetchRegions())
        }
        const req = async () => {
            const types = await adminIncidents.getAdminIncidentsType()
            setIncidentTypes(createSelectOptions(types.data?.data?.incidentTypes))
        }
        req()
    }, [])

    useEffect(() => {
        if (!taskValues.region) return

        setStatesOpt(null);
        // if (!firstLoad) {
        //     setTaskValues(prev => ({...prev, state: null}))
        // }

        const req = async () => {
            try {
                const res = await userManagement.getStates(taskValues.region)

                setStatesOpt(createSelectOptions(res.data.data))
            } catch (err) {

            }
        }

        req()
        
    }, [taskValues.region]);


    const addTaskComment = async (e) => {
        e.preventDefault()
        const id = taskData?.task.id;

        if (!currentStatus || !comment) {
            setAddHistoryErrors({status: Boolean(!currentStatus), comment: Boolean(!comment)})
            return
        }

        if(taskHistory.map(item => item.comment.toLowerCase()).includes(comment.toLowerCase())){
            setAddHistoryErrors({status: Boolean(!currentStatus), comment: true})
            return;
        }
        
        try {
            // const res = await api.put(`/task/${id}/add-history`, {
            //     "new_status": currentStatus,
            //     "comment": comment
            // })
            const res = await executionChecklist.taskAddHistory(id, {
                "new_status": currentStatus,
                "comment": comment
            })
            
            setTaskHistory(prev => [...prev, res.data.data.history]);

            setComment('');
            setCurrentStatus('');
        } catch (err) {
            console.warn('create task history error')
        }
    }

    const circlesColors = [
        { backgroundColor: '#FFB8B5', color: '#93211C' },
        { backgroundColor: '#DCD1FB', color: '#442895' },
        { backgroundColor: '#C6EBFC', color: '#0B5273' },
        { backgroundColor: '#C4F0CD', color: '#0D7021' }
    ];

    const getNextColor = (currentIndex) => {
        return circlesColors[currentIndex % circlesColors.length];
    };

    const [psmaInformationModalOpen, setPsmaInformationModalOpen] = useState(false);
    const [InitialResourceRequestInfoModalOpen, setInitialResourceRequestInfoModalOpen] = useState(false);

    // const getRandomInt = (min, max) => {
    //     min = Math.ceil(min);
    //     max = Math.floor(max);
    //     return Math.floor(Math.random() * (max - min + 1)) + min;
    // }

    const psmaSubmit = async() => {
        await resourceRequest.updateRequestTaskId(taskData?.task?.incident?.id, psmaInformationModalOpen?.id, {
            task_id: taskData?.task?.id
        });

        await getTask()

        await setPsmaInformationModalOpen(false);
    }

    const createdRRF = (value) => {
        setCreateRRFOpen(false);
        setPsmaInformationModalOpen(value?.data?.data?.request);
    }

    // TODO add loading
    if (!taskData) {
        return <div style={{textAlign: 'center'}}>Loading</div>
    }

    return (
        <div className="dash-page-content-item flex-auto">
            <div className="row guttres-20 flex-auto row--fixed">
                <div className="col-12 flex flex-col row--fixed-content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-black">
                                <div className="card-header flex flex-wrap justify-between items-center pt-[12px] px-[16px]">
                                    <div className="card-header--item mb-[12px]">
                                        <h4 className="heading-4 font-[400] text-white mr-[10px]">
                                            <span className="font-[600]">Task ID: &nbsp;</span>
                                            <span className="text-[#95AACB]">{ currentTask.id }</span>
                                        </h4>
                                    </div>
                                    <div className="card-header--item">
                                        <ul className="def-list def-list--24">
                                            <li className="def-list--item flex items-center mb-[12px]">
                                                <span className="text-[#95AACB] font-[500] def-text-1">
                                                    Priority: &nbsp;&nbsp;
                                                </span>
                                                <span className="pill pill-yellow pill-yellow--text font-[500]">
                                                    <span className="ico ico-11 mr-[6px]">
                                                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.15359 2.3C5.30755 2.03333 5.69245 2.03333 5.84641 2.3L9.74353 9.05C9.89748 9.31667 9.70504 9.65 9.39711 9.65H1.60289C1.29497 9.65 1.10252 9.31667 1.25648 9.05L5.15359 2.3Z" stroke="currentColor" strokeWidth="1.2"></path>
                                                        </svg>
                                                    </span>
                                                    { currentTask.priority }
                                                </span>
                                            </li>
                                            <li className="def-list--item flex items-center mb-[12px]">
                                                <span className="text-[#95AACB] font-[500] def-text-1">
                                                    Status: &nbsp;&nbsp;
                                                </span>
                                                <span className="pill pill-primary pill-primary--text font-[500]">
                                                    <span className="pill-status"></span>
                                                    { currentTask.status }
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <form method="post" className="form">
                                <div className={`form-body ${edit ? '' : 'pointer-events-none' }`}>
                                    <div className="row gutters-x-16">
                                        <div className="col-12 mt-[20px]">
                                            <div className="flex flex-col">
                                                <label className="input-custom__label text-07 font-[600] mb-[10px]">
                                                    Description
                                                </label>
                                                <textarea className="input-custom__textarea" rows="4" placeholder="Enter description..."
                                                    value={taskValues.description}
                                                    onInput={(e) => setTaskValues((prev) => ({...prev, description: e.target.value}))}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-sm-12 col-12 mt-[20px]">
                                            <div className="select-wrap select-border h-36px">
                                                <label className="input-custom__label font-[600] mb-[10px] text-07">
                                                    Select lifeline
                                                </label>
                                                <SelectBox 
                                                    className="default-select"
                                                    propsOptions={lifelineOptions}
                                                    value={taskValues.lifeline}
                                                    change={(val) => setTaskValues(prev => ({...prev, lifeline: val}))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-sm-12 col-12 mt-[20px]">
                                            <div className="select-wrap select-border h-36px">
                                                <label className="input-custom__label font-[600] mb-[10px] text-07">
                                                    Agency
                                                </label>
                                                <SelectBox 
                                                    className="default-select"
                                                    propsOptions={agencyOptions}
                                                    value={taskValues.agency}
                                                    change={(val) => setTaskValues(prev => ({...prev, agency: val}))}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-sm-12 col-12 mt-[20px]">
                                            <div className="select-wrap select-border h-36px">
                                                <label className="input-custom__label font-[600] mb-[10px] text-07">
                                                    Incident type
                                                </label>
                                                <SelectBox key={incidentTypes + taskValues.incident_type}
                                                    className="default-select"
                                                    propsOptions={incidentTypes}
                                                    value={taskValues.incident_type}
                                                    change={(val) => setTaskValues(prev => ({...prev, incident_type: val}))}
                                                />
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="col-xl-4 col-lg-6 col-sm-12 col-12 mt-[20px]">
                                            <div className="select-wrap select-border h-36px">
                                                <label className="input-custom__label font-[600] mb-[10px] text-07">
                                                    Region
                                                </label>
                                                <SelectBox key={regionsOptions + taskValues.region}
                                                    className="default-select"
                                                    propsOptions={regionsOptions}
                                                    value={taskValues.region}
                                                    change={(val) => setTaskValues(prev => ({...prev, region: val, state: null}))}
                                                />
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="col-xl-4 col-lg-6 col-sm-12 col-12 mt-[20px]">
                                            <div className="select-wrap select-border h-36px">
                                                <label className="input-custom__label font-[600] mb-[10px] text-07">
                                                    State
                                                </label>
                                                {statesOpt ? <SelectBox key={statesOpt + taskValues.region + taskValues.state}
                                                    className="default-select"
                                                    propsOptions={statesOpt}
                                                    value={taskValues.state}
                                                    change={(val) => setTaskValues(prev => ({...prev, state: val}))}
                                                /> : <div className="default-select d-flex justify-center pt-[8px]" style={{height: '36px', border: '1px solid #EDEDED', borderRadius: '8px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                                                </svg>
                                            </div>}
                                            </div>
                                        </div>


                                        {false && <div className="col-xl-4 col-lg-6 col-sm-12 col-12 mt-[20px]">
                                            <div className="select-wrap select-border h-36px">
                                                <label className="input-custom__label font-[600] mb-[10px] text-07">
                                                    ESF
                                                </label>
                                                <SelectBox 
                                                    className="default-select"
                                                    propsOptions={[
                                                        {label: 'Select ESF', value: '1'},
                                                        {label: 'Select ESF 2', value: '2'},
                                                        {label: 'Select ESF 3', value: '3'},
                                                    ]}
                                                    // value={headerSelects.agency}
                                                />
                                            </div>
                                        </div> }
                                        <div className="col-12 mt-[20px]">
                                            <div className="card card-secondary card-border">
                                                <div className="card-header px-[16px] pt-[16px] 
                                                flex flex-wrap justify-between">
                                                    <div className="card-header--item mb-[16px]">
                                                        <h3 className="def-text-2 font-[600]">
                                                            Resource Request Information
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="card-body mt-[6px] px-[16px] pb-[16px]">
                                                    <ul className="card-list">
                                                        <li className="card-list--item justify-between">
                                                            <div className="max-w-max flex items-center justify-between relative pr-[50px] min-w-[210px]">
                                                                <span className="def-text-3 text-08 font-[600] mr-[55px] w-full">
                                                                    RRF Created
                                                                </span>
                                                                <label className="switch" style={{ position: 'absolute', right: '0', top: '50%', transform:'translateY(-50%)' }}>
                                                                    <input type="checkbox" className="switch-input" checked={!!taskData?.task?.request}  />
                                                                    <div className="switch-el">
                                                                        <span className="switch-circle"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li className="card-list--item justify-between">
                                                            <div className="max-w-max flex items-center justify-between min-w-[210px]">
                                                                <span className="def-text-3 text-08 font-[600] mr-[55px] min-w-[78px]">
                                                                    RRF Status
                                                                </span>
                                                                <span className="def-text-3 text-06-2">
                                                                    { taskData?.task?.request ? taskData?.task?.request.status.split('_').join(' ') : 'None created'}
                                                                </span>
                                                            </div>
                                                        </li>
                                                        <li className="card-list--item justify-between">
                                                            <div className="max-w-max flex items-center justify-between min-w-[210px]">
                                                                <span className="def-text-3 text-08 font-[600] mr-[55px] min-w-[78px]">
                                                                    RRF Number
                                                                </span>
                                                                <span className="def-text-3 text-06-2">
                                                                    { taskData?.task?.request ? taskData?.task?.request.rrf : 'None created'}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                { edit ? 
                                                <div className="card-footer mt-[6px] px-[16px] pb-[16px]">
                                                    <div className="dbl-btns mb-[-8px]">
                                                        <button
                                                            className="btn btn--primary whitespace-nowrap h-[32px] px-[10px] rounded-[8px] mb-[8px]"
                                                            onClick={(e)=>{
                                                                e.preventDefault();
                                                                taskData?.task?.request ? navigate(`/resource-request/${taskData?.task?.incident?.id}/${taskData?.task?.request.id}?task=${taskData?.task?.id}`) :
                                                                setCreateRRFOpen(true);
                                                            }}
                                                        >
                                                            <span className="font-[500]">
                                                                Create / View RRF
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div> : null }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer mt-[20px]">
                                    <div className="dbl-btns mb-[-8px] justify-end">
                                        <Link to="/execution-checklist" className="btn btn--outline-secondary-3 whitespace-nowrap h-[32px] px-[10px] rounded-[8px] mb-[8px]">
                                            <span className="font-[500]">
                                                Cancel
                                            </span>
                                        </Link>
                                        { edit ? 
                                        <button
                                            type="submit" className="btn btn--primary whitespace-nowrap h-[32px] px-[10px] rounded-[8px] mb-[8px]"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                // setInitialResourceRequestInfoModalOpen(true);
                                                update()
                                            }}
                                        >
                                            <span className="font-[500]">
                                                Update Task
                                            </span>
                                        </button> : null }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row--fixed-sidebar flex flex-col">
                    <div className="card card-border flex-auto card-history">
                        <div className="card-header card-header-border flex flex-wrap justify-between p-[16px] pb-0">
                            <div className="card-header--item mb-[16px]">
                                <h5 className="font-semibold">Task History</h5>
                            </div>
                        </div>
                        <div className="card-body px-[16px] flex-auto flex flex-col" style={{ height: 'calc(100vh - 310px)' }}>
                            <ul className="list-comments flex-auto">
                                {
                                    taskHistory && taskHistory.length ? taskHistory.map((item, index)=>{
                                        return <li className="comment-item" key={index}>
                                            <div className="comment">
                                                <div className="comment-block line-comment">
                                                    <div className="user-photo user-photo--32" style={getNextColor(index)}>
                                                        <span>{ item?.user?.name.split('')[0] || 'err' }</span>
                                                    </div>
                                                </div>
                                                <div className="comment-block flex-auto pt-[5px]">
                                                    <div className="comment-head justify-between">
                                                        <span className="def-text-3 font-[700] mb-[8px] flex-1">
                                                            { item?.user?.full_name || 'err' } { item?.user?.email ? `(${item?.user?.email})` : '' }
                                                        </span>
                                                        <span className="pill pill-primary mb-[8px] font-[500]">
                                                            <span className="pill-status"></span>
                                                            { item.new_status }
                                                        </span>
                                                    </div>
                                                    <div className="comment-body mb-[8px]">
                                                        <p className="def-text-1 text-08">{ item.comment }</p>
                                                    </div>
                                                    <div className="comment-footer">
                                                        <span className="font-[500] text-04 def-text-4">
                                                            { item?.created_at.split('T')[0] || 'no created date in model' }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    }) : <p style={{textAlign: 'center', marginTop: '25px'}}>No history</p>
                                }
                            </ul>
                        </div>
                        { edit ?
                        <div className="card-footer p-[15px] pt-0" style={{ background: 'rgba(248, 248, 248, 0.5)' }}>
                            <form onSubmit={addTaskComment} className="form">

                                <label className="def-input mt-[5px]">
                                    <textarea className={`input px-[0px] py-[15px] ${addHistoryErrors.comment && 'textarea-error'}`} rows="1" type="text" placeholder="Type progress comment here..."
                                        value={comment}
                                        onChange={(e) => {
                                            setComment(e.target.value)
                                            setAddHistoryErrors(prev => ({...prev, comment: false}))
                                        }}
                                    ></textarea>
                                </label>

                                <div className="form-dbl mb-[-8px] items-center mt-[4px]">
                                    <div className="w-full mb-[8px]">
                                        <SelectBox
                                            key={currentStatus}
                                            className={addHistoryErrors.status && 'select-error'}
                                            propsOptions={statusesOptions}
                                            value={currentStatus}
                                            change={(val) => {
                                                setCurrentStatus(val)
                                                setAddHistoryErrors(prev => ({...prev, status: false}))
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <button type="submit" className="btn mb-[8px] btn--primary font-[500] 
                                        whitespace-nowrap h-[32px] px-[10px] rounded-[8px] justify-between">
                                            <span>Add Comment</span>
                                            <span className="ico ico-18 ml-[8px]">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.6096 9.14794L3.9606 10.0892C3.89566 10.1 3.83471 10.1278 3.78389 10.1696C3.73307 10.2115 3.69417 10.266 3.6711 10.3277L1.72335 15.5462C1.53735 16.0262 2.0391 16.4837 2.4996 16.2527L15.9996 9.50269C16.0929 9.45592 16.1714 9.38412 16.2262 9.29531C16.281 9.2065 16.3101 9.10419 16.3101 8.99982C16.3101 8.89544 16.281 8.79313 16.2262 8.70432C16.1714 8.61551 16.0929 8.54371 15.9996 8.49694L2.4996 1.74694C2.0391 1.51669 1.53735 1.97419 1.72335 2.45344L3.67185 7.67194C3.69492 7.73361 3.73382 7.78812 3.78464 7.82999C3.83546 7.87185 3.89641 7.8996 3.96135 7.91044L9.61035 8.85169C9.64559 8.85732 9.67767 8.87532 9.70083 8.90247C9.72399 8.92962 9.73671 8.96413 9.73671 8.99982C9.73671 9.0355 9.72399 9.07002 9.70083 9.09716C9.67767 9.12431 9.64559 9.14232 9.61035 9.14794H9.6096Z" fill="currentcolor"></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> : null }
                    </div>
                </div>
            </div>

            <PsmaInformationModal
                open={psmaInformationModalOpen}
                request={psmaInformationModalOpen}
                close={()=>{setPsmaInformationModalOpen(false);}}
                submit={psmaSubmit}
            />

            <InitialResourceRequestInfoModal
                open={InitialResourceRequestInfoModalOpen}
                close={()=>{setInitialResourceRequestInfoModalOpen(false);}}
            />

            { createRRFOpen ? 
            <CreateRRFModal
                taskId={taskData?.task?.id}
                created={createdRRF}
                close={()=>{ setCreateRRFOpen(false); getTask() }}
            /> : null }
        </div>
    )
}
