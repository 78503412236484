import { createSlice, } from '@reduxjs/toolkit'
import { 
    getProfile,
    getShowingTabs,
} from '../thunks/settingsThunks'

const initialState = {
    profile: null,
    tabs: null,
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setProfile: (state, action) => {
			state.profile = action.payload;
		},
    },
    extraReducers(builder) {
        builder.addCase(getProfile.fulfilled, (state, action) => {
            return {
                ...state,
                profile: action.payload.data,
            };
        });
        builder.addCase(getProfile.rejected, (state) => {
            return {
                ...state,
                profile: null
            };
        });

        builder.addCase(getShowingTabs.fulfilled, (state, action) => {
            return {
                ...state,
                tabs: action.payload,
            };
        });
        builder.addCase(getShowingTabs.rejected, (state) => {
            return {
                ...state,
                tabs: null
            };
        });
    }
})


export const { setProfile, } = settingsSlice.actions

export const getProfileData = (state) => state.settings.profile;
export const getShowingTabsData = (state) => state.settings.tabs;

export default settingsSlice.reducer