import React, { useEffect, useState } from 'react'
import IconDeleteDisabled from "../../assets/img/delete-disabled.svg"

const PsmaInformationModal = ({ open, close, submit, request }) => {

    return (
        <div className={`modal modal-1 ${open ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__header justify-end">
                        <a href="#" className="btn btn-close" onClick={(e)=>{
                            e.preventDefault();
                            close();
                        }}>
                            <img src={IconDeleteDisabled} alt="" />
                        </a>
                    </div>
                    <div className="modal__body items-center">
                        <div className="psma-information-img mb-[16px] mt-[-65px]">
                            <img src={require('../../assets/img/psma-information.png')} />
                        </div>
                        <h2 className="font-[700] mb-[14px] text-center">Resource Request { request.rrf } Information</h2>
                        <p className="text-[14px] leading-[22px] text-center opacity-60">Would you like to use the Resource Request { request.rrf } associated with this task</p>
                    </div>
                    <div className="modal__footer">
                        <a
                            href="#" className="btn btn--primary btn--lg-3 rounded-[8px] mr-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                submit();
                            }}
                        >Yes</a>
                        <a 
                            href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] w-full"
                            onClick={(e)=>{
                                e.preventDefault();
                                close();
                            }}
                        >No</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PsmaInformationModal;