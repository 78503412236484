import { createAsyncThunk } from '@reduxjs/toolkit';
import { settings } from '../../api/index';

export const getProfile = createAsyncThunk(
    '/get-profile',
    async (_, { rejectWithValue }) => {
        try {
            const res = await settings.getProfile();

            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updateProfile = createAsyncThunk(
    '/update-profile',
    async ({ id, payload }, { rejectWithValue }) => {
        try {
            const res = await settings.updateProfile(id, payload);

            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getShowingTabs = createAsyncThunk(
    '/showing-tabs',
    async (_, { rejectWithValue }) => {
        try {
            const res = await settings.getShowingTabs();

            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);