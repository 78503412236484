import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { 
    incidentsFetch, 
} from '../thunks/incidentsThunks'

const initialState = {
    data: null,
    selectedIncidentId: null,
    selectedIncident: null,
}

const incidentsSlice = createSlice({
    name: 'incidents',
    initialState,
    extraReducers(builder) {
        builder.addCase(incidentsFetch.fulfilled, (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        });
        builder.addCase(incidentsFetch.rejected, (state) => {
            return {
                ...state,
                data: null
            };
        });
    },
    reducers: {
        setSelectedIncident: (state, action) => {
			state.selectedIncident = action.payload;
		},
        setSelectedIncidentId: (state, action) => {
			state.selectedIncidentId = action.payload;
		},
    }
})


export const { setSelectedIncident, setSelectedIncidentId, } = incidentsSlice.actions

export const getIncidents = (state) => state.incidents.data?.data;
export const getSelectedIncidentId = (state) => state.incidents.selectedIncidentId;
export const getSelectedIncident = (state) => state.incidents.selectedIncident;

export default incidentsSlice.reducer