import { createAsyncThunk } from '@reduxjs/toolkit';
import { incidents, auth } from '../../api/index';

export const incidentsFetch = createAsyncThunk(
    '/',
    async (_, { rejectWithValue }) => {
        try {
            const res = await incidents.getIncidents();

            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchIncidentSupportPlan = createAsyncThunk(
    '/incident/support-plan',
    async (id, { rejectWithValue }) => {
        try {
            const res = await incidents.getIncidentSupportPlan(id);
            return res.data?.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdateSupportPlan = createAsyncThunk(
    '/incident/:incident/support-plan/:plan/edit',
    async (payload, { rejectWithValue }) => {
        try {
            const { incidentId, planId, ...resData } = payload
            const res = await incidents.updateSupportPlan(incidentId, planId, resData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchIncidentSituationAwareness = createAsyncThunk(
    '/incident/situation-awareness',
    async (id, { rejectWithValue }) => {
        try {
            const res = await incidents.getSituationAwareness(id);
            return res.data;
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

