import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SideBar } from "../components/SideBar/SideBar";
import { Header } from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../redux/thunks/notificationThunks";
import { NotificationContainer } from "react-notifications";
import { incidentsFetch } from "../redux/thunks/incidentsThunks";
import { getSelectedIncident, } from "../redux/slices/incidentsSlice";
import { setProfile } from "../redux/slices/settingsSlice";
import {fetchIsAdmin} from "../redux/thunks/authThunks";
import { getSelectedPosition } from "../redux/slices/authSlice";

const Layout = ({ breadcrumbs, hideHeaderNav, headerTitle, tabListProps, }) => {
    const dispatch = useDispatch();

    const selectedIncident = useSelector(getSelectedIncident);
    const selectedPosition = useSelector(getSelectedPosition);

    const getMainData = async() => {
        let res = await dispatch(incidentsFetch()).unwrap();

        if(res?.data?.auth_user){
            dispatch(setProfile(res.data.auth_user));
        }
    }

    useEffect(() => {
        getMainData();
        dispatch(fetchIsAdmin())
        dispatch(fetchNotifications())
    }, [])

    return (
        <>
            <SideBar />
            <div className="wrapper sidebar-fixed dashboard-wrapper">
                { selectedIncident ?
                <main className="content dashboard-content">
                    <Header breadcrumbs={breadcrumbs} hideHeaderNav={hideHeaderNav} headerTitle={headerTitle} tabListProps={tabListProps} />
                    <Outlet key={ selectedPosition } />
                </main> : <main className="content dashboard-content d-flex items-center justify-center py-[20px]" style={{ height: "100vh" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                        <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                    </svg>
                </main> }
            </div>

            <NotificationContainer />
        </>
    )
}

export default Layout