import React, { forwardRef, useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAgencies, getPositions, getRegions} from "../../redux/slices/admin/userManagementSlice";
import {api, userManagement} from "../../api";
import {adminCreateUser, adminUpdateUser} from "../../redux/thunks/admin/userManagementThunks";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addUserShema, editUserShema } from '../../schemas/addUserSchemas';


const AddUserModal = ({ open, close, onSuccess, mode, userId, onError }) => {

    const dispatch = useDispatch()

    const disableField = mode === 'show'

    const schema = {
        'edit': editUserShema,
        'add': addUserShema
    }
    const { handleSubmit, reset, register, setValue, getValues, clearErrors, formState: {errors} } = useForm({
        mode: 'all',
        resolver: yupResolver(schema[mode]),
    })


    const positions = useSelector(getPositions)
    const regions = useSelector(getRegions)
    const agencies = useSelector(getAgencies)

    const [show, setShow] = useState(false);

    const [isLoadingUser, setIsLoadingUser] = useState(false)

    const [rolesOptions, setRolesOption] = useState(null)
    const [regionsOptionsSelect, setRegionsOptionSelect] = useState(null)
    const [agenciesOptionsSelect, setAgenciesOptionSelect] = useState(null)
    const [statesOptionsSelect, setStatesOptionSelect] = useState(null)
    const [statesOpt, setStatesOpt] = useState([]);
    const [formErrors, setFormErrors] = useState({})

    // const [formState, setFormState] = useState({
    //     name: '',
    //     surname: '',
    //     email: '',
    //     alter_email: '',
    //     phone: '',
    //     password: '',
    // })

    const regionsOpt = regions ? createSelectOptions(regions) : null
    const agenciesOpt = agencies ? createSelectOptions(agencies) : null

    function createSelectOptions(arr) {
        const options = []
        arr.forEach(el => options.push({value: el.id, label: el.name}))
        return options
    }

    const onlyLetersAllow = (event, name) => {
        const value = event.target.value.replace(/\d/g, '')
        setValue(name, value.trimStart())
        clearErrors(name)
    }

    const dividePosition = () => {
        if (!positions) return [[], []]
        const array = [...positions]

        const halfLength = Math.ceil(array.length / 2)
        const part1 = array.slice(0, halfLength)
        const part2 = array.slice(halfLength)

        return [part1, part2]
    }

    const validateSelects = () => {
        let error = false
        let fields = {}
        if (!regionsOptionsSelect) {
            error = true
            fields['region'] = true
        }
        if (!agenciesOptionsSelect) {
            error = true
            fields['agency'] = true
        }
        if (!statesOptionsSelect) {
            error = true
            fields['state'] = true
        }

        setFormErrors(fields)

        return error
    }

    const submitUser = async (formData) => {

        console.log(formData);

        const errorsForm = validateSelects()

        if (errorsForm) return

        const { name, surname, phone, alter_email, email, password } = formData
        
        const rolesConvert = () => {
            const rolesArr = Object.entries(rolesOptions)
            const payload = []

            for (let i = 0; i < rolesArr.length; i++) {
                if (rolesArr[i][1]) {
                    payload.push({'id': rolesArr[i][0]})
                }
            }
            return payload
        }
        
        const payload = {
            name,
            surname,
            phone,
            alter_email,
            email,
            region: regionsOptionsSelect,
            state: statesOptionsSelect,
            agency: agenciesOptionsSelect,
            roles: rolesConvert(),
            password,
        }

        let res = null
        
            if (mode === 'add') {
                res = await dispatch(adminCreateUser(payload))
             }
     
             if (mode === 'edit') {
                 res = await dispatch(adminUpdateUser({id: userId, userData: payload}))
             }
     
             console.log(res, 'res');


             if (res.meta.requestStatus === 'fulfilled') {
                console.log('fulfilled', res);
                closeModal();
                onSuccess()
                return
             } else {
                onError && onError(res?.payload?.message)
             }
            //  onError && onError(res?.payload?.message)
             
            // onError && onError(err?.message)
       
    }

    const closeModal = () => {
        setShow(false);

        setTimeout(() => {
            close();
        }, 300);
    }

    // const changeFormValues = (value, name) => {
    //     setFormState(prev => ({...prev, [name]: value}))
    //     setFormErrors(prev => ({...prev, [name]: false}))
    // }

    const onlyNumbers = (value, name) => {
        const newVal = value.replace(/[^0-9]/g, '')
        return newVal
    }

    const titles = {
        add: 'Add User',
        edit: 'Edit User',
        show: 'Information'
    }
    const buttonText = {
        add: 'Create User',
        edit: 'Update User',
        show: null
    }

    useEffect(() => {
        setShow(true);
    }, []);


    useEffect(() => {
        if (!userId){
            // setRegionsOptionSelect(regionsOpt?.[0].value)
            // setValue('region', regionsOpt?.[0].value)
            return;
        }

        const req = async () => {
            setIsLoadingUser(true)
            try {
                const res = await userManagement.getOneUser(userId)

                const u = res.data.data


                console.log(u, 'user');
                const options = {}
                for (let i = 0; i < u.roles.length; i++) {
                    options[u.roles[i].id] = true
                }

                // setFormState({
                //     name: u.name ,
                //     surname: u.surname,
                //     email: u.email,
                //     alter_email: u.alter_email,
                //     phone: u.phone,
                // })
                // setRegionsOptionSelect(u.region.id)
                // setAgenciesOptionSelect(u.agency.id)
                // setStatesOptionSelect(u.state.id)

                setValue('name', u.name || '')
                setValue('surname', u.surname || '')
                setValue('email', u.email || '')
                setValue('alter_email', u.alter_email || '')
                setValue('phone', u.phone || '')
                // setValue('region', u.region.id || '')
                // setValue('agency', Number(u.agency.id) || '')
                // setValue('state', Number(u.state.id) || '')

                setRegionsOptionSelect(u.region.id || null)
                setAgenciesOptionSelect(u.agency.id || null)
                setStatesOptionSelect(u.state.id || null)
                

                setRolesOption(prev => ({...prev, ...options}))

            } catch (e) {
                
            }
            setIsLoadingUser(false)
        }

        req()


        return () => {
            reset()
            // setFormState({
            //     name: '',
            //     surname: '',
            //     email: '',
            //     alter_email: '',
            //     phone: '',
            // })
            setRegionsOptionSelect(null)
            setAgenciesOptionSelect(null)
            setStatesOptionSelect(null)
            setRolesOption((prevRoles) => {
                const updatedRoles = {};
                for (const key in prevRoles) {
                    updatedRoles[key] = false;
                }
                return updatedRoles;
            });
        }
    }, [userId]);


    const [forceRegion, setForceRegion] = useState('')

    useEffect(() => {
        if (!regionsOptionsSelect) return

        if(!userId){ setStatesOptionSelect(null); }
        setStatesOpt(null);

        const req = async () => {
            try {
                const res = await userManagement.getStates(regionsOptionsSelect)

                setStatesOpt(createSelectOptions(res.data.data))
            } catch (err) {

            }
        }

        req()
        
    }, [regionsOptionsSelect]);

    useEffect(() => {
        if (!positions) return
        const option = {}

        for (let i = 0; i < positions.length; i++) {
            if(!userId && positions[i].is_viewer) option[positions[i].id] = true
            else option[positions[i].id] = false
        }

        setRolesOption(option)
        console.log(userId, positions, option);
    }, [positions]);

    return (
        <FullHeightModal
            title={titles[mode]}
            headerIcon={IconPlusAdd}
            open={show}
            close={closeModal}
            submitBtnText={buttonText[mode]}
            submitMethod={() => {handleSubmit(submitUser)(); validateSelects()}}
        >
            {!isLoadingUser ? <div className="row gutter-x-16">
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">First Name</label>
                        <input
                            {...register('name')}
                            className={`input-custom__input ${errors?.name && 'error-field'}`}
                            type="text"
                            id="name"
                            disabled={disableField}
                            placeholder="First Name"
                            onChange={(e) => {
                                onlyLetersAllow(e, 'name');
                            }}
                        />
                        <p className="error-field-text">{errors.name?.message}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Last Name</label>
                        <input
                            {...register('surname')}
                            className={`input-custom__input ${errors?.surname && 'error-field'}`}
                            type="text"
                            id="surname"
                            disabled={disableField}
                            placeholder="Last Name"
                            onChange={(e) => {
                                onlyLetersAllow(e, 'surname');
                            }}
                        />
                        <p className="error-field-text">{errors.surname?.message}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Email</label>
                        <input
                            {...register('email')}
                            className={`input-custom__input ${errors?.email && 'error-field'}`}
                            type="email"
                            id="email"
                            disabled={disableField}
                            placeholder="Enter email"
                            onChange={(e) => {
                                setValue('email', e.target.value.trimStart());
                                clearErrors('email')
                            }}
                        />
                        <p className="error-field-text">{errors.email?.message}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Alternate Email</label>
                        <input
                            {...register('alter_email')}
                            className={`input-custom__input ${errors?.alter_email && 'error-field'}`}
                            type="email"
                            id="alter_email"
                            disabled={disableField}
                            placeholder="Enter email"
                            onChange={(e) => {
                                setValue('alter_email', e.target.value.trimStart());
                                clearErrors('alter_email')
                            }}
                        />
                        <p className="error-field-text">{errors.alter_email?.message}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-12">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Password</label>
                        <input
                            {...register('password')}
                            className={`input-custom__input ${errors?.password && 'error-field'}`}
                            type="text"
                            id="password"
                            disabled={disableField}
                            placeholder="Enter password"
                            onChange={(e) => {
                                setValue('password', e.target.value.trimStart());
                                clearErrors('password')
                            }}
                        />
                        <p className="error-field-text">{errors.password?.message}</p>
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Phone</label>
                        <input
                            {...register('phone')}
                            className={`input-custom__input ${errors?.phone && 'error-field'}`}
                            type="text"
                            id="phone"
                            disabled={disableField}
                            placeholder="Enter phone"
                            onChange={(e) => {
                                setValue('phone', onlyNumbers(e.target.value));
                                clearErrors('phone')
                            }}
                        />
                        <p className="error-field-text">{errors.phone?.message}</p>
                    </div>
                </div>
                <div className="col-12">
                    <h5 className="mb-[16px] font-[600]">Agency / Location Information</h5>
                </div>
                <div className="col-12 col-lg-6">
                    <div className={`select-wrap select-border h-36px mb-[16px] ${disableField && 'pointer-events-none'}`} >
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Agency</label>
                        <SelectBox
                            key={agenciesOpt + formErrors?.agency}
                            // {...register('agency')}
                            className={`default-select ${formErrors?.agency && 'select-error'} `}
                            change={(val) => {
                                setAgenciesOptionSelect(val)
                                setFormErrors(prev => ({...prev, agency: false}))
                            }}
                            value={agenciesOptionsSelect}
                            propsOptions={agenciesOpt}
                        />
                        <p className="error-field-text">{formErrors.agency ? 'Is a required field' : ''}</p>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className={`select-wrap select-border h-36px mb-[16px] ${disableField && 'pointer-events-none'}`}>
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Region</label>
                        
                            <>
                            <SelectBox key={regionsOpt + formErrors.region}
                                // {...register('region')}
                                className={`default-select ${formErrors.region && 'select-error'} `}
                                change={(val) => { 
                                    setRegionsOptionSelect(val)
                                    setFormErrors(prev => ({...prev, region: false}))
                                }}
                                value={regionsOptionsSelect}
                                propsOptions={regionsOpt ? regionsOpt : []}
                            />
                            <p className="error-field-text">{formErrors.region ? 'Is a required field' : ''}</p>
                            </>
                    </div>
                </div>

                <div className="col-12">
                    <div className={`select-wrap select-border h-36px mb-[16px] ${disableField && 'pointer-events-none'}`}>
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">State</label>
                        {statesOpt ? <SelectBox
                            className={`default-select ${formErrors.state && 'select-error'} `}
                            value={statesOptionsSelect}
                            change={(val) => {
                                setStatesOptionSelect(val)
                                setFormErrors(prev => ({...prev, state: false}))
                            }}
                            propsOptions={statesOpt}
                        /> : <div className="default-select d-flex justify-center pt-[8px]" style={{height: '36px', border: '1px solid #EDEDED', borderRadius: '8px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="#2F80ED" />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="#2F80ED" className="spinner_ajPY"/>
                            </svg>
                        </div>}

                        <p className='error-field-text'>{formErrors.state ? 'Is a required field' : ''}</p>
                        {/* <SelectBox key={statesOpt + getValues('state')}
                            {...register('state')}
                            className={`default-select ${errors.state && 'select-error'} `}
                            change={(val) => setValue('state', val)}
                            propsOptions={statesOpt ? statesOpt : []}
                        />
                        <p className="error-field-text">{errors.state?.message || ''}</p> */}
                    </div>
                </div>

                <div className="col-12">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Position</label>
                </div>

                {rolesOptions ? dividePosition().map((arr, idx) => (
                    <div key={idx} className="col-12 col-lg-6">
                        <div className="flex flex-col">
                            {arr.length ? arr.map(el => (
                                <label key={el.id} className="custom-checkbox checkbox-border mb-[18px]">
                                    <input type="checkbox" className="custom-checkbox__input"
                                           checked={rolesOptions[el.id]}
                                           disabled={disableField}
                                           onChange={(e) => setRolesOption(prev => ({
                                        ...prev,
                                        [el.id]: e.target.checked
                                    }))}
                                    />
                                    <span className="custom-checkbox__input-fake"></span>
                                    <span className="custom-checkbox__label">{el.name.replace(/_/g, " ")}</span>
                                </label>
                            )) : null}
                        </div>
                    </div>
                )) : null}


                {/*<div className="col-12 col-lg-6">*/}
                {/*    <div className="flex flex-col">*/}
                {/*        <label className="custom-checkbox checkbox-border mb-[18px]">*/}
                {/*            <input type="checkbox" className="custom-checkbox__input" />*/}
                {/*            <span className="custom-checkbox__input-fake"></span>*/}
                {/*            <span className="custom-checkbox__label">ADMIN</span>*/}
                {/*        </label>*/}
                {/*        <label className="custom-checkbox checkbox-border mb-[18px]">*/}
                {/*            <input type="checkbox" className="custom-checkbox__input" />*/}
                {/*            <span className="custom-checkbox__input-fake"></span>*/}
                {/*            <span className="custom-checkbox__label">Emergency Support Function 3</span>*/}
                {/*        </label>*/}
                {/*        <label className="custom-checkbox checkbox-border mb-[18px]">*/}
                {/*            <input type="checkbox" className="custom-checkbox__input" />*/}
                {/*            <span className="custom-checkbox__input-fake"></span>*/}
                {/*            <span className="custom-checkbox__label">Operations Section Chief</span>*/}
                {/*        </label>*/}
                {/*        <label className="custom-checkbox checkbox-border mb-[18px]">*/}
                {/*            <input type="checkbox" className="custom-checkbox__input" />*/}
                {/*            <span className="custom-checkbox__input-fake"></span>*/}
                {/*            <span className="custom-checkbox__label">Situational Awareness Unit Lead</span>*/}
                {/*        </label>*/}
                {/*        <label className="custom-checkbox checkbox-border mb-[18px]">*/}
                {/*            <input type="checkbox" className="custom-checkbox__input" />*/}
                {/*            <span className="custom-checkbox__input-fake"></span>*/}
                {/*            <span className="custom-checkbox__label">Situational Awareness Unit Lead</span>*/}
                {/*        </label>*/}
                {/*        <label className="custom-checkbox checkbox-border mb-[18px]">*/}
                {/*            <input type="checkbox" className="custom-checkbox__input" />*/}
                {/*            <span className="custom-checkbox__input-fake"></span>*/}
                {/*            <span className="custom-checkbox__label">Emergency Support Function 12</span>*/}
                {/*        </label>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div> : <div style={{textAlign: 'center', marginTop: '25px'}}>Loading</div>}
        </FullHeightModal>
    )
}

export default AddUserModal;