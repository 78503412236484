import { createAsyncThunk } from '@reduxjs/toolkit';
import { impacts } from '../../api/index';

export const impactsFetch = createAsyncThunk(
    '/impacts-fetch',
    async (id, { rejectWithValue }) => {
        try {
            const res = await impacts.getImpacts(id);
            return res.data?.data;
        } catch (err) {
            if(err?.response.data.message === "Unauthenticated."){
                window.location.replace('/login');
            }
            return rejectWithValue(err?.response.data);
        }
    },
);