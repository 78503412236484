import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import IconCalendarSchedule from "../../assets/img/icon-calendar-schedule.svg";
import { useDispatch, useSelector } from 'react-redux';

import { executionChecklistFetch } from '../../redux/thunks/executionChecklistThunk';
import { getTasks } from "../../redux/slices/executionChecklistSlice";

import { adminFetchAllUsers, adminFetchAgencies, } from '../../redux/thunks/admin/userManagementThunks';
import { getUsers, getAgencies } from "../../redux/slices/admin/userManagementSlice";

import { bluf } from '../../api/index';

import { getTasksData } from "../../redux/slices/executionChecklistSlice";

import { getProfileData } from "../../redux/slices/settingsSlice";

const AddBLUFModal = ({ open, close, selectedIncidentId, created, selectedBluf, edit }) => {
    const dispatch = useDispatch();

    const closeModal = () => {
        setShow(false);
        setTimeout(() => {
            close();
        }, 300);
    }

    const [show, setShow] = useState(false);

    const tasks = useSelector(getTasks);
    const users = useSelector(getUsers);
    const profileData = useSelector(getProfileData);
    const agencies = useSelector(getAgencies);
    const taskOptions = useSelector(getTasksData);
    const lifelinesOptions = taskOptions?.lifelines.map(el => ({ label: el.name, value: el.name }));
    
    const clearData = {
        agency_name: "",
        lifeline_name: "",
        username: "",
        description: "",
        documentation: null,
        date: new Date().toISOString().split('T')[0],
        actions: [
            // {
            //     user_id: "",
            //     task_id: "",
            // }
        ]
    };

    const [actionLastKey, setActionLastKey] = useState(0);

    const [data, setData] = useState(clearData);

    const [dragActive, setDragActive] = useState(false);

    const [formErrors, setFormErrors] = useState({})

    const validateForm = () => {
        let obj = Object.entries(data)
        let errors = {}
        for (let i = 0; i < obj.length; i++) {
            const [name, val] = obj[i]
            if(name === 'actions' && !val.length){
                errors[name] = true
            } else if (!val) {
                errors[name] = true
            }
        }

        setFormErrors(errors)
        return errors
    }

    const createBLUF = async() => {
        if(!selectedIncidentId){
            return false;
        }

        const errors = validateForm()
        if (Object.values(errors).includes(true)) return

        await bluf.createBluf(selectedIncidentId, data);
        closeModal();
        if(created){ created(); }
    }

    const updateBLUF = async() => {
        if(!selectedIncidentId || !selectedBluf.id){
            return false;
        }

        let dataForUpdate = {...data};

        delete dataForUpdate.documentation;
        delete dataForUpdate.id;

        if(dataForUpdate.date.includes('.')){
            dataForUpdate.date = new Date(`${dataForUpdate.date.split('.')[2]}-${dataForUpdate.date.split('.')[1]}-${dataForUpdate.date.split('.')[0]}`).toISOString().split('T')[0]
        }

        await bluf.updateBluf(selectedIncidentId, selectedBluf.id, dataForUpdate);
        await closeModal();
        if(created){ created(true); }
    }

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };


    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setData((prevState) => { return { ...prevState, documentation: e.dataTransfer.files[0] }});
        }
    };


    useEffect(()=>{
        setShow(true);
        dispatch(adminFetchAgencies())

        if(!users?.length){
            dispatch(adminFetchAllUsers())
        }
    },[])

    useEffect(()=>{
        if(!tasks?.length && selectedIncidentId){
            dispatch(executionChecklistFetch(selectedIncidentId))
        }
    },[selectedIncidentId])

    useEffect(()=>{
        if(selectedBluf){
            setData(selectedBluf);
        } else {
            setData(clearData);
        }
    },[selectedBluf])

    useEffect(()=>{
        if(profileData){
            setData((prevState)=> {return { ...prevState, username: profileData.full_name }})
        }
    },[profileData])

    return (
        <FullHeightModal
            title="Add BLUF"
            headerIcon={IconPlusAdd}
            open={show}
            close={closeModal}
            // submitBtnText={"Create"}
            // submitMethod={createBLUF}

            submitBtnText={selectedBluf && !edit ? null : selectedBluf && edit ? "Save" : "Create"}
            submitMethod={selectedBluf && edit ? updateBLUF : createBLUF}

            widthClassName="modal__dialog--500"
        >
            <div style={{ pointerEvents: selectedBluf && !edit ? 'none' : 'initial' }}>
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">ESF / Agency</label>
                    { agencies ?
                        <SelectBox
                            className={`default-select ${formErrors.agency_name && 'select-error'}`}
                            value={data.agency_name}
                            change={(val) => {
                                setData((prevState)=> {return { ...prevState, agency_name: val }});
                                setFormErrors(prev => ({...prev, agency_name: false}));
                            }}
                            propsOptions={ agencies.map(item => ({ value: item.name, label: item.name })) }
                        />
                    : null }
                    { formErrors.agency_name && <p className='error-label'>ESF / Agency required</p> }
                </div>
                <div className="input-custom h-36px input-border mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Lifelines</label>
                    { lifelinesOptions ?
                    <SelectBox 
                        key={tasks?.length}
                        className={`default-select flex-1 ${formErrors.lifeline_name && 'select-error'}`}
                        propsOptions={lifelinesOptions}
                        value={data.lifeline_name}
                        change={(value)=>{
                            setData((prevState)=>{
                                return {
                                    ...prevState,
                                    lifeline_name: value
                                }
                            })
                            setFormErrors(prev => ({...prev, lifeline_name: false}))
                        }}
                        isSearchable={true}
                    /> : null }
                    { formErrors.lifeline_name && <p className='error-label'>Lifelines required</p> }
                </div>
                <div className="input-custom mb-[16px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">BLUF</label>
                    <textarea
                        className={`input-custom__textarea ${formErrors.description && 'textarea-error'}`}
                        rows="5" placeholder="Enter BLUF text..."
                        value={ data.description } onInput={(e) => { 
                            setData((prevState)=> {return { ...prevState, description: e.target.value }})
                            setFormErrors(prev => ({...prev, description: false}))
                        }}
                    ></textarea>
                    { formErrors.description && <p className='error-label'>BLUF text required</p> }
                </div>
                <div className="input-custom mb-[16px]" style={{ pointerEvents: selectedBluf ? 'none' : 'initial' }}>
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Documentation</label>
                    <label className={`drag-and-drop`} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                        <input type="file" className="hidden" onChange={(e)=>{
                            setData((prevState)=> {return { ...prevState, documentation: e.target.files[0] }});
                            setFormErrors(prev => ({...prev, documentation: false}));
                        }} />
                        <span className={`drag-and-drop-fake ${dragActive ? 'drag-active' : ''}`}>
                            <span className="drag-and-drop-fake-title">
                                { dragActive ? 'Drop File for Upload' : data.documentation ?  data.documentation?.name || data.documentation.split('/').pop() : 'Drag & Drop or Upload' }
                            </span>
                        </span>
                    </label>
                    { formErrors.documentation && <p className='error-label'>Documentation required</p> }
                </div>
                <div className="row gutter-x-16">
                    <div className="col-12 col-lg-6">
                        <div className="input-custom h-36px input-border mb-[16px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">User Name</label>
                            <input
                                className={`input-custom__input ${formErrors.username && 'error-field'}`} type="text" placeholder="Enter user name"
                                value={ data.username } onInput={(e) => { 
                                    setData((prevState)=> {return { ...prevState, username: e.target.value }})
                                    setFormErrors(prev => ({...prev, username: false}))
                                }}    
                            />
                            { formErrors.username && <p className='error-label'>User Name required</p> }
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="input-custom h-36px input-border">
                            <label className="input-custom__label text-[13px] font-[600] mb-3 opacity-70">Date</label>
                            <div className="relative">
                                <div className="icon-right" style={{ background: "#fff", pointerEvents: "none" }}>
                                    <img src={IconCalendarSchedule} />
                                </div>
                                <input
                                    key={selectedBluf?.date}
                                    className={`input-custom__input data-calendar pr-[40px] ${formErrors.date && 'error-field'}`}
                                    type="date" placeholder="--/--/----"
                                    defaultValue={ new Date( (selectedBluf ? `${selectedBluf.date.split('.')[2]}-${selectedBluf.date.split('.')[1]}-${selectedBluf.date.split('.')[0]}` : new Date().toISOString())).toISOString().split('T')[0] } onInput={(e) => { setData((prevState)=> {return { ...prevState, date: e.target.value }}) ; setFormErrors(prev => ({...prev, date: false}))  }}
                                />
                                { formErrors.date && <p className='error-label'>Date required</p> }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-custom mb-[16px]">
                    <div className="d-flex justify-between items-center">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Actions Taken / Planned</label>
                        { selectedBluf && !edit ? null :
                            <a href="#" className="btn btn--primary btn--lg-3 rounded-[8px] mb-[10px]" onClick={(e)=>{
                                e.preventDefault();
                                // setActionsTakenPlanned([...actionsTakenPlanned, { id: (actionsTakenPlanned.length ? String(+actionsTakenPlanned.pop().id+1) : '1'), task: null, user: null }]);
                                setData((prevState)=>{
                                    return { ...prevState, actions: [...prevState.actions, {
                                        "task_id": null,
                                        "user_id": null
                                    } ] }
                                });
                                setFormErrors(prev => ({...prev, actions: false}));
                            }}>
                            {/* <Link to="/execution-checklist" className="btn btn--primary btn--lg-3 rounded-[8px] mb-[10px]"> */}
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 4.16663V15.8333" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.16797 10H15.8346" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Add Task
                            </a>
                        }
                    </div>
                    {
                        data.actions.map((item, index)=>{
                            return <div className="flex items-center mb-[8px]" key={index+'-'+actionLastKey}>
                                {
                                    selectedBluf && !edit ? null :
                                    <label className="custom-checkbox checkbox-border">
                                        <input type="checkbox" className="custom-checkbox__input" />
                                        <span className="custom-checkbox__input-fake"></span>
                                    </label>
                                }
                                <div className={`${selectedBluf && !edit ? 'pr-[10px]' : 'px-[10px]'} flex-auto`} style={{ maxWidth: '222px' }}>
                                    <SelectBox 
                                        key={tasks?.length}
                                        className="default-select flex-1"
                                        propsOptions={tasks.map((itemTask) => {
                                            return {label: itemTask.description, value: itemTask.id}
                                        })}
                                        isSearchable={true}
                                        value={item?.task_id}
                                        change={(value)=>{
                                            setData((prevState)=>{
                                                return {
                                                    ...prevState,
                                                    actions: [...prevState.actions].map((itemMap, itemMapIndex) => itemMapIndex === index ? ({...itemMap, task_id: value}) : itemMap)
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <SelectBox
                                    key={users?.length}
                                    className="default-select flex-1"
                                    propsOptions={users.map((userItem) => {
                                        return {label: userItem.full_name, value: userItem.id}
                                    })}
                                    value={item?.user_id}
                                    change={(value)=>{
                                        setData((prevState)=>{
                                            return {
                                                ...prevState,
                                                actions: [...prevState.actions].map((itemMap, itemMapIndex) => itemMapIndex === index ? ({...itemMap, user_id: value}) : itemMap)
                                            }
                                        })
                                    }}
                                />
                                {
                                    selectedBluf && !edit ? null :
                                    <a href="#" className="btn" onClick={(e)=>{
                                        e.preventDefault();
                                        setActionLastKey((prevState)=>{return prevState+data.actions.length;})
                                        setData((prevState)=>{
                                            return { ...prevState, actions: prevState.actions.filter((item, index2) => {
                                                return index2 !== index
                                            }) }
                                        });
                                    }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.4">
                                                <path d="M6.66797 6.66663L13.3346 13.3333" stroke="#414252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M13.3346 6.66663L6.66797 13.3333" stroke="#414252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </g>
                                        </svg>
                                    </a>
                                }
                            </div>
                        })
                    }
                    { formErrors.actions && <p className='error-label'>Actions required</p> }
                </div>
            </div>
        </FullHeightModal>
    )
}

export default AddBLUFModal;