import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2'
import { Icon } from '../../components/ui/Icon';
import SearchLoupe from "../../assets/img/search-loupe.svg";
import CreateRRFModal from "../../components/Modals/CreateRRFModal";
import { SelectBox } from "../../components/ui/SelectBox";
import Table from "../../components/ui/Table";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { resourceRequest } from '../../api/index';
import { getSelectedIncident, getSelectedIncidentId } from "../../redux/slices/incidentsSlice";

import {fetchRequests, fetchRequestsChart} from "../../redux/thunks/resourceRequestThunks";
import { getResourceRequests } from "../../redux/slices/resourceRequestSlice";

import {NotificationManager, NotificationContainer} from "react-notifications";

import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";

import { executionChecklistFetch } from '../../redux/thunks/executionChecklistThunk';
import { getTasksData } from "../../redux/slices/executionChecklistSlice";
import { ReadClose } from "../../helpers/checkPermission";

import { resourceRequestApi } from '../../api/utils/resourceRequestApi';
import downloadBlob from '../../helpers/downloadBlob';
import { getShowingTabs } from "../../redux/thunks/settingsThunks";

export const IncidentResourceRequest = ({ innerPage = false }) => {
    const localReadClose = (name, navigateActive, field = 'read') => {
        return ReadClose(name, navigateActive, field)
    }

    const checkRead = localReadClose('rrf', true);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fileInp = useRef(null)

    const [createRRFOpen, setCreateRRFOpen] = useState(false);
    const [confirmDeleteModalOpenId, setConfirmDeleteModalOpenId] = useState(false);

    const selectedIncident = useSelector(getSelectedIncident);
    const initialDataStore = useSelector(getResourceRequests);

    const subIncident = useSelector(getSelectedIncidentId)
    
    const taskOptions = useSelector(getTasksData);

    const formatData = (data) => {
        return data.map((item)=>{
            return {
                id: item.id,
                status: item.status || "-",
                lifeline: item?.lifeline || "-",
                esf: item.esf || "-",
                rrf_number: item.rrf || "-",
                resources: item.description || "-",
                source: item.source || "-",
                priority: item.priority || "-",
            }
        })
    }

    const initialData = initialDataStore ? formatData(initialDataStore) : [];

    const [tableData, setTableData] = useState([]);

    const [chartData, setChartData] = useState({
        // "total": 0,
        // "submitted": 0,
        // "forwarded_for_action": 0,
        // "approved_for_ordering": 0,
        // "ordered": 0,
        // "completed": 0,
        // "not_started": 0,
        

        "not_started": 0,
        "in_progress": 0,
        "completed": 0,
        "pending": 0,
        "canceled": 0,
    })
    // 
    // 
    // 
    // 
    // 
    const doughnutChartData = {
        labels: [
            'Not started', 'In progress', 
            'Completed', 'Pending', 'Canceled'
        ],
        datasets: [
            {
                data: [
                    chartData.not_started || 0,
                    chartData.in_progress || 0,
                    chartData.completed || 0,
                    chartData.pending || 0,
                    chartData.canceled || 0,
                    // chartData.not_started || tableData?.filter(item => item.status === "not_started")?.length || 0,
                ],
                backgroundColor: [
                    '#B3B5D4',
                    '#2F80ED',
                    '#02BC77',
                    '#FF9800',
                    '#EA5E42',
                ]
            }
        ],
        options: {
            cutout: 60,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
            },
        
        }
    }

    const [configureOpen, setConfigureOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [headerFilter, setHeaderFilter] = useState({
        lifeline: '',
        esf: '',
        status: '',
    });

    const [tableColumnsShow, setTableColumnsShow] = useState(null);

    const statusPills = {
        'in_progress': 'pill-primary',
        'not_started': 'pill-gray',
        'completed': 'pill-success',
        'pending': 'pill-yellow',
        'canceled': 'pill-danger',
    }
    const priorityPills = {
        'high': 'pill-yellow',
        'low': 'pill-primary',
        'medium': 'pill-success',
        'critical': 'pill-danger',
    }

    const actionView = (value) => {
        navigate(`/resource-request/${selectedIncident?.id}/${value}`);
    }
    const actionEdit = (value) => {
        navigate(`/resource-request/edit/${selectedIncident?.id}/${value}`);
    }
    const actionDelete = async() => {
        if(confirmDeleteModalOpenId){
            try {
                await resourceRequest.deleteRequest(confirmDeleteModalOpenId);
                setConfirmDeleteModalOpenId(false);
                await fetchResourceRequest();
                NotificationManager.success("Request has been removed");
            } catch (err) {
                console.error(err, 'error')
                NotificationManager.error("Something went wrong");
            }
        }
    }

    const handleClickOutside = (e) => {
        if(!e.target.closest('.configure-dropdown')){
            setConfigureOpen(false);
        }
    }

    

    const exportCsv = async() => {
        let result = await resourceRequest.requestExport(selectedIncident?.id);
        if(result?.data?.success){
            window.location.assign(result?.data?.data?.url);
        }
    }

    const fetchResourceRequest = async () => {
        try {
            const [res, resStatus] = await Promise.allSettled([
                dispatch(fetchRequests(selectedIncident?.id)),
                dispatch(fetchRequestsChart(selectedIncident?.id)),
            ]);

            if (res.status === 'fulfilled' && res.value?.payload?.data) {
                setTableData(formatData(res.value.payload.data));
            }

            if (resStatus.status === 'fulfilled' && resStatus.value?.payload?.data) {
                setChartData((prev) => ({ ...prev, ...resStatus.value.payload.data }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const importCsv = async (event) => {
        const selectedFile = event.target.files[0];

        let formData = new FormData();

        formData.append('file', selectedFile)
        formData.append('sub_incident_id', subIncident)
        formData.append('incident_id', selectedIncident.id)

        const res = await resourceRequest.requestImport(formData)

        if (res?.data?.success) {
            NotificationManager.success('File imported')
            fetchResourceRequest()
        } else {
            NotificationManager.error("Something went wrong")
        } 

        event.target.value = '';

    };

    const tableDropdownPermissions = {
        'edit': localReadClose('rrf', false, 'create'),
        'delete': localReadClose('rrf', false, 'delete'),
    }

    const checkOpenedTab = async() => {
        let id = 6;
        let hiddenTabs = localStorage.getItem('hidden_tabs') || [];

        if(hiddenTabs?.length !== 0){
            hiddenTabs = JSON.parse(hiddenTabs).filter(item => +item !== +id);
        }
        localStorage.setItem('hidden_tabs', JSON.stringify(hiddenTabs));

        await dispatch(getShowingTabs());
    }

    useEffect(()=>{
        if(initialData?.length) {
            setTableData([...initialData].filter(item => 
                (headerFilter?.status ? item.status === headerFilter.status : true) &&
                (headerFilter?.esf ? item.esf === headerFilter.esf : true) &&
                (headerFilter?.lifeline ? item.lifeline === headerFilter.lifeline : true)
            ))
        }
    },[headerFilter])

    useEffect(()=>{
        let gettedTableConfig = localStorage.getItem('tableconfig-resource-request');

        if(gettedTableConfig && !tableColumnsShow){
            setTableColumnsShow(JSON.parse(gettedTableConfig));
        } else {
            setTableColumnsShow({
                id: true,
                status: true,
                lifeline: true,
                esf: true,
                rrf_number: true,
                resources: true,
                source: true,
                priority: true,
            })
        }

        if(!taskOptions){
            dispatch(executionChecklistFetch(selectedIncident?.id))
        }

        document.addEventListener('click', handleClickOutside, true);

        return ()=>{
            document.removeEventListener('click', handleClickOutside, true);
        }
    },[])

    useEffect(()=>{
        if(checkRead && selectedIncident?.id){
            checkOpenedTab();

            fetchResourceRequest();
        }
    },[selectedIncident])

    const downloadTemplate = async () => {
        const res = await resourceRequestApi.requestDownloadTemplate()
        if (res?.status === 200) {
            downloadBlob(res.data, 'rrf-template.csv');
            NotificationManager.success('File downloaded')
        } else {
            NotificationManager.error("Something went wrong")
        }
    }

    return (
        <>
            {
                !innerPage && initialData.length ?
                <div className="dash-page-content-item">
                    <div className="chart-wrapper">
                        <div className="doughnut chart-wrap">
                            <Doughnut
                                data={doughnutChartData}
                                options={doughnutChartData.options}
                                width="150" 
                                height="150"
                                style={{display: 'block', boxSizing: 'border-box'}}
                            />

                            <div className="doughnut-info">
                                <span className="doughnut-heading">
                                    Total Requests
                                </span>
                                <span className="doughnut-colls mt-[2px]">
                                    {chartData.total}
                                </span>
                            </div>
                        </div>
                        
                        <ul className="row bs-gutter-x-14 chart-card-list">
                            <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <svg width="36" height="36" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="29.0233" height="29.0233" rx="14.5116" fill="#B3B5D4" fill-opacity="0.2"/>
                                                <rect x="0.25" y="0.25" width="28.5233" height="28.5233" rx="14.2616" stroke="#B3B5D4" stroke-opacity="0.2" stroke-width="0.5"/>
                                                <line x1="10.5" y1="9.4989" x2="10.5" y2="19.4989" stroke="#B3B5D4"/>
                                                <path d="M12.2622 18.4078L12.2622 10.6155L19.0105 14.5116L12.2622 18.4078Z" stroke="#B3B5D4"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#B3B5D4] mr-[8px]"></span>
                                            Not started:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.not_started}</span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <Icon src="img/decor-icon-1.svg" />
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#2F80ED] mr-[8px]"></span>
                                            In Progress:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.in_progress}</span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <Icon src="img/decor-icon-2.svg" />
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#FF9800] mr-[8px]"></span>
                                            Pending:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.pending}</span>
                                    </div>
                                </div>
                            </li>
                            {/* <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <Icon src="img/decor-icon-3.svg" />
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#F25DC8] mr-[8px]"></span>
                                            Approved for Ordering:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.approved_for_ordering}</span>
                                    </div>
                                </div>
                            </li> */}
                            {/* <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <Icon src="img/decor-icon-4.svg" />
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#7416F7] mr-[8px]"></span>
                                            Ordered:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.ordered}</span>
                                    </div>
                                </div>
                            </li> */}
                            <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <svg width="36" height="36" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="27" height="28" rx="13.5" fill="#EA5E42" fill-opacity="0.2"/>
                                                <rect x="0.25" y="0.25" width="26.5" height="27.5" rx="13.25" stroke="#EA5E42" stroke-opacity="0.2" stroke-width="0.5"/>
                                                <path d="M13.5013 14.6067V12.5808" stroke="#EA5E42" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.5008 16.2344C13.426 16.2344 13.3653 16.295 13.3659 16.3698C13.3659 16.4445 13.4265 16.5052 13.5013 16.5052C13.576 16.5052 13.6367 16.4445 13.6367 16.3698C13.6367 16.295 13.576 16.2344 13.5008 16.2344Z" stroke="#EA5E42" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5976 9.74196L18.7462 17.0025C19.2283 17.8464 18.6189 18.8967 17.6472 18.8967H9.34994C8.37765 18.8967 7.76828 17.8464 8.2509 17.0025L12.3995 9.74196C12.8854 8.891 14.1117 8.891 14.5976 9.74196Z" stroke="#EA5E42" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#EA5E42] mr-[8px]"></span>
                                            Canceled:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.canceled}</span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-12 col-lg-6 col-xl-4">
                                <div className="chart-card-item">
                                    <div className="chart-card-item__head">
                                        <div className="chart-card-item__img">
                                            <Icon src="img/decor-icon-5.svg" />
                                        </div>
                                    </div>
                                    <div className="chart-card-item__footer">
                                        <span className="chart-card-item__footer-title">
                                            <span className="chart-card-item__color bg-[#02BC77] mr-[8px]"></span>
                                            Completed:
                                        </span>
                                        <span className="chart-card-item__footer-amount">{chartData.completed}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> : null
            }
            { !innerPage ? <div className="bg-gray w-full flex justify-between items-center flex-wrap px-[29px] pt-[20px] pb-[10px]">
                { initialData.length ? <div className="flex items-center flex-wrap">
                    <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                        <SelectBox
                            className="default-select"
                            propsOptions={[
                                {label: 'Select', value: '', placeholder: true},
                                {label: 'In-progress', value: 'In-progress'},
                                {label: 'Not Started', value: 'not_started'},
                                {label: 'Completed', value: 'Completed'},
                                {label: 'Pending', value: 'Pending'},
                                {label: 'Canceled', value: 'Canceled'},
                                {label: 'All Statuses', value: ''},
                            ]}
                            value={headerFilter.status}
                            change={(value)=>{
                                setHeaderFilter((prevState)=>{
                                    return ({...prevState, status: value})
                                })
                            }}
                        />
                    </div>
                    <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                        <SelectBox
                            className="default-select"
                            propsOptions={[
                                {label: 'ESF - 13', value: 'ESF - 13'},
                                {label: 'ESF - 14', value: 'ESF - 14'},
                                {label: 'All ESF', value: ''},
                            ]}
                            value={headerFilter.esf}
                            change={(value)=>{
                                setHeaderFilter((prevState)=>{
                                    return ({...prevState, esf: value})
                                })
                            }}
                        />
                    </div>
                    <div className="select-wrap w-[150px] mb-[10px] mr-[8px]">
                        <SelectBox
                            className="default-select"
                            propsOptions={[
                                {label: 'Safety and Security', value: 'Safety and Security'},
                                {label: 'Communications', value: 'Communications'},
                                {label: 'All Lifeline', value: ''},
                            ]}
                            value={headerFilter.lifeline}
                            change={(value)=>{
                                setHeaderFilter((prevState)=>{
                                    return ({...prevState, lifeline: value})
                                })
                            }}
                        />
                    </div>
                </div> : <div className="h-[46px]"></div> }

                <div className="flex items-center flex-wrap">
                    { localReadClose('rrf', false, 'create') ? 
                    <a href="#" className="btn btn--primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]" onClick={(e)=>{
                        e.preventDefault();
                        setCreateRRFOpen(true);
                    }}>
                        <span className="ico mr-[8px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 4.16666V15.8333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M4.16602 10H15.8327" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span>New RRF</span>
                    </a> : null }

                    <a
                        href="#" className="btn btn--shadow-primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]"
                        onClick={(e)=>{
                            e.preventDefault();
                            console.log(fileInp);
                            fileInp.current.click()
                        }}
                    >
                        
                        <span className="ico mr-[8px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3086 3.81159C10.9502 4.07903 9.16785 6.07338 9.16602 8.44686V8.74198C10.1909 7.50706 11.704 6.78115 13.3086 6.75448V8.3118C13.3087 8.53783 13.4378 8.74397 13.6412 8.84263C13.8445 8.9413 14.0864 8.91516 14.264 8.77533L18.1197 5.72989C18.2568 5.62209 18.3368 5.45738 18.3368 5.28304C18.3368 5.10869 18.2568 4.94399 18.1197 4.83619L14.264 1.79158C14.0867 1.65114 13.8446 1.62454 13.6411 1.72312C13.4375 1.82171 13.3083 2.02809 13.3086 2.25428V3.81159Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M17.5043 10V13.3347C17.5043 15.6369 15.638 17.5031 13.3359 17.5031H6.66645C4.3643 17.5031 2.49805 15.6369 2.49805 13.3347V6.66528C2.49805 4.36314 4.3643 2.49688 6.66645 2.49688H9.16749" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span>Import</span>
                    </a>
                    <input
                        ref={fileInp}
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={importCsv}
                    />

                    <a
                        href="#" className="btn btn--shadow-primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]"
                        onClick={(e)=>{
                            e.preventDefault();
                            exportCsv();
                        }}
                    >
                        <span className="ico mr-[8px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3086 3.81159C10.9502 4.07903 9.16785 6.07338 9.16602 8.44686V8.74198C10.1909 7.50706 11.704 6.78115 13.3086 6.75448V8.3118C13.3087 8.53783 13.4378 8.74397 13.6412 8.84263C13.8445 8.9413 14.0864 8.91516 14.264 8.77533L18.1197 5.72989C18.2568 5.62209 18.3368 5.45738 18.3368 5.28304C18.3368 5.10869 18.2568 4.94399 18.1197 4.83619L14.264 1.79158C14.0867 1.65114 13.8446 1.62454 13.6411 1.72312C13.4375 1.82171 13.3083 2.02809 13.3086 2.25428V3.81159Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M17.5043 10V13.3347C17.5043 15.6369 15.638 17.5031 13.3359 17.5031H6.66645C4.3643 17.5031 2.49805 15.6369 2.49805 13.3347V6.66528C2.49805 4.36314 4.3643 2.49688 6.66645 2.49688H9.16749" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span>Export</span>
                    </a>

                    <a
                        href="#" className="btn btn--shadow-primary btn--lg-2 leading-[18px] font-[500] rounded-[8px] mb-[10px] mr-[8px]"
                        onClick={(e)=>{
                            e.preventDefault();
                            downloadTemplate()
                        }}
                    >
                        
                        <span className="ico mr-[8px]">
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"/>
                            <path d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>Template</span>
                    </a>

                    <div className="dropdown configure-dropdown relative" data-dropdown="dropdown">
                        <a href="#" className="btn btn--shadow-primary btn--lg-2 rounded-[10px] mb-[10px]" data-role="button" onClick={(e)=>{
                            e.preventDefault();
                            setConfigureOpen(!configureOpen);
                        }}>
                            <span className="ico mr-[8px]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 4.16667H17.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2.5 4.16667H9.16667" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M10.834 9.99967H17.5007" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2.5 9.99967H5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15 15.8327H17.5" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M2.5 15.8327H9.16667" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M14.5108 2.98813C15.1616 3.63896 15.1616 4.69396 14.5108 5.34479C13.86 5.99563 12.805 5.99563 12.1541 5.34479C11.5033 4.69396 11.5033 3.63896 12.1541 2.98813C12.805 2.33729 13.86 2.33729 14.5108 2.98813" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M7.84479 8.82113C8.49563 9.47197 8.49563 10.527 7.84479 11.1778C7.19396 11.8286 6.13896 11.8286 5.48813 11.1778C4.83729 10.527 4.83729 9.47197 5.48813 8.82113C6.13896 8.1703 7.19396 8.1703 7.84479 8.82113" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M14.5108 14.6551C15.1616 15.306 15.1616 16.361 14.5108 17.0118C13.86 17.6626 12.805 17.6626 12.1541 17.0118C11.5033 16.361 11.5033 15.306 12.1541 14.6551C12.805 14.0043 13.86 14.0043 14.5108 14.6551" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                            Configure
                        </a>
                        <div className={`dropdown__body w-[370px] ${configureOpen ? 'is-open' : ''}`} data-role="dropdown" style={{ right: '0', position: 'absolute' }}>
                            <div className="dropdown-content p-[14px] border-bottom">
                                <div className="row w-full gx-3 gy-4">
                                    { tableColumnsShow &&
                                        [
                                            { label: 'Status', value: 'status' },
                                            { label: 'Lifeline', value: 'lifeline' },
                                            { label: 'ESF', value: 'esf' },
                                            { label: 'RRF Number', value: 'rrf_number' },
                                            { label: 'Resources', value: 'resources' },
                                            { label: 'Source', value: 'source' },
                                            { label: 'Priority', value: 'priority' },
                                            
                                        ].map((item, index)=>{
                                            return <div className="col-6" key={index}>
                                                <label className="custom-checkbox checkbox-gray">
                                                    <input type="checkbox" className="custom-checkbox__input" checked={tableColumnsShow[item.value]} onChange={(e)=>{
                                                        setTableColumnsShow((prevState)=>{
                                                            return {
                                                                ...prevState, [item.value]: e.target.checked
                                                            }
                                                        })
                                                    }} />
                                                    <span className="custom-checkbox__input-fake"></span>
                                                    <span className="custom-checkbox__label">{item.label}</span>
                                                </label>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="dropdown__footer p-[14px] flex justify-end">
                                <a
                                    href="#" className="btn btn--primary btn--lg rounded-[10px]"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        setConfigureOpen(!configureOpen);
                                        localStorage.setItem(
                                            'tableconfig-resource-request',
                                            JSON.stringify(tableColumnsShow)
                                        );
                                    }}
                                >Update Configuration</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null }
            <div className={!innerPage ? `dash-page-content-item` : ''}>
                { !innerPage ? <div className="flex items-center justify-between flex-wrap">
                    <h5 className="font-[600] mr-[10px] mb-[16px]">All Requests</h5>

                    <div className="input-custom mb-[16px]" style={{ maxWidth: '300px', width: '100%' }}>
                        <form action="" className="form-search search-border h-[32px]">
                            <button type="submit" className="form-search__btn">
                                <img src={SearchLoupe} />
                            </button>
                            <input
                                type="search" className="form-search__input" placeholder="Search..."
                                value={searchString}
                                onInput={(e)=>{
                                    setSearchString(e.target.value)
                                }}
                            />
                        </form>
                    </div>
                </div> : null }
                <div className="card-body">
                    { tableColumnsShow && <Table 
                        // actions={true}
                        initialData={(innerPage ? initialData.slice(-5) : initialData)}
                        setSortedData={setTableData}
                        tHead={innerPage ? [
                            {
                                content: 'LL',
                                sortField: 'lifeline',
                                show: tableColumnsShow?.lifeline
                            },
                            {
                                content: 'RRF',
                                show: tableColumnsShow?.rrf_number
                            },
                            {
                                content: 'ESF',
                                show: tableColumnsShow?.esf
                            },
                            {
                                content: 'Resources',
                                sortField: 'resources',
                                show: tableColumnsShow?.resources
                            },
                            {
                                content: 'Source',
                                sortField: 'source',
                                show: tableColumnsShow?.source
                            },
                            {
                                content: 'Status',
                                sortField: 'status',
                                show: tableColumnsShow?.status
                            },
                        ] : [
                            {
                                content: 'Review',
                                sortField: 'is_reviewer', // bool
                                show: tableColumnsShow?.is_reviewer
                            },
                            {
                                content: 'Status',
                                sortField: 'status',
                                show: tableColumnsShow?.status
                            },
                            {
                                content: 'Lifeline',
                                sortField: 'lifeline',
                                show: tableColumnsShow?.lifeline
                            },
                            {
                                content: 'ESF',
                                show: tableColumnsShow?.esf
                            },
                            {
                                content: 'RRF Number',
                                show: tableColumnsShow?.rrf_number
                            },
                            {
                                content: 'Resources',
                                sortField: 'resources',
                                show: tableColumnsShow?.resources
                            },
                            {
                                content: 'Source',
                                sortField: 'source',
                                show: tableColumnsShow?.source
                            },
                            {
                                content: 'Priority',
                                show: tableColumnsShow?.priority
                            },
                        ]}
                        tBody={
                            (innerPage ? tableData.slice(-5) : tableData).filter(itemF => itemF.status.toLowerCase().includes(searchString.toLowerCase()) ||
                                itemF.lifeline.name.toLowerCase().includes(searchString.toLowerCase()) ||
                                itemF.resources.toLowerCase().includes(searchString.toLowerCase())
                                 || itemF.source.toLowerCase().includes(searchString.toLowerCase())
                            ).map((item, index)=>{
                                return innerPage ? [
                                    item.id,
                                    // tableColumnsShow?.lifeline ? <img src={item.lifeline?.image} alt="" class="w-[30px] h-[30px]" /> : null,
                                    tableColumnsShow?.lifeline ? item.lifeline?.name : null,
                                    tableColumnsShow?.rrf_number ? item.rrf_number : null,
                                    tableColumnsShow?.esf ? item.esf : null,
                                    tableColumnsShow?.resources ? item.resources : null,
                                    tableColumnsShow?.source ? 
                                        <div className="max-w-[200px]" style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                            {item.source}
                                        </div> : null,
                                    tableColumnsShow?.status ? <span className={`pill ${statusPills[item.status]}`}>
                                        <span className="pill-status"></span>
                                        { item.status.split("_").map(c => c[0].toUpperCase()+c.slice(1)).join(" ") }
                                    </span> : null,
                                ] : [
                                    item.id,
                                    <div className={`${tableColumnsShow?.is_reviewer ? '' : 'opacity-30'}`}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="1">
                                                <path d="M10.3125 13.0625L8.25 11" stroke="#2F80ED" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M13.75 9.625L10.3125 13.0625" stroke="#2F80ED" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19.25C6.44365 19.25 2.75 15.5563 2.75 11V11C2.75 6.44365 6.44365 2.75 11 2.75V2.75C15.5563 2.75 19.25 6.44365 19.25 11V11C19.25 15.5563 15.5563 19.25 11 19.25V19.25Z" stroke="#2F80ED" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </g>
                                        </svg>
                                    </div>,
                                    tableColumnsShow?.status ? <span className={`pill ${statusPills[item.status]}`}>
                                        <span className="pill-status"></span>
                                        {/* { item.is_reviewer ? '[Reviewer] ' : ''} */}
                                        { item.status.split("_").map(c => c[0].toUpperCase()+c.slice(1)).join(" ") }
                                        
                                    </span> : null,
                                    tableColumnsShow?.lifeline ? item?.lifeline?.name : null,
                                    tableColumnsShow?.esf ? item.esf : null,
                                    tableColumnsShow?.rrf_number ? item.rrf_number : null,
                                    tableColumnsShow?.resources ? item.resources : null,
                                    tableColumnsShow?.source ? item.source : null,
                                    tableColumnsShow?.priority ? <span className={`pill ${priorityPills[item.priority]}`}>
                                        <span className="flex mr-[5px]">
                                            { item.priority === 'high' && 
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.15359 2.3C5.30755 2.03333 5.69245 2.03333 5.84641 2.3L9.74353 9.05C9.89748 9.31667 9.70504 9.65 9.39711 9.65H1.60289C1.29497 9.65 1.10252 9.31667 1.25648 9.05L5.15359 2.3Z" stroke="#F59E0C" strokeWidth="1.2"></path>
                                                </svg>
                                            }
                                            { item.priority === 'low' && 
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.84641 9.7C5.69245 9.96667 5.30755 9.96667 5.15359 9.7L1.25648 2.95C1.10252 2.68333 1.29497 2.35 1.60289 2.35L9.39712 2.35C9.70504 2.35 9.89749 2.68333 9.74353 2.95L5.84641 9.7Z" stroke="#2F80ED" strokeWidth="1.2"></path>
                                                </svg>
                                            }
                                            { item.priority === 'medium' && 
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="5.5" cy="6" r="4.9" stroke="#02BC77" strokeWidth="1.2"></circle>
                                                </svg>
                                            }
                                            { item.priority === 'critical' && 
                                                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.49935 7.60667V5.58084" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M6.49881 9.23437C6.42406 9.23437 6.36339 9.29504 6.36393 9.36979C6.36393 9.44454 6.4246 9.5052 6.49935 9.5052C6.5741 9.5052 6.63477 9.44454 6.63477 9.36979C6.63477 9.29504 6.5741 9.23437 6.49881 9.23437" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.59957 2.74196L11.7482 10.0025C12.2303 10.8464 11.6209 11.8967 10.6491 11.8967H2.3519C1.37961 11.8967 0.770231 10.8464 1.25286 10.0025L5.40148 2.74196C5.88736 1.891 7.11369 1.891 7.59957 2.74196Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg> 
                                            }
                                        </span>
                                        {item.priority[0].toUpperCase()+item.priority.slice(1)}
                                    </span> : null,
                                ]
                            })
                        }
                        actionsSingleBtn={innerPage}
                        actions={innerPage ? 
                            {
                                onClick: actionView,
                                content: <span className="ico ico-20">
                                    <Icon src="img/share-arrow-square.svg" />
                                </span>
                            } : [
                            {
                                onClick: actionView,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </>
                            },
                            {
                                onClick: tableDropdownPermissions.edit ? actionEdit : () => { NotificationManager.error("You do not have access to this action") },
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </>
                            },
                            {
                                listLine: true,
                            },
                            {
                                onClick: tableDropdownPermissions.delete ? setConfirmDeleteModalOpenId : () => { NotificationManager.error("You do not have access to this action") },
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>

                                    </span>
                                    <span className="text-[#EA5E42]">
                                        Delete
                                    </span>
                                </>
                            }
                        ]}
                        actionClear={innerPage}
                    /> }
                    {
                        !initialData.length ? <h3 className="text-center p-[30px] font-[600]">Request list is empty</h3> : null
                    }
                </div>
            </div>

            { createRRFOpen ? 
                <CreateRRFModal
                    created={()=>{ fetchResourceRequest(); }}
                    close={()=>{ setCreateRRFOpen(false); }}
                />
            : null }

            <ConfirmDeleteModal
                open={confirmDeleteModalOpenId}
                close={()=>{setConfirmDeleteModalOpenId(false)}}
                submit={()=>{
                    actionDelete();
                }}
            />

            <NotificationContainer />
        </>
    )
}
