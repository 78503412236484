import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import { SelectBox } from "../ui/SelectBox";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAdminIncidents, getAdminIncidentsType} from "../../redux/slices/admin/adminIncidentsSlice";
import {adminCreateUser} from "../../redux/thunks/admin/userManagementThunks";
import {adminCreateIncident, adminUpdateIncident} from "../../redux/thunks/admin/adminIncidentsThunks";

const AddMainIncidentModal = ({ open, close, onSuccess, mode, incidentId }) => {

    const incidents = useSelector(getAdminIncidentsType)

    const allIncidents = useSelector(getAdminIncidents)

    const [requestSend, setRequestSend] = useState(false)

    const dispatch = useDispatch()

    const [show, setShow] = useState(false);

    const incidentsOptions = incidents && incidents.length ? createSelectOptions(incidents) : null

    const [formErrors, setFormErrors] = useState({})

    const [formState, setFormState] = useState({
        incident_name: '',
        incident_type: '',
        incident_comment: '',
    })

    const validateForm = () => {
        const obj = Object.entries(formState)
        const errors = {}
        for (let i = 0; i < obj.length; i++) {
            const [name, val] = obj[i]
            if (!val) {
                errors[name] = true
            }
        }

        setFormErrors(errors)
        return errors
    }

    function createSelectOptions(arr) {
        const options = []
        arr.forEach(el => options.push({value: el.id, label: el.name}))
        return options
    }

    const createIncident = async () => {
        const errors = validateForm()
        if (Object.values(errors).includes(true)) return
        
        if (requestSend) return
        setRequestSend(true)

        if (mode === 'add') {
            try {
                await dispatch(adminCreateIncident(formState))
                onSuccess()
                closeModal();
            } catch (err) {

            }

            return
        }

        try {
            await dispatch(adminUpdateIncident({id: incidentId, data: formState}))
            onSuccess()
            closeModal();
        } catch (err) {

        }

    }

    const closeModal = () => {
        setShow(false);

        setTimeout(() => {
            close();
        }, 300);
    }

    useEffect(() => {
        setShow(true);
    }, []);

    useEffect(() => {
        if (!incidentId) return

        const currentInc = allIncidents && allIncidents.find(el => el.id === incidentId)
        
        setFormState({
            incident_name: currentInc.name,
            incident_type: currentInc.type.id,
            incident_comment: '',
        })

        return () => setFormState({
                incident_name: '',
                incident_type: '',
                incident_comment: '',
            })

    }, [incidentId]);

    return (
        <FullHeightModal
            title="Add Main Incident"
            headerIcon={IconPlusAdd}
            open={show}
            close={closeModal}
            submitBtnText={"Save"}
            submitMethod={createIncident}
        >
            <div className="row gutter-x-16">
                <div className="col-12">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Main Incident Name</label>
                        <input className={`input-custom__input ${formErrors.incident_name && 'error-field'}`} type="text" placeholder="Incident Name"
                            value={formState.incident_name}
                               onChange={(e) => {
                                   setFormState(prevState => ({...prevState, incident_name: e.target.value}))
                                   setFormErrors(prev => ({...prev, incident_name: false}))
                               }}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-custom h-36px input-border mb-[16px]">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Incident Type</label>
                        <SelectBox
                            className={`default-select ${formErrors.incident_type && 'select-error'}`}
                            value={formState.incident_type}
                            change={(val) => {
                                setFormState(prevState => ({...prevState, incident_type: val}))
                                setFormErrors(prev => ({...prev, incident_type: false}))
                            }}
                            propsOptions={incidentsOptions}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-custom">
                        <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Additional Comments</label>
                        <textarea className={`input-custom__textarea ${formErrors.incident_comment && 'textarea-error'}`} rows="5" placeholder="Enter comments..."
                              value={formState.incident_comment}
                              onChange={(e) => {
                                  setFormState(prevState => ({...prevState, incident_comment: e.target.value}))
                                  setFormErrors(prev => ({...prev, incident_comment: false}))
                              }}
                        ></textarea>
                    </div>
                </div>
            </div>
        </FullHeightModal>
    )
}

export default AddMainIncidentModal;