import React, { useEffect, useState } from 'react'
import ImgLogo from '../../assets/img/logo.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { auth } from '../../api/index';

import 'swiper/css';
import 'swiper/css/pagination';

const AuthLayout = ({children}) => {

    const [sliderContent, setSliderContent] = useState(null);

    const getSliderContent = async() => {
        let res = await auth.signSlider();
        setSliderContent(res.data);
    }

    useEffect(()=>{
        getSliderContent();
    }, [])

    return (
        <>
        <div className="wrapper">
            <main className="content">
                <section className="double-fullpage">
                    <div className="container">
                        <div className="row container--row w-full">
                            <div className="col-lg-6 row-item">
                                <div className="header-login">
                                    <a href="#" className="flex max-w-max">
                                        <img src={ImgLogo} />
                                    </a>
                                </div>
                                <div className="flex flex-col flex-auto justify-center">
                                    { children }
                                </div>
                            </div>
                            <div className="col-lg-6 row-item">
                                <div className="bg-image--container">
                                    { sliderContent ? <Swiper
                                        className="swiper swiper-card swiper-initialized swiper-horizontal swiper-backface-hidden"
                                        loop={true}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        modules={[Pagination]}
                                        pagination={{ clickable: true }}
                                    >
                                        { sliderContent.map((slide, index) => 
                                            <SwiperSlide key={index}>
                                                <div className="card-black p-[24px] m-[30px] max-w-[400px] pb-[50px]">
                                                    <h3 className="heading-3 text-white font-[600]">{ slide.title }</h3>
                                                    <p className="mt-[10px] def-text-1 text-06">{ slide.description }</p>
                                                </div>
                                            </SwiperSlide>
                                        )}
                                    </Swiper> : null }
                                    <div className="bg-image">
                                        <img src={require('../../assets/img/image-promo.jpg')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <a href="" className="scroll_to_top"></a>
            <footer></footer>
        </div>
        </>
    )
}

export default AuthLayout