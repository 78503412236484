import React, {useEffect, useMemo, useState} from 'react'
import {Calendar as CalendarComponent} from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../assets/css/calendar.css'

import {CalendarEvents} from "./CalendarEvents";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedIncident} from "../../redux/slices/incidentsSlice";
import {fetchAllMeetings} from "../../redux/thunks/meetingsThunks";
import {getMeetHashTable} from "../../redux/slices/meetingsSlice";

export const Calendar = () => {

    const dispatch = useDispatch()
    const inc = useSelector(getSelectedIncident)
    const dateHashTable = useSelector(getMeetHashTable)

    useEffect(() => {
        dispatch(fetchAllMeetings(inc.id))
    }, []);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ]
    const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

    const getFormattedDate = (date) => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        return `${year}-${month}-${day}`
    }
    
    const nextLabel = <div className="react-calendar__nav-action" data-action="next"><svg><path d="M 14,12 l 5,5 l -5,5"></path></svg></div>
    const prevLabel = <div className="react-calendar__nav-action" data-action="next"><svg><path d="M 17,12 l -5,5 l 5,5"></path></svg></div>


    
    const [selectedDate, setSelectedDate] = useState(getFormattedDate(new Date()))

    const [activeCalendarDate, setActiveCalendarDate] = React.useState(new Date());

    const changeDataFromDate = (noFormatDate) => {
        const formatDate = getFormattedDate(noFormatDate)
        setSelectedDate(formatDate)
        setActiveCalendarDate(formatDate)
    }

    if (!dateHashTable) {
        return <div>Loading</div>
    }

    return (
        <div className='calendar-events'>
            <div className='calendar-air'>
                <CalendarComponent 
                    onChange={changeDataFromDate}
                    value={activeCalendarDate}
                    next2Label={null}
                    prev2Label={null}
                    formatShortWeekday={(locale, date) => `${weekDays[date.getDay()]}`}
                    tileContent={({ activeStartDate, date, view }) => {
                        const formattedDate = getFormattedDate(date)

                        const filled = (dateHashTable && dateHashTable[formattedDate] && dateHashTable[formattedDate].length) || null
                        
                        return (
                            <div  className={`date-block`}>
                                <div>{date.getDate()}</div>
                                {filled && <span className='date-status'>•</span>}
                            </div>

                        )
                    }}
                    // activeStartDate={new Date()}
                    navigationLabel={({ date }) => `${monthNames[date.getMonth()]}, ${date.getFullYear()}`}
                    nextLabel={nextLabel}
                    prevLabel={prevLabel}
                    />
            </div>

            <CalendarEvents dayEvents={dateHashTable && dateHashTable[selectedDate]} selectedDate={selectedDate} />
        </div>
    )
}