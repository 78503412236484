import axios from "axios";
import { axiosConfig } from "../config";
import { authApi } from "./utils/authApi";
import { incidentsApi } from "./utils/incidentsApi";
import { launchApi } from "./utils/launchApi";
import { notificationsApi } from "./utils/notificationsApi";
import { resourceRequestApi } from "./utils/resourceRequestApi";
import { meetingsApi } from "./utils/meetingsApi";
import { messagesApi } from "./utils/messagesApi";
import { executionChecklistApi } from "./utils/executionChecklistApi";
import { impactsApi } from "./utils/impactsApi";
import {userManagementApi} from "./utils/admin/userManagementApi";
import {adminIncidentsApi} from "./utils/admin/adminIncidentsApi";
import { settingsApi } from "./utils/settingsApi";
import { blufApi } from "./utils/blufApi";
import {sharedApi} from "./utils/sharedApi";
import { NotificationManager } from "react-notifications";


export const api = axios.create(axiosConfig);


api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

export const auth = authApi;
export const incidents = incidentsApi;
export const notifications = notificationsApi;
export const launch  = launchApi;
export const resourceRequest  = resourceRequestApi;

export const sharedRequests = sharedApi
export const executionChecklist = executionChecklistApi;
export const impacts = impactsApi;
export const meetings = meetingsApi;
export const messages = messagesApi;
export const settings = settingsApi;
export const bluf = blufApi;

export const userManagement = userManagementApi

export const adminIncidents = adminIncidentsApi;

let forbiddenNotifShow = false;

api.interceptors.response.use(function (response) {
    return response;
  }, async function(error) {
    if(error.response.status === 401){
        if(localStorage.getItem('remember') && localStorage.getItem('remember') === "true" && localStorage.getItem('last_login') && localStorage.getItem('refresh_token')){
            if(new Date(localStorage.getItem('last_login')) > new Date()){
                const refreshTokenRes = await auth.refreshToken();
                if(refreshTokenRes?.access_token && refreshTokenRes?.refresh_token){
                    localStorage.setItem('access_token', refreshTokenRes?.access_token);
                    localStorage.setItem('refresh_token', refreshTokenRes?.refresh_token);
                    window.location.reload();
                } else {
                    window.location.replace('/login');
                }
            } else {
                window.location.replace('/login');
            }
        } else {
            window.location.replace('/login');
        }
    }

    if(error.response.status === 403 && !forbiddenNotifShow){
        NotificationManager.error(error.response.data.message);
        forbiddenNotifShow = true;

        setTimeout(() => {
            forbiddenNotifShow = false;
        }, 2000);
    }

    return {
        ...error.response,
        data: {
            ...error.response.data,
            success: false
        }
    };
});