import React from 'react'
import { Icon } from '../ui/Icon'

export const SaveChangesModal = ({onConfirm, onClose}) => {

    const confirm = () => onConfirm && onConfirm()
    const close = () => onClose && onClose()

    return (
        <div id="save-changes" className="modal modal-1 modal--show">
            <div className="modal__dialog modal__dialog--365">
                <div className="modal__content">
                    <div className="modal__header justify-end">
                        <a href="#" className="btn btn-close" data-close-modal="" onClick={close}>
                            <Icon src="img/delete-disabled.svg" alt="" />
                        </a>
                    </div>
                    <div className="modal__body items-center mt-[-55px]">
                        <h2 className="font-[700] mb-[14px]">Save Changes</h2>
                        <p className="text-[14px] leading-[22px] text-center max-w-[210px] opacity-60">Do you want to save changes?</p>
                    </div>
                    <div className="modal__footer">
                        <a onClick={confirm} href="#" className="btn btn--primary btn--lg-3 rounded-[8px] mr-[8px] w-full">Yes</a>
                        <a onClick={close} href="#" className="btn btn--outline-secondary-3 btn--lg-3 rounded-[8px] w-full">No</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
