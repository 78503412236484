import { createAsyncThunk } from '@reduxjs/toolkit';
import { launch } from '../../api/index';

export const fetchLaunch = createAsyncThunk(
    '/fetchLaunch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await launch.getLaunch();
            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const setDisplaySettings = createAsyncThunk(
    '/setDisplaySettings',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await launch.setLaunchSettings(payload);
            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);