import {api} from "../../index";

export const adminIncidentsApi = {
    getAllIncidents() {
        return api.get("/admin/incidents");
    },
    getOneIncidents(id) {
        return api.get(`/admin/incident/${id}/show`);
    },
    updateIncident(id, payload) {
      return api.put(`/admin/incident/${id}/edit`, payload)
    },
    getAdminIncidentsType() {
        return api.get('/admin/incident-types')
    },
    adminCreateIncident(payload) {
        return api.post('/admin/incident/store', payload)
    },
    adminDeleteIncident(id) {
        return api.delete(`/admin/incident/${id}/delete`)
    },

    createSubIncident(incidentId, payload) {
        return api.post(`/admin/incident/${incidentId}/sub-incidents/store`, payload)
    },
    getSubIncidents(incidentId) {
        return api.get(`/admin/incident/${incidentId}/sub-incidents`)
    },
    getSubIncident(incidentId, subId) {
        return api.get(`/admin/incident/${incidentId}/sub-incidents/${subId}/show`)
    },
    editSubIncident(incidentId, subId, payload) {
        return api.put(`/admin/incident/${incidentId}/sub-incidents/${subId}/edit`, payload)
    },
    deleteSubIncident(incidentId, subId) {
        return api.delete(`/admin/incident/${incidentId}/sub-incidents/${subId}/delete`)
    }
}