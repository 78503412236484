import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsApi } from '../../api/utils/notificationsApi';

export const fetchNotifications = createAsyncThunk(
    '/notifications/fetchNotifications',
    async (_, { rejectWithValue }) => {
        try {
            const res = await notificationsApi.getAll()
            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data)
        }
    },
)

export const fetchClearAllNotifications = createAsyncThunk(
    '/notifications/fetchClearAllNotifications',
    async (_, { rejectWithValue }) => {
        try {
            const res = await notificationsApi.clearAll()
            return res.data
        } catch (err) {
            return rejectWithValue(err?.response.data)
        }
    },
)

export const fetchRemoveNotifications = createAsyncThunk(
    '/notifications/fetchRemoveNotifications',
    async (id, { rejectWithValue }) => {
        try {
            const res = await notificationsApi.deleteOne(id)
            return {...res.data, id}
        } catch (err) {
            return rejectWithValue(err?.response.data)
        }
    },
)