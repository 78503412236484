import React from 'react'
import { NavLink, useLocation } from "react-router-dom";
import { SideBarAccordion } from './SideBarAccordion'
import ArrowDownSvg from '../../assets/img/down-arrow.svg'
import { useDispatch, useSelector } from "react-redux";
import {getIsAdmin} from "../../redux/slices/authSlice";
import { getShowingTabs } from "../../redux/thunks/settingsThunks";
import { ReadClose } from "../../helpers/checkPermission";

export const SideBarNavigation = () => {
    const localReadClose = (name, navigateActive) => {
        return ReadClose(name, navigateActive)
    }

    const dispatch = useDispatch();

    const isAdmin = useSelector(getIsAdmin)

    const openTab = async(id) => {
        let hiddenTabs = localStorage.getItem('hidden_tabs') || [];

        // hiddenTabs !== 
        if(hiddenTabs?.length !== 0){
            hiddenTabs = JSON.parse(hiddenTabs).filter(item => +item !== +id);
        }

        localStorage.setItem('hidden_tabs', JSON.stringify(hiddenTabs));

        await dispatch(getShowingTabs());
    }

    const planningShow = {
        all: localReadClose(['tasks', 'support-plan'], false),
        tasks: localReadClose('tasks', false),
        support: localReadClose('support-plan', false)
    };

    return (
        <>
        <ul className="sidebar-menu">

            {/* General Information */}
            { isAdmin ? 
                <label className="form-group-title">Main App</label>
            : null }
            <SideBarAccordion 
                linkContent={
                    <>
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9.99968" cy="10" r="7.91667" fill="#00AAE2" fillOpacity="0.05" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></circle>
                                <path d="M10.9375 12.9167H10V9.375H9.0625" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M9.7275 6.72819L9.72787 6.72782C9.80911 6.64678 9.94066 6.6469 10.0218 6.7281C10.1028 6.80929 10.1028 6.94084 10.0217 7.02198C9.94052 7.10313 9.80897 7.10317 9.72778 7.02208C9.64658 6.94098 9.64646 6.80943 9.7275 6.72819" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">General Information</span>
                        <span className="flex menu-arrow">
                            <img src={ArrowDownSvg} alt="" />
                        </span>
                    </>
                }
                ulContent={
                    <>
                        <li className="sidebar-item">
                            <NavLink to="/coordination" className="sidebar-link">
                                Coordination
                            </NavLink>
                        </li>
                    </>
                }
                initOpen={true}
            />

            {/* Leadership */}
            <SideBarAccordion
                linkContent={
                   <>
                    <span className="flex sidebar-menu-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 12.6583H7.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.11745 12.4133C5.63745 11.3675 4.74995 9.54167 5.06245 7.53167C5.40078 5.35917 7.21161 3.625 9.39578 3.36917C12.4274 3.01333 14.9999 5.37333 14.9999 8.33333C14.9999 10.0208 14.1616 11.51 12.8808 12.415C12.6508 12.5775 12.4999 12.8292 12.4999 13.1108V15.4167C12.4999 16.5675 11.5674 17.5 10.4166 17.5H9.58328C8.43245 17.5 7.49995 16.5675 7.49995 15.4167V13.1142C7.49995 12.83 7.34828 12.5767 7.11745 12.4133Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M16.667 4.16667L17.6587 3.175" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M3.33346 12.5L2.3418 13.4917" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16647 3.33333L3.1748 2.34167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M16.667 12.5L17.6587 13.4917" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M17.5 8.33333H18.9083" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M1.0918 8.33333H2.50013" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M7.5 15H12.3917" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    <span className="flex-auto px-[10px]">Leadership</span>
                    <span className="flex menu-arrow">
                        <img src={ArrowDownSvg} alt="" />
                    </span>
                 </>
                }

                ulContent={
                    <>
                        <li className="sidebar-item">
                            <NavLink to="/incident-overview" className="sidebar-link" onClick={()=>{
                                openTab(3);
                            }}>
                                Incident Overview
                            </NavLink>
                        </li>
                    </>
                }
            />

            {/* Planning */}
            { planningShow.all ? <SideBarAccordion 
                linkContent={
                    <>
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.87467 5L12.5055 4.98917C13.8913 4.98667 15.0155 6.1125 15.0097 7.49833V7.51C15.0038 8.88667 13.8863 10 12.5105 10H8.33217C6.95217 10 5.83301 11.1192 5.83301 12.4992V12.4992C5.83301 13.88 6.95301 14.9992 8.33384 14.9983L16.6663 14.9958" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16.6671 14.9958L15.0029 16.66" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M15.0029 13.3308L16.6671 14.9958" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M6.32582 3.67417C7.05806 4.40641 7.05806 5.59359 6.32582 6.32582C5.59359 7.05806 4.40641 7.05806 3.67417 6.32582C2.94194 5.59359 2.94194 4.40641 3.67417 3.67417C4.40641 2.94194 5.59359 2.94194 6.32582 3.67417" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">Planning</span>
                        <span className="flex menu-arrow">
                            <img src={ArrowDownSvg} alt="" />
                        </span>
                    </>
                }
                ulContent={
                    <>
                        { planningShow.tasks ? <li className="sidebar-item">
                            <NavLink to="/execution-checklist" className="sidebar-link" onClick={()=>{
                                openTab(4);
                            }}>
                                Execution Checklist
                            </NavLink>
                        </li> : null }
                        { planningShow.support ? <li className="sidebar-item">
                            <NavLink to="/support-plan" className="sidebar-link" onClick={()=>{
                                openTab(5);
                            }}>
                                Support Plan
                            </NavLink>
                        </li> : null }
                    </>
                }
            /> : null }

            {/* Resource Support */}
            { localReadClose('rrf', false) ? <SideBarAccordion 
                linkContent={
                    <>
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.81836 13.7526H3.33505C3.68897 13.7526 4.02791 13.6126 4.27785 13.3626V13.3626C4.5278 13.1127 4.66777 12.7738 4.66777 12.4198V7.08694C4.66777 6.73301 4.5278 6.39408 4.27785 6.14413V6.14413C4.02791 5.89418 3.68897 5.75421 3.33505 5.75421H2.06631" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M17.9335 5.75421H16.6657C16.3118 5.75421 15.9729 5.89418 15.7229 6.14413V6.14413C15.473 6.39408 15.333 6.73301 15.333 7.08694V12.4198C15.333 12.7738 15.473 13.1127 15.7229 13.3626V13.3626C15.9729 13.6126 16.3118 13.7526 16.6657 13.7526H18.1824" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M15.3528 12.6498H14.493C14.207 12.6498 13.9241 12.7108 13.6641 12.8298L9.45801 14.7454" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.64515 6.67202C8.14125 6.37908 7.53238 6.3061 6.9705 6.50806L4.66797 7.33689" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M15.4923 6.44007L12.6169 5.17433C12.001 4.90339 11.2892 4.96038 10.7243 5.3263L8.11481 7.01695C7.28498 7.55384 7.19 8.7316 7.92185 9.39546V9.39546C8.37076 9.80238 9.02063 9.90036 9.56951 9.64441L11.1252 8.91856" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M11.1252 8.91756L11.9151 9.63441C12.7879 10.4252 12.7909 11.795 11.9231 12.5908L9.90647 14.4364C9.14563 15.1343 7.97787 15.1363 7.21403 14.4434L5.82831 13.1867C5.50038 12.8897 5.08246 12.7228 4.64355 12.6868" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">Resource Support</span>
                        <span className="flex menu-arrow">
                            <img src={ArrowDownSvg} alt="" />
                        </span>
                    </>
                }
                ulContent={
                    <>
                        <li className="sidebar-item">
                            <NavLink to="/resource-request" className="sidebar-link" onClick={()=>{
                                openTab(6);
                            }}>
                                Resource Request
                            </NavLink>
                        </li>
                    </>
                }
            /> : null }

            {/* Situational Awareness */}
            { localReadClose('situational-awareness', false) ? <SideBarAccordion
                linkContent={
                    <>
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.61215 12.4983C2.46132 12.2442 2.46132 11.9225 2.61215 11.6683C4.19965 8.9825 7.09965 6.66666 9.99965 6.66666C12.8996 6.66666 15.7988 8.9825 17.3863 11.6675C17.5371 11.9225 17.5371 12.245 17.3863 12.5C15.7988 15.1842 12.8996 17.5 9.99965 17.5C7.09965 17.5 4.19965 15.1842 2.61215 12.4983Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.7674 10.3158C12.744 11.2925 12.744 12.875 11.7674 13.8517C10.7907 14.8283 9.20819 14.8283 8.23152 13.8517C7.25486 12.875 7.25486 11.2925 8.23152 10.3158C9.20819 9.33917 10.7915 9.33917 11.7674 10.3158" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M9.99967 2.08333V4.16667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M4.16602 3.75L5.56602 5.41667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.8326 3.75L14.4326 5.41667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>   
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">Situational Awareness</span>
                        <span className="flex menu-arrow">
                            <img src={ArrowDownSvg} alt="" />
                        </span>
                    </>
                }
                ulContent={
                    <>
                        <li className="sidebar-item">
                            <NavLink to="/situational-awareness" className="sidebar-link" onClick={()=>{
                                openTab(7);
                            }}>
                                Situational Awareness
                            </NavLink>
                        </li>
                        <li className="sidebar-item">
                            <NavLink to="/impact" className="sidebar-link" onClick={()=>{
                                openTab(7);
                            }}>
                                Impact
                            </NavLink>
                        </li>
                    </>
                }
            /> : null }
        </ul>
        { isAdmin ? <>
            <hr className="sidebar-hr my-[16px]"></hr>
            <label className="form-group-title">Admin</label>
            <ul className="sidebar-menu">
                <li className="sidebar-item admin-link">
                    <NavLink to="/superuser/incidents" className="sidebar-link">
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99967 10.9333V7.81668" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M9.99884 13.4375C9.88384 13.4375 9.79051 13.5308 9.79134 13.6458C9.79134 13.7608 9.88468 13.8542 9.99968 13.8542C10.1147 13.8542 10.208 13.7608 10.208 13.6458C10.208 13.5308 10.1147 13.4375 9.99884 13.4375" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.691 3.44916L18.0735 14.6192C18.8151 15.9175 17.8776 17.5333 16.3826 17.5333H3.61763C2.12179 17.5333 1.18429 15.9175 1.92679 14.6192L8.30929 3.44916C9.05679 2.13999 10.9435 2.13999 11.691 3.44916Z" fill="#2F80ED" fillOpacity="0.1" stroke="#2F80ED" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">Incidents</span>
                    </NavLink>
                </li>
                <li className="sidebar-item admin-link">
                    <NavLink to="/superuser/user-manager" className="sidebar-link">
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.3271 7.93615C17.9855 8.59451 17.9855 9.66192 17.3271 10.3203C16.6688 10.9786 15.6013 10.9786 14.943 10.3203C14.2846 9.66192 14.2846 8.59451 14.943 7.93615C15.6013 7.27779 16.6688 7.27779 17.3271 7.93615Z" fill="#7416F7" fillOpacity="0.05"></path>
                                <path d="M17.3271 7.93615C17.9855 8.59451 17.9855 9.66192 17.3271 10.3203C16.6688 10.9786 15.6013 10.9786 14.943 10.3203C14.2846 9.66192 14.2846 8.59451 14.943 7.93615C15.6013 7.27779 16.6688 7.27779 17.3271 7.93615" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.8357 4.92729C12.8494 5.94103 12.8494 7.58462 11.8357 8.59836C10.8219 9.61209 9.17834 9.61209 8.1646 8.59836C7.15086 7.58463 7.15086 5.94103 8.1646 4.92729C9.17833 3.91356 10.8219 3.91356 11.8357 4.92729Z" fill="#7416F7" fillOpacity="0.05"></path>
                                <path d="M11.8357 4.92729C12.8494 5.94103 12.8494 7.58462 11.8357 8.59836C10.8219 9.61209 9.17834 9.61209 8.1646 8.59836C7.15086 7.58463 7.15086 5.94103 8.1646 4.92729C9.17833 3.91356 10.8219 3.91356 11.8357 4.92729" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.05758 7.93615C5.71594 8.59451 5.71594 9.66192 5.05758 10.3203C4.39922 10.9786 3.33182 10.9786 2.67346 10.3203C2.0151 9.66192 2.0151 8.59451 2.67346 7.93615C3.33182 7.27779 4.39922 7.27779 5.05758 7.93615Z" fill="#7416F7" fillOpacity="0.05"></path>
                                <path d="M5.05758 7.93615C5.71594 8.59451 5.71594 9.66192 5.05758 10.3203C4.39922 10.9786 3.33182 10.9786 2.67346 10.3203C2.0151 9.66192 2.0151 8.59451 2.67346 7.93615C3.33182 7.27779 4.39922 7.27779 5.05758 7.93615" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M19.1668 15.8336V14.9202C19.1668 13.7694 18.2343 12.8369 17.0835 12.8369H16.416" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M0.833984 15.8336V14.9202C0.833984 13.7694 1.76648 12.8369 2.91732 12.8369H3.58482" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M14.4499 15.8338V14.4997C14.4499 12.8888 13.1441 11.583 11.5333 11.583H8.46745C6.85661 11.583 5.55078 12.8888 5.55078 14.4997V15.8338" stroke="#7416F7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">User Manager</span>
                    </NavLink>
                </li>
                {/* <li className="sidebar-item admin-link">
                    <NavLink to="/superuser/permissions" className="sidebar-link">
                        <span className="flex sidebar-menu-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.99909 17.5034H3.33173C2.87154 17.5034 2.49805 17.1299 2.49805 16.6697V11.6677C2.49805 11.2075 2.87154 10.834 3.33173 10.834H4.99909C5.45928 10.834 5.83277 11.2075 5.83277 11.6677V16.6697C5.83277 17.1299 5.45928 17.5034 4.99909 17.5034Z" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.8341 14.1687H12.7791C13.1401 14.1687 13.4911 14.052 13.7795 13.8353L15.7937 12.3246C16.2964 11.9478 17 11.9978 17.4444 12.4422V12.4422C17.9379 12.9357 17.9379 13.7352 17.4444 14.2279L15.7161 15.9561C15.251 16.4213 14.6574 16.739 14.0121 16.8682L11.5694 17.3567C11.0851 17.4534 10.5857 17.4418 10.1063 17.3217L7.89706 16.7698C7.63278 16.7031 7.36184 16.6698 7.08922 16.6698H5.83203" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.8341 14.1687H12.0721C12.7699 14.1687 13.3352 13.6035 13.3352 12.9057V12.6531C13.3352 12.0737 12.9408 11.5685 12.3789 11.4284L10.4681 10.9507C10.1572 10.8732 9.8387 10.834 9.51857 10.834V10.834C8.74574 10.834 7.9896 11.0624 7.34683 11.4918L5.83203 12.5013" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.2086 3.47551L7.35287 3.47801C7.13195 3.47801 6.92019 3.56638 6.76429 3.72228L6.07651 4.41007C5.75054 4.73604 5.75054 5.26376 6.07651 5.58889L6.7668 6.27918C6.92269 6.43508 7.13528 6.52345 7.35621 6.52261L11.2111 6.52011L11.2203 6.52095C11.773 7.59389 12.8793 8.3342 14.1707 8.33253C16.0115 8.32753 17.5038 6.83107 17.5038 4.99114C17.5046 3.15037 16.014 1.66142 14.1732 1.66309C12.8868 1.66642 11.7831 2.40757 11.2278 3.47634" fill="#02BC77" fillOpacity="0.1"></path>
                                <path d="M11.2086 3.47551L7.35287 3.47801C7.13195 3.47801 6.92019 3.56638 6.76429 3.72228L6.07651 4.41007C5.75054 4.73604 5.75054 5.26376 6.07651 5.58889L6.7668 6.27918C6.92269 6.43508 7.13528 6.52345 7.35621 6.52261L11.2111 6.52011L11.2203 6.52095C11.773 7.59389 12.8793 8.3342 14.1707 8.33253C16.0115 8.32753 17.5038 6.83107 17.5038 4.99114C17.5046 3.15037 16.014 1.66142 14.1732 1.66309C12.8868 1.66642 11.7831 2.40757 11.2278 3.47634" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M14.1716 5.21744C14.2916 5.21744 14.3883 5.1199 14.3883 4.99985C14.3883 4.8798 14.29 4.78226 14.1707 4.78226C14.0507 4.78226 13.9532 4.8798 13.9532 4.99985C13.9532 5.1199 14.0507 5.21744 14.1716 5.21744" stroke="#02BC77" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="flex-auto px-[10px]">Permissions</span>
                    </NavLink>
                </li> */}
            </ul>
        </> : null }
    </>
  )
}
