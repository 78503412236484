import React, {useEffect, useState} from 'react';

import IconSearchLoupe from "../../assets/img/search-loupe.svg";
import IconDots from "../../assets/img/dots.svg";

import AddUserModal from "../../components/Modals/AddUserModal";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";
import InfoModal from "../../components/Modals/InfoModal";
import {useDispatch, useSelector} from "react-redux";
import {
    adminDeleteUser,
    adminFetchAgencies,
    adminFetchAllUsers,
    adminFetchPositions,
    adminFetchRegions
} from "../../redux/thunks/admin/userManagementThunks";
import {getUsers} from "../../redux/slices/admin/userManagementSlice";

import Table from "../../components/ui/Table";

export const UserManagerAdmin = () => {
    const dispatch = useDispatch()
    const [addUserModalState, setAddUserModalState] = useState({open: false, mode: null, id: null}); // mode: add | show | edit
    const [confirmDeleteModalState, setConfirmDeleteModalState] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('')
    const [debouncedValue, setDebouncedValue] = useState('');
    const [userFiltered, setUserFiltered] = useState(null);



    const allUsers = useSelector(getUsers)

    const initialData = allUsers ? allUsers : [];
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (debouncedValue) {
            setUserFiltered([...allUsers].filter(el => el.full_name.toLowerCase().includes(debouncedValue)))
            setTableData([...allUsers].filter(el => el.full_name.toLowerCase().includes(debouncedValue)))
        } else {
            setUserFiltered(allUsers && allUsers.length ? allUsers : null)
            setTableData(allUsers && allUsers.length ? allUsers : [])
        }
        
    }, [debouncedValue, allUsers]);
    

    useEffect(() => {
        dispatch(adminFetchAllUsers())
        dispatch(adminFetchPositions())
        dispatch(adminFetchRegions())
        dispatch(adminFetchAgencies())
    }, []);

    const onCreateSuccess = () => {
        setInfoModalConfig({
            icon: require('../../assets/img/updated-img.png'),
            title: 'User Created',
            subtitle: 'User create successfully'
        });
        setInfoModalOpen(true);
    }

    const onUpdatedSuccess = () => {
        setInfoModalConfig({
            icon: require('../../assets/img/updated-img.png'),
            title: 'User Updated',
            subtitle: 'User update successfully'
        });
        setInfoModalOpen(true);
    }

    

    useEffect(() => {
        const debounceDelay = setTimeout(() => {
            setDebouncedValue(searchVal);
        }, 500)

        return () => {
            clearTimeout(debounceDelay)
        };
    }, [searchVal]);

    // Deleted Success
    // {
    //     icon: require('../../assets/img/updated-img.png'),
    //     title: 'Deleted Success',
    //     subtitle: 'John Doe user detail deleted successfully'
    // }
    // Password Reset Email Sent
    // {
    //     icon: require('../../assets/img/new-mail.png'),
    //     title: 'Password Reset Email Sent',
    //     subtitle: 'Please check your inbox to find your Password Reset Link'
    // }
    // Updated Success
    // {
    //     icon: require('../../assets/img/updated-img.png'),
    //     title: 'Updated Success',
    //     subtitle: 'Your data has been updated successfully'
    // }


    const actionView = (value) => {
        // console.log('value:', value);
        setAddUserModalState({ open: true, mode: 'show', id: value });
    }
    const actionEdit = (value) => {
        // console.log('value:', value);
        setAddUserModalState({ open: true, mode: 'edit', id: value });
    }


    const deleteUser = async ()=> {
           let res = await dispatch(adminDeleteUser(confirmDeleteModalState))
            if (res.error) {
                setConfirmDeleteModalState(null);
                setInfoModalConfig({
                    // icon: require(''),
                    title: 'Oops',
                    subtitle: 'We have some problem, please try again later'
                });
                setInfoModalOpen(true);
                return
            }
            setConfirmDeleteModalState(null);
            setInfoModalConfig({
                icon: require('../../assets/img/updated-img.png'),
                title: 'Deleted Success',
                subtitle: 'John Doe user detail deleted successfully'
            });
            setInfoModalOpen(true);
    }

    const [infoModalConfig, setInfoModalConfig] = useState({
        icon: false,
        title: '',
        subtitle: ''
    });

    if (!allUsers) {
        return <div style={{textAlign: 'center'}}>Loading</div>
    }

    return (
        <>
            <div className="bg-gray w-full flex justify-between items-center flex-wrap px-[29px] pt-[20px]">
                <div className="input-custom mb-[20px]" style={{ maxWidth: '300px', width: '100%' }}>
                    <form action="" className="form-search search-border h-[32px]">
                        <button type="submit" className="form-search__btn">
                            <img src={IconSearchLoupe} />
                        </button>
                        <input type="search" className="form-search__input" placeholder="Search..." value={searchVal} onChange={(e) => setSearchVal(e.target.value)} />
                    </form>
                </div>
                <a
                    href="#" className="btn btn--primary btn--lg-2 rounded-[10px] mb-[20px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        setAddUserModalState({open: true, mode: 'add'});
                    }}
                >
                    <span className="ico mr-[8px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.16699V15.8337" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M4.16602 10H15.8327" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    Add User
                </a>
            </div>
            <div className="dash-page-content-item">
                <div className="card-body mb-[24px]">
                    { false && <div className="table-wrapper">
                        <table className="table thead-rounded" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th className="text-left by-sort">Name</th>
                                    <th className="text-left">Email</th>
                                    <th className="text-left">Phone</th>
                                    <th className="text-left by-sort">Agency</th>
                                    <th className="text-left by-sort">Region</th>
                                    <th className="text-left by-sort">State</th>
                                    <th className="text-left by-sort">Positions</th>
                                    <th className="text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userFiltered && userFiltered.length ? userFiltered.map((u, index)=>{
                                        return <tr key={index}>
                                            <td className="text-left">
                                                { u.full_name }
                                            </td>
                                            <td>
                                                { u.email }
                                            </td>
                                            <td className="text-left">
                                                { u.phone }
                                            </td>
                                            <td className="text-left">
                                                { u?.agency?.name || '-' }
                                            </td>
                                            <td className="text-left">
                                                { u?.region?.name || '-' }
                                            </td>
                                            <td className="text-left">
                                                { u?.state?.name || '-' }
                                            </td>
                                            <td className="text-left" style={{ textTransform: "capitalize" }}>
                                                { u.roles.map(item => item.name.split('_').join(' ')).join(', ') || '-' }
                                            </td>
                                            <td className="text-center --sticky" style={{ zIndex: dropdownOpen === index ? '2' : '1' }}>
                                                <div className="event-action">
                                                    <div className={`dropdown ${dropdownOpen === index ? 'dropdown-open' : ''}`} data-dropdown="dropdown">
                                                        <a href="#" className="btn btn-dropdown" data-role="button" onClick={(e)=>{
                                                            e.preventDefault();
                                                            setDropdownOpen(dropdownOpen === index ? null : index);
                                                        }}>
                                                            <span className="ico ico-20 justify-center">
                                                                <img src={IconDots} />
                                                            </span>
                                                        </a>
        

                                                        <div className={`dropdown__body dropdown__custom ${dropdownOpen === index ? 'is-open' : ''}`} data-role="dropdown" style={{right: '40px'}}>
                                                            <ul className="dropdown-list whitespace-nowrap p-[6px] rounded-[8px]">
                                                                <li className="dropdown-list--item">
                                                                    <a href="#" className="dropdown-link pr-[40px]" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setAddUserModalState({open: true, mode: 'show', id: u.id});
                                                                        setDropdownOpen(false);
                                                                    }}>
                                                                        <span className="ico ico-12 mr-[8px]">
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </span>
                                                                        <span>
                                                                            View
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                                <li className="dropdown-list--item">
                                                                    <a href="#" className="dropdown-link pr-[40px]" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setAddUserModalState({open: true, mode: 'edit', id: u.id});
                                                                        setDropdownOpen(false);
                                                                    }}>
                                                                        <span className="ico ico-12 mr-[8px]">
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </span>
                                                                        <span>
                                                                            Edit
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                                <li className="dropdown-list-line">
                                                                    <span></span>
                                                                </li>
                                                                <li className="dropdown-list--item">
                                                                    <a href="#" className="dropdown-link pr-[40px]" onClick={(e)=>{
                                                                        e.preventDefault();
                                                                        setConfirmDeleteModalState({open: true, id: u.id});
                                                                        setDropdownOpen(false);
                                                                    }}>
                                                                        <span className="ico ico-12 mr-[8px]">
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
        
                                                                        </span>
                                                                        <span className="text-[#EA5E42]">
                                                                            Delete
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }) : null}
                            </tbody>
                        </table>
                        {!userFiltered?.length ? <div style={{textAlign: 'center', marginTop: '25px'}}>No Users Found</div> : null}
                    </div> }
                    
                    <Table 
                        initialData={initialData}
                        setSortedData={setTableData}
                        tHead={[
                            {
                                content: 'Name',
                                sortField: 'full_name',
                                show: true
                            },
                            {
                                content: 'Email',
                                show: true
                            },
                            {
                                content: 'Phone',
                                show: true
                            },
                            {
                                content: 'Agency',
                                sortField: 'agency.name',
                                show: true
                            },
                            {
                                content: 'Region',
                                sortField: 'region.name',
                                show: true
                            },
                            {
                                content: 'State',
                                sortField: 'state.name',
                                show: true
                            },
                            {
                                content: 'Positions',
                                sortField: 'roles',
                                show: true
                            },
                        ]}
                        tBody={
                            tableData.map((item, index)=>{
                                return [
                                    item.id,
                                    item.full_name,
                                    item.email,
                                    item.phone,
                                    item?.agency?.name || '-',
                                    item?.region?.name || '-',
                                    item?.state?.name || '-',
                                    <div style={{ maxWidth: "400px", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                        { item.roles.map(item => item.name.split('_').join(' ')).join(', ') || '-' }
                                    </div>,
                                ]
                            })
                        }
                        actions={[
                            {
                                onClick: actionView,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.83398 17.5H14.1673C15.548 17.5 16.6673 16.3807 16.6673 15V6.97639C16.6673 6.31335 16.4039 5.67747 15.9351 5.20862L13.9587 3.23223C13.4899 2.76339 12.854 2.5 12.1909 2.5H5.83398C4.45327 2.5 3.33398 3.61929 3.33398 5V15C3.33398 16.3807 4.45327 17.5 5.83398 17.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 14.1667H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M13.3327 11.25H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16602 8.33333H6.66602" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M16.6673 7.08333H13.7507C12.8302 7.08333 12.084 6.33714 12.084 5.41667V2.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        View
                                    </span>
                                </>
                            },
                            {
                                onClick: actionEdit,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.41018 13.0158L13.0152 4.41083C13.3402 4.08583 13.8677 4.08583 14.1927 4.41083L15.5893 5.8075C15.9143 6.1325 15.9143 6.66 15.5893 6.985L6.98352 15.5892C6.82768 15.7458 6.61602 15.8333 6.39518 15.8333H4.16602V13.6042C4.16602 13.3833 4.25352 13.1717 4.41018 13.0158Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.459 5.96667L14.034 8.54167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </>
                            },
                            {
                                listLine: true,
                            },
                            {
                                onClick: setConfirmDeleteModalState,
                                content: <>
                                    <span className="ico ico-12 mr-[8px]">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8267 4.88617 15.9558L4.13867 5.83335H15.8337L15.1112 15.9517C15.0487 16.8242 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0007 9.16668V14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.33398 5.83335H16.6673" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.1673 5.83335L13.3232 3.58168C13.079 2.93085 12.4573 2.50002 11.7623 2.50002H8.23898C7.54398 2.50002 6.92232 2.93085 6.67815 3.58168L5.83398 5.83335" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.8576 9.16668L12.4992 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.1418 9.16668L7.50013 14.1667" stroke="#EA5E42" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>

                                    </span>
                                    <span className="text-[#EA5E42]">
                                        Delete
                                    </span>
                                </>
                            }
                        ]}
                    />
                </div>
            </div>

            { addUserModalState?.open ? <AddUserModal
                open={addUserModalState.open}
                close={()=>{setAddUserModalState({open: false, mode: null, id: null})}}
                onSuccess={addUserModalState.id ? onUpdatedSuccess : onCreateSuccess}
                mode={addUserModalState.mode}
                userId={addUserModalState.id}
                onError={(message) => {
                    setInfoModalConfig({
                        // icon: require(''),
                        title: 'Oops',
                        subtitle: message ? message : 'We have some problem, please try again later'
                    });
                    setInfoModalOpen(true);
                }}
            /> : null }

            <ConfirmDeleteModal
                open={confirmDeleteModalState}
                close={()=>{setConfirmDeleteModalState(null)}}
                submit={deleteUser}
            />

            <InfoModal
                icon={infoModalConfig.icon}
                title={infoModalConfig.title}
                subtitle={infoModalConfig.subtitle}
                open={infoModalOpen}
                close={()=>{setInfoModalOpen(false)}}
                submit={()=>{setInfoModalOpen(false)}}
            />
        </>
    )
}
