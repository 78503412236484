import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import EmptyAvatar from '../../assets/img/empty-avatar.svg';

import { getProfileData } from "../../redux/slices/settingsSlice";
import { getProfile, updateProfile } from "../../redux/thunks/settingsThunks";

export const MyProfile = () => {
    const dispatch = useDispatch();

    const [profileData, setProfileData] = useState({
        id: null,
        avatar: null,
        name: "",
        surname: "",
        organization: "",
        email: "",
        phone: "",
    })

    const [avatarLoading, setAvatarLoading] = useState(false);
    const [deleteAvatarLoading, setDeleteAvatarLoading] = useState(false);
    const [saveChangesLoading, setSaveChangesLoading] = useState(false);

    const gettedProfileData = useSelector(getProfileData);

    const fetchProfileData = async() => {
        await dispatch(getProfile());
    }

    const [errorEmail, setErrorEmail] = useState(false)

    const updateProfileAction = async(payload) => {

        const emailValidator = (val) => /\S+@\S+\.\S+/.test(val)

        if (!emailValidator(payload.email)) {
            setErrorEmail(true)
            return
        }

        setSaveChangesLoading(true);
        await dispatch(updateProfile({
            id: profileData.id,
            payload
        }));

        fetchProfileData();
        setSaveChangesLoading(false);
    }

    const changeAvatar = async(e) => {
        setAvatarLoading(true);
        await updateProfileAction({
            ...profileData,
            avatar: e.target.files[0],
        });
        setAvatarLoading(false);
    }

    useEffect(()=>{
        fetchProfileData();
    }, []);

    useEffect(()=>{
        if(gettedProfileData){
            setProfileData({
                id: gettedProfileData.id,
                avatar: gettedProfileData.avatar,
                name: gettedProfileData.name,
                surname: gettedProfileData.surname,
                organization: gettedProfileData.organization,
                email: gettedProfileData.email,
                phone: gettedProfileData.phone,
            });
        }
    }, [gettedProfileData]);

    return (
        <>
            <div className="settings-page-container">
                <h3 className="settings-page-main-title">General Settings</h3>
                <p className="settings-page-subtitle">Manage your personal information and contacts.</p>
                <div className="avatar-block d-flex align-items-center mb-[32px]">
                    <div className="profile-avatar mr-[24px]">
                        <img src={profileData?.avatar ? profileData.avatar : EmptyAvatar} />
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                        <label className="btn btn--primary btn--lg rounded-[8px] cursor-pointer m-[4px] relative">
                            Upload New Picture
                            {
                                avatarLoading ? <div className="btn-loader">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="currentColor" />
                                        <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="currentColor" className="spinner_ajPY"/>
                                    </svg>
                                </div> : null
                            }
                            <input type="file" name="" id="" className="d-none" onChange={(e)=>{ changeAvatar(e); }} />
                        </label>
                        <a href="#" className="btn btn--outline-default btn--lg rounded-[8px] m-[4px] relative" onClick={async (e)=>{
                            e.preventDefault();
                            setDeleteAvatarLoading(true);
                            await updateProfileAction({
                                ...profileData,
                                avatar: null,
                            });
                            setDeleteAvatarLoading(false);
                        }}>
                            {
                                deleteAvatarLoading ? <div className="btn-loader">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="currentColor" />
                                        <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="currentColor" className="spinner_ajPY"/>
                                    </svg>
                                </div> : null
                            }
                            Delete
                        </a>
                    </div>
                </div>
                <div className="row gutter-x-16">
                    <div className="col-12 col-lg-6">
                        <div className="input-custom input-border mb-[24px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">First Name</label>
                            <input
                                className="input-custom__input" type="text" placeholder="Enter your first name..."
                                value={profileData?.name} onInput={(e)=>{ setProfileData((prevState)=>{return {...prevState, name: e.target.value }}) }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="input-custom input-border mb-[24px]">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Last Name</label>
                            <input
                                className="input-custom__input" type="text" placeholder="Enter your last name..."
                                value={profileData?.surname} onInput={(e)=>{ setProfileData((prevState)=>{return {...prevState, surname: e.target.value }}) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-custom input-border mb-[24px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Organization</label>
                    <input
                        className="input-custom__input" type="text" placeholder="Enter your organization..."
                        value={profileData?.organization} onInput={(e)=>{ setProfileData((prevState)=>{return {...prevState, organization: e.target.value }}) }}
                    />
                </div>
                <div className="input-custom input-border mb-[24px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Email</label>
                    <input
                        className={`input-custom__input ${errorEmail && 'error-field'}`} type="text" placeholder="Enter your email..."
                        value={profileData?.email} onInput={(e)=>{ setProfileData((prevState)=>{return {...prevState, email: e.target.value }}); setErrorEmail(false) }}
                    />
                    <p className="error-field-text">{errorEmail ? 'Enter a valid email' : ''}</p>
                </div>
                <div className="input-custom input-border mb-[24px]">
                    <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Phone Number</label>
                    <input
                        className="input-custom__input" type="text" placeholder="Enter your phone number..."
                        value={profileData?.phone} onInput={(e)=>{ setProfileData((prevState)=>{return {...prevState, phone: (e.target.value).replace(/\D/g, '') }}) }}
                    />
                </div>
                <div className="d-flex justify-end">
                    <a href="#" className="btn btn--primary btn--lg rounded-[8px] relative" onClick={(e)=>{
                        e.preventDefault();
                        updateProfileAction({
                            name: profileData.name,
                            surname: profileData.surname,
                            organization: profileData.organization,
                            email: profileData.email,
                            phone: profileData.phone,
                        });
                    }}>
                        Save Changes
                        {
                            saveChangesLoading ? <div className="btn-loader">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" fill="currentColor" />
                                    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" fill="currentColor" className="spinner_ajPY"/>
                                </svg>
                            </div> : null
                        }
                    </a>
                </div>
            </div>
        </>
    )
}
