import * as yup from 'yup';

const contactSupportSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    name: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    phone: yup
        .number()
        .typeError('Must be a number')
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    subject: yup
        .string()
        .required('Is a required field')
        .min(2, 'Min 2 characters'),
    message: yup
        .string()
        .required('Is a required field')
        .min(5, 'Min 5 characters'),
})

export default contactSupportSchema;
