import React, { useEffect, useState } from 'react'
import FullHeightModal from "./FullHeightModal";
import IconPlusAdd from "../../assets/img/plus-add.svg";
import IconCalendarSchedule from "../../assets/img/icon-calendar-schedule.svg";

const RRFAddResourcesModal = ({ open, close, data, setData }) => {
    const [localResources, setLocalcResources] = useState(
        data?.resources?.length ? 
        data?.resources.pop()?.types.map(item => {return {"source": item.type, "checked": item.checked}}) :
        [{
            "source": "Donations",
            "checked": false
        },
        {
            "source": "Requisitions",
            "checked": false
        },
        {
            "source": "Procurement",
            "checked": false
        },
        {
            "source": "Interagency Agreement",
            "checked": false
        },
        {
            "source": "Missions Assignment",
            "checked": false
        },
        {
            "source": "Other",
            "checked": false
        },
    ]);

    const [otherExplanation, setOtherExplanation] = useState(data?.resources?.length ? data.resources.pop().explanation : "");
    
    const save = () => {
        setData((prevState) => {
            return {
                ...prevState, 
                resource_types: localResources,
                other_explanation: otherExplanation,
            }
        });

        closeModal();
    }

    // const [localResources, setLocalcResources] = useState(data?.resources?.length ? data?.resources[0] : {
    //     "id": 1,
    //     "request_id": data?.id,
    //     "types": [],
    //     "explanation": "other_explanation"
    // });
    
    // const save = () => {
    //     setData((prevState) => {
    //         return {...prevState, resources: [localResources]}
    //     });

    //     closeModal();
    // }

    const closeModal = () => {
        close();
    }

    return (
        <FullHeightModal
            title="Sourced as"
            headerIcon={IconPlusAdd}
            open={open}
            close={closeModal}
            submitBtnText={"Save"}
            submitMethod={save}
        >
            <div className="row">
                {
                    localResources.map((item, index)=> {
                        return <div className="col-12 col-lg-6" key={index}>
                            <label className="custom-checkbox checkbox-border mb-[20px]">
                                <input
                                    type="checkbox" className="custom-checkbox__input"
                                    value={item.source} checked={item.checked}
                                    onChange={(e)=>{
                                        setLocalcResources((prevState)=>{
                                            let copied = [...prevState];
                                            copied[index].checked = e.target.checked
                                            return copied;
                                        })
                                    }}
                                />
                                <span className="custom-checkbox__input-fake"></span>
                                <span className="custom-checkbox__label">{item.source}</span>
                            </label>
                        </div>
                    })
                }
                {/* {
                    [
                        "Donations",
                        "Requisitions",
                        "Procurement",
                        "Interagency Agreement",
                        "Missions Assignment",
                        "Other",
                    ].map((item, index)=> {
                        return <div className="col-12 col-lg-6" key={index}>
                            <label className="custom-checkbox checkbox-border mb-[20px]">
                                <input
                                    type="checkbox" className="custom-checkbox__input"
                                    value={item} checked={localResources.types.map(itemM=>itemM.type).includes(item)}
                                    onChange={(e)=>{
                                        setLocalcResources((prevState)=>{
                                            return {
                                                ...prevState,
                                                types: e.target.checked ? [...prevState.types, {
                                                    id: index+1,
                                                    resource_id: data?.id,
                                                    type: item,
                                                }] : prevState.types.filter(itemF => itemF.type !== item)
                                            }
                                        })
                                    }}
                                />
                                <span className="custom-checkbox__input-fake"></span>
                                <span className="custom-checkbox__label">{item}</span>
                            </label>
                        </div>
                    })
                } */}

                <div className="col-12">
                    <div data-id="other">
                        <div className="input-custom">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Other</label>
                            <textarea className="input-custom__textarea" rows="5" placeholder="Other explanations"
                                value={otherExplanation}
                                onInput={(e) => {
                                    setOtherExplanation(e.target.value);
                                }}
                            ></textarea>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12">
                    <div data-id="other">
                        <div className="input-custom">
                            <label className="input-custom__label text-[13px] font-[600] mb-[10px] opacity-70">Other</label>
                            <textarea className="input-custom__textarea" rows="5" placeholder="Other explanations"
                                value={data?.other_explanation}
                                onInput={(e) => {
                                    setData((prevState) => {
                                        return {...prevState, other_explanation: e.target.value}
                                    })
                                }}
                            ></textarea>
                        </div>
                    </div>
                </div> */}
            </div>
        </FullHeightModal>
    )
}

export default RRFAddResourcesModal;