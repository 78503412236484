import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {fetchClearAllNotifications, fetchNotifications, fetchRemoveNotifications} from "../thunks/notificationThunks";


const initialState = {
    data: null
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchNotifications.pending, (state) => {
            return {
                data: null,
            }
        })
        builder.addCase(fetchNotifications.fulfilled, (state, action) => {
            // return {
            //     data: action.payload.data
            // }
            return {
                data: [ ...action.payload.data, {
                    "id": 1,
                    "title": "Notification: type_1",
                    "context": "I can't be deleted, I will appear on every reboot, that's ok",
                    "date": "Jul 05,2023"
                }
                ]
            }
        })
        builder.addCase(fetchNotifications.rejected, (state) => {
            return {
                data: null
            }
        })
        builder.addCase(fetchRemoveNotifications.fulfilled, (state, action) => {
            const newData = state.data.filter(el => el.id !== action.payload.id)
            return {
                data: newData,
            }
        })
        builder.addCase(fetchClearAllNotifications.fulfilled, (state) => {
            return {
                data: null
            }
        })
        builder.addMatcher(isAnyOf(fetchRemoveNotifications.rejected, fetchClearAllNotifications.rejected), (state) => {
            return {
                ...state
            }
        })
    }
})

export const {  } = notificationSlice.actions

export const getAllNotifications = (state) => state.notifications.data

export default notificationSlice.reducer