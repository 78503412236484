import React, { useState, useEffect } from 'react'
import { Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Line } from 'react-chartjs-2';

import Map from "../../components/ui/Map"
import Accordeon from "../../components/ui/Accordeon"

import IcoEnergy from "../../assets/img/ico-energy.png";
import IcoTransportation from "../../assets/img/ico-transportation.png";
import IcoSafety from "../../assets/img/ico-safety.png";
import IcoHealth from "../../assets/img/ico-health.png";
import IcoHazardous from "../../assets/img/ico-hazardous.png";
import IcoFood from "../../assets/img/ico-food.png";
import IcoCommunications from "../../assets/img/ico-communications.png";

import { useDispatch, useSelector } from "react-redux";
import { fetchRequests, fetchRequestOptions } from "../../redux/thunks/resourceRequestThunks";
import { getResourceRequests, getResourceRequestOptions } from "../../redux/slices/resourceRequestSlice";
import { getSelectedIncident } from "../../redux/slices/incidentsSlice";

import { incidents } from '../../api/index';

import { ReadClose } from "../../helpers/checkPermission";

export const IncidentOverview = () => {
    const localReadClose = (name, navigateActive) => {
        return ReadClose(name, navigateActive)
    }

    const dispatch = useDispatch();

    const selectedIncident = useSelector(getSelectedIncident);
    const resourceRequestData = useSelector(getResourceRequests);
    
    const resourceRequestOptions = useSelector(getResourceRequestOptions);

    const [supportPlanText, setSupportPlanText] = useState('');
    const [situationAwarenessText, setSituationAwarenessText] = useState('');

    const markers = resourceRequestData?.filter(item => item.latitude.includes(".")).map(item => {
        return {
            latitude: item.latitude,
            longitude: item.longitude,
        }
    })

    ChartJS.register(ChartDataLabels)

    const requestsSatesIds = resourceRequestData?.map(item => item.state_id);

    const filteredStates = resourceRequestOptions?.states?.filter(item => requestsSatesIds?.includes(item.id)).map(item => {
        return {
            name: item.name,
            value: requestsSatesIds?.filter(itemF => itemF === item.id).length
        }    
    });

    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const generateDate = (date) => {
        return `${shortMonthNames[new Date(date).getMonth()]} ${new Date(date).getDate()}`
    }

    const [byStatusData, setByStatusData] = useState(null);
    const [lineData, setLineData] = useState(null);
    const [lineChartKey, setLineChartKey] = useState(0);

    const byStatusGenerate = () => {
        let dates = [];
        let dataLines = {};

        if(resourceRequestData){
            for (let index = 0; index < resourceRequestData.length; index++) {
                if(!dates.length || (!dates.find(item => item.date === generateDate(resourceRequestData[index].created_at) && item.status === resourceRequestData[index].status))){
                    dates.push({
                        date: generateDate(resourceRequestData[index].created_at),
                        status: resourceRequestData[index].status,
                        value: 1
                    })
                } else {
                    dates.find(item => item.date === generateDate(resourceRequestData[index].created_at)).value++;
                }
            }
            
            // заполняем объект статус именами
            [...new Set(dates.map(item => item.status))].forEach(item => {
                dataLines[item] = [];
            });
    
            [...new Set(dates.map(item => item.date))].forEach(dateItem => {
                // фильтруем данные по дате
                let filteredDataItem = dates.filter(item => item.date === dateItem);
                // виды статусов
                let statusNames = [...new Set(dates.map(item => item.status))];

                for (let index = 0; index < statusNames.length; index++) {
                    let findedByStatus = filteredDataItem.find(itemF => itemF.status === statusNames[index]);
                    // если нашли данные по такому же статусу в данной дате, то пушим количество rrf, если нет то 0
                    if(findedByStatus){
                        dataLines[statusNames[index]].push(findedByStatus.value)
                    } else {
                        dataLines[statusNames[index]].push(0);
                    }
                }
            })
        }

        setByStatusData({
            dates,
            dataLines,
        });

        setLineData({
            labels: [...new Set(dates.map(item => item.date))],
            datasets: Object.entries(dataLines).map(item => {
                return {
                    data: item[1],
                    backgroundColor: "transparent",
                    borderColor: statusColors[item[0]],
                    tension: 0,
                }
            })
        });

        setLineChartKey((prevState) => ++prevState);
    }

    const barChartData = {
        month_array: filteredStates?.map(item => item.name),
        data_bar: filteredStates?.map(item => item.value),
        options: {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                point: {
                    hoverBorderWidth: 0,
                    borderWidth: 0,
                    hoverRadius: 0
                },
                line: {
                    borderWidth: 2
                }
            },
            plugins: {
                ChartDataLabels,
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
               },
               datalabels: {
                    family: "'Lato', sans-serif",
                    color: "rgba(18, 19, 39, 0.6)",
                    size: 12,
                    weight: 600,
                    anchor: "end",
                    align: "top",
                },
            },
            scales: {
                x: {
                    min: 0,
                    max: 25,
                    grid: {
                        drawTicks: false,
                        display: false,
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                    },
                    
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 12,
                        stepSize: 1.0,
                        font: {
                            color: "rgba(18, 19, 39, 0.6)",
                            size: 12,
                            weight: 400,
                            family: "'Lato', sans-serif"
                        }
                    }
                    
                },
                
                y: {
                    min: 0,
                    max: 10,
                    grid: {
                        drawTicks: false,
                        drawBorder: false,
                        color: (context) => {
                            if(context.index === 0){
                                return "";
                            }else{
                                return 'rgba(0, 0, 0, 0.1)';
                            }
                        },
                        zeroLineWidth: 1,
                    },
                    border: {
                        dash:[5,5]
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 12,
                        stepSize: 5,
                        font: {
                            color: "rgba(18, 19, 39, 0.6)",
                            size: 12,
                            weight: 400,
                            family: "'Lato', sans-serif"
                        }
                    }
                }
            },
        }
    }

    const statusColors = {
        'in_progress': '#2F80ED',
        'not_started': '#B3B5D5',
        'completed': '#02BC77',
        'pending': '#F59E0C',
        'canceled': '#EA5E42',
    }

    const lineChartDataOption = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                borderWidth: 2
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += resourceRequestOptions?.statuses[Object.entries(statusColors).find(item => item[1] === context.dataset.borderColor)[0]] + `: ${context.parsed.y}`;
                        }
                        return label;
                    }
                }
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            x: {
                min: 0,
                // max: 100,
                grid: {
                    drawTicks: false,
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    padding: 12,
                    stepSize: 1.0,
                    font: {
                        color: "rgba(18, 19, 39, 0.6)",
                        size: 12,
                        weight: 400,
                        family: "'Lato', sans-serif"
                    }
                },
                
            },
            y: {
                min: 0,
                // max: 100,
                grid: {
                    drawBorder: false,
                    drawTicks: false,
                    
                    color: (context) => {
                        if(context.index === 0){
                            return "";
                        }else{
                            return 'rgba(0, 0, 0, 0.1)';
                        }
                    },
                    zeroLineWidth: 1,
                },
                border: {
                    dash:[5,5],
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    padding: 12,
                   
                    stepSize: 25,
                    font: {
                        color: "rgba(18, 19, 39, 0.6)",
                        size: 12,
                        weight: 400,
                        family: "'Lato', sans-serif"
                    }
                }
            }
        }
    };



    const barData = {
        labels: barChartData.month_array,
        datasets: [
            {
                data: barChartData.data_bar,
                backgroundColor: [
                    '#FF9800',
                    '#2F80ED',
                    '#FF9800',
                    '#2F80ED',
                    '#FF9800',
                    '#2F80ED'
                ],
                borderRadius: 8,
                barPercentage: 0.50,
                categoryPercentage: 1,
            },
        ]
    }

    const fetchResourceRequest = async () => {
        try {
            await dispatch(fetchRequests(selectedIncident?.id));
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTopTexts = async(id) => {
        const resSupportPlan = await incidents.getIncidentSupportPlan(id);
        const resSituationAwareness = await incidents.getSituationAwareness(id);

        setSupportPlanText(resSupportPlan?.data?.data.plan.description);
        setSituationAwarenessText(resSituationAwareness?.data?.data?.description);
    }

    useEffect(()=>{
        if(selectedIncident?.id || (selectedIncident?.id && !resourceRequestData)){
            fetchResourceRequest();
        }

        if(selectedIncident?.id){
            fetchTopTexts(selectedIncident?.id)
        }

        if(!resourceRequestOptions?.statuses){
            dispatch(fetchRequestOptions());
        }
    },[selectedIncident])

    useEffect(()=>{
        byStatusGenerate();
    },[])

    useEffect(()=>{
        byStatusGenerate();
    },[resourceRequestData])

    return (
        <div className="dash-page-content-item">
            
            <div className="row guttres-20">
                <div className="col-xl-12 col-lg-12 col-12">
                    <ul className="list-feature mb-[-12px]">
                        {
                            [
                                IcoEnergy, IcoTransportation, IcoSafety, IcoHealth,
                                IcoHazardous, IcoFood, IcoCommunications
                            ].map((item, index)=>{
                                return <li className="list-feature--item mb-[12px]" key={index}>
                                <span className="icon-feature icon-feature--60">
                                    <span className="icon-wrapp">
                                        <img src={item} />
                                    </span>
                                </span>
                            </li>
                            })
                        }
                    </ul>
                    <div className="flex flex-col mt-[20px] mb-[-8px]">
                        {
                            localReadClose('support-plan', false) ? <Accordeon 
                                title="Planning BLUF" 
                                content={supportPlanText}
                                // initOpen={true}
                                htmlContent={true}
                            /> : null
                        }
                        {
                            localReadClose('situational-awareness', false) ? <Accordeon 
                                title="Situational Awareness BLUF" 
                                content={situationAwarenessText}
                                htmlContent={true}
                            /> : null
                        }
                    </div>
                    <div className="mt-[20px]">
                        <div className="chart-block" style={{maxHeight:'460px', height:'460px'}}>
                            <div className="chart-header p-[16px] pb-[24px]">
                                <span className="def-text-2 font-[600]">
                                    Resource Request by Status (+)
                                </span>
                            </div>
                            { byStatusData?.dataLines && resourceRequestOptions?.statuses && lineData ? <>
                                <div className="chart-body px-[16px] pb-[20px]" style={{height:'calc(100% - (60px + 52px))'}} key={lineChartKey}>
                                    <Line
                                        data={lineData}
                                        options={lineChartDataOption}
                                        width='1004'
                                        height="326" 
                                        style={{display: 'block', boxSizing: 'border-box', width: '1004px', height: '326px'}}
                                    />
                                </div>
                                <div className="chart-footer p-[16px]">
                                    <ul className="chart-list mb-[-8px]">
                                        {
                                            Object.entries(byStatusData.dataLines).map(item => item[0]).map(item => {
                                                return <li className="chart-list--item mb-[8px]">
                                                    <div className="label-chart">
                                                        <span className="chart-status mr-[8px]" style={{ backgroundColor: statusColors[item] }}></span>
                                                        { resourceRequestOptions?.statuses?.[item] }
                                                    </div>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </> : <div className="px-[16px] pb-[20px]">
                                <h3>Loading...</h3>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12 flex flex-col">
                    <div className="chart-block w-full h-full">
                        <div className="chart-header p-[16px] pb-[24px]">
                            <span className="def-text-2 font-[600]">
                                Resource Request by States
                            </span>
                        </div>
                        { resourceRequestData && resourceRequestOptions ? <div className="chart-body px-[16px] pb-[20px]">
                            <Bar
                                data={barData}
                                options={barChartData.options}
                                width="475"
                                height="237"
                                style={{display: 'block', boxSizing: 'border-box', width: '475px', height: '237px'}}
                            />
                        </div> : 
                        <div className="chart-body px-[16px] pb-[20px]">
                            <h3>Resource Request List Is Empty</h3>
                        </div> }
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12 flex flex-col" key={markers?.length}>
                    {
                        markers ? <Map lat={markers[0]?.latitude} lng={markers[0]?.longitude} markers={markers} /> : null
                    }
                </div>
            </div>
        </div>
    )
}
